<!--
    * 自定义头部布局
    * @date 2023/01/04 16:48:18
    * @author haowei
-->
<template>
  <div id="header-custom">
    <div class="header">
      <div class="header_text">
        <div class="logo">
          <img src="@/assets/img/layout/szbg.png" alt/>
        </div>
        <span>智慧机房数字孪生运维系统</span>
      </div>
    </div>
    <div class="header_right">
      <div class="function">
        <div
            class="item_fun"
            v-for="(item, index) in imgFun"
            :key="index"
            :class="funIndex == index ? 'item_fun_ac' : ''"
            @click="funInteraction(index, item)"
        >
          <img :src="item.img" :title="item.text"/>
        </div>
      </div>
      <Clock/>
      <div class="user"></div>
    </div>
  </div>
</template>

<script>
import Clock from "@/components/common/Clock";
import StateManager from "@/core/StateManager";
import SpaceController from "@/core/logic/SpaceController";

export default {
  name: "HeaderCustom",
  components: {
    Clock
  },
  data: () => {
    return {
      imgFun: [
        {
          img: require('../../assets/img/layout/zhishu.png'),
          text: '数据分析',
        },
        {
          img: require('../../assets/img/layout/yingyong.png'),
          text: '对象管理',
        },
        {
          img: require('../../assets/img/layout/gaojing.png'),
          text: '实时告警',
        },
        {
          img: require('../../assets/img/layout/search.png'),
          text: '高级搜索',
        },
        {
          img: require('../../assets/img/layout/view.png'),
          text: '演示汇报',
        },
        {
          img: require('../../assets/img/layout/earth.png'),
          text: '返回地球',
        },
      ],
      funIndex: -1,
    }
  },
  methods: {
    // 顶部legend点击事件
    funInteraction(index, item) {
      if (this.funIndex == index) {
        this.funIndex = -1
        this.$store.commit('setFunctionStatus','')
        this.$store.commit('setLeftType','')
        this.$store.commit('setRightType','')
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo();
        window.stateManager.switchState(StateManager.STATES[`standardLv${nowSpaceLevelInfo.lv}`].key)
        return
      }
      this.funIndex = index
      this.$store.commit('setFunctionStatus',item.text)

      this.handleSwitch(item)
    },
    handleSwitch(item){
      switch (item.text){
        case '数据分析':
          this.$store.commit('setRightType','')
          window.stateManager.switchState(StateManager.STATES.analysis.key)
          break
        case '对象管理':
          this.$store.commit('setLeftType','DuiXiangGuanLi')
          break
        case '实时告警':
          this.$store.commit('setRightType','')
          this.$store.commit('setLeftType','ShiShiGaoJingleft')
          break
        case '高级搜索':
          break
        case '演示汇报':
          break
        case '返回地球':
          break
        default:break
      }
    }
  }
}
</script>

<style lang="less" scoped>
#header-custom {
  height: 100px;
  width: 100%;
  z-index: 10;
  background: #12202e;
  position: absolute;
  pointer-events: all;

  .header {
    .temp {
      position: absolute;
      top: 10px;
      left: 800px;
      display: flex;

      div {
        padding: 0 5px;
        color: #fff;
        font-size: 20px;
      }
    }

    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    height: 100px;

    box-sizing: border-box;

    .header_text {
      .logo {
        height: 58px;
        padding-top: 2px;
      }

      margin-left: 40px;
      width: 730px;
      height: 100px;
      line-height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: '微软雅黑';
      font-size: 54px;
      color: #ffffff;
      font-weight: 700;
    }
  }

  .header_right {
    width: 890px;
    height: 100px;
    display: flex;
    position: absolute;
    right: 20px;
    top: 0px;

    .function {
      display: flex;
      height: 100px;

      .item_fun {
        width: 80px;
        line-height: 100px;
        position: relative;
        text-align: center;
        cursor: pointer;

        img {
          vertical-align: middle;
        }
      }

      .item_fun::after {
        content: '';
        display: block;
        width: 2px;
        height: 60px;
        position: absolute;
        top: 20px;
        right: 2px;
        background-color: #ffffff25;
      }

      .item_fun_ac {
        background: #189bfa;
      }
    }

    .user {
      width: 240px;
      height: 100px;
      background: url('../../assets/img/layout/admin.png') no-repeat center center;
    }
  }
}
</style>
