export default class Area {
  // 添加区域图
  static async addArea(params) {
    let defaultOption = {
      id: 'Area',
      name: '区域轮廓',
      coordType: 0,
      coordTypeZ: 0,
      coordZ: 64,
      type: 'Segment03',
      color: '#00008C',
      areaHeight: 150,
      fillArea: 'Gradient01',
      fillPosition: 'top',
      tag: '',
      points: []
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('addArea', jsonData, res => {
      // console.log(`jsonData`, jsonData, res)
    })
  }
  // 更新区域图坐标
  static async updateAreaCoord(params) {
    let defaultOption = {
      id: 'Area',
      name: '区域轮廓',
      coordType: 0,
      coordTypeZ: 0,
      coordZ: 60,
      tag: '',
      points: []
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('updateAreaCoord', jsonData, res => {
      // console.log(`updateAreaCoord`, jsonData, res)
    })
  }
  // 更新区域图样式
  static updateAreaStyle() {
    window.appInstance.uniCall('updateAreaStyle', {
      id: 'Area01',
      type: 'wave',
      color: '#dd0000',
      areaHeight: 1000,
      fillArea: 'none'
    })
  }
  // 删除区域图
  static removeArea() {
    window.appInstance.uniCall('removeOverlay', {
      id: 'Area01',
      overlayType: 'area'
    })
  }
}
