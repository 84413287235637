import api from '@/api'

/**
 * 设备态势相关接口
 * @author haowei
 */
export default {
    // 机柜数量
    reqGetjgslData() {
        return api.doGet('/data/SBTS/jgslData.json');
    }
    // 设备数量
    , reqGetsbslData() {
        return api.doGet('/data/SBTS/sbslData.json');
    }
    // 设备类型占比
    , reqGetsblxzbData() {
        return api.doGet('/data/SBTS/sblxzbData.json');
    }
    // 告警列表
    , reqGetgklbData() {
        return api.doGet('/data/SBTS/gklbData.json');
    }
    // 环境监测
    , reqGethjjcData() {
        return api.doGet('/data/SBTS/hjjcData.json');
    }
    // 能效监测
    , reqGetnxjcData() {
        return api.doGet('/data/SBTS/nxjcData.json');
    }
}
