// import gisDataManager from '../../api/gisDataManager';

export default class Bubble {
  //添加气泡图图层
  static async addBubbleLayer(params) {
    let defaultOption = {
      id: '气泡-告警点位',
      name: '气泡-告警点位',
      fillArea: 'none',
      speed: 50,
      radiusMax: 1000,
      radiusMin: 50,
      valueMax: 1000,
      valueMin: 0,
      visible: true,
      legends: []
    };
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('addBubbleLayer', jsonData, result => {
      // console.log('addBubbleLayer', jsonData, result)
    })
  }

  // 更新气泡图数据
  static async updateBubbleLayerCoord(layer, subLayer) {
    const updateConfig = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      isAppend: false,
      data: []
    };
    try {
      let layerInfo = await gisDataManager.getBubbleLayerData(
        layer,
        updateConfig,
        subLayer
      );
      appInstance.uniCall('updateBubbleLayerCoord', layerInfo, (res) => {
        // console.log("更新气泡图层", layer.id, res)
      });
    } catch (error) {
      // console.log("更新气泡图层失败", layer.id, res)
    }
  }

  //更新气泡图样式
  static updateBubbleLayerStyle() {
    appInstance.uniCall('updateBubbleLayerStyle', {});
  }
  static removeOverlay(params) {
    appInstance.uniCall('removeOverlay', {
      id: params.id,
      overlayType: 'bubbleLayer',
    }, (result) => {
      // console.log('删除气泡图', result);
    });
  }
}
