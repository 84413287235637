/**
 * @typedef SpaceInfo
 * @property {number} lv - 总优先级
 * @property {string} name - 名称
 * @property {string} group - 分组
 * @property {boolean} hasSub - 是否有二级层级
 */



/**
 * 空间层级分布信息
 * @author haowei
 * @type {Array.<SpaceInfo>}
 */
const spaceInfo = [
    {
        // 总优先级
        lv: 1,
        // 名称
        name: '中国',
        // 分组
        group: '中国',
        // 是否有二级层级
        hasSub: false
    },
    {
        // 总优先级
        lv: 2,
        // 名称
        name: '北京',
        // 分组
        group: '北京',
        // 是否有二级层级
        hasSub: false
    },
    {
        // 总优先级
        lv: 3,
        // 名称
        name: '园区',
        // 分组
        group: '园区',
        // 是否有二级层级
        hasSub: true
    },
    {
        // 总优先级
        lv: 4,
        name: '楼层',
        // 分组
        group: '园区',
        // 是否有二级层级
        hasSub: true
    },
    {
        // 总优先级
        lv: 5,
        name: '房间',
        // 分组
        group: '园区',
        // 是否有二级层级
        hasSub: true
    }, {
        // 总优先级
        lv: 6,
        // 名称
        name: '机柜组',
        // 分组
        group: '园区',
        // 是否有二级层级
        hasSub: true
    }, {
        // 总优先级
        lv: 7,
        // 名称
        name: '机柜',
        // 分组
        group: '园区',
        // 是否有二级层级
        hasSub: true
    }, {
        // 总优先级
        lv: 8,
        // 名称
        name: '设备',
        // 分组
        group: '园区',
        // 是否有二级层级
        hasSub: true
    }
]


export default spaceInfo
