<template>
  <div class="layout_wraper">
    <div class="layout_left">
      <BaseDrawer>
        <div class="ssgjleft">
          <h1>实时告警</h1>
          <div class="Screen" @click="screenlistSate">
            <span>{{ currentScreen }}</span>
            <img
              src="../../assets/img/SSGJ/screenICON.png"
              alt
              class="screenIcon"
            />
          </div>
          <div class="ScreenList" v-if="listSate">
            <div
              class="listItem"
              v-for="(item, index) in screenlist"
              :key="index"
              @click="selectScreen(item)"
            >
              {{ item }}
            </div>
          </div>
          <div class="GJbox">
            <div
              class="box_item"
              :class="boxAc == index ? 'box_item_ac' : ''"
              v-for="(item, index) in ssgjleft"
              :key="index"
              @click="selectGJ(item, index)"
            >
              <p class="category">{{ item.category }}</p>
              <p class="time">{{ item.time }}</p>
              <p class="cont">{{ item.cont }}</p>
            </div>
          </div>
          <div class="pagination">
            <div class="pagination_left">
              <span>每页显示</span>
              <el-input
                type="text"
                maxlength="2"
                v-model="pageSize"
                @input="pageChange"
              ></el-input>
              <span>条/共</span>
              <span>{{ alldata.length }}</span>
              <span>条</span>
            </div>
            <div class="pagination_mid">
              共{{ Math.ceil(alldata.length / pageSize) }}页
            </div>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              layout="prev, jumper, next"
              :total="alldata.length"
            ></el-pagination>
          </div>
        </div>
      </BaseDrawer>
    </div>
  </div>
</template>
<script>
import BaseDrawer from '@/components/common/BaseDrawer.vue'

import { mapState } from 'vuex'
export default {
  data() {
    return {
      listSate: false,
      screenlist: ['全部'],
      ssgjleft: [],
      totalPage: 1,
      pageSize: 12,
      currentPage: 0,
      currentScreen: '全部',
      alldata: 1,
      boxAc: null,
      ComponentRightAc: '',
      preAlertType: '',
      AlertList: [], ///从点位汇总处理之后的所有告警数据
    }
  },
  methods: {
    screenlistSate() {
      this.listSate = !this.listSate
    },
    pageChange(value) {
      this.pageSize = Number(value)
    },
    selectGJ(item, index) {
      this.boxAc = index
      this.$store.commit('setRightComponents', 'SheBeiGaoJing')
      this.$layerManager.onLayerClick(item.id, item.idLayer)
      // this.$store.commit('setLandmarkLayerIdObj', item.category)
    },
    selectScreen(item) {
      this.currentScreen = item
      this.listSate = !this.listSate
      this.getssgj(item, 0)
    },
    handleCurrentChange(val) {
      this.getssgj(this.currentScreen, val - 1)
    },
    getssgj(params, Page) {
      let list = document.querySelectorAll('.GJbox .category')
      for (let index = 0; index < list.length; index++) {
        const element = list[index]
        switch (element.innerHTML) {
          case '紧急告警':
            element.style.color = '#FF794D'
            break
          case '重要告警':
            element.style.color = '#FFA91A'
            break
          case '次要告警':
            element.style.color = '#ECEC28'
            break
          case '提示告警':
            element.style.color = '#DAF48A'
            break
        }
      }
      switch (this.spaceLevel) {
        case '中国':
          let ArrZG = this.AlertList.filter((item) => {
            return item.type == '中国'
          })
          if (params == '全部') {
            this.alldata = ArrZG
          } else {
            this.alldata = ArrZG.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '北京':
          let ArrBJ = this.AlertList.filter((item) => {
            return item.type == '北京'
          })
          if (params == '全部') {
            this.alldata = ArrBJ
          } else {
            this.alldata = ArrBJ.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '园区':
          let ArrYQ = this.AlertList.filter((item) => {
            return item.type == '园区'
          })
          if (params == '全部') {
            this.alldata = ArrYQ
          } else {
            this.alldata = ArrYQ.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '楼层':
          let ArrLC = this.AlertList.filter((item) => {
            return item.type == '楼层'
          })
          if (params == '全部') {
            this.alldata = ArrLC
          } else {
            this.alldata = ArrLC.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '房间':
          let ArrFJ = this.AlertList.filter((item) => {
            return item.type.indexOf('房间') != -1
          })
          if (params == '全部') {
            this.alldata = ArrFJ
          } else {
            this.alldata = ArrFJ.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '机柜组':
          let ArrJGz = this.AlertList.filter((item) => {
            return item.type.indexOf('机柜组') != -1
          })
          if (params == '全部') {
            this.alldata = ArrJGz
          } else {
            this.alldata = ArrJGz.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '机柜':
          let ArrJG = this.AlertList.filter((item) => {
            return item.type.indexOf('机柜') != -1
          })
          if (params == '全部') {
            this.alldata = ArrJG
          } else {
            this.alldata = ArrJG.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
        case '设备':
          let ArrSB = this.AlertList.filter((item) => {
            return (
              item.type.indexOf('Box_A01') != -1 ||
              item.type.indexOf('SM_UPS') != -1
            )
          })
          if (params == '全部') {
            this.alldata = ArrSB
          } else {
            this.alldata = ArrSB.filter((f) => {
              if (f.category == params) {
                return f
              }
            })
          }
          break
      }
      let splice = []
      for (
        let index = Page * this.pageSize;
        index < Page * this.pageSize + this.pageSize;
        index++
      ) {
        if (index < this.alldata.length) {
          splice.push(this.alldata[index])
        }
      }
      this.ssgjleft = splice
    },
    getscreenlist(res) {
      let rowData = ['全部']
      res.forEach((ele) => {
        rowData.push(ele.category)
      })
      let fil = rowData.filter((item, index) => {
        return rowData.indexOf(item) === index
      })
      this.screenlist = fil
    },
  },
  created() {
    let allData = JSON.stringify(window.allData)
    let Data = JSON.parse(allData)
    let AlertList = []
    // console.warn('Data',Data)
    for (let allDatum of Data) {
      if (!allDatum.idLayer){
        console.warn('allDatum',allDatum)
        continue
      }
      if (allDatum.idLayer.indexOf('告警') > -1) {
        AlertList.push(allDatum)
      }
    }
    this.AlertList = AlertList
    console.log('this.AlertList ', this.AlertList)
    this.getssgj(this.currentScreen, 0)
    this.getscreenlist(AlertList)
  },
  mounted() {
    let list = document.querySelectorAll('.GJbox .box_item .category')
    for (let index = 0; index < list.length; index++) {
      const element = list[index]
      switch (element.innerHTML) {
        case '紧急告警':
          element.style.color = '#FF794D'
          break
        case '重要告警':
          element.style.color = '#FFA91A'
          break
        case '次要告警':
          element.style.color = '#ECEC28'
          break
        case '提示告警':
          element.style.color = '#DAF48A'
          break
      }
    }
  },
  computed: {
    ...mapState(['spaceLevel']),
  },
  watch: {
    pageSize: {
      handler(val) {
        if (val == '' && val == 0) {
          this.pageSize = 12
        }
        this.getssgj(this.currentScreen, 0)
      },
    },
    spaceLevel: {
      handler(val) {
        this.getssgj('全部', 0)
      },
      deep: true,
    },
  },
  components: {
    BaseDrawer,
  },
}
</script>
<style lang="less" scoped>
.layout_left {
  .ssgjleft {
    width: 680px;
    height: 1720px;
    background: url('../../assets/img/SSGJ/ssgjleft.png');
    box-sizing: border-box;
    padding-top: 18px;
    position: relative;
    h1 {
      color: #fff;
      font-family: '微软雅黑';
      font-size: 36px;
      font-weight: bold;
      margin-left: 77px;
    }
    .Screen {
      cursor: pointer;
      width: 640px;
      height: 50px;
      background: #272729;
      border: 2px solid #4d505e;
      border-radius: 2px;
      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 27px;
      padding-left: 12px;
      line-height: 45px;
      font-family: '微软雅黑';
      color: #bfcfff;
      font-size: 28px;
      position: relative;
      .screenIcon {
        width: 20px;
        height: 12px;
        position: absolute;
        right: 20px;
        top: 17px;
      }
    }
    .ScreenList {
      cursor: pointer;
      width: 640px;
      position: absolute;
      top: 148px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      .listItem {
        background: #272729;
        border: 2px solid #4d505e;
        box-sizing: border-box;
        border-radius: 2px;
        width: 640px;
        height: 50px;
        padding-left: 12px;
        line-height: 45px;
        font-family: '微软雅黑';
        color: #bfcfff;
        font-size: 28px;
        margin-bottom: 5px;
      }
    }
    .GJbox {
      width: 638px;
      height: 1420px;
      margin: 0 auto;
      margin-top: 45px;
      overflow-y: scroll;
      .box_item {
        cursor: pointer;
        width: 589px;
        height: 125px;
        background: url('../../assets/img/SSGJ/ssgjitem.png') no-repeat;
        padding: 24px 0 0 29px;
        box-sizing: border-box;
        font-family: '微软雅黑';
        font-size: 24px;
        position: relative;
        margin-bottom: 20px;
        .category {
          color: #ffd24c;
          font-size: 28px;
        }
        .time {
          color: #54b7ff;
          position: absolute;
          right: 18px;
          top: 24px;
        }
        .cont {
          color: #ffffff;
          margin-top: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .box_item_ac {
        background: url('../../assets/img/SSGJ/ssgjitem_ac.png');
      }
      .box_item:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    .pagination {
      width: 660px;
      height: 50px;
      margin: 0 auto;
      margin-top: 45px;
      display: flex;
      font-family: '微软雅黑';
      font-size: 26px;
      line-height: 50px;
      color: #999999;
      .pagination_left {
        display: flex;
        width: 340px;
        .pageSize {
          width: 50px;
          height: 50px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 2px solid #4d505e;
          color: #cccccc;
          background: #272729;
          text-align: center;
          margin: 0 4px;
        }
        /deep/.el-input {
          width: 70px;
        }
        /deep/.el-input__inner {
          width: 70px;
          height: 50px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 2px solid #4d505e;
          color: #cccccc;
          text-align: center;
          background: #272729;
          font-size: 26px;
        }
      }
      .pagination_mid {
        width: 120px;
      }
      /deep/.btn-prev {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 2px solid #4d505e;
        color: #cccccc;
        text-align: center;
        background: #272729;
      }
      /deep/.el-icon {
        font-size: 30px;
      }
      /deep/.el-pagination__jump {
        margin-left: 0;
        font-size: 26px;
        font-family: '微软雅黑';
        color: #cccccc;
        height: 50px;
        line-height: 50px;
      }
      /deep/.el-input__inner {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 2px solid #4d505e;
        color: #cccccc;
        text-align: center;
        background: #272729;
        font-size: 26px;
      }
      /deep/.btn-next {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 2px solid #4d505e;
        color: #cccccc;
        text-align: center;
        background: #272729;
      }
    }
  }
}
</style>
