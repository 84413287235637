import StandardStateHandler from '@/core/state/StandardStateHandler'
import DataAnalysisStateHandler from '@/core/state/DataAnalysisStateHandler'
import WarningStateHandler from '@/core/state/WarningStateHandler'
import LookEqStateHandler from '@/core/state/LookEqStateHandler'
import LookLinkStateHandler from '@/core/state/LookLinkStateHandler'
import ChinaStandardStateHandler from "@/core/state/standard/ChinaStandardStateHandler";
import BeijingStandardStateHandler from "@/core/state/standard/BeijingStandardStateHandler";
import GardenStandardStateHandler from "@/core/state/standard/GardenStandardStateHandler";
import FloorStandardStateHandler from "@/core/state/standard/FloorStandardStateHandler";
import RoomStandardHandler from "@/core/state/standard/RoomStandardHandler";
import RackGroupStandardHandler from "@/core/state/standard/RackGroupStandardHandler";
import RackStandardHandler from "@/core/state/standard/RackStandardHandler";
import EqStandardHandler from "@/core/state/standard/EqStandardHandler";
// import InspectionStateHandler from '@/utils/control/state/InspectionStateHandler'
// import PlaybackStateHandler from '@/utils/control/state/PlaybackStateHandler'
// import RoamingStateHandler from '@/utils/control/state/RoamingStateHandler'


/**
 * 状态信息类
 */
class StateInfo {
  /**
   * 关键字
   * @type {string}
   */
  key = ''
  /**
   * 状态标签
   * @type {string}
   */
  label = ''

  /**
   * 处理器
   * @type {BaseStateHandler | *}
   */
  handler = null

  /**
   * 构造方法
   * @param key 关键字
   * @param label 状态标签
   * @param handler 状态处理器
   */
  constructor(key, label, handler) {
    this.key = key
    this.label = label
    this.handler = handler
  }
}

/**
 * 状态管理器
 */
export default class StateManager {

  static STATES = {
    // 标准状态
    // standard: new StateInfo('standard', '标准状态', new StandardStateHandler()),
    // 中国
    standardLv1 : new StateInfo('standardLv1','标准状态中国',new ChinaStandardStateHandler()),
    // 北京
    standardLv2 : new StateInfo('standardLv2','标准状态北京',new BeijingStandardStateHandler()),
    // 园区
    standardLv3 : new StateInfo('standardLv3','标准状态园区',new GardenStandardStateHandler()),
    // 楼层
    standardLv4 : new StateInfo('standardLv4','标准状态楼层',new FloorStandardStateHandler()),
    // 房间
    standardLv5 : new StateInfo('standardLv5','标准状态房间',new RoomStandardHandler()),
    // 机柜组
    standardLv6 : new StateInfo('standardLv6','标准状态机柜组',new RackGroupStandardHandler()),
    // 机柜
    standardLv7 : new StateInfo('standardLv7','标准状态机柜',new RackStandardHandler()),
    // 设备
    standardLv8 : new StateInfo('standardLv8','标准状态设备',new EqStandardHandler()),
    // 数据分析状态
    analysis: new StateInfo('analysis', '数据分析', new DataAnalysisStateHandler()),
    // 查看告警状态
    // warning: new StateInfo('warning', '查看告警', new WarningStateHandler()),
    // // 查看设备状态
    // lookEq: new StateInfo('lookEq', '查看设备', new LookEqStateHandler()),
    // // 查看链路状态
    // lookLink: new StateInfo('lookLink', '查看链路', new LookLinkStateHandler()),
    // // 自动巡检状态
    // inspection: new StateInfo('inspection', '自动巡检', new InspectionStateHandler()),
    // // 自动巡检回放状态
    // playback: new StateInfo('playback', '自动巡检', new PlaybackStateHandler()),
    // // 漫游状态
    // roaming: new StateInfo('roaming', '漫游', new RoamingStateHandler())
  }

  /**
   * 当前状态信息
   * @type {StateInfo}
   */
  currentState = null
  /**
   * 上一次状态信息
   * @type {StateInfo}
   */
  lastTimeState = null

  static instance

  /**
   * 获取状态管理器实例
   * @return {StateManager}
   */
  static getInstance() {
    return this.instance ? this.instance : new StateManager()
  }

  /**
   * 切换状态
   * @param stateKey 状态关键字
   */
  async switchState(stateKey) {
    console.debug(`状态管理器-状态切换 【${this.currentState?this.currentState.key:'none'}】 => 【${stateKey}】`)
    // if (this.currentState?.key == stateKey) {
    //   // 相同状态下不触发任何事件
    //   return
    // }
    // 自动巡检回放状态只能从自动巡检状态切换
    // if (!this.currentState&&stateKey == StateManager.STATES.playback.key){
    //   console.warn('【自动巡检回放】状态只能从【自动巡检】状态切换')
    //   return
    // }
    // if (this.currentState){
    //   if (this.currentState.key != StateManager.STATES.inspection.key && stateKey == StateManager.STATES.playback.key) {
    //     console.warn('【自动巡检回放】状态只能从【自动巡检】状态切换')
    //     return
    //   }
    // }
    const state = this.getState(stateKey)
    if (state) {
      this.lastTimeState = Object.assign({}, this.currentState?this.currentState:{key:'none'})
      this.currentState = Object.assign({}, state)
      if (this.lastTimeState&&this.lastTimeState.handler){
        // 复位上一次状态
        await this.lastTimeState.handler.reset()
        // 初始化下一次状态
        await this.currentState.handler.init()
        console.debug('状态管理器调用完成!')
      }else{
        // 初始化下一次状态
        await this.currentState.handler.init()
      }
    } else {
      console.error(`【${stateKey}】 状态 不存在`)
    }
  }

  /**
   * 根据关键字获取状态信息
   * @param key
   * @return {null|StateInfo}
   */
  getState(key) {
    for (let keyStr in StateManager.STATES) {
      if (key == keyStr) {
        return StateManager.STATES[key]
      }
    }
    return null
  }
}
