import Store from "@/store";
import spaceLevelInfo from "@/config/spaceLevelInfo";
import UEapi from "@/utils/UEapi/UEapi";
import StateManager from "@/core/StateManager";

/**
 * 空间层级控制器
 *
 * @author haowei
 */
export default class SpaceController {

    /**
     * 空间层级变换记录队列（长度10）
     * @type {[]}
     */
    static recordsQueue = []

    /**
     * 空间变化记录
     * @type {[]}
     */
    static lastLevelRecord = []

    static lastLevel = ''

    static {
        for (let item of spaceLevelInfo) {
            this.lastLevelRecord.push({level: item.name, subSpaceLevel: '', lv: item.lv})
        }
    }

    /**
     * 切换空间层级
     * @param {string} level 空间层级
     * @param {string} subSpaceLevel 二级空间层级
     */
    static changeSpaceLevel(level = '', subSpaceLevel = '') {
        return new Promise((resolve, reject) => {
                if (!level) reject('level 不存在')
                let nowSpaceLevelInfo = this.getNowSpaceLevelInfo();
                let spaceLevelInfo = this.getSpaceLevelInfoByName(level);
                console.warn(`changeSpaceLevel 【${nowSpaceLevelInfo.name}】 => 【${level}】`)
                // 初始化
                Store.commit('setRightType', '')
                Store.commit('setObjectInfo', {})

                // 记录上一层空间信息
                if (subSpaceLevel) {
                    let find = this.lastLevelRecord.find(item => item.level == spaceLevelInfo.name);
                    if (!find) reject('记录上一层空间信息时 level 不存在')
                    find.subSpaceLevel = subSpaceLevel
                }
                this.lastLevel = nowSpaceLevelInfo.name

                // 切换层级的分组不同，要进行切换场景操作
                if (nowSpaceLevelInfo.group != spaceLevelInfo.group) {
                    Store.commit('setShowLoading', true)
                    Store.commit('setSpaceLevel', level)
                    Store.commit('setSubSpaceLevel', subSpaceLevel)
                    // 同步等待
                    let now = new Date();
                    let exitTime = now.getTime() + 100;
                    while (true) {
                        now = new Date();
                        if (now.getTime() > exitTime) {
                            break
                        }
                    }
                    window.layerManager.removeAllLayers().then(()=>{
                        UEapi.Common.switchServiceLoaded(spaceLevelInfo.group).then((res) => {
                            this._recordHistory({level, subSpaceLevel})
                            console.warn('switchServiceLoaded', res)
                            window.stateManager.switchState(StateManager.STATES[`standardLv${spaceLevelInfo.lv}`].key).then(() => {
                                this._handleJigui(nowSpaceLevelInfo.name,spaceLevelInfo.name)
                                this._handleFangChen(nowSpaceLevelInfo.name,spaceLevelInfo.name)
                                window.ctrlEventManager.publish(window.ctrlEventManager.eventsMap.SpaceLevelChange,{oldLevel:this.lastLevel,level:level})
                                resolve(res)
                            })
                        }).catch((err) => {
                            reject(err)
                        })
                    })

                } else {
                    Store.commit('setSpaceLevel', level)
                    Store.commit('setSubSpaceLevel', subSpaceLevel)
                    this._recordHistory({level, subSpaceLevel})
                    // 切换到标准状态
                    // if (spaceLevelInfo.lv <= 6 ){
                    window.stateManager.switchState(StateManager.STATES[`standardLv${spaceLevelInfo.lv}`].key).then(() => {
                        this._handleJigui(nowSpaceLevelInfo.name,spaceLevelInfo.name)
                        this._handleFangChen(nowSpaceLevelInfo.name,spaceLevelInfo.name)
                        window.ctrlEventManager.publish(window.ctrlEventManager.eventsMap.SpaceLevelChange,{oldLevel:this.lastLevel,level:level})
                        resolve()
                    })
                    // }
                }
            }
        )
    }

    /**
     * 获取当前空间层级信息
     * @return {SpaceInfo} 空间层级信息
     */
    static getNowSpaceLevelInfo() {
        let spaceLevel = Store.state.spaceLevel
        return this.getSpaceLevelInfoByName(spaceLevel)
    }

    /**
     * 设置上层级数据
     * @param nowLevel 当前层级
     * @param subLevel 二级层级
     */
    static setLastLevelRecord(nowLevel,subLevel){
        let spaceLevel = this.getSpaceLevelInfoByName(nowLevel);
        let find = this.lastLevelRecord.find(item=>item.lv == spaceLevel.lv -1)
        console.warn('setLastLevelRecord',nowLevel,subLevel,find)
        find.subSpaceLevel = subLevel
    }


    /**
     * 根据层级名称获取空间层级信息
     * @param name 层级名称
     * @return {SpaceInfo} 空间层级信息
     */
    static getSpaceLevelInfoByName(name) {
        return spaceLevelInfo.find(item => item.name == name)
    }

    static getSpaceLevelInfoByLv(lv){
        return spaceLevelInfo.find(item => item.lv == lv)
    }

    /**
     * 返回上一级空间层级
     */
    static goBackSpaceLevel() {
        let nowSpaceLevelInfo = this.getNowSpaceLevelInfo()
        let find = this.lastLevelRecord.find(item => item.lv == nowSpaceLevelInfo.lv - 1)
        if (!find) return
        this.changeSpaceLevel(find.level, find.subSpaceLevel)
    }

    /**
     * 记录层级变化历史
     * @param level 层级
     * @param subSpaceLevel 二级
     * @private
     */
    static _recordHistory({level, subSpaceLevel}) {
        this.recordsQueue.push({level, subSpaceLevel})
        if (this.recordsQueue.length > 10) {
            this.recordsQueue.shift()
        }
    }

    /**
     * 处理机柜创建和删除逻辑--因为机柜的创建是无关状态的，只和当前层级有关，故在此处理
     * @param oldSpaceLevel 旧空间层级
     * @param newSpaceLevel 新空间层级
     * @private
     */
    static _handleJigui(oldSpaceLevel,newSpaceLevel){
        let spaceLevelInfo = this.getSpaceLevelInfoByName(newSpaceLevel)
        let rackCtrl = window.modelManager.controls.rack
        if (spaceLevelInfo.lv >= 4){
            if(!rackCtrl.rackShow){
                rackCtrl.createModelExec()
            }
        }else{
            rackCtrl.removeAllModel()
        }
    }

    /**
     * 处理防尘顶、防尘门在不同层级中的展现
     * @param oldSpaceLevel 旧空间层级
     * @param newSpaceLevel 新空间层级
     * @private
     */
    static _handleFangChen(oldSpaceLevel,newSpaceLevel){
        let fangChenCtrl = window.modelManager.controls.fangChen
        let spaceLevelInfo = this.getSpaceLevelInfoByName(newSpaceLevel)
        if (spaceLevelInfo.lv >= 6){
            fangChenCtrl.hideAllModelExec()
        }else{
            fangChenCtrl.showAllModelExec()
        }
    }
}
