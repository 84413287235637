export default class HeatMapLayer {
  // 添加热力图图层
  static async addHeatMapLayer(params) {
    let defaultOption = {
      id: 'heat',
      name: '热力图',
      coordType: 0,
      coordTypeZ: 0,
      coordZ: 64,
      type: 'default',
      alpha: 1,
      colorMax: '#FF6000',
      colorMin: '#009acf',
      valueMax: 60,
      valueMin: 20,
      radius: 1,
      visible: true,
      pointDataId: '',
      data: []
    }
    let jsonData = Object.assign({}, defaultOption, params)
    delete jsonData.layerConfig
    window.appInstance.uniCall('addHeatMapLayer', jsonData, res => {
      // console.log('addHeatMapLayer', jsonData, res)
    })
  }
  /**
   * 更新热力图数据
   * @param {Array} data 图层数据
   */
  static async updateHeatMapLayerCoord(params) {
    let defaultOption = {
      id: 'heat',
      name: '热力图',
      coordType: 0,
      coordTypeZ: 0,
      coordZ: 64,
      isAppend: false,
      data: []
    }
    let jsonData = {
      ...defaultOption
    }
    for (const key in jsonData) {
      jsonData[key] = params[key] || jsonData[key]
    }
    window.appInstance.uniCall('updateHeatMapLayerCoord', jsonData, (res) => {
      // console.log('updateHeatMapLayerCoord', jsonData, res)
    })
  }

  static appendData(layerInfo, index, len, allData) {
    let timer
    layerInfo.isAppend = true
    timer = setInterval(() => {
      let maxIndex = Math.floor(allData.length / len)
      if (index < maxIndex) {
        layerInfo.data = allData.slice(index * len, (index + 1) * len)
        index++
        appInstance.uniCall('updateHeatMapLayerCoord', layerInfo, result => {
          // console.log('追加热力图' + layerInfo.id, result)
        })
      } else {
        clearInterval(timer)
      }
    }, 300)
  }
  /**
   * 更新热力图样式
   * @param {Object} layer 图层配置
   */
  updateStyle(layer) {}
}