export default [
  {
    id: '北京-数据中心',
    name: '北京-数据中心',
    offset: 90,
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'shujuzhongxin' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '数据中心',
      jsonPath: './data/layers/北京/北京-数据中心.json',
      isClick: true,
      coordZ: 5000,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '北京-行政区标识',
    name: '北京-行政区标识',
    coordType: 0,
    coordTypeZ: 0,
    offset: 10,
    visible: true,
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'shujuzhongxin' }, {
      name: 'quchangping',
      color: '#ffff',
      'iconName': 'quchangping'
    }, { name: 'qudaxing', color: '#ffff', 'iconName': 'qudaxing' }, {
      name: 'quchaoyang',
      color: '#ffff',
      'iconName': 'quchaoyang'
    }, { name: 'qudongcheng', color: '#ffff', 'iconName': 'qudongcheng' }, {
      name: 'qufangshan',
      color: '#ffff',
      'iconName': 'qufangshan'
    }, { name: 'qufengtai', color: '#ffff', 'iconName': 'qufengtai' }, {
      name: 'quhaidian',
      color: '#ffff',
      'iconName': 'quhaidian'
    }, { name: 'quhuairou', color: '#ffff', 'iconName': 'quhuairou' }, {
      name: 'qumentougou',
      color: '#ffff',
      'iconName': 'qumentougou'
    }, { name: 'qumiyun', color: '#ffff', 'iconName': 'qumiyun' }, {
      name: 'qupinggu',
      color: '#ffff',
      'iconName': 'qupinggu'
    }, { name: 'qushijingshan', color: '#ffff', 'iconName': 'qushijingshan' }, {
      name: 'qushunyi',
      color: '#ffff',
      'iconName': 'qushunyi'
    }, { name: 'qutongzhou', color: '#ffff', 'iconName': 'qutongzhou' }, {
      name: 'quyanqing',
      color: '#ffff',
      'iconName': 'quyanqing'
    }, { name: 'quxicheng', color: '#ffff', 'iconName': 'quxicheng' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '行政区标识',
      jsonPath: './data/layers/北京/北京-行政区标识.json',
      isClick: false,
      coordZ: 12000,
      status: 'none',
      rawData: [],
      jsonData: {},
      clickShowDecorator:false,
      hoverShowDecorator:false,
      clickEventDisable:true
    }
  }, {
    id: '北京-数据传输',
    name: '北京-数据传输',
    coordType: 0,
    coordTypeZ: 0,
    'valueMax': 10,
    'valueMin': 10,
    'isShowBubble': false,
    'bubbleRadiusMax': 1000,
    'bubbleRadiusMin': 50,
    'bubbleSpeed': 50,
    'lineWidthMax': 1000,
    'lineWidthMin': 1000,
    'lineSpeed': 60000,
    'curvature': 0.5,
    legends: [{
      name: 'default',
      type: 'ray',
      'bubbleColor': 'ff0000',
      'lineColor': '#f8d74b',
      'labelColor': '#ffffffff',
      'labelBackgroundColor': '#ffffffff'
    }],
    data: [],
    layerConfig: {
      layerType: 'ODLineLayer',
      scatterType: '数据传输',
      'startCoordZ': '0',
      'targetCoordZ': '0',
      jsonPath: './data/layers/北京/北京-数据传输.json',
      isClick: false,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '北京-告警_数据传输',
    name: '北京-告警_数据传输',
    coordType: 0,
    coordTypeZ: 0,
    visible: true,
    legends: [{
      name: '紧急告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#ff0000',
      fillArea: 'type01',
      speed: 6000,
      radius: 10000
    }, {
      name: '重要告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#FF7F27',
      fillArea: 'type01',
      speed: 3000,
      radius: 10000
    }, {
      name: '次要告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#A69D00',
      fillArea: 'type01',
      speed: 3000,
      radius: 5000
    }, {
      name: '提示告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#2C71D6',
      fillArea: 'type01',
      speed: 1000,
      radius: 5000
    }],
    data: [],
    layerConfig: {
      layerType: 'eventLayer',
      scatterType: '告警_数据传输',
      jsonPath: './data/layers/北京/北京-告警-数据传输.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }
]
