import CameraList from '@/config/cameraList'
import BaseCameraController from '@/core/camera/BaseCameraController'
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 默认模型镜头处理
 * @author haowei
 */
export default class DefaultModelCameraController extends BaseCameraController{

  cameraInfo = {}

  async setCamera(params) {
    // 去cameraList查看是否存在相机数据，如不存在就默认为聚焦模型
    const cameraInfoStatic = CameraList[params.id]
    if (cameraInfoStatic){
      this.cameraInfo = cameraInfoStatic
      this.cameraInfo.type = super.TYPE_CAMERA
      return await UEapi.Camera.setCamera(cameraInfoStatic)
    }
    let jsonData = {
      id: params.id,
      modelType: 'model',
      distance: params.distance ? params.distance : super.getNowFocusDistance(),
    }
    this.cameraInfo = jsonData
    this.cameraInfo.type = super.TYPE_FOCUS
    return await UEapi.Camera.focusModel(jsonData)
  }


  getNowCameraInfo() {
    return this.cameraInfo
  }
}
