import UEapi from "@/utils/UEapi/UEapi";
import {boxGroupList, boxList, linkList, linkList2} from "@/config/ModelTemp";
import Store from "@/store";

/**
 * 主楼逻辑
 * @author haowei
 */
export default class BuildingController{
    /**
     * 展开楼体
     */
    static expandBuilding(){
        if (!Store.state.floorExpand){
            // 隐藏线路
            UEapi.Model.setModelVisibility({
                ids: linkList,
                visible: false,
            });
            UEapi.Model.setModelVisibility({
                ids: linkList2,
                visible: false,
            })
            UEapi.Model.setModelVisibility({
                ids: ['SM_IDC机房楼', 'SM_IDC建筑标示_建筑'],
                visible: false,
            })
            boxList.forEach((item) => {
                UEapi.Model.setModelStyle({
                    id: item,
                    alpha: 0,
                })
            }) // 灰色
            UEapi.Model.setModelVisibility({
                ids: boxList,
                visible: false,
            })
            boxGroupList.forEach((item) => {
                UEapi.Model.setModelStyle({
                    id: item,
                    alpha: 1,
                })
            }) // 黑色
            Store.commit('setFloorExpand',true)
        }
    }

    /**
     * 收起楼体
     */
    static unExpandBuilding(){
        UEapi.Model.setModelVisibility({
            ids: ['SM_IDC机房楼', 'SM_IDC建筑标示_建筑'],
            visible: true,
        })
        Store.commit('setFloorExpand',false)
    }
}
