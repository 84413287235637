import BaseModelController from '@/core/model/BaseModelController'
import UEapi from "@/utils/UEapi/UEapi";
import Store from "@/store";
import SpaceController from "@/core/logic/SpaceController";
import DataManager from "@/core/DataManager";
import ObjectManager from "@/components/objectManager/ObjectManager";

/**
 * 机柜控制器
 * @author haowei
 */
export default class RackModelController extends BaseModelController {
    rackGroupIds = [];

    // 机柜列表
    rackList = []

    currentRack = ''

    currentRackGroup = ''

    rackModelName = '机柜'

    rackShow = false

    // 遮挡机柜组对应关系
    blockGroupMapping = [{
        group: 'C03-004', //被遮挡机柜
        blockGroup: 'C03-003' // 遮挡机柜
    }, {
        group: 'B02-004',
        blockGroup: 'B02-003'
    }, {
        group: 'A01-004',
        blockGroup: 'A01-003'
    }]

    constructor() {
        super();
        this.updateModelData()
    }


    async createModelExec(params) {
        for (let rack of this.rackList) {
            let temp = {
                ...rack,
                modelType: this.rackModelName
            }
            UEapi.Model.addModel(temp)
        }
        this.rackShow = true
    }

    async removeAllModel() {
        for (let rack of this.rackList) {
            await UEapi.Model.removeModel(rack.id)
        }
        this.rackShow = false
    }

    async updateModelData() {
        DataManager.getBoxData().then(res => {
            window.allBoxData = res
            this.rackGroupIds = res.map(item => item.id);
            for (let re of res) {
                this.rackList.push(...re.rackList)
            }
        })
    }

    getModelIdsList() {
        let map = this.rackList.map(item => item.id)
        return [...this.rackGroupIds, ...map]
    }

    clickModelExec(e) {
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo()
        if (nowSpaceLevelInfo.name == '房间') {
            // 如果点击了机柜，但不是从机柜组层级点击，要切换到机柜组层级
            if (this.isRack(e.id)) {
                let find = this.rackList.find(item => item.id == e.id);
                if (!find) return
                console.warn('clickModelExec-rack',find)
                this.currentRackGroup = find.group
                SpaceController.changeSpaceLevel('机柜组', find.group).then(() => {
                    ObjectManager.updateExpandKeys(find.group)
                    Store.commit('setRightType', 'JiGuiZu')
                    Store.commit('setObjectInfo', {name: find.group})
                    SpaceController.setLastLevelRecord('机柜组', '机柜组机房')
                    this.highlightRackGroup(find.group)
                })
            } else {
                this.currentRackGroup = e.id
                SpaceController.changeSpaceLevel('机柜组', e.id).then(() => {
                    ObjectManager.updateExpandKeys(e.id)
                    Store.commit('setRightType', 'JiGuiZu')
                    Store.commit('setObjectInfo', {name: e.id})
                    SpaceController.setLastLevelRecord('机柜组', '机柜组机房')
                    this.highlightRackGroup(e.id)
                })
            }
        } else if (nowSpaceLevelInfo.name == '机柜组') {
            if (this.isRack(e.id)) {
                // 机柜组层级下，如果切换了机柜组，那需要到对应机柜组
                let find = this.rackList.find(item => item.id == e.id);
                console.warn('nowSpaceLevelInfo=机柜组', find, this.currentRackGroup)
                if (this.currentRackGroup != find.group) {
                    this.currentRackGroup = find.group
                    ObjectManager.updateExpandKeys(find.group)
                    Store.commit('setRightType', 'JiGuiZu')
                    Store.commit('setObjectInfo', {name: find.group})
                    SpaceController.setLastLevelRecord('机柜组', '机柜组机房')
                    // 同级切换直接设置镜头
                    window.cameraManager.onSetCamera(find.group, null, {})
                    this.highlightRackGroup(find.group)
                } else {
                    this.handleRackClick(e.id)
                }
            } else {
                this.handleRackGroupClick(e.id)
            }
        } else if (nowSpaceLevelInfo.name == '楼层') {
            // 从楼层点击模型，切换到房间层级
            SpaceController.changeSpaceLevel('房间', '机柜组机房').then(() => {
                ObjectManager.updateExpandKeys('机柜组机房')
                Store.commit('setRightType', 'FangJian')
                Store.commit('setObjectInfo', {name: '机柜组机房'})
            })
        } else {
            // 跨级跳转处理
            if (this.isRack(e.id)) {
                this.handleRackClick(e.id)
            } else {
                this.handleRackGroupClick(e.id)
            }
        }
    }

    handleRackClick(id) {
        this.currentRack = id
        SpaceController.changeSpaceLevel('机柜', id).then(() => {
            ObjectManager.updateExpandKeys(id)
            Store.commit('setRightType', 'JiGuiLixian')
            Store.commit('setObjectInfo', {name: id})
            let find = this.rackList.find(item => item.id == id);
            let rackGroupId = this.rackGroupIds.find(itid => itid == find.group)
            console.warn('openRack',rackGroupId)
            this.highlightRackGroup(rackGroupId)
            this.currentRackGroup = rackGroupId
            SpaceController.setLastLevelRecord('机柜', rackGroupId)
            SpaceController.setLastLevelRecord('机柜组', '机柜组机房')
            this.openRack(id)
            this.highlightRack(id)
        })
    }

    handleRackGroupClick(id) {
        this.currentRackGroup = id
        SpaceController.changeSpaceLevel('机柜组', id).then(() => {
            ObjectManager.updateExpandKeys(id)
            Store.commit('setRightType', 'JiGuiZu')
            Store.commit('setObjectInfo', {name: id})
            SpaceController.setLastLevelRecord('机柜组', '机柜组机房')
            this.highlightRackGroup(id)
        })
    }

    async showAllModels() {
        await UEapi.Model.setModelVisibility({
            ids: this.getModelIdsList(),
            visible: true
        })
    }

    async hideAllModels() {
        await UEapi.Model.setModelVisibility({
            ids: this.getModelIdsList(),
            visible: false
        })
        this.rackShow = false
    }

    /**
     * 是否是机柜
     * @param id 模型id
     * @return {boolean}
     */
    isRack(id) {
        let find = this.rackList.find(item => item.id == id);
        return !!find;
    }

    /**
     * 机柜打开
     * @param id 模型id
     */
    async openRack(id) {
        let find = this.rackList.find(item => item.id == id);
        if (find.openDoor) return
        // 处理机柜遮挡问题
        let findBlock = this.blockGroupMapping.find(item => item.group == find.group)
        if (findBlock) {
            let map = this.rackList.filter(item => item.group == findBlock.blockGroup).map(item => item.id)
            await this.hideModelExec(map)
        }
        // 创建设备模型
        let ctrl = window.modelManager.controls.equipment
        for (let item of find.equipmentList) {
            let param = {
                ...item,
                coord: find.coord,
                coordZ: find.coordZ,
                rotation: this.isReverseRack(find) ? [0, 0, 0] : [0, 180, 0]
            }
            await ctrl.createModelExec(param)
        }

        UEapi.Model.playAnimation({
            id: id,
            name: '冷通道门开启',
            state: 'begin',
        })
        UEapi.Model.playAnimation({
            id: id,
            name: '热通道门开启',
            state: 'begin',
        })
        UEapi.Model.playAnimation({
            id: id,
            name: '支架显示',
            state: 'begin',
        })


        find.openDoor = true

        // 只有链路展示的时候才能打开多个机柜
        if (Store.state.showLinkList&&Store.state.showLinkList.length > 0){
        }else {
            for (let rackListElement of this.rackList) {
                if (rackListElement.openDoor&&rackListElement.id != id) {
                    this.closeRack(rackListElement.id)
                }
            }
        }

        // let filter = equipmentCtrl.equipmentList.filter(item=>item.rack == id)
        // let map = filter.map(item=>item.id);
        // equipmentCtrl.showModelExec(map)

        //C03-004_SM_机柜组会被C03-003_SM_机柜组 遮挡的问题处理
        // this._c03003Handle(false)
        // 打开机柜的时候，虚拟机处理
        // equipmentCtrl._showVMNotInside()
    }

    _c03003Handle(show) {
        if (!show) {
            this.hideModelExec(['C03-003_SM_机柜组'])
        } else {
            this.showModelExec(['C03-003_SM_机柜组'])
        }
    }

    closeRack(id) {
        UEapi.Model.playAnimation({
            id: id,
            name: '冷通道门关闭',
            state: 'begin',
        })
        UEapi.Model.playAnimation({
            id: id,
            name: '热通道门关闭',
            state: 'begin',
        })
        UEapi.Model.playAnimation({
            id: id,
            name: '支架隐藏',
            state: 'begin',
        })
        let find = this.rackList.find(item => item.id == id);
        find.openDoor = false

        let equipmentCtrl = window.modelManager.controls.equipment
        let tempList = [...find.equipmentList]
        for (const item of tempList) {
            equipmentCtrl.removeModelExec(item.id)
        }

        // 处理机柜遮挡问题
        let findBlock = this.blockGroupMapping.find(item => item.group == find.group)
        if (findBlock) {
            let map = this.rackList.filter(item => item.group == findBlock.blockGroup).map(item => item.id)
            this.showModelExec(map)
        }

        //C03-004_SM_机柜组会被C03-003_SM_机柜组 遮挡的问题处理
        // this._c03003Handle(true)
    }

    closeAllRack() {
        for (let rackListElement of this.rackList) {
            if (rackListElement.openDoor) {
                this.closeRack(rackListElement.id)
            }
        }
    }

    /**
     * 高亮某一机柜组
     * @param id 机柜组id
     */
    highlightRackGroup(id) {
        for (let itemId of this.rackGroupIds) {
            if (itemId != id) {
                UEapi.Model.setModelStyle({
                    id: itemId,
                    alpha: 0.2,
                })
            } else {
                UEapi.Model.setModelStyle({
                    id: itemId,
                    alpha: 1,
                })
            }
        }
        for (let item of this.rackList) {
            if (item.group != id) {
                UEapi.Model.setModelStyle({
                    id: item.id,
                    alpha: 0.2,
                })
            } else {
                UEapi.Model.setModelStyle({
                    id: item.id,
                    alpha: 1,
                })
            }
        }
    }

    /**
     * 取消机柜组高亮
     * @author haowei
     */
    unDoHighlightRackGroup() {
        this.unDoHighlightRack()
        for (let itemId of this.rackGroupIds) {
            UEapi.Model.setModelStyle({
                id: itemId,
                alpha: 1,
            })
        }
    }

    /**
     * 高亮机柜
     * @param id 机柜id
     */
    highlightRack(id) {
        let find = this.rackList.find(item => item.id == id)
        if (!find) return
        let filter = this.rackList.filter(item => item.group == find.group)
        for (let item of filter) {
            if (item.id != id) {
                UEapi.Model.setModelStyle({
                    id: item.id,
                    alpha: 0.2,
                })
            } else {
                UEapi.Model.setModelStyle({
                    id: item.id,
                    alpha: 1,
                })
            }
        }
        for (let rackGroupId of this.rackGroupIds) {
            UEapi.Model.setModelStyle({
                id: rackGroupId,
                alpha: 0.2,
            })
        }
    }

    /**
     * 取消机柜高亮状态
     */
    unDoHighlightRack() {
        for (let item of this.rackList) {
            UEapi.Model.setModelStyle({
                id: item.id,
                alpha: 1,
            })
        }

    }

    /**
     * 判断机柜是否是反向的
     * @param rackInfo
     * @return {boolean}
     *
     */
    isReverseRack(rackInfo) {
        return !!(rackInfo.rotation && rackInfo.rotation[1] && rackInfo.rotation[1] != 0);
    }

    /**
     * 关闭所有机柜（除了被选择的）
     */
    closeAllRacksNotCurrent() {
        for (let rack of this.rackList) {
            if (rack.id != this.currentRack) {
                if (rack.openDoor)
                    this.closeRack(rack.id)
            }
        }
    }
    /**
     * 机柜门是否开启
     * @param rackId 机柜id
     * @return {boolean|*} 是否开启
     */
    isRackOpen(rackId) {
        let find = this.rackList.find(item => item.id == rackId)
        return find.openDoor
    }

}
