<template>
  <div class="line">
    <div class="con">
      <div class="bau">
        <img src="../../../assets/img/SSGJ/bau.png" alt />
      </div>
      <div class="libox">
        <div
          class="li"
          v-for="(item, index) in List"
          :key="index"
          @click="changeStatue(item, index)"
        >
          <div class="wz">
            <p>{{ item.name }}</p>
            <p v-if="index % 2 != 1">
              <span></span>
              <span>{{ item.value }}</span>
            </p>
            <p v-else>
              <span></span>
              <span>{{ item.value }}</span>
            </p>
          </div>
          <div class="btn">
            <p
              v-for="(val, ind) in item.btn"
              :key="ind"
              :ref="`btn${index}`"
              :class="index.toString() + ind == SBDKPXindex ? 'ac' : ''"
              @click="SBDKPXPoint(ind, index, $event, val)"
            >
              {{ val }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UEapi from '@/utils/UEapi/UEapi'
import { linkList } from './link.js'
import PortDeviceData from './PortDeviceData'
import LinkController from "@/core/logic/linkController";
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    List: {
      type: Array,
      default: () => linkList,
    },
  },
  data() {
    return {
      SBDKPXindex: -1,
    }
  },
  methods: {
    changeStatue(item, index) {
      // this.List.forEach((ite) => {
      //   UEapi.Model.setModelStyle({
      //     id: ite.id,
      //     alpha: 0.1,
      //   })
      // })
      // UEapi.Model.setModelStyle({
      //   id: item.id,
      //   alpha: 1,
      // })
      // console.log(item)
      // console.log(item.name)
      const btn = this.$refs['btn' + index]
      console.warn('btn',btn)
      let actDomName
      let act = -1;
      btn.forEach((dom) => {
        if (dom.className === 'ac') {
          actDomName = dom.innerHTML
          if (actDomName.indexOf('设 备') > -1){
            act = 1;
          }else if (actDomName.indexOf('端 口') > -1){
            act = 2;
          }else if (actDomName.indexOf('配 线') > -1){
            act = 3;
          }else {
            act = -1
          }
        }
      })
      console.warn('actDomName', actDomName)
      // 聚焦模型
      this.focusPortDeviceModel(item,act);
    },
    SBDKPXPoint(i, maxIndex) {
      if (this.SBDKPXindex == maxIndex.toString() + i) {
        this.SBDKPXindex = -1
        // this.$emit('changeSBDKPXPoint', '')
      } else {
        this.SBDKPXindex = maxIndex.toString() + i
        // this.$emit('changeSBDKPXPoint', this.title)
      }
    },
    async focusPortDeviceModel(item,act){
      console.error(act,'act')
      if(act == 1){
        // 设备
        // await this.focusDevice(item);
        LinkController.focusLinkEq(item.id)
      }else if (act == 2){
        // 端口
        // await this.focusDevice(item);
        // this.dealPortShow(item);
      }else if(act == 3){
        // 配线
        // let jsonData = {
        //   id: item.id,
        //   modelType: 'model',
        //   distance: 2
        // }
        // window.appInstance.uniCall('focusModel', jsonData, res => {
        //   console.log('focusModel', jsonData, res)
        // })
        LinkController.focusLink(item.id)
      }
    },
    async focusDevice(item){
      // '#82ff47'
      // 聚焦模型
    },
    dealPortShow(item){
      // todo 高亮
      // UEapi.Camera.setCamera(cameraList[item.id]);
      let highlightPortId = '';
      PortDeviceData.forEach(it=>{
        if(it.id == item.id){
          highlightPortId = it.portId;
        }
      });
      if (highlightPortId){
        window.appInstance.uniCall('setModelStyle',{
          id:highlightPortId,
          maskColor: '#ff0000'
        },res=>{
          console.log(res,'dealPortShow-setModelStyle')
        })
      }
    }
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.line {
  margin-left: -50px;
  width: 676px;
  height: 1004px;
  background: rgba(24, 155, 250, 0.1);
  .con {
    display: flex;
    width: 576px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    .bau {
      margin-top: 58px;
    }
    .btn {
      width: 110px;
      // margin-top: 40px;
      p {
        pointer-events: all;
        margin-bottom: 12px;
        width: 110px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: url('../../../assets/img/SSGJ/btn.png') no-repeat;
        font-size: 26px;
        font-family: '微软雅黑';
        color: #ccc;
      }
      // p:nth-child(2),
      // p:nth-child(3),
      // p:nth-child(5),
      // p:nth-child(6),
      // p:nth-child(8),
      // p:nth-child(9) {
      //   margin-bottom: 52px;
      // }
      .ac {
        margin-bottom: 12px;
        width: 110px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: url('../../../assets/img/SSGJ/btnac.png') no-repeat;
        font-size: 26px;
        font-family: '微软雅黑';
        color: #ccc;
      }
    }
    .libox {
      height: 902px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    ::-webkit-scrollbar {
      width: 0px;
      border-radius: 0px;
      background: rgba(17, 63, 89, 0.65);
    }
    ::-webkit-scrollbar-thumb {
      width: 0px;
      border-radius: 0px;
      background: rgba(255, 255, 255, 0.15);
      min-height: 00px;
    }
    .li {
      margin-bottom: 16px;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      .wz {
        width: 390px;
        p:nth-child(1) {
          font-size: 26px;
          font-family: '微软雅黑';
          color: #fff;
        }
        p:nth-child(2) {
          display: flex;
          align-items: center;
          span:nth-child(1) {
            width: 10px;
            height: 10px;
            background: #55b7ff;
            border-radius: 50%;
            margin-right: 10px;
          }
          span:nth-child(2) {
            font-size: 26px;
            font-family: '微软雅黑';
            color: #fff;
          }
        }
      }
    }
    .li:nth-child(odd) {
      height: 136px;
    }
    .li:nth-child(even) {
      height: 110px;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      .wz {
        width: 390px;
        p:nth-child(1) {
          font-size: 26px;
          font-family: '微软雅黑';
          color: #52f2ff;
        }
        p:nth-child(2) {
          display: none;
          align-items: center;
          span:nth-child(1) {
            width: 10px;
            height: 10px;
            background: #55b7ff;
            border-radius: 50%;
          }
          span:nth-child(2) {
            font-size: 26px;
            font-family: '微软雅黑';
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
