/**
 * 数据缓存
 * @author haowei
 */
export default class DataCache {
    static instance

    /**
     * 获取单例
     */
    static getInstance() {
        return this.instance ? this.instance : new DataCache()
    }

    // 缓存仓库
    cacheStore = {}


    constructor() {

    }

    /**
     * 设置缓存
     * @param key 关键字
     * @param obj 数据
     */
    setCache(key, obj) {
        this.cacheStore[key] = obj
    }

    /**
     * 获取缓存中的数据
     * @param key 关键字
     * @return {null|*}
     */
    getCache(key) {
        if (!this._isExistKey(key)) {
            // console.warn(`数据缓存中没有【${key}】关键字`)
            return null
        }
        return this.cacheStore[key]
    }

    /**
     * 清空缓存仓库
     */
    clearCache() {
        this.cacheStore = {}
    }

    /**
     * 清空某个缓存数据
     * @param key 关键字
     */
    rmCache(key) {
        delete this.cacheStore[key]
    }

    /**
     * 检验key是否存在
     * @param key 关键字
     * @return {boolean}
     * @private
     */
    _isExistKey(key) {
        for (let sKey in this.cacheStore) {
            if (sKey == key) {
                return true
            }
        }
        return false
    }
}
