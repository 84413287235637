import axios from "axios";
import Token from "@/utils/token";

// 基础请求路径
const BASE_URL = '';

const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    timeout: 50000000
});

// 定义响应拦截器
api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // 状态错误的情况下，不由axios处理
    return error.response;
});

/**
 * 如何处理token
 */
function tokenHandle(){
}

/**
 * 如何处理响应
 * @param response 响应体
 * @param warning 是否警告
 */
function responseHandle(response,warning){

}
/**
 * 封装post请求
 * @param url 地址
 * @param params 参数
 * @param warning 是否警告
 * @param headers header
 * @param tokenNeed 是否需要token
 * @returns {Promise<any>}
 */
api.doPost = async function (url, params, warning = true, headers = {},tokenNeed = true) {
    if (tokenNeed){
        tokenHandle();
    }
    let response = await api.post(url, params, {headers, warning});
    responseHandle(response,warning);
    return response.data;
}
/**
 * 封装get请求
 * @param url 地址
 * @param warning 是否警告
 * @param headers header
 * @param tokenNeed 是否需要token
 * @returns {Promise<any>}
 */
api.doGet = async function (url, warning = true, headers = {},tokenNeed = true) {
    if (tokenNeed){
        tokenHandle()
    }
    let response = await api.get(url, {headers});
    responseHandle(response,warning);
    return response.data;
}

export default api;
