/**
 * 数据管理器
 *
 * @author haowei
 */
import base from '@/api/index'

export default class DataManager {

    static jsonPathDataMap = {}

    /**
     * 根据路径获取数据
     * @param jsonPath json路径
     * @returns {Promise<any>}
     */
    static async getDataByPath(jsonPath) {
        return await this.getDataByPathParams(jsonPath, null)
    }

    /**
     * 根据json路径和请求参数获取数据
     * @param jsonPath json路径
     * @param params 请求参数
     * @return {Promise<*>}
     */
    static async getDataByPathParams(jsonPath, params) {
        try {
            let jsonData = null;
            if (this.jsonPathDataMap[jsonPath]){
                jsonData = this.jsonPathDataMap[jsonPath]
                this.jsonPathDataMap[jsonPath] = jsonData
            }
            if (!jsonData){
                let data = await base.get(jsonPath)
                jsonData = data.data
                this.jsonPathDataMap[jsonPath] = jsonData
            }
            // 是否使用缓存数据
            if (jsonData.cache) {
                let cache = window.dataCache.getCache(jsonPath)
                if (cache) {
                    return {
                        RECORDS: cache
                    }
                }
            }
            // 如果是从后端获取数据的情况下，或者是json单独配置从后端获取数据
            if (window.serverConfig.isApi || jsonData.isApi) {
                if (!jsonData.isApi) {
                    // isApi优先级更高
                    return {
                        RECORDS: jsonData.RECORDS
                    }
                }
                let param = params ? params : jsonData.param
                // json里面单独配置了请求服务地址
                if (jsonData.url) {
                    // get请求
                    if (jsonData.method == 'get') {
                        const {data: getData} = await base.get(jsonData.url + jsonData.path)
                        console.warn(`DataManager获取数据(自定义url) ${jsonData.url}${jsonData.path}`)
                        return getData
                    } else if (jsonData.method == 'post') {
                        const {data: postData} = await base.post(jsonData.url + jsonData.path, param)
                        console.warn(`DataManager获取数据(自定义url) ${jsonData.url}${jsonData.path}`)
                        return postData
                    }
                }

                if (jsonData.method == 'get') {
                    let getData = await base.doGet(jsonData.path, false, null, jsonData.needToken)
                    console.warn(`DataManager获取数据 ${jsonData.path}`)
                    let dealRecords = this.dealRecords(getData);
                    if (jsonData.cache){
                        window.dataCache.setCache(jsonPath,dealRecords.RECORDS)
                    }
                    return dealRecords
                } else if (jsonData.method == 'post') {
                    const postData = await base.doPost(jsonData.path, param, false, null, jsonData.needToken)
                    console.warn(`DataManager获取数据 ${jsonData.path}`)
                    let dealRecords = this.dealRecords(postData);
                    if (jsonData.cache){
                        window.dataCache.setCache(jsonPath,dealRecords.RECORDS)
                    }
                    return dealRecords
                }
            }
            // 如果不是从后端获取的数据，就直接读取json的文件内容
            // console.warn('jsonData',jsonData)
            if (jsonData.cache){
                window.dataCache.setCache(jsonPath,jsonData.RECORDS)
            }
            return {
                RECORDS: jsonData.RECORDS
            }
        } catch (e) {
            console.error(`获取${jsonPath}失败，请检查配置的json路径`, e)
            return {
                RECORDS: []
            }
        }
    }

    /**
     * 判断json是否从后端接口获取数据
     * @param jsonPath json路径
     * @return {Promise<boolean>} 是否从后端接口获取数据
     */
    static async isApiGetData(jsonPath) {
        let jsonData = await base.get(jsonPath)
        if (window.serverConfig.isApi) {
            return !!jsonData.isApi;
        }
        return !!jsonData.isApi
    }

    static dealRecords(data) {
        if (data.RECORDS) {
            return data
        } else {
            data.RECORDS = data.records
            return data
        }
    }

    /**
     * 获取全部数据
     * @returns {Promise<*[]>}
     */
    static async getAllData() {
        let allData = []
        for (let layerMap of window.layerList) {
            if (!layerMap.layerConfig.jsonPath) {
                console.warn('图层没有配置jsonPath', layerMap)
                continue
            }
            let data = await this.getDataByPath(layerMap.layerConfig.jsonPath)
            let record = data.RECORDS
            for (let recordElement of record) {
                recordElement.idLayer = layerMap.id
            }
            allData.push(...record)
        }
        return allData
    }

    /**
     * 获取机柜机柜组设备数据
     * @returns {Promise<*>}
     */
    static async getBoxData() {
        let {RECORDS} = await DataManager.getDataByPath('./data/机柜组-机柜-设备数据.json')
        console.log('getBoxData', RECORDS)
        return RECORDS
    }

    /**
     * 获取上架设备模板数据
     * @returns {Promise<*>}
     */
    static async getTempEquipmentData() {
        let {RECORDS} = await DataManager.getDataByPath('./data/上架设备类型.json')
        return RECORDS
    }

    /**
     * 获取全部设备数据
     * @return {Promise<*>}
     */
    static async getAllEqData() {
        let data = []
        let boxData = await this.getBoxData()
        for (const rackGroup of boxData) {
            for (const rack of rackGroup.rackList) {
                for (const eq of rack.equipmentList) {
                    eq.coord = rack.coord
                    data.push(eq)
                }
            }
        }
        return data
    }

    /**
     * 获取无需上架的设备
     * @return {Promise<*>}
     */
    static async getAllDeviceList() {
        let {RECORDS} = await this.getDataByPath('./data/设备数据.json')
        return RECORDS
    }

    /**
     * 获取人员数据
     * @return {Promise<*>}
     */
    static async getAllPersonList() {
        let {RECORDS} = await this.getDataByPath('./data/人员数据.json')
        return RECORDS
    }

}
