export default class BaseHandler {
    /**
     * 处理点击逻辑--子类必须实现
     * @abstract
     * @param id 图层点位子id
     * @param idLayer 图层id
     */
    handleClick({id,idLayer}){

    }

    /**
     * 处理鼠标浮动逻辑--子类必须实现
     * @abstract
     * @param id 图层点位子id
     * @param idLayer 图层id
     */
    handleHover({id,idLayer}){

    }

    /**
     * 过滤图层
     * @abstract
     * @param id 图层点位子id
     * @param idLayer 图层id
     * @return {boolean} 是否属于处理范围
     */
    filterLayer({id,idLayer}){
        return false
    }
}
