<template>
  <!--  -->
  <div class="layout_wraper" v-show="functionStatus == '数据分析'">
    <div class="layout_left">
      <BaseDrawer>
        <div class="nhgl_left">
          <div class="zltj">
            <MainTitle val="总量统计" />
            <ul>
              <li v-for="(item,index) in zltjData" :key="index">
                <img :src="img[index]" alt />
                <div class="wz">
                  <p>{{item.name}}</p>
                  <p>{{item.value}}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="fzxjszy">
            <MainTitle val="分中心计算资源" />
            <ul>
              <li v-for="(item,index) in fzxjszyData" :key="index">
                <p>{{item.name}}</p>
                <p>{{item.value}}</p>
              </li>
            </ul>
          </div>
          <div class="zysblttj">
            <MainTitle val="资源设备类型统计" />
            <pieChart
              :displayModel="8"
              roseheight="256px"
              :rowData="zysblttjData"
              class="charts"
              :color="['#A68CDB','#17CAA6','#73FFFF','#56A2E7','#1F7ED6']"
              :loop_labelStyle="{

                  left:'-8px',
                  top:'2%'
                }"
              :backImg="{
               url: require('@/assets/img/WLTS/zxpie.png'),
             pos: '54px 68px',
             size: '226px 226px'


              }"
            />
          </div>
          <div class="zysylybhqs">
            <MainTitle val="资源使用率月变化趋势" />
            <ChartLineArea class="charts" :rowData="zysylybhqsData" />
          </div>
        </div>
      </BaseDrawer>
    </div>
    <div class="layout_center"></div>
    <div class="layout_right">
      <BaseDrawer direction="rtl">
        <div class="nhgl_right">
          <div class="jffbt">
            <MainTitle val="机房分布图" />
            <ul>
              <li v-for="(item,index) in  jffbtData.list" :key="index">
                <p>{{item.name}}</p>
                <p :style="{color:['#4cffa5','#A5A5FF'][index]}">{{item.value}}</p>
              </li>
            </ul>
            <DuitieZhutu class="charts" :rowData="jffbtData.data" />
          </div>
          <div class="jfbb">
            <MainTitle val="机房报表" />
            <ul>
              <li
                :class="index==i?'ac':''"
                v-for="(item,index) in ['服务器','网络设备','存储设备','扩容设备']"
                @click="jfbbtabList(item,index)"
                :key="index"
              >{{item}}</li>
            </ul>
            <pieChart
              :displayModel="8"
              roseheight="324px"
              :rowData="jfbbData"
              class="charts"
              :color="['#A68CDB','#17CAA6','#73FFFF','#56A2E7','#1F7ED6']"
              :loop_labelStyle="{

                  left:'-8px',
                  top:'13%'
                }"
              :backImg="{
               url: require('@/assets/img/WLTS/zxpie.png'),
             pos: '54px 68px',
             size: '226px 226px'


              }"
            />
          </div>

          <div class="fzxywgj">
            <MainTitle val="分中心业务告警" />
            <Table
              :Data="fzxywgjData"
              :NavData="['时间','服务器名称','信息']"
              class="tab"
              :TableStyle="{
                 TableHeaderWidth:['233px', '151px', '232px',],
                 TableWidth: '619px',
                  NavHeight: '50px',
                NavlineHeight: '50px',
                NavTop: '0px',
            }"
            />
          </div>
        </div>
      </BaseDrawer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseDrawer from '@/components/common/BaseDrawer'
import MainTitle from '@/components/common/MainTitle'
import BarChart from '@/components/charts/BarChart'
import pieChart from '@/components/charts/pieChart'
import BarChartV2 from '@/components/charts/BarChartV2'
import BarDomChart from '@/components/charts/BarDomChart'
import LineCharts from '@/components/charts/LineCharts+xuxian'
import Table from '@/components/charts/Table'
import ChartLineArea from '@/components/charts/ChartLineArea'
import DuitieZhutu from '@/components/charts/DuitieZhutu'
export default {
  name: 'ZiYuanTaiShi',
  components: {
    BaseDrawer,
    MainTitle,
    BarChart,
    pieChart,
    BarChartV2,
    BarDomChart,
    LineCharts,
    Table,
    DuitieZhutu,
    ChartLineArea
  },
  data() {
    return {
      i: 0,
      img: [
        require('@/assets/img/ZYTS/l1.png'),
        require('@/assets/img/ZYTS/l2.png'),
        require('@/assets/img/ZYTS/l3.png'),
        require('@/assets/img/ZYTS/l4.png')
      ],
      // 总量统计
      zltjData: [
        {
          name: '服务器总数(个)',
          value: '4845'
        },

      ],
      // 分中心计算资源
      fzxjszyData: [
        {
          name: 'CPU使用率',
          value: '60%'
        },
        {
          name: '内存使用率',
          value: '80%'
        },
        {
          name: '内存使用比例',
          value: '45%'
        },
        {
          name: '已使用存储比例',
          value: '35%'
        }
      ],
      // 资源设备类型统计
      zysblttjData: [
        { category: '配件设备', value: 410 },
        { category: '服务器', value: 310 },
        { category: '存储设备', value: 210 },
        { category: '网络设备', value: 200 }
      ],
      // 资源使用率月变化趋势
      zysylybhqsData: [
        {
          name: '01',
          value: 40
        },
        {
          name: '02',
          value: 50
        },
        {
          name: '03',
          value: 30
        },
        {
          name: '04',
          value: 30
        },
        {
          name: '05',
          value: 20
        },
        {
          name: '06',
          value: 30
        },
        {
          name: '07',
          value: 40
        },
        {
          name: '08',
          value: 30
        },
        {
          name: '09',
          value: 70
        },
        {
          name: '10',
          value: 50
        },
        {
          name: '11',
          value: 30
        },
        {
          name: '12',
          value: 30
        }
      ],
      // 机房分布图
      jffbtData: {
        list: [
          {
            name: '机房数量',
            value: '16'
          },
          {
            name: '机架数量',
            value: '145'
          }
        ],
        data: [
          {
            name: '北京',
            value: 90,
            item: 10
          },
          {
            name: '上海',
            value: 80,
            item: 20
          },
          {
            name: '天津',
            value: 70,
            item: 30
          },
          {
            name: '重庆',
            value: 30,
            item: 70
          },
          {
            name: '内蒙古',
            value: 30,
            item: 70
          },
          {
            name: '江苏',
            value: 70,
            item: 30
          }
        ]
      },
      // 机房报表
      jfbbData: [
        { category: '机架式小型机', value: 39 },
        { category: '机架式PC服务器', value: 39 },
        { category: '刀片式PC服务器', value: 39 },
        { category: '刀片式小型机', value: 34 },
        { category: '工控机', value: 33 }
      ],
      // 分中心业务告警
      fzxywgjData: [
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 001',
          third: '服务01 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 002',
          third: '服务02 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 003',
          third: '服务03 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 004',
          third: '服务04 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 005',
          third: '服务05 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 006',
          third: '服务06 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 007',
          third: '服务07 CPU超负荷运行'
        },
        {
          first: '2022-07-30 05:22:18',
          second: '服务器 008',
          third: '服务08 CPU超负荷运行'
        }
      ]
    }
  },
  computed: {
    ...mapState(['functionStatus'])
  },

  created() {
    // 总量统计
    this.reqGetzltjData()
    // 分中心计算资源
    this.reqGetfzxjszyData()
    // 资源设备类型统计
    this.reqGetzysblttjData()
    // 资源使用率月变化趋势
    this.reqGetzysylybhqsData()
    // 机房分布图
    this.reqGetjffbtData()
    // 机房报表
    this.reqGetjfbbData('服务器')
    // 分中心业务告警
    this.reqGetfzxywgjData()
  },
  beforeDestroy() {},
  methods: {
    jfbbtabList(item, index) {
      this.i = index
      this.reqGetjfbbData(item)
    },
    // 总量统计
    async reqGetzltjData() {
      let res = await this.$req.ZYTS.reqGetzltjData()
      this.zltjData = res.data
    },
    // 分中心计算资源
    async reqGetfzxjszyData() {
      let res = await this.$req.ZYTS.reqGetfzxjszyData()
      this.fzxjszyData = res.data
    },
    // 资源设备类型统计
    async reqGetzysblttjData() {
      let res = await this.$req.ZYTS.reqGetzysblttjData()
      this.zysblttjData = res.data
    },
    // 资源使用率月变化趋势
    async reqGetzysylybhqsData() {
      let res = await this.$req.ZYTS.reqGetzysylybhqsData()
      this.zysylybhqsData = res.data
    },
    // 机房分布图
    async reqGetjffbtData() {
      let res = await this.$req.ZYTS.reqGetjffbtData()
      this.jffbtData = res.data
    },
    // 机房报表
    async reqGetjfbbData(p) {
      let res = await this.$req.ZYTS.reqGetjfbbData(p)
      this.jfbbData = res.data
    },
    // 分中心业务告警
    async reqGetfzxywgjData() {
      let res = await this.$req.ZYTS.reqGetfzxywgjData()
      this.fzxywgjData = res.data

    }
  }
}
</script>

<style lang="less" scoped>
.layout_wraper {
  width: 100%;
  height: 100%;
  position: relative;
  .nhgl_left {
    width: 100%;
    height: 100%;
    background: url('@/assets/img/WLTS/left.png') no-repeat;
    box-sizing: border-box;
    text-align: center;
    .zltj {
      height: 376px;
      ul {
        margin: 0 auto;
        margin-top: 30px;
        width: 640px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 320px;
          display: flex;
          margin-bottom: 40px;
          img {
            width: 90px;
            height: 90px;
            margin-right: 8px;
          }
          .wz {
            text-align: left;
            p:nth-child(1) {
              font-family: '微软雅黑';
              font-size: 28px;
              color: #fff;
              text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
              // text-shadow: 0px 10px 10px red;
            }
            p:nth-child(2) {
              font-family: 'Voltage';
              font-size: 48px;
              color: #189af9;
            }
          }
        }
      }
    }
    .fzxjszy {
      height: 418px;
      ul {
        margin: 0 auto;
        margin-top: 24px;
        width: 640px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 290px;
          height: 144px;
          background: url('@/assets/img/ZYTS/liBG.png') no-repeat;
          text-align: center;
          margin-bottom: 20px;
          p:nth-child(1) {
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
            text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
            // text-shadow: 0px 10px 10px red;
          }
          p:nth-child(2) {
            font-family: 'Voltage';
            font-size: 60px;
            color: #fff;
          }
        }
      }
    }
    .zysblttj {
      height: 400px;
      .charts {
        transform: translate(0%, -5%);
      }
    }
    .zysylybhqs {
      position: relative;
      height: 500px;
      .charts {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
      }
    }
  }
  .nhgl_right {
    width: 100%;
    height: 100%;
    background: url('@/assets/img/WLTS/left.png') no-repeat;
    box-sizing: border-box;
    text-align: center;
    .jffbt {
      height: 664px;
      position: relative;
      ul {
        width: 625px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        margin-top: 20px;
        li {
          width: 308px;
          height: 60px;
          display: flex;
          align-items: center;
          background: url('@/assets/img/ZYTS/jf.png') no-repeat;
          p:nth-child(1) {
            text-align: left;
            text-indent: 14px;
            width: 234px;
            color: #fff;
            font-size: 28px;
            font-family: '微软雅黑';
            text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
          }
          p:nth-child(2) {
            color: #4cffa5;
            font-size: 48px;
            font-family: 'Voltage';
          }
        }
      }
      .charts {
        position: absolute;
        left: 55%;
        top: 58%;
        transform: translate(-50%, -50%);
      }
    }
    .jfbb {
      height: 518px;
      ul {
        width: 625px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: -20px;
        .ac {
          background: #189bfa;
          color: #fff;
        }
        li {
          cursor: pointer;
          pointer-events: all;
          width: 140px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #bfebff;
          font-size: 26px;
          border-radius: 5px;
          background: #02487b;
        }
      }
      .charts {
        pointer-events: none;
        transform: translate(0%, -7%);
      }
    }
    .fzxywgj {
      position: relative;
      height: 478px;
      .tab {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
