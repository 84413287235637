/**
 * 事件控制混入
 * @author haowei
 */
export default {
    created(){
        console.log('组件绑定控制器事件',this.$options.name)
        window.ctrlEventManager.bindComponent(this)
    },
    beforeDestroy(){
        console.log('组件解绑控制器事件',this.$options.name)
        window.ctrlEventManager.unBindComponent(this)
    }
}
