export default class Building {
  static showBuildingFloor(params) {
    window.appInstance.uniCall('showBuildingFloor', {
      id: params.id,
      floor: params.floor,
      animation: params.animation || 1
    })
  }
  static resetBuildingFloor(params, callback) {
    let jsonData = {
      id: params.id,
      animation: params.animation || 1
    }
    window.appInstance.uniCall('resetBuildingFloor', jsonData, res => {
      // console.log('resetBuildingFloor')
      callback && callback()
    })
  }
  // 高亮建筑
  static highlightBuilding(id) {
    window.appInstance.uniCall('highlightBuilding', {
      id: id,
      type: 'style1'
    })
  }
  // 高亮楼层
  static highlightFloor(id, floor) {
    window.appInstance.uniCall('highlightFloor', {
      id: id,
      floor: floor,
      type: 'style1'
    })
  }

  // 建筑剖分
  static setModelClipping(id = 'building01') {
    let jsonData = {
      id: id,
      modelType: 'building',
      planePadding: [0, 0, 0, 0, 0, 0],
      planeColor: '#0000ff',
      planeStyle: 'style1',
    }
    appInstance.uniCall(
      'setModelClipping',
      jsonData,
      result => {
        // console.log(result);
      }
    );
  }

  // 恢复建筑剖分
  static clearModelClipping() {
    appInstance.uniCall('clearModelClipping', {}, result => {
      // console.log(result);
    });
  }

  // 楼层展开
  static expandBuildingFloor(params) {
    let jsonData = {
      id: params.id,
      floorPadding: params.floorPadding,
      duration: params.duration || 1,
    };

    appInstance.uniCall('expandBuildingFloor', jsonData, result => {
      // console.log(result);
    });
  }
}