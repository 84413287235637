<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: guozhiqing
 * @Date: 2021-06-21 15:22:22
 * @LastEditors: guozhiqing
 * @LastEditTime: 2021-06-21 15:22:22
-->
<template>
  <div class="title_wrap">
    <div class="botline"></div>
    <div class="left_txt">
      <img src="../../assets/img/layout/maintitlelogo.png" />
      <span>{{ val }}</span>
    </div>
    <div class="right_cont">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MainTitle',
  props: {
    val: {
      type: String,
      default: '标题名称'
    }
  },
  data() {
    return {}
  },
  created() {}
}
</script>

<style lang="less">
.title_wrap {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  height: 78px;
  position: relative;
  .botline {
    width: calc(100% - 4px);
    height: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    left: 0;
    bottom: 0;
    position: absolute;
  }
  .leftline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 8px;
    background-color: #9febfb;
  }
  .rightract {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 4px;
    height: 4px;
    background-color: #bbf1f7;
  }
  .left_txt {
    width: 1380px;
    height: 32px;
    position: relative;
    display: flex;
    img {
      position: absolute;
      left: 30px;
      top: 20px;
    }
    span {
      position: absolute;
      font-family: '微软雅黑';
      font-weight: bold;
      font-size: 36px;
      line-height: 76px;
      background: linear-gradient(to bottom, #fff, #dbf2f5);
      -webkit-background-clip: text;
      color: transparent;
      left: 78px;
    }
  }
  .right_cont {
    position: absolute;
    pointer-events: all;
    top: 7px;
    right: 10px;
    display: flex;
  }
}
</style>
