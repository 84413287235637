import BaseLayerController from './BaseLayerController'
import Store from '@/store'
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 地标图层控制器
 *
 * @author haowei
 */
export default class LandmarkLayerController extends BaseLayerController{
  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap);
    layerMap.layerConfig.jsonData = data;
    layerMap.layerConfig.status = 'hide';
    data.clickShowDecorator = layerMap.layerConfig.clickShowDecorator
    data.hoverShowDecorator = layerMap.layerConfig.hoverShowDecorator
    data.clickEventDisable = layerMap.layerConfig.clickEventDisable
    await UEapi.LandmarkLayer.addLandmarkLayer(data)
  }

  async getData(layerMap) {
    let rowData = await super.getRowData(layerMap)
    layerMap.layerConfig.rawData = rowData;
    const { spaceLevel } = Store.state
    let defaultOption = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      autoScale: false,
      visible: false,
      legends: [],
      data: [],
    }
    let jsonData = Object.assign({}, defaultOption, layerMap)
    // console.log(jsonData)
    jsonData.data = rowData.map((item) => {
      return {
        id: item.id,
        cnName: item.cnName,
        label: '',
        coord: [item.lon, item.lat],
        coordZ: layerMap.layerConfig.coordZ || BaseLayerController.coordZList[spaceLevel] || 1,
        type: item.legend || 'default',
        iconName:
          item.iconName ||
          layerMap.legends[0].iconName ||
          layerMap.iconName ||
          'shujuzhongxin',
      }
    });
    return jsonData;
  }
}
