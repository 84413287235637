import StandardStateHandler from "@/core/state/StandardStateHandler";
import {boxGroupList, boxList, linkList, linkList2} from "@/config/ModelTemp";
import UEapi from "@/utils/UEapi/UEapi";
import SpaceController from "@/core/logic/SpaceController";
import BuildingController from "@/core/logic/BuildingController";

export default class FloorStandardStateHandler extends StandardStateHandler {

    async init() {
        BuildingController.expandBuilding()
        window.cameraManager.onSetCamera('F3', null, {})
        await super.init();
    }

    async reset() {
        await super.reset();
    }
}
