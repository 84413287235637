import api from "@/api/index";
import wangluotaishi from "@/api/taishi/wangluotaishi";
import ziyuantaishi from "@/api/taishi/ziyuantaishi";
import shebeitaishi from "@/api/taishi/shebeitaishi";

const instance = {};

instance.WLTS = {...wangluotaishi}
instance.ZYTS = {...ziyuantaishi}
instance.SBTS = {...shebeitaishi}
/**
 * 登录请求
 * @param params 参数
 * @returns {Promise<*>}
 */
instance.login = async (params) => {
    return await api.doPost('/login', params, true, {}, false);
}

export default instance;
