<template>
  <div class="shujuzhongxinSheng" >
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse title="基础信息" @changeisReceiveFair="isReceiveFair" :isReceiveFair="jcxxIsShow">
            <div class="con">
              <div class="img">
                <img src="@/assets/img/SSGJ/ABRY.png" alt />
              </div>
              <div class="xx">
                <Tag
                  v-for="(item,index) in anbaorenyuanjcxx"
                  :key="index"
                  :name="item.name"
                  :value="item.value"
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div class="lssj">
          <Collapse
            title="历史数据"
            :iconUrl="require('@/assets/img/SSGJ/lssjIcon.png')"
            :isywxt="true"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table
              :CData="anbaorenyuangjxx"
              :NavData="['时间 ','位置信息']"
              :NavWidth="['268px','340px']"
            />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea
  },
  data() {
    return {
      anbaorenyuanjcxx: [
        {
          name: '姓名',
          value: '张三'
        },
        {
          name: '类别',
          value: '安保人员'
        },
        {
          name: '所属',
          value: '安保一队'
        },
        {
          name: '值班时间',
          value: '12:00-24:00'
        },
        {
          name: '联系方式',
          value: '1361526253'
        }
      ],
      anbaorenyuangjxx: [
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-F8-电表间 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-B1  '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G6-F3-走廊  '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G1-F2-储藏室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: 'G5-大厅-值班室 '
        }
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo'])
  },
  mounted() {
    this.anbaorenyuanjcxx[0].value = this.objectInfo.name
  },
  watch: {
    objectInfo: {
      handler(val) {
        this.anbaorenyuanjcxx[0].value = val.name
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  .jcxx {
    .con {
      display: flex;
      justify-content: space-between;
      .img {
        width: 240px;
      }
      .xx {
        width: 310px;
      }
    }
  }
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
        .Tag:nth-child(2),
        .Tag:nth-child(3),
        .Tag:nth-child(4),
        .Tag:nth-child(5),
        .Tag:nth-child(6),
        .Tag:nth-child(7) {
          .Tag_right {
            color: #00ffff;
          }
        }
      }
    }
  }
}
</style>
