<!--
 * @Author: yejinliang
 * @Date: 2022-05-26 16:25:07
 * @LastEditTime: 2022-05-27 02:02:00
 * @LastEditors: yejinliang
 * @Description: 通用弹窗标牌
-->
<template>
  <div class="common_panel"></div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'YQJSPanel',
  props: {
    leftList: {
      type: Array,
      default: () => {
        return ['仓储位置', '实验室样品数', '实验室耗材数', '统计时间']
      },
    },
    rightList: {
      type: Array,
      default: () => {
        return ['4楼B428', '10个', '10个', '2022年7月8日']
      },
    },
  },
  mounted() {},
  destroyed() {},
  data() {
    return {}
  },
  computed: {
    ...mapState(['prevModelLayer']),
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="less" scoped>
.common_panel {
  pointer-events: all;
  position: absolute;
  top: 0px;
  transform: scale(0.6);

  left: 0px;
  width: 330px;
  min-height: 170px;
  background-image: url('../../assets/img/YQTS/q.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .titys {
    position: absolute;
    left: 90px;
    top: 5px;
    font-size: 32px;
    color: #fff;
    font-family: '微软雅黑';
  }
  .common_panel_header {
    display: flex;
    position: relative;
    justify-content: space-between;
    box-sizing: border-box;
    height: 26px;
    padding: 0 13px;
    top: 8px;
    .title {
      font-family: '碳纤维正粗黑简体';
      font-size: 20px;
      background: linear-gradient(to bottom, #ffffff, #c5fbfc);
      -webkit-background-clip: text;
      color: transparent;
      position: absolute;
      bottom: 2px;
    }
    .close {
      width: 18px;
      height: 18px;
      // background: url('../../assets/img/GisPanel/close.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 3px;
      right: 10px;
    }
  }
  .common_panel_body {
    width: 282px;
    height: 99px;
    display: flex;
    justify-content: space-between;
    font-family: 'PingFang SC';
    font-size: 16px;
    margin: 0px auto;
    margin-top: 20px;
    .body_left {
      height: 100%;
      width: 131px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #bfebff;
      line-height: 24px;
      .leftItem {
        width: 100%;
        height: 24px;
        background: #00000035;
        display: flex;
        box-sizing: border-box;
        padding-left: 5px;
      }
    }
    .body_right {
      height: 100%;
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #fff;
      .rightItem {
        width: 100%;
        height: 24px;
        background: #00000020;
        line-height: 24px;
        box-sizing: border-box;
        padding: 0 5px;
      }
      .rightItemzx {
        color: #4cffd2;
        width: 100%;
        height: 24px;
        background: #00000020;
        line-height: 24px;
        box-sizing: border-box;
        padding: 0 5px;
      }
    }
  }
}
</style>
