<template>
  <div class='shujuzhongxinSheng'>
    <BaseDrawer direction='rtl' :ShowClose='true'>
      <div class='wrap'>
        <Title/>
        <div class='jcxx'>
          <Collapse
              title='基础信息'
              @changeisReceiveFair='isReceiveFair'
              :isReceiveFair='jcxxIsShow'
          >
            <Tag
                v-for='(item, index) in fuwuqibiaozhunjcxx'
                :key='index'
                :name='item.name'
                :value='item.value'
            />
          </Collapse>
        </div>
        <div class='ztxx'>
          <Collapse
              title='状态信息'
              :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
              @changeisReceiveFair='isReceiveFair'
              :isReceiveFair='ztxxIsShow'
          >
            <Tag
                v-for='(item, index) in fuwuqibiaozhunztxx'
                :key='index'
                :name='item.name'
                :value='item.value'
            />
          </Collapse>
        </div>
        <div class='czzl'>
          <Collapse
              title='操作指令'
              :iconUrl="require('@/assets/img/SSGJ/czzlIcon.png')"
              @changeisReceiveFair='isReceiveFair'
              :isReceiveFair='czzlIsShow'
          >
            <TagButton
                v-for='(item, index) in fuwuqibiaozhunczzl'
                :key='index'
                @runInHole='runInHole'
                :text='item'
                :iconUrl='btnimg[index]'
            />
          </Collapse>
        </div>
        <div class='ckll' v-if="linkNameList.length>0">
          <Collapse
              title='查看链路'
              :iconUrl="require('@/assets/img/SSGJ/ckll.png')"
              @changeisReceiveFair='isReceiveFair'
              :isReceiveFair='ckllIsShow'
          >
            <div class='ul'>
              <div
                  class='li'
                  @click='linkAllPoint(fuwuqibiaozhunckll[0])'
                  :class="i == 0 ? 'ac' : ''"
              >
                <div class='TagButton_image'>
                  <img :src='ckllimg[0]' alt/>
                </div>
                <div class='TagButton_text'>{{ fuwuqibiaozhunckll[0] }}</div>
              </div>

              <div v-for="(item,index) in linkNameList" :key="item.id">
                <div
                    class='li'
                    @click="LinkLiPoint(fuwuqibiaozhunckll[index+1], index)"
                    :class="i == (index+1) ? 'ac' : ''"
                >
                  <div class='TagButton_image'>
                    <img :src='ckllimg[1]' alt/>
                  </div>
                  <div class='TagButton_text'>{{ fuwuqibiaozhunckll[index+1] }}</div>
                </div>
                <LinkDetails v-show='(i == index+1)' :title='fuwuqibiaozhunckll[index+1]' :list="item.details"/>
              </div>
<!--              <div-->
<!--                  class='li'-->
<!--                  @click='LinkLiPoint(fuwuqibiaozhunckll[1], 1)'-->
<!--                  :class="i == 1 ? 'ac' : ''"-->
<!--              >-->
<!--                <div class='TagButton_image'>-->
<!--                  <img :src='ckllimg[1]' alt/>-->
<!--                </div>-->
<!--                <div class='TagButton_text'>{{ fuwuqibiaozhunckll[1] }}</div>-->
<!--              </div>-->
<!--              <LinkDetails v-show='i == 1' :title='fuwuqibiaozhunckll[1]'/>-->
<!--              <div-->
<!--                  class='li'-->
<!--                  @click='LinkLiPoint(fuwuqibiaozhunckll[2], 2)'-->
<!--                  :class="i == 2 ? 'ac' : ''"-->
<!--              >-->
<!--                <div class='TagButton_image'>-->
<!--                  <img :src='ckllimg[2]' alt/>-->
<!--                </div>-->
<!--                <div class='TagButton_text'>{{ fuwuqibiaozhunckll[2] }}</div>-->
<!--              </div>-->
<!--              <LinkDetails v-show='i == 2' :title='fuwuqibiaozhunckll[2]'/>-->
            </div>
          </Collapse>
        </div>
        <div class='gjxx'>
          <Collapse
              title='告警信息'
              :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
              @changeisReceiveFair='isReceiveFair'
              :isReceiveFair='gjxxIsShow'
          >
            <Table
                :CData='fuwuqibiaozhungjxx'
                :NavData="['时间 ', '告警级别', '内容 ']"
                :NavWidth="['278px', '128px', '200px']"
            />
          </Collapse>
        </div>
        <div class='lssj'>
          <Collapse
              title='历史数据'
              :iconUrl="require('@/assets/img/SSGJ/lssjIcon.png')"
              @changeisReceiveFair='isReceiveFair'
              :isReceiveFair='lssjIsShow'
              :isywxt='true'
          >
            <Tag :name="'CPU使用情况'" :value="''"/>
            <ChartLineArea
                class='charts'
                :rowData='cpusyqk'
                left='3%'
                right='5%'
                unit='GHz'
                height='350px'
                :barColor="['#73FFFF', '#73FFFF00']"
            />
            <Tag :name="'内存使用情况'" :value="''"/>
            <ChartLineArea
                class='charts'
                :rowData='ncsyqk'
                left='3%'
                right='5%'
                unit='GB'
                height='350px'
                :barColor="['#94DE6B', '#94DE6B00']"
            />
            <Tag :name="'硬盘使用情况'" :value="''"/>
            <ChartLineArea
                class='charts'
                :rowData='cpsyqk'
                left='3%'
                right='5%'
                unit='TB'
                height='350px'
                :barColor="['#4DA6FF', '#4DA6FF00']"
            />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import LinkDetails from '@/components/common/link/LinkDetails'
import cameraList from '@/config/cameraList'
import UEapi from '@/utils/UEapi/UEapi'
import {mapState} from 'vuex'
import {linkList, linkList2} from "@/config/ModelTemp";
import LinkController from "@/core/logic/linkController";

export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea,
    LinkDetails
  },
  data() {
    return {
      fuwuqibiaozhunjcxx: [
        {
          name: '编号',
          value: 'G2 7914AC10-01'
        },
        {
          name: '所属',
          value: 'SJZX-01-JF'
        },
        {
          name: '品牌',
          value: 'IBM'
        },
        {
          name: '型号',
          value: 'G2 7914AC10'
        },
        {
          name: '类型',
          value: '存储服务器'
        }
      ],
      fuwuqibiaozhunztxx: [
        {
          name: '设备状态',
          value: '在线'
        },
        {
          name: 'CPU负载',
          value: '11%'
        },
        {
          name: 'GPU负载',
          value: '23%'
        },
        {
          name: '内存已占用空间',
          value: '20GB'
        },
        {
          name: '内存负载',
          value: '31%'
        }
      ],
      fuwuqibiaozhunczzl: ['设备正面', '设备反面'],
      fuwuqibiaozhunckll: [
        '全部显示',
        'Server-b22 F03 BANKA 02',
        'Server-b22 F03 BANKA 04'
      ],
      fuwuqibiaozhungjxx: [
        {
          time: '2022-07-11 12:00:00',
          content: '三级',
          second: '服务器连接异常'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '三级',
          second: '服务器连接异常'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '三级',
          second: 'CPU使用率过高'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '三级',
          second: 'CPU使用率过高'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '三级',
          second: '服务器连接异常'
        },
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'},
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'},
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'},
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'},
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'},
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'},
        {time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障'}
      ],
      ckllimg: [
        require('@/assets/img/SSGJ/qbxxIcon.png'),
        require('@/assets/img/SSGJ/ckll.png'),
        require('@/assets/img/SSGJ/ckll.png')
      ],
      btnimg: [
        require('@/assets/img/SSGJ/sbfmIcon.png'),
        require('@/assets/img/SSGJ/sbfmIcon.png'),
        require('@/assets/img/SSGJ/sbfmIcon.png')
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: false,
      cpusyqk: [
        {
          name: '00:00',
          value: 40
        },
        {
          name: '04:00',
          value: 50
        },
        {
          name: '08:00',
          value: 30
        },
        {
          name: '12:00',
          value: 30
        },
        {
          name: '16:00',
          value: 20
        },
        {
          name: '20:00',
          value: 30
        },
        {
          name: '24:00',
          value: 40
        }
      ],
      ncsyqk: [
        {
          name: '00:00',
          value: 40
        },
        {
          name: '04:00',
          value: 50
        },
        {
          name: '08:00',
          value: 30
        },
        {
          name: '12:00',
          value: 30
        },
        {
          name: '16:00',
          value: 20
        },
        {
          name: '20:00',
          value: 30
        },
        {
          name: '24:00',
          value: 40
        }
      ],
      cpsyqk: [
        {
          name: '00:00',
          value: 40
        },
        {
          name: '04:00',
          value: 50
        },
        {
          name: '08:00',
          value: 30
        },
        {
          name: '12:00',
          value: 30
        },
        {
          name: '16:00',
          value: 20
        },
        {
          name: '20:00',
          value: 30
        },
        {
          name: '24:00',
          value: 40
        }
      ],
      i: -1,
      link1Show: false,
      link2Show: false,

      deviceId:'',
      linkNameList: []
    }
  },
  methods: {
    runInHole(p) {
      // console.log('this.objectInfo', this.objectInfo)
      const name = this.objectInfo.name || 'C03-004-04_SM_配线架'
      let equipmentCtrl = window.modelManager.controls.equipment
      if (p == '显示虚拟机') {
        equipmentCtrl._handleVmBox(false).then(() => {
          equipmentCtrl._handleVM(false)
        })
        this.fuwuqibiaozhunczzl = ['隐藏虚拟机', '设备正面', '设备反面'];
      } else if (p == '隐藏虚拟机') {
        window.modelManager.controls.equipment._handleVM(true)
        equipmentCtrl._handleVmBox(true).then(() => {
          equipmentCtrl._handleVM(false)
        })
        this.fuwuqibiaozhunczzl = ['显示虚拟机', '设备正面', '设备反面'];
      } else {
        // UEapi.Camera.setCamera(
        //   cameraList[name + (p === '设备反面' ? '设备反面' : '')]
        // )
        let equipmentCtrl = this.$modelManager.controls.equipment
        if (p == '设备正面') {
          this.$cameraManager.onSetCamera(equipmentCtrl.currentEqId, null, {id: equipmentCtrl.currentEqId, pos: 0})
        } else {
          this.$cameraManager.onSetCamera(equipmentCtrl.currentEqId, null, {id: equipmentCtrl.currentEqId, pos: 1})
        }
      }
      // if (p === '显示虚拟机' || p === '隐藏虚拟机' || p === '虚拟机') {
      //   // 这里写虚拟机去掉外壳代码
      //   if (this.AnimationState === '隐藏_虚拟机机箱') {
      //     UEapi.Model.playAnimation({
      //       id: 'C03-004-04_SM_数据服务器_虚拟机',
      //       name: '显示_虚拟机机箱',
      //       state: 'begin'
      //     })
      //
      //     this.vmShow = false;
      //     this.fuwuqibiaozhunczzl = ['隐藏虚拟机', '设备正面', '设备反面'];
      //
      //     this.$store.commit('setAnimationState', '显示_虚拟机机箱')
      //   } else {
      //     UEapi.Model.playAnimation({
      //       id: 'C03-004-04_SM_数据服务器_虚拟机',
      //       name: '隐藏_虚拟机机箱',
      //       state: 'begin'
      //     })
      //
      //     this.vmShow = true;
      //     this.$store.commit('setAnimationState', '隐藏_虚拟机机箱')
      //   }
      // } else {
      //   UEapi.Camera.setCamera(
      //     cameraList[name + (p === '设备反面' ? '设备反面' : '')]
      //   )
      // }
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      let head = document.querySelectorAll('.Collapse_head')
      for (let index = 0; index < head.length; index++) {
        const element = head[index]
      }
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    },
    linkAllPoint(item){
      this.i = 0
      if (item == '全部显示') {
        // UEapi.Model.setModelVisibility({ids: linkList, visible: true});
        // UEapi.Model.setModelVisibility({ids: linkList2, visible: true});
        // this.link1Show = true;
        // this.link2Show = true;
        for (let item of this.linkNameList) {
          LinkController.showLinkCollection(item.id,this.deviceId)
          item.show = true
        }
      } else if (item == '全部隐藏') {
        // UEapi.Model.setModelVisibility({ids: linkList, visible: false});
        // UEapi.Model.setModelVisibility({ids: linkList2, visible: false});
        // this.link1Show = false;
        // this.link2Show = false;
        for (let item of this.linkNameList) {
          LinkController.hideLinkCollection(item.id,this.deviceId)
          item.show = false
        }
      }
    },
    LinkLiPoint(item, index) {
      let cancel = false
      if (this.i == index + 1) {
        this.i = -1
        cancel = true
      } else {
        this.i = index + 1
      }
      // console.debug('LinkLiPoint', item,index)
      // console.debug('LinkLiPoint-1', this.link1Show, this.link2Show);

      // 第一次点击（从其他按钮点击状态切换）
      if (item == '全部显示') {
        // UEapi.Model.setModelVisibility({ids: linkList, visible: true});
        // UEapi.Model.setModelVisibility({ids: linkList2, visible: true});
        // this.link1Show = true;
        // this.link2Show = true;
        for (let item of this.linkNameList) {
          LinkController.showLinkCollection(item.id,this.deviceId)
          item.show = true
        }
        window.cameraManager.onSetCamera('机柜组机房', null, {})
      } else if (item == '全部隐藏') {
        // UEapi.Model.setModelVisibility({ids: linkList, visible: false});
        // UEapi.Model.setModelVisibility({ids: linkList2, visible: false});
        // this.link1Show = false;
        // this.link2Show = false;
        for (let item of this.linkNameList) {
          LinkController.hideLinkCollection(item.id,this.deviceId)
          item.show = false
        }
      }else{
        if (cancel){
          this.linkNameList[index].show = false
          LinkController.hideLinkCollection(this.linkNameList[index].id,this.deviceId)
        }else{
          this.linkNameList[index].show = true
          LinkController.showLinkCollection(this.linkNameList[index].id,this.deviceId).then(()=>{
            LinkController.focusLinkCollection(this.linkNameList[index].id,this.deviceId)
            let find = window.modelManager.controls.equipment.equipmentList.find(item=>item.id == this.deviceId)
            if (find){
              window.cameraManager.onSetCamera(find.group, null, {})
            }
          })
        }
      }

      // if (index == 1) {
      //   if (!this.link1Show) {
      //     UEapi.Model.setModelVisibility({ids: linkList, visible: true});
      //     this.link1Show = true;
      //   } else {
      //     UEapi.Model.setModelVisibility({ids: linkList, visible: false});
      //     this.link1Show = false;
      //   }
      // } else if (index == 2) {
      //   if (!this.link2Show) {
      //     UEapi.Model.setModelVisibility({ids: linkList2, visible: true});
      //     this.link2Show = true;
      //   } else {
      //     UEapi.Model.setModelVisibility({ids: linkList2, visible: false});
      //     this.link2Show = false;
      //   }
      //
      // }
      // if (!this.linkShow) {
      //   this.setCamera(true);
      // } else {
      //   this.setCamera();
      // }
    },
    setCamera(reset = false) {
      let cameraConfig = {
        'coordType': 0,
        'coordTypeZ': 0,
        'centerCoord': [116.2925411, 39.828212],
        'coordZ': -1.32,
        'distance': 21.58,
        'pitch': 58.43,
        'heading': 335.58,
        'status': 'free'
      };
      if (!reset) {
        UEapi.Camera.setCamera(cameraConfig)
      } else {
        UEapi.Camera.setCamera(cameraList['C03-004-04_SM_配线架'])
      }
    },
    setupInitData(){
      this.fuwuqibiaozhunjcxx[0].value = this.objectInfo.name
      this.deviceId = this.objectInfo.id
      this.linkNameList = this.objectInfo.linkNameList
      this.fuwuqibiaozhunckll = ['全部显示']
      for (let item of this.linkNameList) {
        this.fuwuqibiaozhunckll.push(item.label)
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo', 'AnimationState']),
    linkShow() {
      return this.$store.state.showLinkList.length > 0
    },
    objName() {
      return this.objectInfo.name;
    }
  },
  mounted() {
    // console.debug('objectInfo-mounted',this.objectInfo.name,!this.fuwuqibiaozhunczzl.includes('虚拟机'));
    this.setupInitData()
    if (this.objectInfo.name === 'C03-004-04_SM_数据服务器_虚拟机') {
      this.fuwuqibiaozhunczzl = ['显示虚拟机', '设备正面', '设备反面'];
      window.modelManager.controls.equipment._handleVM(true)
    } else {
      window.modelManager.controls.equipment._handleVM(true)
    }
  },
  watch: {
    objectInfo: {
      handler(val) {
        this.setupInitData()
      },
      deep: true
    },
    objName(val) {
      if (val === 'C03-004-04_SM_数据服务器_虚拟机') {
        this.fuwuqibiaozhunczzl = ['显示虚拟机', '设备正面', '设备反面']
        // 虚拟机外壳100%显示，虚拟机内部设备要显示出来
        window.modelManager.controls.equipment._handleVM(true)
      } else {
        this.fuwuqibiaozhunczzl = ['设备正面', '设备反面']
        // 其他设备情况下，要隐藏虚拟机内部设备
        window.modelManager.controls.equipment._handleVM(false)
        // 这个地方应该要对虚拟机动画进行处理，把动画停止掉
        window.modelManager.controls.equipment._stopAnimation();
      }
    },
    linkShow(val) {
      if (val) {
        this.fuwuqibiaozhunckll[0] = '全部隐藏';
      } else {
        this.fuwuqibiaozhunckll[0] = '全部显示';
      }
    }
  },
  beforeDestroy() {

    UEapi.Model.setModelVisibility({ids: linkList, visible: false});
    UEapi.Model.setModelVisibility({ids: linkList2, visible: false});
  }
}
</script>

<style lang='less' scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  overflow-y: auto;
  overflow-x: hidden;

  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
          }
        }
      }
    }
  }

  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
      }
    }
  }

  .ckll {
    .ul {
      .li {
        cursor: pointer;
        width: 100%;
        height: 50px;
        background: #189bfa;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 20px;

        .TagButton_image {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        .TagButton_text {
          margin-left: 10px;
          font-size: 28px;
          font-family: '微软雅黑';
          color: #ffffff;
          font-weight: bold;
        }
      }

      .ac {
        width: 100%;
        height: 50px;
        background: #116caf;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 20px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
  border-radius: 0px;
  background: rgba(17, 63, 89, 0.65);
}

::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 0px;
  background: rgba(255, 255, 255, 0.15);
  min-height: 00px;
}
</style>
