<template>
  <div :style="styleBarChart" class="bar-chart" ref="chart"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    styleBarChart: {
      type: Object,
      default: () => {
        return {
          width: '620px',
          height: '430px'
        }
      }
    },
    xtype: {
      type: String,
      default: 'category'
    },
    ytype: {
      type: String,
      default: 'value'
    },
    maxData: {
      type: Number,
      default: 300
    },
    coloreBarChart: {
      type: Array,
      default: () => {
        return ['#00BFFF', '#73FFFF']
      }
    },
    rowData: {
      type: Array,
      default: () => {
        return [
          {
            name: '2',
            value: '10'
          },
          {
            name: '3',
            value: '20'
          },
          {
            name: '4',
            value: '50'
          },
          {
            name: '20210604',
            value: '20'
          },
          {
            name: '20210605',
            value: '30'
          },
          {
            name: '20210606',
            value: '45'
          },
          {
            name: '20210607',
            value: '12'
          },
          {
            name: '20210608',
            value: '23'
          }
        ]
      }
    },
    chartStylr: {
      type: Object,
      default: () => {
        return {
          unit: '单位：条',
          barWidth: 20
        }
      }
    },
    gridData: {
      type: Object,
      default: () => {
        return {
          top: '20%',
          bottom: '13%',
          left: '12%',
          right: '10%'
        }
      }
    }
  },
  data() {
    return {
      chartInst: null,
      option: {},
      setTime: null
    }
  },
  methods: {
    instantiation() {
      this.chartInst = echarts.init(this.$refs.chart)
      this.option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765'
            }
          }
        },
        grid: this.gridData,
        xAxis: {
          type: this.xtype,
          data: [],
          axisLine: {
            show: true,
            lineStyle: {
              color: '#BFEBFF'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,.15)'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              fontSize: 24,
              interval: 10,
              color: '#CCCCCC'
            }
          }
        },
        yAxis: {
          type: this.ytype,
          name: this.chartStylr.unit,
          // max: this.maxData,
          nameTextStyle: {
            fontSize: 24,
            padding: [0, 0, 0, 35] // 四个数字分别为上右下左与原位置距离
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.15)'
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#CCCCCC'
            }
          },
          axisLabel: {
            // formatter: '{value}M',
            textStyle: {
              fontSize: 24,
              interval: 10,
              color: '#CCCCCC'
            }
          },
          splitArea: {
            show: false,
            areaStyle: {
              color: ['rgba(255,255,255,0.1)', 'rgba(238,238,238,0)']
            }
          }
        },
        series: [
          {
            data: [],
            avoidLabelOverlap: false,
            type: 'bar',
            barWidth: this.chartStylr.barWidth,
            itemStyle: {
              barBorderRadius: 15,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: this.coloreBarChart[0] },
                { offset: 1, color: this.coloreBarChart[1] }
              ])
            }
          }
        ]
      }
    },
    rowDataChart() {
      let name = []
      let value = []
      this.rowData.forEach(val => {
        name.push(val['name'])
        value.push(val['value'])
      })
      this.option.xAxis.data = name
      this.option.series[0].data = value
      this.chartInst.setOption(this.option, true)
    },
    tooltipShuffling() {
      //
      clearInterval(this.setTime)
      let count = 0
      this.setTime = setInterval(() => {
        if (count == this.rowData.length) {
          count = 0
        }
        this.chartInst.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: count
        })
        count++
      }, 3000)
    }
  },
  mounted() {
    this.instantiation()
    this.rowDataChart()
    this.tooltipShuffling()
  },
  beforeDestroy() {
    clearInterval(this.setTime)
    echarts.init(this.$refs.chart).dispose()
  },
  watch: {
    rowData: {
      handler(newV, oldV) {
        this.instantiation()
        this.rowDataChart()
        this.tooltipShuffling()
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped></style>
