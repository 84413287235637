/**
 * 对象管理逻辑
 * @author haowei
 */
import SpaceController from "@/core/logic/SpaceController";
import Store from "@/store";
import DataManager from "@/core/DataManager";

export default class ObjectManager {
    /**
     * 数据中心数据处理
     * @param node 树节点
     * @param curTreeRef 树dom
     */
    static async execSpaceModule(node, curTreeRef) {
        console.log('execSpaceModule', node, curTreeRef)
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo()
        // 中国-北京-数据中心，只需要切换层级
        if (node.level <= 3 && nowSpaceLevelInfo.name != node.spaceLevel) {
            await SpaceController.changeSpaceLevel(node.spaceLevel)
            // 更新展开节点id数组
            this.updateExpandKeys(node.id)
        }
        // 建筑需要手动切换层级
        if (node.level == 4) {
            if (nowSpaceLevelInfo.name != '园区') {
                await SpaceController.changeSpaceLevel(node.spaceLevel)
                // 更新展开节点id数组
                this.updateExpandKeys(node.id)
            }
            window.modelManager.onSelected(node.id)
        }
        // 其他层级直接相当于点击模型
        if (node.level > 4) {
            if (nowSpaceLevelInfo.name != node.spaceLevel){
                await SpaceController.changeSpaceLevel(node.spaceLevel,node.id)
            }
            // 房间没有模型处理，先这样处理
            if (node.spaceLevel == '房间'){
                await SpaceController.changeSpaceLevel('房间',node.id)
                window.cameraManager.onSetCamera(node.id,null,{})
            }
            window.modelManager.onSelected(node.id)
        }
    }

    /**
     * 设备数据处理
     * @param node 树节点
     * @param curTreeRef 树dom
     */
    static execDeviceModule(node, curTreeRef) {
        console.log('execDeviceModule', node, curTreeRef)
        if (node.level > 1){
            // 二级才能点击交互
            let find = window.allData.find(item=>item.id == node.id)
            console.log('execDeviceModule', find)
            window.layerManager.onLayerClick(find.id,find.idLayer)
        }
    }

    /**
     * 人员数据处理
     * @param node 树节点
     * @param curTreeRef 树dom
     */
    static execPersonnelModule(node, curTreeRef) {
        console.log('execPersonnelModule', node, curTreeRef)
        if (node.level > 1){
            // 二级才能点击交互
            let find = window.allData.find(item=>item.id == node.id)
            console.log('execDeviceModule', find)
            window.layerManager.onLayerClick(find.id,find.idLayer)
        }
    }

    /**
     * 更新默认打开节点id数据
     * @param id 当前节点
     */
    static updateExpandKeys(id) {
        let expandKey = this.getSpaceTreeParentExpandKey(id);
        expandKey.push(id)
        Store.commit('setSpaceExpandKeys', expandKey)
    }

    /**
     * 根据节点id获取展开的父项id列表
     * @param id 节点id
     * @return {[string]} 父项id列表
     */
    static getSpaceTreeParentExpandKey(id) {
        return this._getTreeParentIds(id, 'spaceTreeData')
    }

    /**
     * 处理对象管理设备数据 list=>tree
     * @param list 设备数据list
     */
    static handleDataForDevice(list) {
        return this._handleData(list, 'device');
    }

    /**
     * 处理对象管理人员数据 list=>tree
     * @param list
     * @return {*[]}
     */
    static handleDataForPerson(list) {
        return this._handleData(list, 'person');
    }

    /**
     * 计算对象管理总数
     * @param args 对象管理数据集合
     * @return {number} 总数
     */
    static calcObjTotal(...args) {
        let num = 0
        for (let arg of args) {
            let length = this._treeToList(arg).length
            num += length
        }
        return num
    }

    /**
     * 处理对象管理设备数据 list=>tree
     * @param list 设备数据list
     * @param objType 数据对象类型
     * @return {*[]}
     * @private
     */
    static _handleData(list, objType) {
        let typeList = []
        for (let item of list) {
            if (!typeList.includes(item.scannerType)) {
                typeList.push(item.scannerType)
            }
        }
        let tree = []
        for (let type of typeList) {
            tree.push({
                id: type,
                label: type,
                level: 1,
                objType: objType,
                children: []
            })
        }

        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo()
        for (let item of list) {
            for (let treeItem of tree) {
                if (item.scannerType == treeItem.id && nowSpaceLevelInfo.name == item.type) {
                    let temp = {
                        ...item,
                        parentId: treeItem.id,
                        label: item.name,
                        num: 1,
                        isSelect: false,
                        objType,
                        level:2
                    }
                    treeItem.children.push(temp)
                }
            }
        }

        for (let item of tree) {
            item.num = item.children.length
        }

        return tree
    }

    /**
     * 根据子节点id获取父节点id（包含父节点的父节点）列表
     * @param id 节点id
     * @param dataKey 数据集key
     * @return {*[]}
     * @private
     */
    static _getTreeParentIds(id, dataKey) {
        let data = this._getParentTreeList(id, window.objectInfo[dataKey]);
        return data.map(item => item.id)
    }

    /**
     * 根据子节点id获取父节点数据（包含父节点的父节点）列表
     * @param id 节点id
     * @param tree 树
     * @return {*[]}
     * @private
     */
    static _getParentTreeList(id, tree) {
        let treeList = this._treeToList(tree);
        let list = []
        let find = treeList.find(item => item.id == id);
        console.warn('_getParentTreeList',id,find)
        this.listDF2(treeList, list, find.parentId)
        list.sort((a, b) => a.level - b.level)
        return list
    }

    /**
     * 树转换为列表
     * @param tree 树
     * @return {*[]}
     * @private
     */
    static _treeToList(tree) {
        let resList = []
        for (const item of tree) {
            resList.push(item)
            if (item.children?.length) {
                this.listDF(item.children, resList, item.id)
            }
        }
        return resList
    }

    /**
     * 遍历树
     * @param tree 树
     * @param list 遍历结果列表
     * @param parentId 父id
     */
    static listDF(tree, list, parentId) {
        for (let item of tree) {
            let assign = Object.assign({}, item);
            assign.parentId = parentId
            list.push(assign)
            if (item.children?.length) {
                this.listDF(item.children, list, item.id)
            }
        }
    }

    /**
     * 获取父树节点数据（父树节点的父节点）
     * @param treeList
     * @param list
     * @param parentId
     */
    static listDF2(treeList, list, parentId) {
        for (let item of treeList) {
            if (item.id == parentId) {
                list.push(item)
                if (item.level != 1)
                    this.listDF2(treeList, list, item.parentId)
            }
        }
    }

    /**
     * 查找树数据
     * @param id 节点id
     * @param tree 树数据（列表）
     * @return {null|*}
     */
    static findTreeData(id,tree){
        let list = this._treeToList(tree);
        for (let item of list) {
            if (item.id == id){
                return item
            }
        }
        return null
    }

    /**
     * 更新树节点数据
     * @param tree 树数据（对象）
     * @param obj 更新对象
     */
    static updateTreeData(tree,obj){
        let objectInfo = window.objectInfo;
        if (tree.id == obj.id){
            for (let objKey in obj) {
                tree[objKey] = obj[objKey]
            }
        }else if (tree.children && tree.children.length > 0){
            for (let child of tree.children) {
                this.updateTreeData(child,obj)
            }
        }
    }

    /**
     * 加载设备数据到树结构中
     * @return {Promise<void>}
     */
    static async loadEqDataTree(){
        let boxData = window.allBoxData
        let treeData = this.findTreeData('机柜组机房',window.objectInfo.spaceTreeData)
        if (!treeData) return

        treeData.children = []
        for (let box of boxData) {
            let temp = {...box}
            temp['num'] = box.rackList.length
            temp['level'] = 7
            temp['isSelect'] = false
            temp['objType'] = 'space'
            temp['components'] = 'JiGuiLixian'
            temp['spaceLevel'] = '机柜组'
            temp['children'] = []
            for (let rack of box.rackList) {
                let rackTemp = {...rack}
                rackTemp['num'] = rack.equipmentList.length
                rackTemp['level'] = 8
                rackTemp['pid'] = rack.group
                rackTemp['isSelect'] = false
                rackTemp['objType'] = 'space'
                rackTemp['components'] = 'JiGuiLixian'
                rackTemp['spaceLevel'] = '机柜'
                rackTemp['children'] = []
                for (const item of rack.equipmentList) {
                    let itemTemp = {...item}
                    itemTemp['num'] = 1
                    itemTemp['level'] = 9
                    itemTemp['pid'] = item.rack
                    itemTemp['isSelect'] = false
                    itemTemp['objType'] = 'space'
                    itemTemp['components'] = 'FuWuQiBiaoZhun'
                    itemTemp['spaceLevel'] = '设备'
                    rackTemp['children'].push(itemTemp)
                }
                temp['children'].push(rackTemp)
            }

            treeData.children.push(temp)
        }
        this.updateTreeData(window.objectInfo.spaceTreeData[0],treeData)
    }

}
