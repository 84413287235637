/**
 * 模型管理器
 * @author haowei
 */
import RackModelController from '@/core/model/RackModelController'
import JiFangBuildingController from "@/core/model/JiFangBuildingController";
import LouCengController from "@/core/model/LouCengController";
import EquipmentController from "@/core/model/EquipmentController";
import FangChenModelController from "@/core/model/FangChenModelController";

/**
 * 模型管理器
 *
 * @author haowei
 */

export default class ModelManager {
  /**
   * 模型控制器变量 todo 可以扩充
   */
  controls = {
    // 机柜、机柜组
    rack: new RackModelController(),
    // 上架设备
    equipment:new EquipmentController(),
    // 机房楼
    jiFangBuilding: new JiFangBuildingController(),
    // 楼层
    louCeng:new LouCengController(),
    // 防尘顶、防尘门
    fangChen:new FangChenModelController(),
  }

  // 上次点击的模型id
  lastTimeClickModelId = null

  static instance

  /**
   * 获取实例
   * @return {ModelManager}
   */
  static getInstance() {
    return this.instance ? this.instance : new ModelManager()
  }

  /**
   * 模型点击事件
   * @param modelId 模型id
   */
  onModelClick(modelId) {
    console.warn('onModelClick', modelId)
    // 寻找对应控制器的点击事件处理

    let { ctrl, key } = this.getControlById(modelId)
    if (!ctrl) {
      console.warn(`模型 ${modelId} 没有匹配到任何控制器，请检查模型控制器`)
      return
    }
    // 模型能被点击，且执行点击处理方法
    if (ctrl.checkCanClick()) {
      ctrl.clickModelExec({ id: modelId })
      this.lastTimeClickModelId = modelId
      window.ctrlEventManager.publish(window.ctrlEventManager.eventsMap.ModelClick,{id:modelId})
    } else {
      console.warn(`${key}控制器 模型点击事件被禁止`)
    }
  }

  /**
   * 选中模型，这个要和模型点击区分开，选中的概念是从其他逻辑（如对象管理）并非是直接点击模型的逻辑中选中
   * @param modelId
   */
  onSelected(modelId) {
    console.debug('onSelected', modelId)
    let { ctrl } = this.getControlById(modelId)
    if (!ctrl) {
      const find = window.allEqData.find((item) => item.id == modelId)
      if (find) {
        this.controls.equipment.clickModelExec({ id: modelId })
        return
      }
      console.warn(`模型 ${modelId} 没有匹配到任何控制器，请检查模型控制器`)
      return
    }
    this.lastTimeClickModelId = modelId
    ctrl.clickModelExec({ id: modelId })
  }

  /**
   * 根据id获取对应控制器和key
   * @param id 模型id
   * @return {null|*}
   */
  getControlById(id) {
    for (const key in this.controls) {
      let checkId = this.controls[key].checkId(id)
      if (checkId) {
        // console.warn('getControlById', `${key}-控制器处理: 模型【${id}】`)
        return {
          ctrl: this.controls[key],
          key: key,
        }
      }
    }
    // console.warn('getControlById', `未找到控制器：模型【${id}】`,this.controls.equipment.getModelIdsList())
    return {
      ctrl: null,
      key: null,
    }
  }

  /**
   * 开启模型点击事件，如果keys不传或者是为空数组的情况下，默认打开所有模型控制器的点击事件
   * @param keys 控制器key数组
   */
  openClickEvent(keys) {
    if (!keys || keys.length == 0) {
      for (let controlsKey in this.controls) {
        this.controls[controlsKey].openClickEvent()
      }
    } else {
      for (let controlsKey in this.controls) {
        if (keys.includes(controlsKey)) {
          this.controls[controlsKey].openClickEvent()
        } else {
          this.controls[controlsKey].closeClickEvent()
        }
      }
    }
  }

  /**
   * 关闭模型点击事件，如果keys不传或者为空数组的情况下，默认关闭所有模型控制器的点击事件
   * @param keys 控制器key数组
   */
  closeClickEvent(keys) {
    if (!keys || keys.length == 0) {
      for (let controlsKey in this.controls) {
        this.controls[controlsKey].closeClickEvent()
      }
    } else {
      for (let controlsKey in this.controls) {
        if (keys.includes(controlsKey)) {
          this.controls[controlsKey].closeClickEvent()
        } else {
          this.controls[controlsKey].openClickEvent()
        }
      }
    }
  }

  /**
   * 获取上次点击的模型id
   * @return {null|string}
   */
  getLastTimeModelId() {
    return this.lastTimeClickModelId
  }
}
