import LandmarkLayer from './landmarkLayer'
import HeatMapLayer from './HeatMapLayer'
import Path from './Path'
import Camera from './Camera'
import BarLayer from './BarLayer'
import Area from './Area'
import Marker3D from './Marker3D'
import Bubble from './Bubble'
import ODLineLayer from './ODLineLayer'
import TrailLayer from './TrailLayer'
import Model from './Model'
import RoadSgHeatLayer from './roadSgHeatLayer'
import Agg3DColumnLayer from './agg3DColumnLayer'
import GridColumnLayer from './GridColumnLayer'
import OverlayTip from './OverlayTip'
import TypeAreaLayer from './TypeAreaLayer'
import Events from './Events'
import Building from './Building'
import EventLayer from './EventLayer'

import TrackLayer from './TrackLayer'

let TimerList = {}

let Common = {
  initUE() {
    window.appInstance = new TGApp.App()
    window.appInstance.initService({
      name: 'serviceName',
      container: document.getElementById('container'),
      mode: 'streaming3',
      url: streamingConfig.url,
      token: streamingConfig.token,
      resolution: [streamingConfig.width, streamingConfig.height],
      editMode: false,
    })
  },
  initAVW(item) {
    window.appInstance = new TGApp.App()
    // 服务信息
    const { name, serve, id } = item
    // 短地址
    const sceneUrl = `${serve}/publish/scene/api/${id}`
    const tokenUrl = `${serve}/api/user/v1/visitorScene/${id}`

    let xhr = new XMLHttpRequest()
    xhr.open('post', tokenUrl, true)
    xhr.setRequestHeader('Content-Type', 'application/json charset=utf-8')
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let result = JSON.parse(xhr.responseText)
        window.appInstance.initService(
          {
            container: document.getElementById('container'),
            mode: 'scene2',
            url: sceneUrl,
            token: result.accessToken,
            editMode: false,
          },
          () => {
            // Store.commit('setSpaceLevel', name)
            // SpaceController.changeSpaceLevel(name)
          }
        )
      }
    }
    xhr.send(JSON.stringify({ password: 'sfasds' }))
  },
  loadService(item) {
    const { name, serve, id } = item
    // 短地址
    const sceneUrl = `${serve}/publish/scene/api/${id}`
    const tokenUrl = `${serve}/api/user/v1/visitorScene/${id}`
    // console.log(sceneUrl, tokenUrl)
    let xhr = new XMLHttpRequest()
    xhr.open('post', tokenUrl, true)
    xhr.setRequestHeader('Content-Type', 'application/json charset=utf-8')
    xhr.send(JSON.stringify({ password: 'sfasds' }))
    xhr.onreadystatechange = async () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let result = JSON.parse(xhr.responseText)
        appInstance.uniCall(
          'loadService',
          {
            name,
            container: document.getElementById('container'),
            mode: 'scene',
            url: sceneUrl,
            token: result.accessToken,
            resolution: [streamingConfig.width, streamingConfig.height], // 控件 像素分辨率，宽，高
            editMode: false,
          },
          (res) => {
            console.log('res', res)
            // Store.commit('setLoading', false)
          },
          (err) => {}
        )
      }
    }
  },
  switchService(name, succseeFn) {
    return new Promise(resolve=>{
      appInstance.uniCall('switchService', { name }, (res) => {
        // console.log('切换场景', name, res)
        try {
          if(succseeFn)
            succseeFn(res)
        }catch (e) {
          console.error('switchService-succseeFn-error',e)
        }
        resolve(res)
      })
    })

  },
  switchServiceLoaded(name){
    return new Promise((resolve,reject) => {
      appInstance.uniCall('switchService',{name},res=>{
        let eventId = window.eventBus.on('service-switch',(res)=>{
          window.eventBus.off('service-switch',eventId)
          resolve(res)
        })
        setTimeout(()=>{
          reject('切换服务超时')
        },1000*120)
      })
    })
  },
  switchState(jsonData) {
    return new Promise((resolve) => {
      appInstance.uniCall('switchState', jsonData, (result) => {
        console.log('switchState', jsonData, result)
        resolve(result)
      })
    })
  },
  removeOverlay(params) {
    return new Promise((resolve) => {
      window.appInstance.uniCall(
        'removeOverlay',
        {
          id: params.id,
          overlayType: params.overlayType,
        },
        (res) => {
          // console.log(`删除图层`, params, res)
          resolve(res)
        }
      )
    })
  },
  clearOverlayType(type) {
    TrailLayer.clearTrailLayerTimers()
    window.appInstance.uniCall(
      'clearOverlayType',
      {
        overlayType: type,
      },
      (res) => {
        // console.log(`清空图层${type}`, res)
      }
    )
  },
  setLayerVisible(layer, isVisible = true) {
    return new Promise((resolve, reject) => {
      if (!layer) {
        reject('参数layer无效')
      }
      let layerType = layer.layerConfig.layerType
      if (layerType.indexOf('tip-') > -1) {
        layerType = layer.layerConfig.layerType.replace('tip-', '')
      }
      window.appInstance.uniCall(
        'setOverlayVisibility',
        {
          id: layer.id,
          overlayType: layerType,
          visible: isVisible,
        },
        (res) => {
          // console.debug('setLayerVisible',        {
          //   id: layer.id,
          //   overlayType: layer.layerConfig.layerType,
          //   visible: isVisible,
          // },res);
          resolve(res)
        }
      )
    })
  },
  /**
   * 向后移动图层
   * @param idLayer 图层id
   * @param offset 移动几层
   */
  moveOverlayBackward(idLayer, offset) {
    return new Promise((resolve) => {
      window.appInstance.uniCall(
        'moveOverlayBackward',
        { idLayer, offset },
        (result) => {
          console.log('moveOverlayBackward', result, { idLayer, offset })
          resolve(result)
        }
      )
    })
  },

  setResolution(width, height) {
    let jsonData = {
      width: width ? width : window.streamingConfig.resolution.width,
      height: height ? height : window.streamingConfig.resolution.height,
    }
    window.appInstance.uniCall('setResolution', jsonData, (result) => {
      console.debug('setResolution', result)
    })
  },

  async clearOverlaySelected() {
    return new Promise((resolve) => {
      window.appInstance.uniCall('clearOverlaySelected', {}, (result) => {
        // console.log(result); // 清除全部图层对象选中状态，并对其置 selected: false
        resolve(result)
      })
    })
  },
}
export default {
  LandmarkLayer,
  ODLineLayer,
  HeatMapLayer,
  Path,
  Camera,
  BarLayer,
  Area,
  Marker3D,
  Bubble,
  TrailLayer,
  Common,
  TimerList,
  Model,
  RoadSgHeatLayer,
  Agg3DColumnLayer,
  GridColumnLayer,
  OverlayTip,
  TypeAreaLayer,
  Events,
  Building,
  EventLayer,
  TrackLayer,
}
