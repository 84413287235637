import BaseModelController from "@/core/model/BaseModelController";
import Store from "@/store";
import SpaceController from "@/core/logic/SpaceController";
import ObjectManager from "@/components/objectManager/ObjectManager";

/**
 * 楼层模型控制器
 * @author haowei
 */
export default class LouCengController extends BaseModelController{
    modelList = [
        {
            id:'SM_IDC室内04',
            alias:'F3',
            subLevel:'F3',
        }
    ]

    getModelIdsList() {
        let map1 = this.modelList.map(item=>item.id);
        let map2 = this.modelList.map(item=>item.alias);
        return [...map1,...map2]
    }

    clickModelExec(e) {
        let obj = this.findObj(e.id);
        SpaceController.changeSpaceLevel('楼层',obj.subLevel).then(()=>{
            ObjectManager.updateExpandKeys(obj.id)
            Store.commit('setRightType','LouCeng')
            Store.commit('setObjectInfo',{name:obj.alias})
        })
    }

    findObj(id){
        let find = this.modelList.find(item=>item.id == id);
        if (!find){
            find = this.modelList.find(item=>item.alias == id)
        }
        return find
    }
}
