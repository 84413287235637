<!--
    * App.vue
    * @date 2023/01/04 16:46:40
    * @author haowei
-->
<template>
  <div id="app" :style="{
      '--resH': resolution.height + 'px',
      '--resW': resolution.width + 'px',
      '--pageW': pageInfo.width + 'px',
      '--pageH': pageInfo.height + 'px',
    }">
    <div
        class="gis-container"
        :style="{
        transform:
          'translateX(-50%) translateY(-50%) translateZ(0) scale(' +
          scaleMap +
          ')',
      }"
    >
      <AVW v-if="!devMode"></AVW>
      <img v-else src="@/assets/img/bg.png" style="width: 100%;height: 100%;">
    </div>

    <div
        class="screen"
        :style="{
        '--resTransform':
          'translateX(-50%) translateY(-50%) translateZ(0) scale(' +
          scale +
          ')',
        transform: 'var(--resTransform)',
      }"
    >
      <HeaderCustom></HeaderCustom>
      <div class="main">
        <!--        <router-view></router-view>-->
      </div>
    </div>
    <div
        id="layer-container"
        class="layer-container"
        :style="{
        transform:
          'translateX(-50%) translateY(-50%) translateZ(0) scale(' +
          scale +
          ')',
      }"
    >

      <ComContainer></ComContainer>
      <!-- 底部 -->
      <BottomCustom></BottomCustom>
      <div class="loading" v-show="showLoading">
        <img src="/img/loading.gif" alt/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import HeaderCustom from "@/components/layout/HeaderCustom";
import BottomCustom from "@/components/layout/BottomCustom";
import AVW from "@/views/AVW";
import ComContainer from "@/components/layout/ComContainer";

export default {
  name: 'App',
  components: {
    ComContainer,
    AVW,
    BottomCustom,
    HeaderCustom
  },
  data: () => {
    return {
      devMode: window.devMode,
      scale: 1,
      scaleMap: 1,
      // 3d场景分辨率
      resolution: {
        width: window.streamingConfig.resolution.width,
        height: window.streamingConfig.resolution.height,
      },
      // 页面信息
      pageInfo: {
        width: window.streamingConfig.width,
        height: window.streamingConfig.height,
      },
    }
  },
  methods: {
    setScreen() {
      let visibleWidth = window.innerWidth
      let visibleHeight = window.innerHeight
      let widthPercentage = (1.0 * visibleWidth) / this.pageInfo.width
      let heightPercentage = (1.0 * visibleHeight) / this.pageInfo.height
      this.scale = Math.min(widthPercentage, heightPercentage)
      let _3dWidthPercentage = (1.0 * visibleWidth) / this.resolution.width
      let _3dHeightPercentage = (1.0 * visibleHeight) / this.resolution.height
      this.scaleMap = Math.min(_3dWidthPercentage, _3dHeightPercentage)
    },
  },
  computed: {
    ...mapState([
      'showLoading'
    ])
  },
  mounted() {
    //立即执行显示分辨率设置方法
    this.setScreen()
    window.addEventListener('resize', this.setScreen.bind(this))
    document.oncontextmenu = function (ev) {
      ev.preventDefault()
    }
  }
}
</script>
<style lang="less">
@appWidth: var(--pageW);
@appHeight: var(--pageH);
@_3dWidth: var(--resW);
@_3dHeight: var(--resH);
#app {
  user-select: none;
  //cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
  pointer-events: all;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: @appWidth;
    height: @appHeight;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: all;
  }

  .gis-container {
    background-color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    width: @_3dWidth;
    height: @_3dHeight;
  }

  .screen {
    position: absolute;
    // background: url(./assets/img/layout/mask.png) no-repeat center center;
    left: 50%;
    top: 50%;
    z-index: 2;
    width: @appWidth;
    height: @appHeight;
    pointer-events: none;
    overflow: hidden;

    .main {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: @appHeight;
      pointer-events: none;
      z-index: 5;
    }

    .gis_btn_pos {
      position: absolute;
      left: 920px;
      bottom: 50px;
      z-index: 10;
    }

    .legend_pos {
      position: absolute;
      bottom: 40px;
      right: 920px;
      z-index: 100;
    }
  }

  .layer-container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    width: @appWidth;
    height: @appHeight;
    pointer-events: none;
    overflow: hidden;

    .main-box {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .xunjianbox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
