import LegendManager from "@/core/LegendManager";
import CameraManager from "@/core/CameraManager";
import LayerManager from "@/core/LayerManager";
import DataManager from "@/core/DataManager";
import LayerList from "@/config/LayerList";
import ModelManager from "@/core/ModelManager";
import StateManager from "@/core/StateManager";
import SpaceController from "@/core/logic/SpaceController";
import CtrlEventManager from "@/core/CtrlEventManager";
import {spaceTreeData} from '@/config/objectInfo'
import EventBus from "@/core/EventBus";
import ObjectManager from "@/components/objectManager/ObjectManager";
import DataCache from "@/core/DataCache";

export default {
    // 初始化，顺序有时很重要！
    async init(Vue){
        window.ctrlEventManager = new CtrlEventManager()
        // 数据缓存
        window.dataCache = DataCache.getInstance()
        // 全局事件总线
        window.eventBus = new EventBus()
        // 挂载底部legend管理到Vue全局
        window.legendManager = LegendManager.getInstance()
        Vue.prototype.$legendManager = window.legendManager
        // 挂载镜头管理到vue全局
        window.cameraManager = CameraManager.getInstance()
        Vue.prototype.$cameraManager = window.cameraManager
        // 图层数据挂载全局
        window.layerList = LayerList
        // 对象管理树数据挂载全局
        window.objectInfo = {spaceTreeData}
        // 挂载图层管理到vue全局
        window.layerManager = LayerManager.getInstance()
        Vue.prototype.$layerManager = window.layerManager
        // 挂载模型管理到vue全局
        window.modelManager = ModelManager.getInstance()
        Vue.prototype.$modelManager = window.modelManager
        // 挂载状态管理到vue全局
        window.stateManager = StateManager.getInstance()
        Vue.prototype.$stateManager = window.stateManager
        // 挂载所有图层点位数据到全局
        window.allData = await DataManager.getAllData()
        // 挂载所有设备数据
        window.allEqData = await DataManager.getAllEqData()
        // 挂载所有机柜组-机柜-设备数据到全局
        window.allBoxData = await DataManager.getBoxData()
        // 初始化设备树
        await ObjectManager.loadEqDataTree()

        window.spaceCrtl = SpaceController
    }
}
