<template>
  <div class="GisButton">
    <div class="reset" @click="resetFn" title="复位"></div>
    <div
      class="rotate"
      title="旋转"
      @click="rotateFn"
      :class="cameraRotate ? 'rotate_ac' : ''"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CameraList from '@/config/cameraList'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['cameraRotate','spaceLevel']),
  },
  methods: {
    resetFn(item) {
      let jsonData;
      if (this.spaceLevel == '中国'){
        jsonData = CameraList['中国'];
      }else if (this.spaceLevel == '北京'){
        jsonData = CameraList['北京']
      }else if (this.spaceLevel == '园区'){
        jsonData = CameraList['园区']
      }else if (this.spaceLevel == '楼层'){
        jsonData = CameraList['F3']
      }else if (this.spaceLevel == '房间'){
        jsonData = CameraList['机柜组机房']
      }else if (this.spaceLevel == '机柜组'){
        jsonData = CameraList['C03-004_SM_机柜组']
      }else if (this.spaceLevel == '机柜'){
        jsonData = CameraList['Box_C03-004-04_机柜容量']
      }else{
        jsonData = CameraList['园区']
      }
      window.appInstance.uniCall('setCamera', jsonData,res=>{
        // console.debug('重置镜头',jsonData,res);
      })
    },
    rotateFn() {
      this.$store.commit('setCameraRotate', !this.cameraRotate)
    },
  },
}
</script>

<style lang="less" scoped>
.GisButton {
  display: flex;
  pointer-events: all;
  .reset {
    width: 60px;
    height: 60px;
    margin: 0px 20px;
    background: url(../../assets/img/commonComponents/positionme.png) no-repeat
      center center;
    background-size: cover;
    cursor: pointer;
  }
  .rotate {
    width: 60px;
    height: 60px;
    background: url(../../assets/img/commonComponents/resovet.png) no-repeat
      center center;
    background-size: cover;
    cursor: pointer;
  }
  .rotate_ac {
    background: url(../../assets/img/commonComponents/resovet_click.png)
      no-repeat center center;
    background-size: cover;
  }
}
</style>
