<template>
  <div class="Pagination">
    <div class="pagination_left">
      <span class="spaceBetween">每页显示</span>
      <el-input maxlength="1" v-model="pageSize" @input="pageChange"></el-input>
      <div class="spaceBetween_t">
        <span>条/共</span>
        <span>{{ alldata.length }}</span>
        <span>条</span>
      </div>
    </div>
    <div class="pagination_mid">
      共{{ Math.ceil(alldata.length / pageSize) }}页
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, jumper, next"
      :total="alldata.length"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    alldata: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      pageSize: 5,
      currentPage: 0,
      rowData: [],
    }
  },

  methods: {
    handleCurrentChange(value) {
      this.changeData(value - 1)
    },

    changeData(Page) {
      let newData = []
      for (
        let index = Page * this.pageSize;
        index < Page * this.pageSize + this.pageSize;
        index++
      ) {
        if (index < this.rowData.length) {
          newData.push(this.rowData[index])
        }
      }
    this.$emit('handleCurrentChange', newData)
    },

    pageChange(value) {
        console.log(value);
      this.pageSize = Number(value)
    },
  },

  mounted() {
    this.changeData(0)
  },

  watch: {
    pageSize: {
      handler(val) {
        if (val == '' && val == 0) {
          this.pageSize = 5
        }
        this.changeData(0)
      },
    },

    alldata: {
      handler(val) {
        this.rowData = val
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="less" scoped>
.Pagination {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-family: '微软雅黑';
  font-size: 20px;
  color: #999999;
  .pagination_left {
    display: flex;
    width: 340px;

    .spaceBetween {
      margin-right: 10px;
      line-height: 40px;
    }

    .spaceBetween_t {
      margin-left: 10px;
      height: 40px;
      display: flex;
      align-items: center;
    }

    .pageSize {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 2px solid #4d505e;
      color: #cccccc;
      background: #272729;
      text-align: center;
      margin: 0 4px;
    }
    /deep/.el-input {
      width: 40px;
    }
    /deep/.el-input__inner {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 2px solid #4d505e;
      color: #cccccc;
      text-align: center;
      background: #272729;
      font-size: 20px;
      padding: 0;
    }
  }
  .pagination_mid {
    width: 120px;
  }
  /deep/.btn-prev {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    // border: 2px solid #4d505e;
    color: #cccccc;
    text-align: center;
    background: #27272900;
  }
  /deep/.el-icon {
    font-size: 30px;
  }
  /deep/.el-pagination__jump {
    margin-left: 0;
    font-size: 20px;
    font-family: '微软雅黑';
    color: #cccccc;
    height: 40px;
    line-height: 40px;
  }
  /deep/.el-input__inner {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid #4d505e;
    color: #cccccc;
    text-align: center;
    background: #272729;
    font-size: 20px;
  }
  /deep/.btn-next {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    // border: 2px solid #4d505e;
    color: #cccccc;
    text-align: center;
    background: #27272900;
  }
}
</style>