import StandardStateHandler from "@/core/state/StandardStateHandler";
import BuildingController from "@/core/logic/BuildingController";
import Store from "@/store";

/**
 * 机柜组标准状态
 * @author haowei
 */
export default class RackGroupStandardHandler extends StandardStateHandler {

    async init() {
        BuildingController.expandBuilding()
        window.cameraManager.onSetCamera(Store.state.subSpaceLevel,null,{})
        window.modelManager.closeClickEvent(['louCeng'])
        await super.init();
    }

    async reset() {
        let rackCtrl = window.modelManager.controls.rack;
        // 重置高亮状态
        rackCtrl.unDoHighlightRackGroup()
        rackCtrl.currentRackGroup = ''
        window.modelManager.openClickEvent()
        await super.reset();
    }
}
