<template>
  <div class="bot_center">
    <!-- 返回按钮 -->
    <div class="go_back" v-show="spaceLevel !== '中国'" @click="backClick">
      返回
    </div>
    <!-- 旋转复位按钮 -->
    <GisButton />
    <!-- 图例 -->
    <Legend
      :legends="legends"
      v-show="functionStatus != '对象管理'"
      @legendClick="legendClick"
      ref="legendRef"
    ></Legend>
    <!-- 二级导航 -->
    <NavSecond
      @TabSwitching="TabSwitching"
      v-show="functionStatus == '数据分析'"
    />
  </div>
</template>

<script>
import GisButton from '@/components/common/GisButton'
import Legend from '@/components/common/Legend'
import NavSecond from './NavSecond.vue'
import { mapState } from 'vuex'
import Store from "@/store";
// import LegendContral from '../../utils/control/LegendContral'
export default {
  components: {
    GisButton,
    Legend,
    NavSecond,
  },
  data() {
    return {
      legends:this.$legendManager.getNowSpaceLevelLegendList()
    }
  },
  computed: {
    ...mapState(['spaceLevel', 'functionStatus']),
  },
  watch:{
    spaceLevel(){
      this.legends = this.$legendManager.getNowSpaceLevelLegendList()
    }
  },
  methods: {
    backClick() {
      this.$emit('spaceBack')
    },
    legendClick(ele) {
      this.$emit('legendClick', ele)
    },
    TabSwitching(p) {
      this.$emit('TabSwitching', p)
    },
  },
  mounted() {
    this.$nextTick(() => {
      // LegendContral.legendVm = this.$refs.legendRef
    })
  },
}
</script>

<style lang="less" scoped>
.bot_center {
  height: 100px;
  pointer-events: all;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .go_back {
    cursor: pointer;
    width: 120px;
    height: 60px;
    text-indent: 58px;
    line-height: 60px;
    background: url('../../assets/img/layout/go_back.png') no-repeat;
  }
}
</style>
