import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
  //   children:[
  //     {
  //       path: '/home',
  //       name: 'Home',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  //       meta:{
  //         path1:'工作台',
  //         path2:'首页',
  //       }
  //     },
  //     {
  //       path: '/about',
  //       name: 'About',
  //       // route level code-splitting
  //       // this generates a separate chunk (about.[hash].js) for this route
  //       // which is lazy-loaded when the route is visited.
  //       component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  //       meta:{
  //         path1:'内容',
  //         path2:'关于',
  //       }
  //     }
  //   ]
  // },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
