// import gisDataManager from '../../api/gisDataManager';

export default class BarLayer {
  static defaultConfig = {
    id: '人口柱图20000', // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
    name: '人口柱图20000', // 图层名称，支持为图层自定义名称
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 10,
    columnType: 'cube',
    columnMinHeight: 1,
    columnMaxHeight: 200,
    columnAlpha: 0.3,
    columnPaint: 'solid',
    columnGap: 0,
    columnWidth: 50,
    colorMax: '#ff0000',
    colorMin: '#ffff00',
    visible: true,
    legends: [
      {
        name: 'legendName1',
        minValue: 0,
        maxValue: 100,
        color: '#ff0000'
      },
      {
        name: 'legendName2',
        minValue: 0,
        maxValue: 100,
        color: '#00ff00'
      },
      {
        name: 'legendName3',
        minValue: 0,
        maxValue: 100,
        color: '#0000ff'
      }
    ],
    valueMax: 1000,
    valueMin: 0,
    labelColor: '#000000',
    labelBackgroundColor: '#ffffffff',
    data: []
  };

  // 添加柱图图层
  static async add3DColumnLayer(layer) {
    let layerInfo = await gisDataManager.get3DColumnLayerData(
      layer,
      this.defaultConfig
    );
    delete layerInfo.layerConfig;
    delete layerInfo.status;
    let index = 0;
    let len = 800;
    if (layerInfo.data.length > 800) {
      let jsonData = Object.assign({}, layerInfo);
      jsonData.data = jsonData.data.slice(0, len);
      window.appInstance.uniCall('add3DColumnLayer', jsonData, res => {
        index++;
        this.append3DColumn(layerInfo, index, len);
        // console.log('添加柱图成功,开始追加数据');
      });
    } else {
      window.appInstance.uniCall('add3DColumnLayer', layerInfo, res => {});
    }
  }

  static append3DColumn(layerInfo, index, len) {
    let deflautConfig = {
      id: layerInfo.id,
      name: layerInfo.name,
      coordType: layerInfo.coordType,
      coordTypeZ: layerInfo.coordTypeZ,
      coordZ: layerInfo.coordZ,
      isAppend: true,
      data: layerInfo.data.slice(index * len, (index + 1) * len)
    };
    window.appInstance.uniCall(
      'update3DColumnLayerCoord',
      deflautConfig,
      res => {
        // console.log('追加柱图成功', index);
        let maxIndex = Math.floor(layerInfo.data.length / len);
        if (index < maxIndex) {
          index++;
          this.append3DColumn(layerInfo, index, len);
        }
      }
    );
  }

  static async update3DColumnLayerCoord(layer, subLayer) {
    const updateConfig = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      coordZ: 10,
      isAppend: false,
      data: []
    };

    try {
      let layerInfo = await gisDataManager.get3DColumnLayerData(
        layer,
        updateConfig,
        subLayer
      );
      let index = 0;
      let len = 800;

      if (layerInfo.data.length > 800) {
        let jsonData = Object.assign({}, layerInfo);
        jsonData.data = jsonData.data.slice(0, len);
        appInstance.uniCall('update3DColumnLayerCoord', layerInfo, res => {
          index++;
          this.append3DColumn(layerInfo, index, len);
          // console.log('更新柱图成功,开始追加数据');
        });
      } else {
        appInstance.uniCall('update3DColumnLayerCoord', layerInfo, res => {
          // console.log('更新柱图成功', layer.id, res);
        });
      }
    } catch (error) {
      // console.log('更新柱图失败', layer.id, res);
    }
  }

  static remove3DColumnLayer() {
    // 删除散点图层
    window.appInstance.uniCall('clearOverlayType', {
      overlayType: '3DColumnLayer'
    });
  }
}
