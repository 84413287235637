export default class Model {
  // 设置模型样式
  static async setModelStyle(params) {
    return new Promise((resolve) => {
      let defaultConfig = {
        id: 'modelId',
        alpha: 1,
        scale: 1,
        maskType: 'none',
        maskAlpha: 0,
        maskColor: '#ff0000',
        maskPicture: 'arrow',
        maskPictureScale: 1.0,
        maskFlowSpeed: 1.0,
        maskFlowDirection: 90,
        includeChildren: true,
        xRay: 'off',
      }
      let resConfig = Object.assign({}, defaultConfig, params)
      window.appInstance.uniCall('setModelStyle', resConfig, (res) => {
        // console.log('设置模型样式', resConfig, res);
        resolve(res)
      })
    })
  }
  // 添加散点图层
  static setModelVisibility(params) {
    return new Promise((resolve) => {
      let defaultConfig = {
        ids: [],
        visible: false,
      }
      let jsonData = Object.assign({}, defaultConfig, params)
      appInstance.uniCall('setModelVisibility', jsonData, (res) => {
        console.log('setModelVisibility', jsonData, res)
        resolve(res)
      })
    })
  }

  // 点击模型
  static clickModel(params, succseeFn = () => {}) {
    let defaultConfig = {
      id: '',
      modelType: 'model', // 模型类别 model：模型，building：建筑物
      selected: true, // 选中状态，true：对象被高亮选中；false：对象被取消高亮选中
    }
    let jsonData = Object.assign({}, defaultConfig, params)
    appInstance.uniCall('clickModel', jsonData, (res) => {
      // console.log('clickModel', jsonData, res)
      succseeFn(res)
    })
  }

  // 播放模型动画
  static playAnimation(params) {
    let defaultConfig = {
      id: '<#id#>', // 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
      name: 'animationName01', // 动画名称
      state: 'pause', //"begin", "pause", "stop", "restart" 四种
    }
    let jsonData = Object.assign({}, defaultConfig, params)
    window.appInstance.uniCall('playModelAnimation', jsonData, (res) => {
      console.log('播放模型动画', jsonData, res)
    })
  }
  // 获取模型动画
  static getAnimation(params, fn = () => {}) {
    let defaultConfig = {
      id: '<#id#>', // 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
    }
    let jsonData = Object.assign({}, defaultConfig, params)
    window.appInstance.uniCall('getModelAnimation', jsonData, (res) => {
      // console.log('获取模型动画', jsonData, res)
      fn(res, jsonData)
    })
  }
  // 设置模型闪烁
  static setModelTwinkle(params) {
    let index = 0
    let colors = ['#ff0000', '#4A87AC']
    let timer = setInterval(() => {
      index++
      this.setModelStyle({
        id: params.id,
        alpha: 1,
        scale: 1,
        maskType: 'color',
        maskAlpha: 1,
        maskColor: colors[index % 2],
      })
      if (index >= 10) {
        clearInterval(timer)
      }
    }, 300)
  }
  // 设置模型基础变化
  static setModelTransform(params) {
    let defaultConfig = {
      id: '', // 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
    }
    let jsonData = Object.assign({}, defaultConfig, params)
    window.appInstance.uniCall('setModelTransform', jsonData, (res) => {
      // console.log('设置模型基础变化', jsonData, res)
    })
  }
  // 添加模型
  static async addModel(params) {
    return new Promise((resolve) => {
      let defaultConfig = {
        id: '', // 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
        coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
        coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
        coord: [], // XY 轴坐标，X：经度；Y：纬度
        coordZ: 0, // Z 轴高度（单位：米）
        rotation: [0, 0, 0], // XYZ 三轴旋转度数 [偏航角（单位：角度，0正北, -360~360), 俯仰角（单位：角度，0水平, -360~360),翻滚角（单位：角度，0水平, -360~360)]
        alpha: 1, // 透明度，0：完全透明；1：完全不透明
        scale: 1, // 特效整体放缩倍数（单位：倍）
        visible: true,
        // titleText: '标题', // 特效标签文本
        // titleColor: '#ffffff', // 特效标签文本颜色，可选值，默认白色
        // titleBackgroundColor: '#333333', // 特效标签文本背景颜色，可选值，默认灰色
        // titleFontSize: 20, // 模型标签文本字体大小，数值越大，文字越大
        // titileAutoScale: false, // 模型标签是否随摄像机距离自动缩放，false，不缩放，永远一样大；true，缩放，摄像机越远标签越小
        // visible: true, // 添加当前模型时默认显示还是隐藏
        // modelType: 'Box_A01-001-05_机柜容量', // 模型类型
      }
      let jsonData = Object.assign({}, defaultConfig, params)
      window.appInstance.uniCall('addModel', jsonData, (res) => {
        // console.log('添加模型', jsonData, res)
        resolve(res)
      })
    })
  }

  static pickModel(params) {
    let defaultConfig = {
      modelType: 'model',
      type: 'hover',
      isShowDecorator: false,
    }

    let jsonData = Object.assign({}, defaultConfig, params)

    window.appInstance.uniCall('pickModel', jsonData, (res) => {
      // console.log('设置模型选中效果', jsonData, res)
    })
  }

  /**
   * 删除模型
   * @param id 模型id
   * @return {Promise<unknown>}
   */
  static removeModel(id) {
    return new Promise((resolve) => {
      let jsonData = {
        id,
      }
      window.appInstance.uniCall('removeModel', jsonData, (result) => {
        // console.log(result);
        resolve(result)
      })
    })
  }

  // /**
  //  * 新增模型
  //  * @param params 参数
  //  * @return {Promise<unknown>}
  //  */
  // static addModel(params){
  //   return new Promise(resolve => {
  //     appInstance.uniCall('addModel', params, (result) => {
  //       console.log(result);
  //     });
  //   });
  // }
}
