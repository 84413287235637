import BaseModelController from "@/core/model/BaseModelController";
import Store from "@/store";
import ObjectManager from "@/components/objectManager/ObjectManager";

/**
 * 机房楼控制器
 * @author haowei
 */
export default class JiFangBuildingController extends BaseModelController{

    modelIdList = ['SM_IDC机房楼']

    clickModelExec(e) {
        Store.commit('setRightType','JianZhu')
        Store.commit('setSubSpaceLevel','建筑01')
        ObjectManager.updateExpandKeys('SM_IDC机房楼')
        window.cameraManager.onSetCamera('建筑01',null,{})
    }

    getModelIdsList() {
        return this.modelIdList
    }
}
