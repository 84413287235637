import LandmarkLayerController from '@/core/layer/LandmarkLayerController'
import ODLineLayerController from '@/core/layer/ODLineLayerController'
import EventLayerController from '@/core/layer/EventLayerController'
import TipLandmarkLayerController from '@/core/layer/TipLandmarkLayerController'
import TipEventLayerController from '@/core/layer/TipEventLayerController'
import CapacityLayerController from '@/core/layer/CapacityLayerController'
import PathController from '@/core/layer/PathController'
import PathLayerController from '@/core/layer/PathLayerController'
import Store from '@/store'
import HeatMapLayerController from '@/core/layer/HeatMapLayerController'
import TrailLayerController from '@/core/layer/TrailLayerController'
import UEapi from "@/utils/UEapi/UEapi";
import DataCenterHandler from "@/core/layer/handler/DataCenterHandler";
import WarningHandler from "@/core/layer/handler/WarningHandler";
import AssetsHandler from "@/core/layer/handler/AssetsHandler";
import PersonHandler from "@/core/layer/handler/PersonHandler";
/**
 * 图层管理器
 * @author haowei
 */
export default class LayerManager{

  static instance


  /**
   * 获取单例
   */
  static getInstance() {
    return this.instance ? this.instance : new LayerManager()
  }

  controls = {
    'landmarkLayer':new LandmarkLayerController(),// 地标图
    'ODLineLayer':new ODLineLayerController(),// 关系图
    'eventLayer':new EventLayerController(),// 事件图
    'tip-landmarkLayer':new TipLandmarkLayerController(),// 带有标牌的地标图
    'tip-eventLayer':new TipEventLayerController(),// 带有标牌的事件图
    'capacityLayer':new CapacityLayerController(),// 容量图层
    'linkLayer':new PathController(),// 链路图层
    'pathLayer':new PathLayerController(),// 路径图层
    'heatMapLayer':new HeatMapLayerController(),// 温度云图
    'trailLayer':new TrailLayerController(), // 轨迹图
  }

  handleMap = {
    // 数据中心处理
    'dataCenter':new DataCenterHandler(),
    // 告警处理
    'warning':new WarningHandler(),
    // 资产
    'assets':new AssetsHandler(),
    // 人员
    'person':new PersonHandler(),
  }

  // 关闭点击事件的图层id
  noneClickEventIdLayers = []

  constructor() {

  }

  /**
   * 添加所有需要添加的图层，状态默认隐藏
   */
  async addAllLayers() {
    let layerNames = window.legendManager.getAllLegendsLayerNames()
    if (Store.state.spaceLevel == '中国'){
      layerNames.push('中国-省份标识')
    }
    if (Store.state.spaceLevel == '北京'){
      layerNames.push('北京-行政区标识')
    }
    console.debug('needAddAllLayers', layerNames)
    let layerMaps = this.getLayerMaps(layerNames);
    for (let layerMap of layerMaps) {
      try {
        // let layerMap = this.getLayerMap(layerName)
        if (layerMap) {
          let control = this.getControl(layerMap.layerConfig.layerType)
          // todo 删除添加再删除是发现图观sdk的问题,已反馈待解决.
          await control.clearLayerExec(layerMap);
          await control.addLayerExec(layerMap)
          await control.clearLayerExec(layerMap)
          await control.addLayerExec(layerMap)
          if (layerMap.layerConfig.layerType != 'linkLayer' && layerMap.layerConfig.layerType != 'pathLayer') {
            //   await control.hideLayerExec(layerMap)
          }
          layerMap.layerConfig.status = 'hide'
          console.debug('addAllLayers', layerMap.id)
        } else {
          console.warn(layerMap.id + '未找到layerMap')
        }
      }catch (e) {
        console.error(`图层${layerMap.id}添加异常`,e)
      }
    }
    // for (let layerName of layerNames) {
    //   try {
    //     let layerMap = this.getLayerMap(layerName)
    //     if (layerMap) {
    //       let control = this.getControl(layerMap.layerConfig.layerType)
    //       // todo 删除添加再删除是发现图观sdk的问题,已反馈待解决.
    //       await control.clearLayerExec(layerMap);
    //       await control.addLayerExec(layerMap)
    //       await control.clearLayerExec(layerMap)
    //       await control.addLayerExec(layerMap)
    //       if (layerMap.layerConfig.layerType != 'linkLayer' && layerMap.layerConfig.layerType != 'pathLayer') {
    //         //   await control.hideLayerExec(layerMap)
    //       }
    //       layerMap.layerConfig.status = 'hide'
    //       console.debug('addAllLayers', layerMap.id)
    //     } else {
    //       console.warn(layerName + '未找到layerMap')
    //     }
    //   }catch (e) {
    //     console.error(`图层${layerName}添加异常`,e)
    //   }
    // }
  }

  /**
   * 所有图层隐藏
   * @param ignoreLayers 忽略隐藏的图层id
   */
  async showAllLayers(ignoreLayers = []) {
    for (let item of window.layerList) {
      if (
        item.layerConfig.status != 'none' &&
        !ignoreLayers.includes(item.id)
      ) {
        item.layerConfig.status = 'show'
        let control = this.getControl(item.layerConfig.layerType)
        await control.showLayerExec(item)
        console.log('showAllLayers', control, item, item.id)
      }
    }
  }

  /**
   * 所有图层隐藏
   * @param ignoreLayers 忽略隐藏的图层id
   */
  async hideAllLayers(ignoreLayers = []) {
    for (let item of window.layerList) {
      if (
        item.layerConfig.status != 'none' &&
        !ignoreLayers.includes(item.id)
      ) {
        item.layerConfig.status = 'hide'
        let control = this.getControl(item.layerConfig.layerType)
        await control.hideLayerExec(item)
        console.log('hideAllLayers', item.id)
      }
    }
  }

  /**
   * 删除所有图层
   */
  async removeAllLayers() {
    for (let item of window.layerList) {
      if (item.layerConfig.status !== 'none') {
        let control = this.getControl(item.layerConfig.layerType)
        item.layerConfig.status = 'none'
        await control?.clearLayerExec(item)
        console.debug('removeAllLayers', item.id)
      }
    }
  }

  /**
   * 根据id获取图层控制器
   * @param id 图层id
   * @return {LandmarkLayerController|*|null}
   */
  getControlById(id) {
    let layerMap = this.getLayerMap(id)
    return this.getControl(layerMap.layerConfig.layerType)
  }

  /**
   * 根据类型获取controller
   * @param type 图层类型
   * @return {LandmarkLayerController|*|null}
   * @private
   */
  getControl(type) {
    let ctrl = null
    for (let controlsKey in this.controls) {
      if (controlsKey == type){
        ctrl = this.controls[controlsKey]
      }
    }
    return ctrl
  }

  /**
   * 获取图层属性对象
   * @param id 图层id
   * @return {*}
   */
  getLayerMap(id) {
    return window.layerList.find((item) => item.id == id)
  }

  /**
   * 获取图层属性对象
   * @param ids layerMap id数组
   */
  getLayerMaps(ids){
    let mapList = []
    for (let layerMap of window.layerList) {
      if (ids.includes(layerMap.id)){
        mapList.push(layerMap)
      }
    }
    return mapList
  }

  /**
   * 添加图层，默认隐藏（并不建议自己添加图层，应该由空间层次变化统一添加图层）
   * @param id 图层id
   */
  async addLayer(id) {
    let layerMap = this.getLayerMap(id)
    if (!layerMap) {
      console.warn(`【${id}】图层信息未在layerList.js中找到!`)
      return
    }
    let control = this.getControl(layerMap.layerConfig.layerType)
    layerMap.layerConfig.status = 'hide'
    await control?.addLayerExec(layerMap)
  }

  /**
   * 删除图层（并不建议自己删除图层，应该由空间层次变化统一删除图层）
   * @param id 图层id
   */
  async removeLayer(id) {
    let layerMap = this.getLayerMap(id)
    if (!layerMap) {
      console.warn(`【${id}】图层信息未在layerList.js中找到!`)
      return
    }
    let control = this.getControl(layerMap.layerConfig.layerType)
    layerMap.layerConfig.status = 'none'
    await control?.clearLayerExec(layerMap)
  }

  /**
   * 更新图层（弃用）
   * @param id 图层id
   * @deprecated 弃用
   */
  updateLayer(id) {
    let layerMap = this.getLayerMap(id)
    let control = this.getControl(layerMap.layerConfig.layerType)
    control?.updateLayerExec(layerMap)
  }

  /**
   * 显示图层
   * @param id 图层id
   */
  async showLayer(id) {
    let layerMap = this.getLayerMap(id)
    if (!layerMap) {
      console.warn(`【${id}】图层信息未在layerList.js中找到!`)
      return
    }
    let control = this.getControl(layerMap.layerConfig.layerType)
    layerMap.layerConfig.status = 'show'
    if (!control){
      return console.warn(`图层 ${layerMap.id} ${layerMap.layerConfig.layerType} 类型未匹配到控制器！`)
    }
    try {
      await control.showLayerExec(layerMap)
    }catch (e){
      console.warn(`展示图层发现问题【${layerMap.id}】`,e)
    }

    console.debug('showLayer', id)
  }

  /**
   * 隐藏图层
   * @param id 图层id
   */
  async hideLayer(id) {
    let layerMap = this.getLayerMap(id)
    if (!layerMap) {
      console.warn(`【${id}】图层信息未在layerList.js中找到!`)
      return
    }
    let control = this.getControl(layerMap.layerConfig.layerType)
    layerMap.layerConfig.status = 'hide'
    await control?.hideLayerExec(layerMap)
    console.debug('hideLayer', id)
  }

  /**
   * 图层点击事件处理
   * @param id 图层子id
   * @param idLayer 图层id
   */
  onLayerClick(id, idLayer) {
    const b = this.checkClickEvent(idLayer)
    if (!b){
      console.warn(`${idLayer} 图层点击事件被禁止`)
      return
    }

    UEapi.Common.clearOverlaySelected().then(()=>{
      console.debug('onLayerClick', id, idLayer)
      let control = this.getControlById(idLayer)
      window.cameraManager.onSetCamera(id,idLayer,{})
      control?.clickLayerExec({ id, idLayer })

      for (let key in this.handleMap) {
        let filterLayer = this.handleMap[key].filterLayer({id, idLayer})
        if (filterLayer){
          this.handleMap[key].handleClick({id, idLayer});
          break
        }
      }
    })
    window.ctrlEventManager.publish(window.ctrlEventManager.eventsMap.LayerClick,{id,idLayer})
  }

  /**
   * 图层加载全部
   * @return {Promise<void>}
   */
  static async loadLayer(){
    console.debug('loadLayer')
    if (!window.layerManager) {
      window.layerManager = LayerManager.getInstance()
    }
    // 删除所有已存在的图层
    await window.layerManager.removeAllLayers()
    // 添加该空间层次下的图层，默认不显示
    await window.layerManager.addAllLayers()
    // 显示legend默认选中的图层
    let layerNames = window.legendManager.getShowLegendsLayerNames()
    console.warn('getShowLegendsLayerNames',layerNames)
    for (const id of layerNames) {
      try {
        await window.layerManager.showLayer(id)
      }catch (e){
        console.warn('showLayer-error',e)
      }
    }


  }

  /**
   * 开启图层点击事件
   * @param idLayer 图层id
   */
  openClickEvent(idLayer){
    const find = this.noneClickEventIdLayers.find(item => itme == idLayer)
    if (find){
      this.noneClickEventIdLayers.splice(this.noneClickEventIdLayers.indexOf(idLayer),1)
    }
  }

  /**
   * 关闭图层点击事件
   * @param idLayer 图层id
   */
  closeClickEvent(idLayer){
    this.noneClickEventIdLayers.push(idLayer)
  }

  /**
   * 检查是否开启点击事件
   * @param idLayer 图层id
   * @return {boolean} 是否开启点击事件
   */
  checkClickEvent(idLayer){
    const find = this.noneClickEventIdLayers.find(item=>item == idLayer)
    return !find;
  }

  /**
   * 开启全部点击事件
   */
  openClickEventAll(){
    this.noneClickEventIdLayers = []
  }

  /**
   * 关闭全部点击事件
   */
  closeClickEventAll(){
    for (let item of window.layerList) {
      if (item.layerConfig.status != 'none'){
        this.noneClickEventIdLayers.push(item.id)
      }
    }
  }

  /**
   * 获取已经添加到场景的图层id
   * @return {*[]}
   */
  getLoadedLayerNames(){
    let res = []
    for (let item of window.layerList) {
      if (item.layerConfig.status != 'none'){
        res.push(item.id)
      }
    }
    return res
  }

}
