<template>
  <div class="Tag">
    <div class="Tag_left">
      <div class="Tag_left_icon"></div>
      <div class="Tag_left_text">{{name}}</div>
    </div>

    <div class="Tag_right">{{value}}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '名称'
    },
    value: {
      type: String,
      default: '北京数据中心'
    }
  }
}
</script>

<style lang="less" scoped>
.Tag {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Tag_left {
    display: flex;
    align-items: center;

    .Tag_left_icon {
      width: 10px;
      height: 10px;
      background: #55b7ff;
      border-radius: 50%;
    }

    .Tag_left_text {
      margin-left: 20px;
      font-size: 26px;
      font-family: '微软雅黑';
      color: #ffffff;
    }
  }

  .Tag_right {
    text-align: right;
    font-size: 26px;
    font-family: '微软雅黑';
    color: #ffffff;
  }
}
</style>
