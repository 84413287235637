<!--
 * @Author: yejinliang
 * @Date: 2022-07-12 19:51:48
 * @LastEditTime: 2022-07-13 10:40:41
 * @LastEditors: yejinliang
 * @Description:
-->
<template>
  <div class="base-drawer__wrap" :class="[hasCollapse, hasDirection]">
    <slot></slot>
    <div class="base-drawer__custom-toggle" @click="toggle()"></div>
    <div class="close" @click="close" v-if="ShowClose">
      <img src="../../assets/img/SSGJ/close.png" alt />
    </div>
  </div>
</template>

<script>
import Store from '@/store'
export default {
  props: {
    defaultVisible: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: 'ltr', // rtl 从右往左  ltr从左往右
    },
    ShowClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: true,
    }
  },
  computed: {
    hasCollapse() {
      return this.drawer ? 'collapse-open' : ''
    },
    hasDirection() {
      if (this.direction === 'ltr') {
        return 'collapse-left'
      }
      if (this.direction === 'rtl') {
        return 'collapse-right'
      }
      return 'collapse-left'
    },
  },
  methods: {
    /**
     * @description: 初始化组件给el组件设置自定义的默认值
     * @return {*}
     */
    initCom() {
      this.drawer = this.defaultVisible
    },
    toggle() {
      this.drawer = !this.drawer
    },
    close() {
      Store.commit('setRightType', '')
    },
  },
  mounted() {
    this.initCom()
  },
}
</script>

<style lang="less">
.base-drawer__wrap {
  position: fixed;
  width: 682px;
  height: 1736px;
  pointer-events: all;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  // background: #fff;
  .close {
    cursor: pointer;
    position: absolute;
    right: 23px;
    top: 20px;
    pointer-events: all;
  }
  .base-drawer__custom-toggle {
    cursor: pointer;
    position: absolute;
    top: 50%;

    box-sizing: border-box;
    width: 40px;
    height: 120px;
    transform: translateY(-50%);
    border: 1px solid #72a2e2;
    border-radius: 0 10px 10px 0;
    // background: rgba(0, 38, 77, 0.75);
    background: url('../../assets/img/commonComponents/arrows.png');
  }
}

.base-drawer__wrap.collapse-left {
  top: 240px;
  left: 0;

  transform: translateX(-100%);
  .base-drawer__custom-toggle {
    right: -39px;
  }
}
.base-drawer__wrap.collapse-right {
  top: 240px;
  right: 0;
  transform: translateX(100%);
  .base-drawer__custom-toggle {
    left: -39px;
    border-radius: 10px 0px 0px 10px;
    background: url('../../assets/img/commonComponents/arrows_right.png');
  }
}
.base-drawer__wrap.collapse-right.collapse-open {
  transform: translateX(0%);
}
.base-drawer__wrap.collapse-left.collapse-open {
  transform: translateX(0%);
}
</style>
