import BaseLayerController from '@/core/layer/BaseLayerController'
import Store from '@/store'
import UEapi from '@/utils/UEapi/UEapi'

/**
 * 温度云图图层控制器
 * @author haowei
 */
export default class HeatMapLayerController extends BaseLayerController{
  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data;
    layerMap.layerConfig.status = 'hide';
    // await UEapi.HeatMapLayer.addHeatMapLayer(data);
  }

  async showLayerExec(layerMap) {
    let data = layerMap.layerConfig.jsonData
    await UEapi.HeatMapLayer.addHeatMapLayer(data);
    await super.showLayerExec(layerMap)
  }

  async hideLayerExec(layerMap) {
    await super.hideLayerExec(layerMap)
    UEapi.Common.removeOverlay({
      id: layerMap.id,
      overlayType: layerMap.layerConfig.layerType,
    })
  }

  async getData(layerMap) {
    let rowData = await super.getRowData(layerMap)
    rowData.map((item) => {
      return {
        coord: [item.lon, item.lat],
        value: parseInt(Math.random() * 10),
      }
    })
    layerMap.layerConfig.rawData = rowData;
    const { spaceLevel } = Store.state

    let defaultOption = {
      visible:false
    }
    let jsonData = Object.assign({}, defaultOption, layerMap)
    jsonData.data = rowData.map((item) => {
      return {
        coord: [item.lon, item.lat],
        value: parseInt(Math.random() * 10),
        coordZ: item.coordZ || BaseLayerController.coordZList[spaceLevel] || 1,
      }
    })
    return jsonData
  }
}
