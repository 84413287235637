export default class Camera {
  // 设置摄像机
  static setCamera(jsonData) {
    return new Promise((resolve) => {
      appInstance.uniCall('setCamera', jsonData, (res) => {
        // console.log('设置相机视角', jsonData, res);
        resolve(res)
      })
    })
  }
  // 设置相机视角限制参数
  static restrictCamera(jsonData, flag = true) {
    if (typeof jsonData == 'boolean' || !jsonData) {
      return
    }
    appInstance.uniCall('restrictCamera', jsonData, (result) => {
      // console.log('视角限制参数', flag, result)
      this.setCameraRestrictionState(flag)
    })
  }
  // 设置相机视角限制状态
  static setCameraRestrictionState(flag = true) {
    appInstance.uniCall(
      'setCameraRestrictionState',
      {
        state: flag ? 'restricted' : 'free',
      },
      (result) => {
        // console.log('视角限制状态', flag, result)
      }
    )
  }
  // 漫游镜头
  static roamingCamera(points) {
    let jsonData = {
      coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
      coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
      loopMode: 'round', //循环模式：none，不循环；round，往返；repeat，从头循环
      points: points.map((point) => {
        return {
          coord: [point.lon, point.lat], // XY 轴坐标，X：经度；Y：纬度
          coordZ: 1, // Z 轴高度（单位：米）
          distance: 10, //镜头与坐标点距离(单位:米)
          pitch: 2, //镜头俯仰角 (-89~+89)
          yaw: 360 - point.value, //镜头偏航角 (0正北, 0~359)
          speed: 5, //镜头速度 (单位:米/秒)
        }
      }),
    }
    window.appInstance.uniCall('roamingCamera', jsonData, (res) => {
      console.log('开启漫游镜头', jsonData, res)
    })
  }
  static roamingCameraByJsonData(data) {
    window.appInstance.uniCall('roamingCamera', data, result => {
      console.log('开启漫游镜头1', result);
    });
  }
  // 路径漫游相机
  static pathingCamera(id) {
    let jsonData = {
      pathId: id,
      loopMode: 'none',
      reverse: false,
      distance: 10,
      pitch: 2,
      speed: 2,
    }
    window.appInstance.uniCall('pathingCamera', jsonData, (res) => {
      // console.log('开启路径漫游', jsonData, res);
    })
  }
  // 设置路径漫游状态
  static setCameraPathingState(state) {
    let jsonData = {
      state,
    }
    window.appInstance.uniCall('setCameraPathingState', jsonData, (res) => {
      // console.log('设置路径移动镜头状态', jsonData, res);
    })
  }
  // 聚焦覆盖物
  static focusById(params) {
    let jsonData = {
      id: params.id,
      overlayType: params.overlayType,
      distance: params.distance || 50,
    }
    appInstance.uniCall('focusById', jsonData, (res) => {
      // console.log('focusById', jsonData, res)
    })
  }
  // 聚焦 图层子覆盖物
  static focusByLayer(params) {
    return new Promise((resolve) => {
      let jsonData = {
        id: params.id,
        idLayer: params.idLayer,
        layerType: params.layerType,
        distance: params.distance || 100,
      }
      appInstance.uniCall('focusByLayer', jsonData, (res) => {
        // console.log('focusByLayer', jsonData, res)
        resolve(res)
      })
    })
  }
  // 聚焦模型
  static focusModel(params) {
    return new Promise((resolve) => {
      let jsonData = {
        id: params.id,
        modelType: params.modelType,
        distance: params.distance,
      }
      appInstance.uniCall('focusModel', jsonData, (res) => {
        // console.log('focusModel', jsonData, res)
        resolve(res)
      })
    })
  }
  // 聚焦 建筑
  static focusBuilding(params) {
    return new Promise((resolve) => {
      let jsonData = {
        buildingId: params.buildingId,
        distance: params.distance || 100,
      }
      appInstance.uniCall('focusBuilding', jsonData, (res) => {
        // console.log('focusBuilding', jsonData, res)
        resolve(res)
      })
    })
  }
  // 聚焦 楼层
  static focusFloor(params) {
    return new Promise((resolve) => {
      let jsonData = {
        buildingId: params.buildingId,
        floor: params.floor,
        distance: params.distance || 100,
      }

      appInstance.uniCall('focusFloor', jsonData, (res) => {
        // console.log('focusFloor', jsonData, res)
        resolve(res)
      })
    })
  }
  //
}
