import DefaultModelCameraController from "@/core/camera/DefaultModelCameraController";
import DataManager from "@/core/DataManager";
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 机柜镜头控制器
 * @author haowei
 */
export default class RackModelCameraController extends DefaultModelCameraController {

    // 基础镜头信息--机柜
    baseCameraInfo = {
        coordType: 0,
        coordTypeZ: 0,
        centerCoord: [],
        coordZ: 9.43,
        distance: 1.86,
        pitch:51,
        // pitch: 23.32,
        heading: 0,
        duration: 1,
        fly: true
    }

    async setCamera(params) {
        let rackCtrl = window.modelManager.controls.rack;
        if (rackCtrl.isRack(params.id)) {
            // 机柜
            let cameraInfo = this._calcJiGuiCameraInfo(params.id, rackCtrl);
            this.cameraInfo = cameraInfo
            await UEapi.Camera.setCamera(cameraInfo)
        } else {
            // 机柜组
            for (let item of window.allBoxData) {
                if (item.id == params.id) {
                    this.cameraInfo = item.cameraInfo
                    await UEapi.Camera.setCamera(item.cameraInfo)
                }
            }
        }
    }

    /**
     * 计算当前机柜镜头信息
     * @param id 机柜id
     * @param ctrl 机柜控制器
     * @return {{duration: number, coordType: number, fly: boolean, coordTypeZ: number, distance: number, coordZ: number, heading: number, pitch: number, centerCoord: *[]}}
     * @private
     */
    _calcJiGuiCameraInfo(id, ctrl) {
        let find = ctrl.rackList.find(item => item.id == id);
        if (!find) return this.baseCameraInfo
        let tempInfo = {...this.baseCameraInfo, centerCoord: find.coord}
        // 判断机柜镜头反向
        tempInfo.heading = ctrl.isReverseRack(find) ? 0 : 180
        console.warn('_calcJiGuiCameraInfo', tempInfo,find)
        return tempInfo
    }
}
