// 数据中心数据
export const spaceTreeData = [
    {
        id: '中国',
        label: '中国',
        num: 2,
        level: 1,
        sort: 1,
        isSelect: false,
        objType: 'space',
        components: '',
        spaceLevel: '中国',
        children: [
            {
                id: '北京数据中心',
                label: '北京数据中心',
                floor: '6',
                num: 2,
                sort: '6',
                level: 2,
                isSelect: false,
                objType: 'space',
                components: '', //'ShuJuZhongXingSheng',
                spaceLevel: '北京',
                children: [
                    {
                        id: '分数据中心-01',
                        label: '分数据中心-01',
                        num: 1,
                        sort: 601,
                        level: 3,
                        isSelect: false,
                        objType: 'space',
                        components: '', //'ShuJuZhongXingYuanQu',
                        spaceLevel: '园区',
                        children: [
                            {
                                id: 'SM_IDC机房楼',
                                label: '建筑01',
                                floor: '6',
                                num: 2,
                                sort: '6',
                                level: 4,
                                isSelect: false,
                                objType: 'space',
                                components: 'JianZhu',
                                spaceLevel: '园区',
                                children: [
                                    {
                                        id: 'SM_IDC室内04',
                                        label: 'F3',
                                        num: 3,
                                        sort: 601,
                                        level: 5,
                                        isSelect: false,
                                        objType: 'space',
                                        components: 'LouCeng',
                                        spaceLevel: '楼层',
                                        children: [
                                            {
                                                id: '机柜组机房',
                                                label: '机柜组机房',
                                                floor: '6',
                                                num: 2,
                                                sort: '6',
                                                level: 6,
                                                isSelect: false,
                                                objType: 'space',
                                                components: 'FangJian',
                                                spaceLevel: '房间',
                                                children: [
                                                    {
                                                        id: 'A01-003_SM_机柜组',
                                                        label: 'A01-003_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'A01-001_SM_机柜组',
                                                        label: 'A01-001_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'B02-001_SM_机柜组',
                                                        label: 'B02-001_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'B02-002_SM_机柜组',
                                                        label: 'B02-002_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'C03-001_SM_机柜组',
                                                        label: 'C03-001_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'C03-002_SM_机柜组',
                                                        label: 'C03-002_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'A01-002_SM_机柜组',
                                                        label: 'A01-002_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'B02-003_SM_机柜组',
                                                        label: 'B02-003_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'C03-003_SM_机柜组',
                                                        label: 'C03-003_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'C03-004_SM_机柜组',
                                                        label: 'C03-004_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [
                                                            {
                                                                id: 'C03-004-04_SM_设备机柜',
                                                                label: 'C03-004-04_SM_设备机柜',
                                                                pid: 'C03-004_SM_机柜组',
                                                                num: 4,
                                                                sort: 601,
                                                                level: 8,
                                                                isSelect: false,
                                                                objType: 'space',
                                                                components: 'JiGuiLixian',
                                                                spaceLevel: '机柜',
                                                                children: [
                                                                    {
                                                                        id: 'C03-004-04_SM_配线架',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        label: 'C03-004-04_SM_配线架',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                    {
                                                                        id: 'C03-004-04_SM_交换机',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        label: 'C03-004-04_SM_交换机',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                    {
                                                                        id: 'C03-004-04_SM_数据服务器_虚拟机',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        label: 'C03-004-04_SM_数据服务器_虚拟机',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                    {
                                                                        id: 'C03-004-04_SM_路由器',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        label: 'C03-004-04_SM_路由器',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                    {
                                                                        id: 'C03-004-04_SM_服务器',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        label: 'C03-004-04_SM_服务器',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                    {
                                                                        id: 'C03-004-04_SM_网络存储服务器',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        label: 'C03-004-04_SM_网络存储服务器',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                    {
                                                                        id: 'C03-004-04_SM_GPU服务器',
                                                                        label: 'C03-004-04_SM_GPU服务器',
                                                                        pid: 'C03-004-04_SM_设备机柜',
                                                                        floor: '6',
                                                                        num: 0,
                                                                        children: [],
                                                                        sort: '6',
                                                                        level: 9,
                                                                        isSelect: false,
                                                                        objType: 'space',
                                                                        components: 'FuWuQiBiaoZhun',
                                                                        spaceLevel: '设备',
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        id: 'B02-004_SM_机柜组',
                                                        label: 'B02-004_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'A01-004_SM_机柜组',
                                                        label: 'A01-004_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [
                                                            {
                                                                id: 'A01-004-04_SM_机柜组',
                                                                label: 'A01-004-04_SM_设备机柜',
                                                                num: 0,
                                                                children: [],
                                                                sort: 601,
                                                                level: 8,
                                                                isSelect: false,
                                                                objType: 'space',
                                                                components: 'JiGuiLixian',
                                                                spaceLevel: '机柜',
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        id: 'D04-001_SM_机柜组',
                                                        label: 'D04-001_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'D04-003_SM_机柜组',
                                                        label: 'D04-003_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'D04-004_SM_机柜组',
                                                        label: 'D04-004_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'E05-001_SM_机柜组',
                                                        label: 'E05-001_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'E05-002_SM_机柜组',
                                                        label: 'E05-002_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'E05-003_SM_机柜组',
                                                        label: 'E05-003_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'E05-004_SM_机柜组',
                                                        label: 'E05-004_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'F06-001_SM_机柜组',
                                                        label: 'F06-001_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'F06-002_SM_机柜组',
                                                        label: 'F06-002_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'F06-003_SM_机柜组',
                                                        label: 'F06-003_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [],
                                                    },
                                                    {
                                                        id: 'F06-004_SM_机柜组',
                                                        label: 'F06-004_SM_机柜组',
                                                        floor: '6',
                                                        num: 2,
                                                        sort: '6',
                                                        level: 7,
                                                        isSelect: false,
                                                        objType: 'space',
                                                        components: 'JiGuiLixian',
                                                        spaceLevel: '机柜组',
                                                        children: [
                                                            {
                                                                id: 'F06-004-07_SM_设备机柜',
                                                                label: 'F06-004-07_SM_设备机柜',
                                                                num: 4,
                                                                children: [],
                                                                sort: 601,
                                                                level: 8,
                                                                isSelect: false,
                                                                objType: 'space',
                                                                components: 'JiGuiZaixian',
                                                                spaceLevel: '机柜',
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                id: '电池组机房',
                                                label: '电池组机房',
                                                floor: '6',
                                                num: 0,
                                                children: [],
                                                sort: '6',
                                                level: 6,
                                                isSelect: false,
                                                objType: 'space',
                                                components: 'FangJian',
                                                spaceLevel: '房间',
                                            },
                                            {
                                                id: 'UPS设备机房',
                                                label: 'UPS设备机房',
                                                floor: '6',
                                                num: 0,
                                                children: [],
                                                sort: '6',
                                                level: 6,
                                                isSelect: false,
                                                objType: 'space',
                                                components: 'FangJian',
                                                spaceLevel: '房间',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'SM_IDC室内03',
                                        label: 'F2',
                                        num: 0,
                                        children: [],
                                        sort: 601,
                                        level: 5,
                                        isSelect: false,
                                        objType: 'space',
                                        components: 'LouCeng',
                                        spaceLevel: '楼层',
                                    },
                                    {
                                        id: 'SM_IDC室内02',
                                        label: 'F1',
                                        num: 0,
                                        children: [],
                                        sort: 601,
                                        level: 5,
                                        isSelect: false,
                                        objType: 'space',
                                        components: 'LouCeng',
                                        spaceLevel: '楼层',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: '分数据中心-02',
                        label: '分数据中心-02',
                        num: '0',
                        children: [],
                        sort: 601,
                        level: '3',
                        isSelect: false,
                        objType: 'space',
                        components: 'ShuJuZhongXingYuanQu',
                        spaceLevel: '',
                    },
                ],
            },
            {
                id: '上海数据中心',
                label: '上海数据中心',
                floor: '6',
                num: 0,
                children: [],
                sort: '6',
                level: '2',
                isSelect: false,
                objType: 'space',
                components: 'ShuJuZhongXingSheng',
                spaceLevel: '',
            },
        ],
    },
]
