import StandardStateHandler from "@/core/state/StandardStateHandler";
import BuildingController from "@/core/logic/BuildingController";
import Store from "@/store";

export default class RoomStandardHandler extends StandardStateHandler{

    async init() {
        BuildingController.expandBuilding()
        // await window.modelManager.controls.equipment.hideAllModelExec()
        window.cameraManager.onSetCamera(Store.state.subSpaceLevel,null,{})
        window.modelManager.closeClickEvent(['louCeng'])
        await super.init();
    }

    async reset() {
        window.modelManager.openClickEvent()
        await super.reset();
    }
}
