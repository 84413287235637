/**
 * 链路逻辑控制
 *
 * @author haowei
 */
import UEapi from "@/utils/UEapi/UEapi";
import Store from "@/store";

export default class LinkController {
  // 突出点纬度偏移量
  static prominentPointLatOffset = 0.000003
  // 其他点纬度偏移量
  static otherPointLatOffset = 0.000006

  static showLinkList = []

  static colorList = [
    {
      id: '五类网线',
      color: '#d50f0f',
    },
    {
      id: '超五类网线',
      color: '#2be7e5',
    },
    {
      id: '六类网线',
      color: '#0fd54c',
    },
    {
      id: '超六类网线',
      color: '#d57a0f',
    },
    {
      id: '七类网线',
      color: '#efe718',
    },
    {
      id: '超七类网线',
      color: '#1c97ff',
    },
    {
      id: '八类网线',
      color: '#cc1cff',
    },
  ]

  // /**
  //  * 展示设备模型之间的链路
  //  * @param eqId1 设备1
  //  * @param eqId2 设备2
  //  * @param linkId 链路id
  //  */
  // static async showLink(eqId1,eqId2,linkId){
  //   let jsonData = {
  //     id: linkId,
  //     overlayType: "model",
  //     idLayer: "",
  //     startIdObj:eqId1,
  //     startOffset: [0, 1, 0],
  //     endIdObj:eqId2,
  //     endOffset: [0, 1, 0],
  //     type: "Segment03",
  //     texture: "",
  //     textureSpeed: 0,
  //     autoScale: false,
  //     color: "#ff0000",
  //     width: 0.1,
  //     shapeType: "angle90", // todo 直角90度线有问题，待sdk更新
  //     curvature: -0.5,
  //     angleOrder: "zxy",
  //     label: "",
  //     labelColor: "#ffffff",
  //     labelBackgroundColor: "#333333",
  //     labelFontSize: 20
  //   };
  //
  //   return new Promise(resolve => {
  //     window.appInstance.uniCall('addConnection', jsonData, (result) => {
  //       console.log(result,jsonData);
  //       resolve(result);
  //     });
  //   })
  // }

  // /**
  //  * 移除链路
  //  * @param linkId 链路id
  //  * @returns {Promise<*>}
  //  */
  // static async removeLink(linkId){
  //   // todo 删除链路的方法待定，等sdk更新
  //   return await UEapi.Model.removeModel(linkId);
  // }

  /**
   * 获取链路数据
   * @param rowData 数据
   * @return {}
   */
  static getLinkData(rowData) {
    console.log('getLinkDataPre', rowData)
    let data = {}
    // json文件只支持一个数据！！！
    const linkGroup = rowData[0]
    for (let item of linkGroup.linkList) {
      data[item.id] = {}
      data[item.id]['layerId'] = linkGroup.id
      data[item.id]['points'] = []
      data[item.id]['config'] = item
      data[item.id]['config'].linkColor = this.getLinkColor(item.linkType)
      // 不同机柜连线
      if (item.startRackId != item.endRackId) {
        const { startEq, endEq } = this.getObjData(item)
        if (startEq.coord[0] != endEq.coord[0]) {
          // 不同经度下的机柜
          const zheXianData = this.getZheXianData(startEq, endEq, item)
          data[item.id]['points'].push(...zheXianData)
        } else {
          // 相同经度、高度下，画直线
          if (startEq.coordZ == endEq.coordZ){
            const zhixianData = this.getZhixianData(startEq, endEq, item)
            data[item.id]['points'].push(...zhixianData)
          }else{
            // 相同经度，高度不同，画折线
            const zheXianData = this.getZheXianData(startEq, endEq, item)
            data[item.id]['points'].push(...zheXianData)
          }
        }
      } else {
        // 同机柜画折线
        const { startEq, endEq } = this.getObjData(item)
        const zheXianData = this.getZheXianData(startEq, endEq, item)
        data[item.id]['points'].push(...zheXianData)
      }
    }
    console.log('getLinkData', data)
    return data
  }

  /**
   * 获取对象数据
   * @param item 数据对象
   * @return {{endRack: *, startEq: {visible: *, coordZ: number, rotation: *, scale: *, modelType: *|string, titleBackgroundColor: string, coordType: *, coord: *, coordTypeZ: *, titleColor: string, alpha: *, titleText: string, id: *}, endEq: {visible: *, coordZ: number, rotation: *, scale: *, modelType: *|string, titleBackgroundColor: string, coordType: *, coord: *, coordTypeZ: *, titleColor: string, alpha: *, titleText: string, id: *}, startRack: *}}
   */
  static getObjData(item) {
    let startRack = window.modelManager.controls.rack.rackList.find(
      (it) => it.id == item.startRackId
    )
    let endRack = window.modelManager.controls.rack.rackList.find(
      (it) => it.id == item.endRackId
    )
    let startEq = startRack.equipmentList.find(
      (it) => it.id == item.startEquipmentId
    )
    let endEq = endRack.equipmentList.find((it) => it.id == item.endEquipmentId)
    startEq = window.modelManager.controls.equipment._getModelJsonData({
      ...startEq,
      coord: startRack.coord,
      coordZ: startRack.coordZ,
    })
    endEq = window.modelManager.controls.equipment._getModelJsonData({
      ...endEq,
      coord: endRack.coord,
      coordZ: endRack.coordZ,
    })

    startEq.group = startRack.group
    endEq.group = endRack.group

    return {
      startRack,
      startEq,
      endRack,
      endEq,
    }
  }

  /**
   * 获取画折线的数据
   * @param startEq 开始设备
   * @param endEq 结束设备
   * @param item 原始数据
   */
  static getZheXianData(startEq, endEq, item) {
    let data = []
    let reverseStart = window.modelManager.controls.rack.isReverseRack(startEq.group)
    let reverseEnd = window.modelManager.controls.rack.isReverseRack(startEq.group)
    // console.log('getZheXianData', reverseStart, reverseEnd, startEq.group, endEq.group)
    // 开始点
    let point1 = {
      coord: [
        startEq.coord[0] - item.startOffsetXYZ[0],
        startEq.coord[1] - item.startOffsetXYZ[1],
      ],
      coordZ: startEq.coordZ - item.startOffsetXYZ[2],
    }
    // 开始点突出点位
    let lat1 = reverseStart
      ? point1.coord[1] + this.prominentPointLatOffset
      : point1.coord[1] - this.prominentPointLatOffset
    let point2 = {
      coord: [point1.coord[0], lat1],
      coordZ: point1.coordZ,
    }
    data.push(point1, point2)
    // 结束点
    let point6 = {
      coord: [
        endEq.coord[0] - item.endOffsetXYZ[0],
        endEq.coord[1] - item.endOffsetXYZ[1],
      ],
      coordZ: endEq.coordZ - item.endOffsetXYZ[2],
    }
    // 结束点突出点位
    let lat2 = reverseEnd
      ? point6.coord[1] + this.prominentPointLatOffset
      : point6.coord[1] - this.prominentPointLatOffset
    let point5 = {
      coord: [point6.coord[0], lat2],
      coordZ: point6.coordZ,
    }
    // 如果开始设备和结束设备，经纬度均不同，需要额外添加两个点
    if (
      startEq.coord[0] != endEq.coord[0] &&
      startEq.coord[1] != endEq.coord[1]
    ) {
      let lng1 = reverseStart
        ? point2.coord[0] + this.otherPointLatOffset
        : point2.coord[0] - this.otherPointLatOffset
      let point3 = {
        coord: [lng1, point2.coord[1]],
        coordZ: point2.coordZ,
      }
      let point4 = {
        coord: [point3.coord[0], point5.coord[1]],
        coordZ: point5.coordZ,
      }
      data.push(point3, point4)
    }

    data.push(point5, point6)
    return data
  }

  /**
   * 获取画直线的数据
   * @param startEq 开始设备
   * @param endEq 结束设备
   * @param item 原始数据
   */
  static getZhixianData(startEq, endEq, item) {
    let data = []
    // 开始点
    let point1 = {
      coord: [
        startEq.coord[0] - item.startOffsetXYZ[0],
        startEq.coord[1] - item.startOffsetXYZ[1],
      ],
      coordZ: startEq.coordZ - item.startOffsetXYZ[2],
    }
    // 结束点
    let point2 = {
      coord: [
        endEq.coord[0] - item.endOffsetXYZ[0],
        endEq.coord[1] - item.endOffsetXYZ[1],
      ],
      coordZ: endEq.coordZ - item.endOffsetXYZ[2],
    }
    data.push(point1, point2)
    return data
  }

  /**
   * 获取链路颜色
   * @param linkType 链路类型
   * @return {string} 颜色值
   */
  static getLinkColor(linkType) {
    let find = this.colorList.find((item) => item.id == linkType)
    return find.color
  }

  /**
   * 根据链路id获取数据
   * @param linkId 链路id--对应链路组*.json 中 linkList 下 元素的id
   * @return {*}
   */
  static getLinkDataById(linkId) {
    let filter = window.allData.filter((item) => item.collectionType == '链路')
    for (let data of filter) {
      for (let link of data.linkList) {
        if (link.id == linkId) {
          link.idLayer = data.idLayer
          return link
        }
      }
    }
  }

  /**
   * 根据设备信息获取链路数据
   * @param eqId 设备id
   * @return {[]} 链路数据
   */
  static getLinkDatasByEq(eqId) {
    let filter = window.allData?.filter((item) => item.collectionType == '链路')
    let arr = []
    if (filter) {
      for (let data of filter) {
        for (let link of data.linkList) {
          if (link.startEquipmentId == eqId || link.endEquipmentId == eqId) {
            if (!arr.find((item) => item.id == data.id)) arr.push(data)
          }
        }
      }

      // 封装详情数据
      for (let item of arr) {
        item.details = []
        for (let link of item.linkList) {
          let startEq = window.allEqData.find(
            (item) => item.id == link.startEquipmentId
          )
          let endEq = window.allEqData.find(
            (item) => item.id == link.endEquipmentId
          )
          if (item.details.length == 0) {
            item.details.push({
              type: 'equipment',
              label: startEq.label,
              name:startEq.label,
              id: startEq.id,
              value: startEq.id,
              btn:['设 备']
            })
          }
          if (
            item.details.length > 0 &&
            !item.details[item.details.length - 1].id == link.endEquipmentId
          ) {
            item.details.push({
              type: 'equipment',
              label: startEq.label,
              name: startEq.label,
              id: startEq.id,
              value: startEq.id,
              btn:['设 备']
            })
          }
          item.details.push(
            {
              type: 'link', // 链路
              id: link.id,
              value: link.id,
              label: link.label,
              name: link.label,
              btn:['配 线'],
              startEquipmentId: link.startEquipmentId,
              startRackId: link.startRackId,
              startRackGroupId: link.startRackGroupId,
              endEquipmentId: link.endEquipmentId,
              endRackId: link.endRackId,
              endRackGroupId: link.endRackGroupId,
            },
            {
              type: 'equipment',
              label: endEq.label,
              name: endEq.label,
              id: endEq.id,
              value: endEq.id,
              btn:['设 备']
            }
          )
        }
      }
    }
    console.warn('getLinkDatasByEq',arr)
    return arr
  }

  /**
   * 根据链路id显示链路
   * @param linkId 链路id
   */
  static async showLinkById(linkId) {
    // if (
    //   window.stateManager.currentState.key != StateManager.STATES.lookLink.key
    // ) {
    //   window.stateManager.switchState(StateManager.STATES.lookLink.key)
    // }
    let linkData = this.getLinkDataById(linkId)
    let crtl = window.layerManager.getControlById(linkData.idLayer)
    await crtl.showLayerSingle(linkData.idLayer, linkId)
    const rackCtrl = window.modelManager.controls.rack
    const eqCtrl = window.modelManager.controls.equipment
    if (
      rackCtrl.isRackOpen(linkData.endRackId) &&
      rackCtrl.isRackOpen(linkData.startRackId)
    ) {
      eqCtrl.setLinkEqFlag(
        [linkData.startEquipmentId, linkData.endEquipmentId],
        true
      )
      eqCtrl.highlightEqs([linkData.startEquipmentId, linkData.endEquipmentId])
    }
    if (!rackCtrl.isRackOpen(linkData.startRackId)) {
      rackCtrl.openRack(linkData.startRackId).then(() => {
        eqCtrl.setLinkEqFlag(
          [linkData.startEquipmentId, linkData.endEquipmentId],
          true
        )
        eqCtrl.highlightEqs([
          linkData.startEquipmentId,
          linkData.endEquipmentId,
        ])
      })
    }
    if (!rackCtrl.isRackOpen(linkData.endRackId)) {
      rackCtrl.openRack(linkData.endRackId).then(() => {
        eqCtrl.setLinkEqFlag(
          [linkData.startEquipmentId, linkData.endEquipmentId],
          true
        )
        eqCtrl.highlightEqs([
          linkData.startEquipmentId,
          linkData.endEquipmentId,
        ])
      })
    }
    this.showLinkList.push(linkId)
    Store.commit('appendShowLinkList',linkId)
  }

  /**
   * 根据链路id隐藏链路
   * @param linkId
   */
  static async hideLinkById(linkId) {
    let linkData = this.getLinkDataById(linkId)
    let crtl = window.layerManager.getControlById(linkData.idLayer)
    await crtl.hideLayerSingle(linkData.idLayer, linkId)
    const rackCtrl = window.modelManager.controls.rack
    const eqCtrl = window.modelManager.controls.equipment
    eqCtrl.setLinkEqFlag(
      [linkData.startEquipmentId, linkData.endEquipmentId],
      false
    )
    rackCtrl.closeAllRacksNotCurrent()
    this.showLinkList.splice(this.showLinkList.indexOf(linkId), 1)
    Store.commit('rmShowLinkList',linkId)
    // const nowSpaceLevel = SpaceController.getNowSpaceLevel()
    // const info = SpaceController.getSpaceInfoByName(nowSpaceLevel)
    // if (info.lv < 7) {
    //   window.stateManager.switchState(StateManager.STATES.standard.key)
    // } else {
    //   window.stateManager.switchState(StateManager.STATES.lookEq.key)
    // }
  }

  /**
   * 根据设备显示所有链路
   * @param eqId 设备id
   */
  static showLinksEq(eqId) {
    console.log('eqId', eqId)
    let linkDatas = this.getLinkDatasByEq(eqId)
    console.log('linkDatas', linkDatas)
    for (let linkData of linkDatas) {
      for (let link of linkData.linkList) {
        this.showLinkById(link.id)
      }
    }
  }

  /**
   * 根据设备隐藏链路
   * @param eqId 设备id
   */
  static hideLinksEq(eqId) {
    let linkDatas = this.getLinkDatasByEq(eqId)
    for (let linkData of linkDatas) {
      for (let link of linkData.linkList) {
        this.hideLinkById(link.id)
      }
    }
  }

  /**
   * 显示链路集合
   * @param collectionId 集合id
   * @param eqId 设备id
   */
  static async showLinkCollection(collectionId, eqId) {
    let linkDatas = this.getLinkDatasByEq(eqId)
    console.log('showLinkCollection',linkDatas)
    for (let linkData of linkDatas) {
      if (linkData.id == collectionId) {
        for (let link of linkData.linkList) {
          await this.showLinkById(link.id)
        }
      }
    }
  }

  /**
   * 隐藏链路集合
   * @param collectionId 集合id
   * @param eqId 设备id
   */
  static async hideLinkCollection(collectionId, eqId) {
    let linkDatas = this.getLinkDatasByEq(eqId)
    for (let linkData of linkDatas) {
      if (linkData.id == collectionId) {
        for (let link of linkData.linkList) {
          await this.hideLinkById(link.id)
        }
      }
    }
  }

  /**
   * 聚焦到链路中的设备,并提供突出显示
   * @param eqId 设备id
   */
  static focusLinkEq(eqId) {
    window.cameraManager.onSetCamera(eqId, null, {id:eqId,pos:0})
    window.modelManager.controls.equipment.highlightEq(eqId)
    this.cancelFocusLink()
  }

  static focusLinkCollection(collectionId, eqId) {
    let linkDatas = this.getLinkDatasByEq(eqId)
    console.warn('focusLinkCollection', linkDatas[0])
    this.focusLink(linkDatas[0].id)

    // if (linkDatas.length > 0) {
    //   UEapi.Camera.focusById({
    //     id: linkDatas[0].idLayer,
    //     overlayType: 'path',
    //     distance: 10,
    //   })
    // }
  }

  /**
   * 聚焦到链路
   * @param linkId 链路id
   */
  static focusLink(linkId) {
    if (linkId) {
      UEapi.Camera.focusById({
        id: linkId,
        overlayType: 'path',
        distance: 2.4,
      })
    }
    for (let link of this.showLinkList) {
      if (linkId != link) {
        let linkData = this.getLinkDataById(link)
        UEapi.Path.updateStyle({
          id: link,
          color: this.getLinkColor(linkData.linkType),
          type: 'Segment06', // 路线样式类别， Arrow01 Arrow02 Arrow03 Arrow04 Arrow05 Arrow06 Arrow07 Segment01 Segment02 Segment03 Segment04 Segment05 Segment06 Segment07 Segment08 ModelCube ModelCylinder
          texture: 'cableFlow', // 材质贴图名称，只有当 type 为 Model 开始的类型，才有用。端渲染：在场景中预置好的图片名称或外部图片 url，流渲染：服务器应用目录里的资源图片
          textureSpeed: 0, // 材质贴图的 UV 动画速度，默认：0，0为不运动，其他数值沿着路径模型方向前进，负数为反向
          colorPass: '#FFFF00', // 模型对象 移动经过后的 路径颜色（HEX 颜色值）
          // width: 10, // 宽度（单位：米）
        })
      } else {
        UEapi.Path.updateStyle({
          id: linkId,
          color: '#ffff00',
          type: 'Segment06', // 路线样式类别， Arrow01 Arrow02 Arrow03 Arrow04 Arrow05 Arrow06 Arrow07 Segment01 Segment02 Segment03 Segment04 Segment05 Segment06 Segment07 Segment08 ModelCube ModelCylinder
          texture: 'cableFlow', // 材质贴图名称，只有当 type 为 Model 开始的类型，才有用。端渲染：在场景中预置好的图片名称或外部图片 url，流渲染：服务器应用目录里的资源图片
          textureSpeed: 0, // 材质贴图的 UV 动画速度，默认：0，0为不运动，其他数值沿着路径模型方向前进，负数为反向
          colorPass: '#FFFF00', // 模型对象 移动经过后的 路径颜色（HEX 颜色值）
          // width: 12, // 宽度（单位：米）
        })
      }
    }
  }

  /**
   * 取消聚焦链路
   */
  static cancelFocusLink() {
    // const tempList = [...this.showLinkList]
    // for (let item of tempList) {
    //   this.hideLinkById(item)
    //   this.showLinkById(item)
    // }
    this.focusLink(null)
  }

  /**
   * 重置链路状态
   */
  static resetLinkState() {
    const tempList = [...this.showLinkList]
    for (let item of tempList) {
      this.hideLinkById(item)
    }
  }
}
