import StateManager from "@/core/StateManager";
import SpaceController from "@/core/logic/SpaceController";

/**
 * 服务切换完成逻辑处理
 * @author haowei
 */
export default class ServiceSwitchFinishController{
    /**
     * 处理逻辑
     * @param res 切换完成的结果
     */
    static handle(res){
        // 提交总线事件
        window.eventBus.emit('service-switch',res)
    }
}
