import UEapi from '@/utils/UEapi/UEapi'
import BaseTipLayerController from '@/core/layer/BaseTipLayerController'

/**
 * 带有标牌的事件图层
 *
 * @author haowei
 */
export default class TipEventLayerController extends BaseTipLayerController{

  constructor() {
    super()
    this.layerType = 'eventLayer'
  }

  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap);
    await UEapi.EventLayer.addEventLayer(data);
    layerMap.layerConfig.status = 'hide';
    // 添加标牌
    // this.addTip(layerMap.id,data.data);
  }

}
