import { render, staticRenderFns } from "./AnBaoRenYuan.vue?vue&type=template&id=c39c8cbe&scoped=true&"
import script from "./AnBaoRenYuan.vue?vue&type=script&lang=js&"
export * from "./AnBaoRenYuan.vue?vue&type=script&lang=js&"
import style0 from "./AnBaoRenYuan.vue?vue&type=style&index=0&id=c39c8cbe&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c39c8cbe",
  null
  
)

export default component.exports