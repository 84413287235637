export default class EventLayer {
  // 添加散点图层
  static async addEventLayer(params, callback) {
    // console.log('params', params)
    return new Promise(resolve => {
      let defaultOption = {
        id: 'idObj',
        name: 'layerName',
        coordType: 0,
        coordTypeZ: 0,
        visible: true,
        legends: [],
        data: [],
      }
      let jsonData = Object.assign({}, defaultOption, params)
      delete jsonData.layerConfig
      window.appInstance.uniCall('addEventLayer', jsonData, (res) => {
        // console.log('addEventLayer', jsonData, res)
        resolve(res);
        let pickJsonData1 = {
          overlayType: 'eventLayer',
          idLayer: jsonData.id,
          type: 'click',
          allowMultiple: false,
          isShowDecorator: true,
        }
        let pickJsonData2 = {
          overlayType: 'eventLayer',
          idLayer: jsonData.id,
          type: 'hover',
          allowMultiple: false,
          isShowDecorator: false,
        }
        appInstance.uniCall('pickOverlay', pickJsonData2, (res) => {
          // console.log('pickOverlay2', pickJsonData2, res)
        })
        setTimeout(() => {
          appInstance.uniCall('pickOverlay', pickJsonData1, (res) => {
            // console.log('pickOverlay1', pickJsonData1, res)
          })
        }, 1000)
      })
    })

  }
  // 更新图层数据
  static async updateEventLayerCoord(params) {
    let defaultOption = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      isAppend: false,
      data: [],
    }
    let jsonData = {
      ...defaultOption,
    }
    for (const key in jsonData) {
      jsonData[key] = params[key] || jsonData[key]
    }
    // if (jsonData.data) {
    //   jsonData.legends = [
    //     {
    //       name: 'default',
    //       icon: 'gaojing',
    //       type: 'wave',
    //       color: '#ff0000',
    //       fillArea: 'type01',
    //       speed: 0.2,
    //       radius: 1,
    //     },
    //   ]
    // }
    window.appInstance.uniCall('updateEventLayerCoord', jsonData, (res) => {
      // console.log('updateEventLayerCoord', jsonData, res)
    })
  }
}
