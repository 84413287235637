import BaseStateHandler from "@/core/state/BaseStateHandler";

/**
 * 数据分析状态处理
 * @author haowei
 */
export default class DataAnalysisStateHandler extends BaseStateHandler{

    async init() {
        window.modelManager.closeClickEvent()
        window.layerManager.closeClickEventAll()
        await super.init();
    }

    async reset() {
        window.modelManager.openClickEvent()
        window.layerManager.openClickEventAll()
        await super.reset();
    }
}
