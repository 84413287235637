import StandardStateHandler from "@/core/state/StandardStateHandler";

/**
 * 标准状态-中国层级
 * @author haowei
 */
export default class ChinaStandardStateHandler extends StandardStateHandler{

    async init() {
        window.cameraManager.onSetCamera('中国',null,{})
        await super.init();
    }

    async reset() {
        await super.reset();
    }
}
