<template>
  <div>
    <base-drawer>
      <div class="object-manager">
        <!-- 标题 -->
        <div class="object-manager-title">
          <i class="object-manager-title__icon"></i>
          <div class="object-manager-title__text">对象管理</div>
        </div>
        <!-- 搜索框 -->
        <div class="object-manager-input__wrap">
          <div class="object-manager-input">
            <input
              type="text"
              placeholder="请输入对象名称"
              :value="inputData"
              @input="onSearchInput"
            />
            <div class="input-right">
              <i
                class="object-manager-input__search"
                @click="inputSearch"
                v-if="hasInput"
              ></i>
              <i
                class="object-manager-input__clear"
                @click="inputClear"
                v-else
              ></i>
            </div>
          </div>
        </div>
        <!-- 折叠面板组 -->
        <div class="object-manager-collapse">
          <div class="custom-collapse">
            <div class="costom-collapse-item" :class="isActive('space')">
              <div
                class="costom-collapse-item-title"
                @click="handleChange('space')"
              >
                <div class="costom-collapse-item-title__left">
                  <i class="el-icon-space"></i>数据中心
                </div>
                <div class="costom-collapse-item-title__right">
                  <div
                    class="el-icon-arrow-right el-collapse-item__arrow"
                  ></div>
                </div>
              </div>
              <div
                class="costom-collapse-item-body"
                v-show="activeNames === 'space'"
              >
                <div class="custom-el-tree-header">
                  <div class="custom-el-tree-header__text">名称</div>
                  <div class="custom-el-tree-header__text">数量</div>
                </div>
                <el-tree
                  class="custom-el-tree"
                  node-key="id"
                  :ref="space.ref"
                  :data="space.treeData"
                  :props="space.treeProps"
                  :accordion="true"
                  highlight-current
                  :render-content="renderSpaceContent"
                  :filter-node-method="filterNode"
                  :default-expanded-keys="spaceExpandKeys"
                  :auto-expand-parent="true"
                  @node-click="handleNodeClick"
                ></el-tree>
                <!-- :default-expanded-keys="['中国']" -->
              </div>
            </div>
            <div class="costom-collapse-item" :class="isActive('device')">
              <div
                class="costom-collapse-item-title"
                @click="handleChange('device')"
              >
                <div class="costom-collapse-item-title__left">
                  <i class="el-icon-device"></i>设备
                </div>
                <div class="costom-collapse-item-title__right">
                  <div
                    class="el-icon-arrow-right el-collapse-item__arrow"
                  ></div>
                </div>
              </div>
              <div
                class="costom-collapse-item-body"
                v-show="activeNames === 'device'"
              >
                <div class="custom-el-tree-header">
                  <div class="custom-el-tree-header__text">名称</div>
                  <div class="custom-el-tree-header__text">数量</div>
                </div>
                <el-tree
                  class="custom-el-tree"
                  node-key="id"
                  :ref="device.ref"
                  :data="device.treeData"
                  :props="device.treeProps"
                  :accordion="true"
                  :render-content="renderContent"
                  :filter-node-method="filterNode"
                  :auto-expand-parent="false"
                  @node-click="handleNodeClick"
                ></el-tree>
              </div>
            </div>
            <div class="costom-collapse-item" :class="isActive('asset')">
              <div
                class="costom-collapse-item-title"
                @click="handleChange('asset')"
              >
                <div class="costom-collapse-item-title__left">
                  <i class="el-icon-asset"></i>资产
                </div>
                <div class="costom-collapse-item-title__right">
                  <div
                    class="el-icon-arrow-right el-collapse-item__arrow"
                  ></div>
                </div>
              </div>
              <div
                class="costom-collapse-item-body"
                v-show="activeNames === 'asset'"
              >
                <div class="custom-el-tree-header">
                  <div class="custom-el-tree-header__text">名称</div>
                  <div class="custom-el-tree-header__text">数量</div>
                </div>
                <el-tree
                  class="custom-el-tree"
                  node-key="id"
                  :ref="asset.ref"
                  :data="asset.treeData"
                  :props="asset.treeProps"
                  :accordion="true"
                  :render-content="renderContent"
                  :filter-node-method="filterNode"
                  :auto-expand-parent="false"
                  @node-click="handleNodeClick"
                ></el-tree>
              </div>
            </div>
            <div class="costom-collapse-item" :class="isActive('personnel')">
              <div
                class="costom-collapse-item-title"
                @click="handleChange('personnel')"
              >
                <div class="costom-collapse-item-title__left">
                  <i class="el-icon-personnel"></i>人员
                </div>
                <div class="costom-collapse-item-title__right">
                  <div
                    class="el-icon-arrow-right el-collapse-item__arrow"
                  ></div>
                </div>
              </div>
              <div
                class="costom-collapse-item-body"
                v-show="activeNames === 'personnel'"
              >
                <div class="custom-el-tree-header">
                  <div class="custom-el-tree-header__text">名称</div>
                  <div class="custom-el-tree-header__text">数量</div>
                </div>
                <el-tree
                  class="custom-el-tree"
                  node-key="id"
                  :ref="personnel.ref"
                  :data="personnel.treeData"
                  :props="personnel.treeProps"
                  :accordion="true"
                  :render-content="renderContent"
                  :filter-node-method="filterNode"
                  :auto-expand-parent="false"
                  @node-click="handleNodeClick"
                ></el-tree>
              </div>
            </div>
          </div>
        </div>
        <!-- 对象数量信息 -->
        <div class="object-manager-bottom">
          {{ objTotal }}对象({{ selectObjNum }}选中)
        </div>
      </div>
    </base-drawer>
  </div>
</template>

<script>
import BaseDrawer from '../common/BaseDrawer.vue'
import { mapState } from 'vuex'
import eventMixin from "@/mixins/eventMixin";
import ObjectManager from "./ObjectManager";
import DataManager from "@/core/DataManager";
import SpaceController from "@/core/logic/SpaceController";
export default {
  name: 'ObjectManager',
  mixins:[eventMixin],
  components: {
    BaseDrawer,
  },
  computed: {
    ...mapState(['appInstance', 'spaceLevel', 'floor','spaceExpandKeys']),
    hasInput() {
      return !this.inputData
    },
    objTotal() {
      return ObjectManager.calcObjTotal(this.space.treeData,this.device.treeData,this.asset.treeData,this.personnel.treeData)
    },
  },
  watch: {
    appInstance(newValue, oldValue) {
      this.init()
    },
    spaceLevel: {
      handler: function (newValue, oldValue) {
        const allList = window.allData
        this.setDeviceList(allList, newValue)
        this.setPersonnelList(allList, newValue)
      },
      deep: true,
    },
    spaceExpandKeys(vals){
    },
    floor: {
      handler: function (newFloor, oldFloor) {
        if (this.spaceLevel === '楼层_默认') {
          const allList = window.sessionStorage.getItem('allList')
          // 筛选设备数据
          this.setDeviceList()
          // 人员数据
          this.setPersonnelList()
          // this.personnel.treeData = ObjectManager.getPersonnelList(
          //   JSON.parse(allList),
          //   `楼层_F${newFloor}`
          // )
        } else {
          return
        }
      },
      deep: true,
    },
    inputData(val) {
      this.$refs[`${this.activeNames}Ref`].filter(val)
    },
  },
  data() {
    return {
      inputData: '',
      activeNames: 'space',
      selectObjNum: 0,
      // 数据中心
      space: {
        ref: 'spaceRef',
        treeData: [],
        treeProps: {
          children: 'children',
          label: 'label',
        },
      },
      // 设备
      device: {
        ref: 'deviceRef',
        treeData: [],
        treeProps: {
          children: 'children',
          label: 'label',
        },
      },
      // 资产
      asset: {
        ref: 'assetRef',
        treeData: [],
        treeProps: {
          children: 'children',
          label: 'label',
        },
      },
      // 人员
      personnel: {
        ref: 'personnelRef',
        treeData: [],
        treeProps: {
          children: 'children',
          label: 'label',
        },
      },
    }
  },
  methods: {
    async init() {
      let allList = window.allData
      try {
        // 加载设备数据
        this.setDeviceList(allList, this.spaceLevel)
      } catch (error) {}
      try {
        // 加载资产数据
        this.asset.treeData = ObjectManager.getAssetList(
          allList,
          this.spaceLevel
        )
      } catch (error) {}
      try {
        // 加载人员数据
        this.setPersonnelList(allList, this.spaceLevel, this.floor)
      } catch (error) {}
      // 加载远程设备数据到树结构中
      await ObjectManager.loadEqDataTree()
      this.space.treeData = window.objectInfo.spaceTreeData
      console.warn('加载远程设备数据',this.space.treeData)

      this.$nextTick(() => {
        const curTreeRef = this.$refs[`${this.activeNames}Ref`]
        this.disableHandleExpandIconClick(curTreeRef.$children)
      })
    },
    // 获取设备数据
    setDeviceList() {
      DataManager.getAllDeviceList().then(res=>{
        this.device.treeData = ObjectManager.handleDataForDevice(res)
      })
      // this.device.treeData = ObjectManager.getDeviceList(allList, spaceLevel)
      // console.log('this.device.treeData', this.device.treeData)
    },
    // 获取人员数据
    setPersonnelList() {
      DataManager.getAllPersonList().then(res=>{
        this.personnel.treeData = ObjectManager.handleDataForPerson(res)
      })
    },
    onSearchInput($event) {
      this.timer && clearTimeout(this.timer)
      let { target } = $event
      this.timer = null
      setTimeout(() => {
        this.inputData = target.value
      }, 1000)
    },
    // 输入框查询
    inputSearch() {},
    // 输入框清空
    inputClear() {
      this.inputData = ''
      this.init()
    },
    // 树节点点击事件
    handleNodeClick(node,handleEnable = true) {
      const curTreeRef = this.$refs[`${this.activeNames}Ref`]
      this.$nextTick(() => {
        const { objType } = node
        this.disableHandleExpandIconClick(curTreeRef.$children)
        const curNode = curTreeRef.getNode(node)
        console.warn('handleNodeClick',curNode,node,curTreeRef,this.personnel.treeData)
        // 同级手风琴效果
        this.handleAccordion(curNode)
        // 节点是否选中
        node.isSelect = !node.isSelect
        this.selectObjNum = node.isSelect
            ? node?.num || 1
            : curNode?.parent?.data?.num ?? 0
        // 设置节点高亮状态
        curNode.isCurrent = node.isSelect

        this.setExpanded(curNode, node.isSelect)
        !node.isSelect && this.setChildrenCollapse(curNode)

        if (!handleEnable){
          return
        }
        switch (objType) {
          case 'space':
            // 空间逻辑
            ObjectManager.execSpaceModule(node, curTreeRef)
            break
          case 'device':
            // 设备逻辑
            ObjectManager.execDeviceModule(node, curTreeRef)
            break
          case 'asset':
            // 资产逻辑
            break
          case 'personnel':
            // 人员逻辑
            ObjectManager.execPersonnelModule(node, curTreeRef)
            break
          default:
            break
        }
      })
    },
    // 自定义空间节点
    renderSpaceContent(h, { node, data, store }) {
      return (
          <div class="custom-el-tree-node">
            <div class="text">{node.label}</div>
            <div class="num">{data.num}</div>
          </div>
      )
      // if (node.level != 9){
      //   return (
      //       <div class="custom-el-tree-node">
      //         <div class="text">{node.label}</div>
      //         <div class="num">{data.num}</div>
      //       </div>
      //   )
      // }else {
      //   return (
      //       <div class="custom-el-tree-node-shebei">
      //         <div class="text">{node.label}</div>
      //         <div class="num">{data.num}</div>
      //       </div>
      //   )
      // }
    },
    // 自定义设备 资产 人员树节点
    renderContent(h, { node, data, store }) {
      return (
          <div class="custom-el-tree-node">
            <div class="text">{node.label}</div>
            <div class="num">{data.num}</div>
          </div>
      )
    },
    // 折叠面板标题点击事件
    handleChange(val) {
      if (val !== this.activeNames) {
        this.activeNames = val
        this.$store.commit('setObjectManagerKey',val);
      }
    },
    // 判断折叠面板是否添加is-active类
    isActive(val) {
      if (this.activeNames == val) {
        return 'is-active'
      } else {
        return ''
      }
    },
    // 临时解决办法 禁用树结构前面的小三角
    disableHandleExpandIconClick(child) {
      if (child.length > 0) {
        for (let i = 0; i < child.length; i++) {
          child[i].handleExpandIconClick = function () {
            console.log('禁用')
          }
          if (child[i].$children.length > 0) {
            this.disableHandleExpandIconClick(child[i].$children)
          }
        }
      }
    },
    // 树组件点击节点同级手风琴效果
    handleAccordion(curNode) {
      let parent = curNode?.parent ?? null
      if (parent) {
        parent.childNodes.forEach((element) => {
          if (curNode !== element) {
            element.collapse()
            element.data.isSelect = false
          }
        })
      }
    },
    // 设置节点是否展开
    setExpanded(curNode, isSelect) {
      if (curNode.childNodes.length > 0) {
        curNode.expanded = isSelect
      }
    },
    // 节点折叠后设置自己的子节点也折叠
    setChildrenCollapse(curNode) {
      if (curNode.childNodes.length > 0) {
        curNode.childNodes.forEach((element) => {
          element.collapse()
          element.data.isSelect = false
          if (element?.childNodes) {
            this.setChildrenCollapse(element)
          }
        })
      }
    },
    // 树节点过滤
    filterNode(value, data) {
      if (!value) return true
      if (data.label.indexOf(value) !== -1) {
        return data.label.indexOf(value) !== -1
      }
    },
  },
  created() {},
  mounted() {
    this.init();
    // this.findDefaultSpaceExtendNode(this.spaceExpandKeys);
  },
  beforeDestroy() {},
  onSpaceLevelChange(params){

  },
  onModelClick(params){
    // console.log('com-onModelClick',params)
    const curTreeRef = this.$refs[`${this.activeNames}Ref`]
    curTreeRef.setCurrentKey(params.id)
    this.$nextTick(()=>{
      let currentNode = curTreeRef.getCurrentNode();
      if (!currentNode.isSelect){
        this.handleNodeClick(currentNode,false)
      }
    })
  },
  onLayerClick(params){
    console.log('com-onLayerClick',params)
  }
}
</script>

<style lang="less" scoped>
.object-manager {
  height: 100%;
  border: 2px solid rgba(146, 172, 186, 0.65);
  background-color: rgba(18, 32, 46, 0.6);
  .object-manager-title {
    box-sizing: border-box;
    height: 78px;
    padding: 22px 28px 15px 28px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    .object-manager-title__icon {
      display: inline-block;
      vertical-align: text-top;
      margin-right: 14px;
      width: 42px;
      height: 42px;
      background-image: url('~@/assets/img/ObjectManager/dxgl_title_icon.png');
      background-repeat: no-repeat;
    }
    .object-manager-title__text {
      display: inline-block;
      vertical-align: text-top;
      color: #fff;
      font-size: 36px;
      line-height: 1;
      font-weight: bold;
      font-family: '微软雅黑';
      text-shadow: 0 3px 4px #000;
    }
  }
  .object-manager-input__wrap {
    height: 70px;
    min-height: 70px;
    overflow: hidden;
    .object-manager-input {
      position: relative;
      margin: 10px 30px;
      input {
        box-sizing: border-box;
        padding: 0 62px 0 15px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: 2px solid rgb(77, 80, 94);
        color: #fff;
        font-size: 28px;
        background-color: rgb(39, 39, 41);
      }
      input::placeholder {
        color: #4a515f;
        font-size: 28px;
      }
      .input-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 62px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .object-manager-input__search,
        .object-manager-input__clear {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
        }
        .object-manager-input__search {
          background-image: url('~@/assets/img/ObjectManager/dxgl_search.png');
        }
        .object-manager-input__clear {
          background-image: url('~@/assets/img/ObjectManager/dxgl_clear.png');
        }
      }
      .input-right:hover {
        opacity: 0.8;
      }
    }
  }

  // 修改element collapse
  .object-manager-collapse {
    height: calc(100% - 223px);
    .custom-collapse {
      height: 100%;
    }
    .costom-collapse-item {
      .costom-collapse-item-title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        background: rgba(17, 21, 34, 0.8);
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-sizing: border-box;
        .costom-collapse-item-title__left {
          color: #fff;
          font-size: 28px;
        }
        .costom-collapse-item-title__right {
          margin-right: 50px;
        }
      }
      .costom-collapse-item-body {
        box-sizing: border-box;
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        border-right: 1px solid rgba(255, 255, 255, 0.4);
      }
      .el-icon-arrow-right:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 12px;
        background-image: url('~@/assets/img/ObjectManager/dxgl_jt1.png');
        background-repeat: no-repeat;
        transform: rotate(-90deg);
      }
    }
    .costom-collapse-item.is-active {
      height: calc(100% - 210px);
      .costom-collapse-item-title {
        background: rgba(3, 109, 188, 0.5);
      }
      .costom-collapse-item-body {
        height: calc(100% - 70px);
        overflow: auto;
      }
      .el-collapse-item__arrow {
        transform: rotate(90deg);
      }
    }

    .el-icon-space,
    .el-icon-device,
    .el-icon-asset,
    .el-icon-personnel {
      display: inline-block;
      width: 26px;
      height: 26px;
      background-repeat: no-repeat;
      margin-right: 20px;
      margin-left: 46px;
    }
    .el-icon-space {
      background-image: url('~@/assets/img/Collapse/SJZX.png');
    }
    .el-icon-device {
      background-image: url('~@/assets/img/ObjectManager/dxgl_sb.png');
    }
    .el-icon-asset {
      background-image: url('~@/assets/img/ObjectManager/dxgl_zc.png');
    }
    .el-icon-personnel {
      background-image: url('~@/assets/img/ObjectManager/dxgl_ry.png');
    }
  }

  // 底部
  .object-manager-bottom {
    padding: 0 37px;
    height: 75px;
    line-height: 75px;
    min-height: 75px;
    color: #7e8ca5;
    font-size: 28px;
    border-top: 2px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.5);
  }

  @oddBg: rgba(0, 0, 0, 0.2);
  @evenBg: rgba(43, 48, 60, 0.9);
  // 修改el-tree
  .custom-el-tree-header {
    display: flex;
    align-items: center;
    height: 70px;
    min-height: 70px;
    background-color: rgba(43, 48, 60, 0.9);
    .custom-el-tree-header__text:nth-child(1) {
      color: #7e8ca5;
      font-size: 28px;
      width: 520px;
      padding-left: 86px;
      box-sizing: border-box;
    }
    .custom-el-tree-header__text:nth-child(2) {
      flex: 1;
      color: #7e8ca5;
      font-size: 28px;
    }
  }
  /deep/ .custom-el-tree.el-tree {
    background-color: rgba(43, 48, 60, 0.9);
    .el-tree-node:nth-child(odd) > .el-tree-node__content {
      height: 60px;
      background: @oddBg;
    }
    .el-tree-node:nth-child(even) > .el-tree-node__content {
      height: 60px;
      background: @evenBg;
    }
    .el-tree-node__expand-icon {
      font-size: 28px;
      margin-left: 75px;
      pointer-events: none;
    }
    .el-tree-node.is-current > .el-tree-node__content
    ,.el-tree-node.is-expanded > .el-tree-node__content
    {
      background: rgba(24, 155, 250, 0.1);
      .el-icon-caret-right {
        color: #54b7ff;
      }
      .el-icon-caret-right.is-leaf {
        color: transparent;
      }
      .custom-el-tree-node {
        .text,
        .num,
        .category {
          color: #54b7ff;
        }
      }
    }
    .el-tree-node.is-current > .el-tree-node__content{
      background: rgba(24, 155, 250, 0.1);
      .el-icon-caret-right {
        color: #54b7ff;
      }
      .el-icon-caret-right.is-leaf {
        color: transparent;
      }
      .custom-el-tree-node-shebei{
        .text,
        .num,
        .category {
          color: #54b7ff;
        }
      }
    }
    .custom-el-tree-node,.custom-el-tree-node-shebei  {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // width: 100%;
      color: #fff;
      font-size: 28px;
      .text {
        flex: 1;
        text-align: left;
      }
      .num {
        flex: 1;
        text-align: center;
      }
    }
  }
}
</style>
