<template>
  <div class="Collapse">
    <div class="Collapse_head" @click="ReceiveFair">
      <div class="Collapse_head_left">
        <div class="Collapse_head_left_image">
          <img :src="iconUrl" alt />
        </div>

        <div class="Collapse_head_left_text">{{ title }}</div>
      </div>
      <div v-if="!isywxt" :class="isReceiveFair ? 'rotate' : 'Collapse_head_right'"></div>
      <div class="ywxt" v-if="isywxt" @click="ywxtFunc">
        <img src="../../../assets/img/SSGJ/ywxt.png" alt />
      </div>
    </div>
    <main class="Collapse_main" v-if="isReceiveFair">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  props: {
    iconUrl: {
      type: String,
      default: require('../../../assets/img/Collapse/JCXX.png')
    },
    title: {
      type: String,
      default: '基础信息'
    },
    isShowMain: {
      type: Boolean,
      default: false
    },
    isReceiveFair: {
      type: Boolean,
      default: true
    },
    isywxt: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    isShowMain: {
      handler(val) {
        this.isReceiveFair = val
      },
      deep: true
    }
  },
  methods: {
    ReceiveFair() {
      this.$emit('changeisReceiveFair', this.title)
    },
    // 跳转业务系统
    ywxtFunc() {}
  },

  mounted() {}
}
</script>

<style lang="less" scoped>
.Collapse {
  width: 100%;

  .Collapse_head {
    width: 100%;
    height: 64px;
    padding: 0 38px;
    background: rgba(62, 68, 77, 0.8);
    border: rgba(255, 255, 255, 0.3) solid 1px;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Collapse_head_left {
      width: 165px;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Collapse_head_left_image {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .Collapse_head_left_text {
        font-size: 30px;
        font-family: '微软雅黑';
        color: #ffffff;
      }
    }

    .rotate {
      width: 20px;
      height: 12px;
      background: url('../../../assets/img/ObjectManager/dxgl_jt1.png')
        no-repeat 100%;
    }

    .Collapse_head_right {
      width: 12px;
      height: 20px;
      background: url('../../../assets/img/ObjectManager/dxgl_jt.png') no-repeat
        100%;
    }
  }

  .Collapse_main {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 30px 50px;
    margin-bottom: -20px;
    box-sizing: border-box;
  }
}
</style>