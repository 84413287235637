import StandardStateHandler from "@/core/state/StandardStateHandler";
import BuildingController from "@/core/logic/BuildingController";
import Store from "@/store";
import SpaceController from "@/core/logic/SpaceController";
import LinkController from "@/core/logic/linkController";

/**
 * 设备标准状态
 * @author haowei
 */
export default class EqStandardHandler extends StandardStateHandler{

    async init() {
        BuildingController.expandBuilding()
        window.cameraManager.onSetCamera(Store.state.subSpaceLevel,null,{})
        window.modelManager.closeClickEvent(['louCeng'])
        LinkController.resetLinkState()
        await super.init();
    }

    async reset() {
        // 重置高亮状态
        let equipmentCtrl = window.modelManager.controls.equipment
        equipmentCtrl.unDoHighlightAllEq()
        equipmentCtrl._showVMNotInside()
        window.modelManager.openClickEvent()
        LinkController.resetLinkState()
        await super.reset();
    }
}
