<template>
  <!--  -->
  <div class="layout_wraper" v-show="functionStatus == '数据分析'">
    <!-- <div class="layout_wraper"> -->
    <div class="layout_left">
      <BaseDrawer>
        <div class="nhgl_left">
          <div class="zskdpm">
            <MainTitle val="专属宽带排名" />
            <BarChart
              :rowData="zskdpmData"
              :coloreBarChart="['#4DFBFF', '#00C5CA']"
              xtype="value"
              ytype="category"
              class="charts"
              :styleBarChart="{
                width: '620px',
                height: '480px',
              }"
            />
          </div>
          <div class="zxlxzb">
            <MainTitle val="专线类型占比" />
            <pieChart
              roseheight="332px"
              :rowData="zxlxzbData"
              class="charts"
              :color="['#A68CDB', '#17CAA6', '#73FFFF', '#56A2E7', '#1F7ED6']"
              :loop_labelStyle="{
                left: '-8px',
                top: '12%',
              }"
              :backImg="{
                url: require('@/assets/img/WLTS/zxpie.png'),
                pos: '54px 68px',
                size: '226px 226px',
              }"
            />
          </div>
          <div class="wlxn">
            <MainTitle val="网络性能" />
            <ul>
              <li
                :class="index == i ? 'ac' : ''"
                v-for="(item, index) in [
                  '路由器CPU负荷',
                  '链路带宽利用率',
                  '链路流量',
                ]"
                @click="wlxntabList(item, index)"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
            <BarChartV2
              :chartStylr="{
                unit: '单位：GHz',
                barWidth: 20,
              }"
              :rowData="wlxnData"
              :coloreBarChart="['#73FFFF', '#4360E4']"
              class="charts"
              :styleBarChart="{
                width: '680px',
                height: '440px',
              }"
            />
          </div>
        </div>
      </BaseDrawer>
    </div>
    <div class="layout_center"></div>
    <div class="layout_right">
      <BaseDrawer direction="rtl">
        <div class="nhgl_right">
          <div class="gszhgjpm">
            <MainTitle val="各省综合告警排名" />
            <div class="progress">
              <BarDomChart :rowData="gszhgjpmData" />
            </div>
          </div>
          <div class="gjtjfx">
            <MainTitle val="告警统计分析" />
            <div class="charts">
              <LineCharts :rowData="gjtjfxData" />
            </div>
          </div>
          <div class="gjxxlb">
            <MainTitle val="告警信息列表" />
            <Table
              :Data="gjxxlbData"
              class="tab"
              :TableStyle="{
                TableHeaderWidth: [
                  '109px',
                  '122px',
                  '62px',
                  '117px',
                  '76px',
                  '124px',
                ],
                TableWidth: '619px',
                NavHeight: '50px',
                NavlineHeight: '50px',
                NavTop: '0px',
              }"
            />
          </div>
        </div>
      </BaseDrawer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseDrawer from '@/components/common/BaseDrawer'
import MainTitle from '@/components/common/MainTitle'
import BarChart from '@/components/charts/BarChart'
import pieChart from '@/components/charts/pieChart'
import BarChartV2 from '@/components/charts/BarChartV2'
import BarDomChart from '@/components/charts/BarDomChart'
import LineCharts from '@/components/charts/LineCharts+xuxian'
import Table from '@/components/charts/Table'
export default {
  name: 'WangLuoTaiShi',
  components: {
    BaseDrawer,
    MainTitle,
    BarChart,
    pieChart,
    BarChartV2,
    BarDomChart,
    LineCharts,
    Table,
  },
  data() {
    return {
      // 专属宽带排名
      zskdpmData: [
        {
          name: '2M',
          value: '10',
        },
      ],
      // 专线类型占比
      zxlxzbData: [{ category: 'PPPoE拨号', value: 20 }],
      // 网络性能
      wlxnData: [
        {
          name: '北京',
          value: '100',
        },

        {
          name: '辽宁',
          value: '60',
        },
      ],
      i: 0,
      // 各省综合告警排名
      gszhgjpmData: [
        {
          category: '河北',
          value: 1000,
          unit: '条',
        },

        {
          category: '山东',
          value: 500,
          unit: '条',
        },
      ],
      // 告警统计分析
      gjtjfxData: [
        {
          name: '北京',
          value: '30',
          item: 40,
          val: 60,
        },
        {
          name: '上海',
          value: '20',
          item: 50,
          val: 70,
        },
        {
          name: '天津',
          value: '20',
          item: 40,
          val: 60,
        },
        {
          name: '重庆',
          value: '30',
          item: 50,
          val: 70,
        },
        {
          name: '浙江',
          value: '20',
          item: 40,
          val: 70,
        },
        {
          name: '广东',
          value: '30',
          item: 50,
          val: 60,
        },
        {
          name: '山东',
          value: '20',
          item: 40,
          val: 70,
        },
        {
          name: '山西',
          value: '30',
          item: 50,
          val: 60,
        },
        {
          name: '辽宁',
          value: '20',
          item: 40,
          val: 90,
        },
      ],
      // 告警信息列表
      gjxxlbData: [
        {
          first: '严重告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '严重告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '重要告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '严重告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '重要告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '重要告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '严重告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
        {
          first: '重要告警',
          second: 'FTP连接失败',
          third: '山东',
          fourth: '辽宁BSS系辽宁BSS辽宁BSS',
          fiveth: '07-10',
          sixth: '黑龙江BSS辽宁BSS辽宁BSS',
        },
      ],
    }
  },
  computed: {
    ...mapState(['functionStatus', 'spaceLevel']),
  },

  created() {
    // 专属宽带排名
    this.reqGetzskdpmData()
    // 专线类型占比
    this.reqGetzxlxzbData()
    // 网络性能
    this.reqGetwlxnData('路由器CPU负荷')
    // 各省综合告警排名
    this.reqGetgszhgjpmData()
    // 告警统计分析
    this.reqGetgjtjfxData()
    // 告警信息列表
    this.reqGetgjxxlbData()
  },
  beforeDestroy() {},
  methods: {
    wlxntabList(item, index) {
      this.i = index
      this.reqGetwlxnData(item)
    },
    // 专属宽带排名
    async reqGetzskdpmData() {
      let res = await this.$req.WLTS.reqGetzskdpmData()
      this.zskdpmData = res.data
    },
    // 专线类型占比
    async reqGetzxlxzbData() {
      let res = await this.$req.WLTS.reqGetzxlxzbData()
      this.zxlxzbData = res.data
    },
    // 网络性能
    async reqGetwlxnData(p) {
      let res = await this.$req.WLTS.reqGetwlxnData(p)
      this.wlxnData = res.data
    },
    // 各省综合告警排名
    async reqGetgszhgjpmData() {
      let res = await this.$req.WLTS.reqGetgszhgjpmData()
      this.gszhgjpmData = res.data
    },
    // 告警统计分析
    async reqGetgjtjfxData() {
      let res = await this.$req.WLTS.reqGetgjtjfxData()
      this.gjtjfxData = res.data
    },
    // 告警信息列表
    async reqGetgjxxlbData() {
      let res = await this.$req.WLTS.reqGetgjxxlbData()
      this.gjxxlbData = res.data
    },
  },
}
</script>

<style lang="less" scoped>
.layout_wraper {
  width: 100%;
  height: 100%;
  position: relative;
  .nhgl_left {
    width: 100%;
    height: 100%;
    background: url('@/assets/img/WLTS/left.png') no-repeat;
    box-sizing: border-box;
    text-align: center;
    .zskdpm {
      height: 612px;
      .charts {
        margin: 0 auto;
      }
    }
    .zxlxzb {
      height: 514px;
      .charts {
        transform: translate(0%, -5%);
        /deep/ .loop {
        }
      }
    }
    .wlxn {
      ul {
        width: 625px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: -20px;
        .ac {
          background: #189bfa;
          color: #fff;
        }
        li {
          cursor: pointer;
          pointer-events: all;
          width: 200px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #bfebff;
          font-size: 26px;
          border-radius: 5px;
          background: #02487b;
        }
      }
      .charts {
        pointer-events: none;
        margin: 0 auto;
      }
    }
  }
  .nhgl_right {
    width: 100%;
    height: 100%;
    background: url('@/assets/img/WLTS/left.png') no-repeat;
    box-sizing: border-box;
    text-align: center;
    .gszhgjpm {
      height: 592px;
      .progress {
        width: 600px;
        margin: 0 auto;
        margin-top: 46px;
      }
    }
    .gjtjfx {
      height: 620px;
      position: relative;
      .charts {
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
      }
    }
    .gjxxlb {
      position: relative;
      height: 478px;
      .tab {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
