import Store from "@/store";
import Legends from "@/config/legends";
import SpaceController from "@/core/logic/SpaceController";

/**
 * 底部legend管理类
 * @author haowei
 */
export default class LegendManager{

    static instance

    static getInstance() {
        return this.instance ? this.instance : new LegendManager()
    }

    // 全部legend列表数据
    legendList = []
    // 配置文件中legend数据
    legends = {}

    constructor() {
        this.legends = Legends
        this._calcLegendList()
    }

    /**
     * 计算legendList数据
     * @private 私有方法
     */
    _calcLegendList(){
        let resList = []
        for (let legendKey in this.legends.legend) {
            let list = this.legends.legend[legendKey]
            for (const group in list) {
                let tempObj = {}
                const element = list[group]
                tempObj.type = group
                tempObj.img = this.legends.groupImgs[group]
                tempObj.highlight = false
                tempObj.children = []
                tempObj.level = legendKey
                for (const legend in element) {
                    const item = element[legend]
                    tempObj.children.push({
                        text: legend,
                        img: this.legends.legendImgs[legend],
                        highlight: item.checked,
                        layers: item.layers,
                        radio: !!item.radio
                    })
                    if (item.checked) {
                        tempObj.highlight = true
                    }
                }
                resList.push(tempObj)
            }
        }
        this.legendList = resList;
    }

    /**
     * 获取当前空间层级下的legendList数据
     * @return {*[]}
     */
    getNowSpaceLevelLegendList(){
        let filter = this.legendList.filter(item=>item.level == Store.state.spaceLevel);
        return filter
    }

    /**
     * 获取当前层级下的legend数据（树）
     * @return {*}
     */
    getLegendInfoByNowLevel(){
        let list = this.legends.legend[Store.state.spaceLevel || '中国']
        // console.warn('getLegendListByNowLevel',list)
        return list
    }

    /**
     * 获取当前层级下所有legend下的图层名称（id）列表
     * @return {*[]}
     */
    getAllLegendsLayerNames(){
        let showLegends = []
        const list = this.getLegendInfoByNowLevel()
        for (const group in list) {
            const element = list[group]
            for (const legend in element) {
                const item = element[legend]
                if (item.layers) {
                    showLegends.push(...item.layers)
                }
            }
        }
        return showLegends
    }

    /**
     * 获取当前层级下需要展示的图层名称（id）列表
     * @return {*[]}
     */
    getShowLegendsLayerNames(){
        let showLegends = []
        const list = this.getLegendInfoByNowLevel()
        for (const group in list) {
            const element = list[group]
            for (const legend in element) {
                const item = element[legend]
                if (!item.layers) {
                    Object.entries(item).forEach((info) => {
                        const [key, value] = info
                        if (value.checked) {
                            showLegends.push(...value.layers)
                        }
                    })
                } else if (item.checked) {
                    showLegends.push(...item.layers)
                }
            }
        }
        return showLegends
    }

    /**
     * 底部legend点击事件
     * @param ele 元素
     * @return {Promise<void>}
     */
    async clickLegend(ele){
        console.log('legendClick', ele)
        if (ele.highlight) {
            // 如果是高亮显示的，那显示该legend下的图层
            for (let layer of ele.layers) {
                await window.layerManager.showLayer(layer)
            }
        } else {
            // 如果是取消选中，那隐藏该legend下的图层
            for (let layer of ele.layers) {
                await window.layerManager.hideLayer(layer)
            }
        }
    }

    highlightLegend(text){
        let find = null
        for (const item of this.legendList) {
            if (item.children.length > 0){
                find = item.children.find(it=>it.text == text)
            }
        }
        if (!find) return
        find.highlight = true
    }

    unHighlightLegend(type,text){
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo();
        let find = null
        let findType = null
        for (const item of this.legendList) {
            if (item.type == type && nowSpaceLevelInfo.name == item.level){
                find = item.children.find(it=>it.text == text)
                findType = item
                break
            }
        }
        if (!find||!findType) return
        find.highlight = false
        findType.highlight = false
    }
}
