<!--
    * 自定义底部布局
    * @date 2023/01/04 17:03:21
    * @author haowei
-->
<template>
  <div id="bottom-custom">
    <div class="con_bottom">
      <div class="bot_left">
        基于图观™数字孪生应用开发引擎构建 www.digihail.com/tuguan.html
      </div>
      <div class="con_center">
        <LayerBottom @spaceBack="spaceBack"
                     @legendClick="legendClick"
                     @TabSwitching="navTab"></LayerBottom>
      </div>
      <div class="bot_right">
        版权所有 北京数字冰雹信息技术有限公司 2022年
      </div>
    </div>
  </div>
</template>

<script>
import LayerBottom from "@/components/layout/LayerBottom";
import SpaceController from "@/core/logic/SpaceController";

export default {
  name: "BottomCustom",
  components: {
    LayerBottom
  },
  data() {
    return {
      showRightBackLevels:[{
        level:'楼层',
        com:'LouCeng',
      },{
        level:'房间',
        com:'FangJian',
        defaultObject:{name:'机柜组机房'} //只有机柜组机房能走到下一层，所以返回时，一定是返回机柜组机房
      },{
        level: '机柜组',
        com:'JiGuiZu'
      },{
        level: '机柜',
        com:'JiGuiLixian'
      }]
    }
  },
  methods:{
    // 场景后退
    spaceBack(){
      SpaceController.goBackSpaceLevel()
      // 层级返回完成后的处理
      this.handleBackComplete()
    },
    legendClick(ele){
      this.$legendManager.clickLegend(ele)
    },
    navTab(){

    },
    handleBackComplete(){
      let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo();
      for (let showRightBackLevel of this.showRightBackLevels) {
        if (nowSpaceLevelInfo.name == showRightBackLevel.level){
          this.$store.commit('setRightType',showRightBackLevel.com)
          if (showRightBackLevel.defaultObject){
            this.$store.commit('setObjectInfo',showRightBackLevel.defaultObject)
          }else{
            this.$store.commit('setObjectInfo',{name:this.$store.state.subSpaceLevel})
          }
        }
      }
    }
  }

}
</script>

<style lang="less" scoped>
#bottom-custom{
  .con_bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100px;
    display: flex;
    font-family: '微软雅黑';
    z-index: 1;
    font-size: 24px;
    color: #cccccc;
    justify-content: space-between;
    line-height: 100px;

    .bot_left {
      text-indent: 30px;
      opacity: 0.6;
    }

    .bot_right {
      opacity: 0.6;
    }

    .con_center {
      background: #15202d;
      height: 100px;

    }
  }
}
</style>
