import BaseLayerController from '@/core/layer/BaseLayerController'
import Store from '@/store'
// import ObjectManager from '@/utils/control/ObjectManager'
import UEapi from '@/utils/UEapi/UEapi'

/**
 * 轨迹图控制器
 * @author haowei
 */
export default class TrailLayerController extends BaseLayerController{
  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data;
    layerMap.layerConfig.status = 'hide';
  }

  async getData(layerMap) {
    return await super.getRowData(layerMap)
  }

  async showLayerExec(layerMap) {
    let data = layerMap.layerConfig.jsonData
    let preTrailLayerData = this.getPreTrailLayerData(layerMap,data)
    let trailLayerData = this.getTrailLayerData(layerMap,data)
    // console.warn('showLayerExec-traillayer',preTrailLayerData)
    try {
      UEapi.TrailLayer.addTrailLayer(preTrailLayerData).then(()=>{
        UEapi.TrailLayer.clearTrailLayerTimers(layerMap.name);
        UEapi.TrailLayer.updateLayerTimer(layerMap,trailLayerData)
      })
    }catch (e){
      console.warn('error',e,preTrailLayerData)
    }

    // setTimeout(()=>{
    //   UEapi.TrailLayer.addTrailLayer(preTrailLayerData).then(()=>{
    //     UEapi.TrailLayer.clearTrailLayerTimers(layerMap.name);
    //     UEapi.TrailLayer.updateLayerTimer(layerMap,trailLayerData)
    //   })
    // },1000);
  }

  async hideLayerExec(layerMap) {
    await super.hideLayerExec(layerMap)
    UEapi.TrailLayer.clearTrailLayerTimers(layerMap.name);
  }

  async clearLayerExec(layerMap) {
    await super.clearLayerExec(layerMap)
    UEapi.TrailLayer.clearTrailLayerTimers(layerMap.name);
  }

  getPreTrailLayerData(layer,rawData){
    let defaultOption = {}
    let jsonData = Object.assign({}, defaultOption, layer)
    let coordZ;
    let tempA = null;
    let tempB = null;
    for (let raw of rawData) {
      if (raw.name == '安保人员1' || raw.name == '巡检人员1' || raw.name == '安保人员3' || raw.name == '巡检人员3'){
        if (tempA) continue;
        tempA = {
          id: raw.name,
          label: '',
          coord: [raw.lon, raw.lat],
          coordZ: layer.layerConfig.coordZ || 0,
          type: raw.name,
        };
      }else if (raw.name == '安保人员2' || raw.name == '巡检人员2' || raw.name == '安保人员4' || raw.name == '巡检人员4'){
        if (tempB) continue;
        tempB = {
          id: raw.name,
          label: '',
          coord: [raw.lon, raw.lat],
          coordZ: layer.layerConfig.coordZ || 0,
          type: raw.name,
        };
      }
      if (tempA&&tempB){
        break;
      }
    }
    jsonData.data = [];
    jsonData.data.push(tempA);
    jsonData.data.push(tempB);
    // console.debug('jsonData',jsonData);
    return jsonData;
  }

  getTrailLayerData(layer,rawData){
    let timeDataArr = [];
    let dataA = [];
    let dataB = [];

    for (let raw of rawData) {
      if (raw.name == '安保人员1' || raw.name == '巡检人员1' || raw.name == '安保人员3' || raw.name == '巡检人员3'){
        dataA.push({
          id: raw.name,
          label: '',
          coord: [raw.lon, raw.lat],
          coordZ: layer.layerConfig.coordZ || 0,
          type: raw.name,
        })
      }else if (raw.name == '安保人员2' || raw.name == '巡检人员2' || raw.name == '安保人员4' || raw.name == '巡检人员4'){
        dataB.push({
          id: raw.name,
          label: '',
          coord: [raw.lon, raw.lat],
          coordZ: layer.layerConfig.coordZ || 0,
          type: raw.name,
        })
      }
    }
    for (let i = 0; i < dataA.length; i++) {
      let jsonData = Object.assign({}, layer);
      jsonData.data = [];
      if(dataA[i]){
        jsonData.data.push(dataA[i]);
      }
      if (dataB[i]){
        jsonData.data.push(dataB[i]);
      }
      timeDataArr.push(jsonData);
    }
    // console.debug('timeDataArr',timeDataArr);
    return timeDataArr;
  }
}
