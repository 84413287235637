export default [
  {
    id: '园区-固定摄像头',
    name: '园区-固定摄像头',
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'shexiangtouguding' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '固定摄像头',
      jsonPath: './data/layers/园区/园区-固定摄像头.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-人脸识别摄像头',
    name: '园区-人脸识别摄像头',
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'shexiangtourenlianshibie' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '人脸识别摄像头',
      jsonPath: './data/layers/园区/园区-人脸识别摄像头.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-门禁设备',
    name: '园区-门禁设备',
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'menjinshijian' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '门禁设备',
      jsonPath: './data/layers/园区/园区-门禁设备.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-可燃气体探测器',
    name: '园区-可燃气体探测器',
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'keranqititanceqi' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '可燃气体探测器',
      jsonPath: './data/layers/园区/园区-可燃气体探测器.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-消防烟感',
    name: '园区-消防烟感',
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'xiaofangyangan' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '消防烟感',
      jsonPath: './data/layers/园区/园区-消防烟感.json',
      isClick: true,
      coordZ: 10.6,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-温度云图',
    name: '园区-温度云图',
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 9.98,
    type: 'default',
    'alpha': 1,
    'colorMax': '#FF6000',
    'colorMin': '#009acf',
    'valueMax': 50,
    'valueMin': 0,
    radius: 2,
    visible: true,
    'pointDataId': '',
    data: [],
    layerConfig: {
      layerType: 'heatMapLayer',
      scatterType: '温度云图',
      jsonPath: './data/layers/园区/园区-温度云图.json',
      isClick: false,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-告警事件分布',
    name: '园区-告警事件分布',
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 1.5,
    type: 'default',
    'alpha': 0.3,
    'colorMax': '#FF6000',
    'colorMin': '#009acf',
    'valueMax': 10,
    'valueMin': 0,
    radius: 10,
    visible: true,
    'pointDataId': '',
    data: [],
    layerConfig: {
      layerType: 'heatMapLayer',
      scatterType: '告警事件分布',
      jsonPath: './data/layers/园区/园区-告警事件分布.json',
      isClick: false,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '园区-云台摄像头',
    name: '园区-云台摄像头',
    coordType: 0,
    coordTypeZ: 0,
    visible: true,
    legends: [
      {
        name: 'default',
        iconName: 'shexiangtouyuntai',
        color: '#ff0000'
      }
    ],
    data: [],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '云台摄像头',
      jsonPath: './data/layers/园区/园区-云台摄像头.json',
      isClick: true,
      status: 'none', // 显示状态 none show hide
      rawData: [], // 原始数据
      jsonData: {} // 添加时候的数据
    }
  }, {
    id: '园区-安保人员',
    name: '园区-安保人员',
    coordType: 0,
    coordTypeZ: 0,
    trackStyle: 'style001',
    trackDuration: 10,
    objLife: 10,
    trackWidth: 13.3,
    duration: 1,
    visible: true,
    legends: [
      {
        iconName: 'anbaorenyuan',
        name: '安保人员1',
        trackColor: '#ff0000'
      },
      {
        iconName: 'anbaorenyuan',
        name: '安保人员2',
        trackColor: '#ff0000'
      },
      {
        iconName: 'anbaorenyuan',
        name: '安保人员3',
        trackColor: '#ff0000'
      },
      {
        iconName: 'anbaorenyuan',
        name: '安保人员4',
        trackColor: '#ff0000'
      }
    ],
    data: [],
    layerConfig: {
      layerType: 'trailLayer',
      scatterType: '安保人员',
      jsonPath: './data/layers/园区/园区-安保人员.json',
      isClick: false,
      coordZ: 0,
      status: 'none', // 显示状态 none show hide
      rawData: [], // 原始数据
      jsonData: {} // 添加时候的数据
    }
  }, {
    id: '园区-巡检人员',
    name: '园区-巡检人员',
    coordType: 0,
    coordTypeZ: 0,
    trackStyle: 'style001',
    trackDuration: 10,
    objLife: 10,
    trackWidth: 0.1 * 133,
    duraiotn: 1,
    visible: true,
    legends: [
      {
        'name': '巡检人员1',
        'iconName': 'xunjianrenyuan',
        'trackColor': '#ff0000'
      },
      {
        'name': '巡检人员2',
        'iconName': 'xunjianrenyuan',
        'trackColor': '#ff0000'
      },
      {
        'name': '巡检人员3',
        'iconName': 'xunjianrenyuan',
        'trackColor': '#ff0000'
      },
      {
        'name': '巡检人员4',
        'iconName': 'xunjianrenyuan',
        'trackColor': '#ff0000'
      }
    ],
    data: [],
    layerConfig: {
      layerType: 'trailLayer',
      scatterType: '巡检人员',
      jsonPath: './data/layers/园区/园区-巡检人员.json',
      isClick: false,
      coordZ: 0,
      status: 'none', // 显示状态 none show hide
      rawData: [], // 原始数据
      jsonData: {} // 添加时候的数据
    }
  }
]
