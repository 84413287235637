/**
 * 模型控制器基类
 *
 * @author haowei
 */
import UEapi from "@/utils/UEapi/UEapi";

export default class BaseModelController {

  canClick = true

  /**
   * 创建模型
   * todo 后面逻辑用到,暂时由子类自己实现吧
   * @param params
   * @return {Promise<void>}
   */
  async createModelExec(params) {}

  /**
   * 删除模型
   * @param id 模型id
   * @return {Promise<*>}
   */
  async removeModelExec(id) {
    if (!this.checkId(id)) return { result: 0 }
    return await UEapi.Model.removeModel(id)
  }

  /**
   * 模型显示
   * @param ids 模型id数组
   * @returns {Promise<*>}
   */
  async showModelExec(ids) {
    // if (!this.checkId(id)) return { result: 0 }
    if (typeof ids === 'string') {
      ids = [ids]
    }
    return await UEapi.Model.setModelVisibility({ ids, visible: true })
  }

  /**
   * 模型隐藏
   * @param id
   * @returns {Promise<*>}
   */
  async hideModelExec(ids) {
    // console.log('id', ids)
    // if (!this.checkId(id)) return { result: 0 }
    if (typeof ids === 'string') {
      ids = [ids]
    }
    return await UEapi.Model.setModelVisibility({ ids, visible: false })
  }

  /**
   * 显示全部模型
   * @return {Promise<unknown>}
   */
  async showAllModelExec(){
    let ids = this.getModelIdsList()
    return await UEapi.Model.setModelVisibility({ ids, visible: true })
  }

  /**
   * 隐藏全部模型
   * @return {Promise<unknown>}
   */
  async hideAllModelExec(){
    let ids = this.getModelIdsList()
    return await UEapi.Model.setModelVisibility({ ids, visible: false })
  }

  /**
   * 设置模型透明度
   * @param id 模型id
   * @param alpha 透明度
   * @returns {Promise<*>}
   */
  async setModelAlpha(id, alpha) {
    if (!this.checkId(id)) return { result: 0 }
    return await UEapi.Model.setModelStyle({
      id,
      alpha,
    })
  }

  /**
   * 模型点击事件处理-子类实现
   * @param e 事件信息
   */
  clickModelExec(e) {}

  /**
   * 模型鼠标移入事件处理-子类实现
   * @param e 事件信息
   */
  hoverModelExec(e) {}

  /**
   * 模型鼠标移出事件处理-子类实现
   * @param e 事件信息
   */
  unHoverModel(e) {}

  /**
   * 获取模型ID列表-子类必须实现!!
   * @return {*[]}
   */
  getModelIdsList() {
    return []
  }

  /**
   * 检查模型id是否在本控制器下
   * @param id 模型id
   */
  checkId(id) {
    let modelIdsList = this.getModelIdsList()
    return modelIdsList.includes(id)
  }

  /**
   * 打开点击事件触发，默认打开
   */
  openClickEvent(){
    this.canClick = true
  }

  /**
   * 关闭点击事件触发
   */
  closeClickEvent(){
    this.canClick = false
  }

  /**
   * 检查是否能触发点击事件
   * @return {boolean} 是否触发点击事件
   */
  checkCanClick(){
    return this.canClick
  }

  /**
   * 更新模型数据
   * @return Promise<*>
   */
  async updateModelData(){

  }
}
