export default class Marker3D {
  static add3DMarker(params) {
    let defaultOption = {
      id: '3DMarker',
      coordType: 0,
      coordTypeZ: 0,
      alpha: 1,
      scale: 20,
      type: 'Spread06',
      titleText: '特效06',
      titleColor: '#ffffff',
      titleBackgroundColor: '#333333',
      tag: 'custominfo',
      coord: [113.92338958000916, 22.507616105668163], //以深圳湾经纬度为例
      coordZ: 100,
      visible: true
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('add3DMarker', jsonData, result => {
      // console.log('add3DMarker', jsonData, result)
      let pickJsonData2 = {
        overlayType: "3DMarker",
        idLayer: jsonData.id,
        type: "click",
        allowMultiple: false,
        isShowDecorator: false
      }
      appInstance.uniCall('pickOverlay', pickJsonData2, res => {
        // console.log('pickOverlay2', pickJsonData2, res)
      })
    })
  }
  // 更新特效数据
  static update3DMarkerCoord(params) {
    let defaultOption = {
      id: '3DMarker',
      coordType: 0,
      coordTypeZ: 0,
      coord: [113.92104945588623, 22.511068539824436], //以深圳湾经纬度为例
      coordZ: 150,
      tag: 'custominfo'
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('update3DMarkerCoord', jsonData, result => {
      // console.log('update3DMarkerCoord', jsonData, result)
    })
  }
  static update3DMarkerStyle() {
    appInstance.uniCall('update3DMarkerStyle', {
      id: '3DMarker',
      alpha: 0.5,
      scale: 50,
      type: 'floor_01',
      titleText: 'XYZ'
    })
  }
  static remove3DMarker() {
    appInstance.uniCall('removeOverlay', {
      id: window.sessionStorage.getItem('MarkerID'),
      overlayType: '3DMarker'
    })
  }
}
