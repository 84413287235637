/**
 * 状态处理基类
 * @author haowei
 */
export default class BaseStateHandler{

    /**
     * 状态初始化
     * @abstract
     */
    async init(){
        let event = {
            key:window.stateManager.currentState.key,
            label:window.stateManager.currentState.label
        }
        console.warn(`【${event.label}】初始化`)
    }

    /**
     * 状态复位
     * @abstract
     */
    async reset(){
        let event = {
            key:window.stateManager.lastTimeState.key,
            label:window.stateManager.lastTimeState.label
        }
        console.warn(`【${event.label}】复位`)
    }
}
