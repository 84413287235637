import BaseModelController from "@/core/model/BaseModelController";
import DataManager from "@/core/DataManager";
import UEapi from "@/utils/UEapi/UEapi";
import SpaceController from "@/core/logic/SpaceController";
import Store from "@/store";
import ObjectManager from "@/components/objectManager/ObjectManager";
import LinkController from "@/core/logic/linkController";

/**
 * 上架设备控制器
 * @author haowei
 */
export default class EquipmentController extends BaseModelController{

    equipmentList = []

    tempTypeList = []

    currentEqId = ''

    // 虚拟机模型名称
    VM_MODEL_ID = 'C03-004-04_SM_数据服务器_虚拟机'
    // 虚拟机内部关节名称
    VM_INSIDE_AL_ID = '虚拟机显隐'
    // 机箱外壳关键名称
    VM_BOX_AL_ID = '机箱外壳'
    // 虚拟机动画-显示虚拟机机箱
    VM_ANIM_SHOW_BOX = '显示_虚拟机机箱';
    // 虚拟机动画-隐藏虚拟机机箱
    VM_ANIM_HIDE_BOX = '隐藏_虚拟机机箱'

    // 1U的高度值
    // U = 0.061063643
    U = 0.032

    defaultDeviceModelId= '1U'


    constructor() {
        super();
        this.updateModelData()
    }

    getModelIdsList() {
        return this.equipmentList.map(item=>item.id)
    }


    async updateModelData() {
        this.tempTypeList = await DataManager.getTempEquipmentData()
        this.equipmentList = []
    }


    async createModelExec(params) {
        // 判断类型是否正确
        let find = this.tempTypeList.find(item => item.id == params.type)
        if (!find) {
            console.warn(`${params.type}模型模板不存在！`)
            return
        }
        let jsonData = this._getModelJsonData(params)
        // console.log(jsonData)
        try {
            let res = await UEapi.Model.addModel(jsonData)
            // UEapi.Events.pickModel()
            console.debug('createModel', res, jsonData)
            jsonData.group = params.group
            jsonData.rack = params.rack
            jsonData.type = params.type
            if (res.result == 1) {
                // 添加模型成功 也要添加到数据中
                this.addEqToList(jsonData)
            } else {
                await this._useDefaultType(jsonData)
            }
        } catch(err) {
            await this._useDefaultType(jsonData)
        }
    }

    _getModelJsonData(params) {
        // 根据U位和所占U值计算摆放高度
        let z = +(params.coordZ + (params.up - 1) * this.U)

        let jsonData = {
            id: params.id, // 新创建模型的id
            pid: params.rack,
            label: params.label,
            coordType: params.coordType,
            coordTypeZ: params.coordTypeZ,
            coord: params.coord, // 模型创建的坐标
            coordZ: z,
            rotation: params.rotation,
            alpha: params.alpha,
            scale: params.scale,
            titleText: '',
            titleColor: '#ffffff',
            titleBackgroundColor: '#333333',
            visible: params.visible,
            modelType: params.type ? params.type : this.defaultDeviceModelId // 模型创建模板名称
        }

        return jsonData
    }

    async _useDefaultType(jsonData) {
        // 添加失败尝试用默认类型
        jsonData.modelType = this.defaultDeviceModelId
        try{
            let res1 = await UEapi.Model.addModel(jsonData)
            if (res1) {
                this.addEqToList(jsonData)
            }
        } catch(err) {
            console.log('addModel err', err,jsonData);
        }
    }

    /**
     * 添加设备信息到数组中
     * @param eqData 设备信息
     */
    addEqToList(eqData) {
        const find = this.equipmentList.find(item => item.id == eqData.id)
        if (find) return
        this.equipmentList.push(eqData)
    }


    async removeModelExec(id) {
        await super.removeModelExec(id);
        let index = this.equipmentList.findIndex(item=>item.id == id);
        console.warn('removeModelExec-eq',index)
        this.equipmentList.splice(index,1)
    }

    clickModelExec(e) {
        SpaceController.changeSpaceLevel('设备',e.id).then(()=>{
            ObjectManager.updateExpandKeys(e.id)
            this.currentEqId = e.id
            let find = this.equipmentList.find(item=>item.id == e.id)
            // console.warn('clickModelExec-eq',find,rack)
            if (!find){
                find = window.allEqData.find(item=>item.id == e.id)
                // 如果机柜没开门，需要开门，同时处理高亮
                let rackCtrl = window.modelManager.controls.rack;
                let rack = rackCtrl.rackList.find(item=>item.id == find.rack);
                rackCtrl.currentRack = rack.id
                console.warn('needOpenDoor',rack)
                rackCtrl.openRack(rack.id).then(()=>{
                    rackCtrl.highlightRack(rack.id)
                    this.highlightEq(e.id)
                    SpaceController.setLastLevelRecord('设备',rack.id)
                    window.cameraManager.onSetCamera(e.id,null,{})
                })
            }else{
                this.highlightEq(e.id)
            }
            Store.commit('setRightType','FuWuQiBiaoZhun')
            // 点击设备时获取一下链路数据
            let linkDatas = LinkController.getLinkDatasByEq(e.id);
            Store.commit('setObjectInfo',{id:find.id,name:find.label,linkNameList:linkDatas})
            // 虚拟机特殊处理
            if (e.id == this.VM_MODEL_ID){
                // 虚拟机外壳100%显示，虚拟机内部设备要显示出来
                this._handleVmBox(true).then(()=>{
                    this._handleVM(true)
                })
            }else{
                // 其他设备情况下，要隐藏虚拟机内部设备
                this._handleVmBox(true).then(()=>{
                    this._handleVM(false)
                })
                // 这个地方应该要对虚拟机动画进行处理，把动画停止掉
                this._stopAnimation()
            }
        })
    }

    /**
     * 高亮设备
     * @param id 设备id
     */
    highlightEq(id){
        let find = this.equipmentList.find(item=>item.id == id)
        let filter = this.equipmentList.filter(item=>item.rack == find.rack)
        for (let item of filter) {
            if (item.id != id){
                UEapi.Model.setModelStyle({
                    id: item.id,
                    alpha: 0.2,
                })
            }else {
                UEapi.Model.setModelStyle({
                    id: item.id,
                    alpha: 1,
                })
            }
        }
    }

    /**
     * 取消设备高亮状态
     */
    unDoHighlightAllEq(){
        for (let item of this.equipmentList) {
            UEapi.Model.setModelStyle({
                id: item.id,
                alpha: 1,
            })
        }
    }

    /**
     * 虚化虚拟机
     * @return {Promise<unknown>}
     */
    _fadeVM(){
        let jsonData = {
            id: this.VM_MODEL_ID,
            duration: 0.01,
            data: [
                {
                    articulation: this.VM_INSIDE_AL_ID,
                    type: 'float',
                    value: '0'
                },
                {
                    articulation: this.VM_BOX_AL_ID,
                    type: 'float',
                    value: '80'
                }
            ]
        }
        // 先将外壳显示出来，然后再进行内部关节的显隐
        return new Promise(resolve => {
            window.appInstance.uniCall('setModelArticulation', jsonData, res => {
                // console.debug('handleInsideVmAl-setModelArticulation',jsonData, res)
                resolve(true)
            });
        });
    }

    /**
     * 只显示虚拟机外壳
     * @return {Promise<unknown>}
     */
    _showVMNotInside(){
        let jsonData = {
            id: this.VM_MODEL_ID,
            duration: 0.01,
            data: [
                {
                    articulation: this.VM_INSIDE_AL_ID,
                    type: 'float',
                    value: '0'
                },
                {
                    articulation: this.VM_BOX_AL_ID,
                    type: 'float',
                    value: '0'
                }
            ]
        }
        // 先将外壳显示出来，然后再进行内部关节的显隐
        return new Promise(resolve => {
            window.appInstance.uniCall('setModelArticulation', jsonData, res => {
                // console.debug('handleInsideVmAl-setModelArticulation',jsonData, res)
                resolve(true)
            });
        });
    }

    _handleVM(show){
        let jsonData = {
            id: this.VM_MODEL_ID,
            duration: 0.01,
            data: [
                {
                    articulation: this.VM_INSIDE_AL_ID,
                    type: 'float',
                    value: show ? '100' : '0'
                },
                {
                    articulation: this.VM_BOX_AL_ID,
                    type: 'float',
                    value: show ? '0' : '80'
                }
            ]
        }
        // 先将外壳显示出来，然后再进行内部关节的显隐
        return new Promise(resolve => {
            window.appInstance.uniCall('setModelArticulation', jsonData, res => {
                // console.debug('handleInsideVmAl-setModelArticulation',jsonData, res)
                resolve(true)
            });
        });
    }

    /**
     * 虚拟机机箱处理
     * @param show 是否显示虚拟机机箱
     * @return {Promise<unknown>}
     */
     _handleVmBox(show = true) {
        let jsonData = {
            id: this.VM_MODEL_ID,
            name: show ? this.VM_ANIM_SHOW_BOX : this.VM_ANIM_HIDE_BOX,
            state: 'begin'
        }

        // Store.commit('setAnimationState', show ? this.VM_ANIM_SHOW_BOX  : this.VM_ANIM_HIDE_BOX )

        return new Promise(resolve => {
            window.appInstance.uniCall('playModelAnimation', jsonData, (res) => {
                // console.debug('handleVmBox-playModelAnimation', jsonData, res)
                resolve(res)
            })
        })
    }

    /**
     * 停止虚拟机的相关动画
     */
     _stopAnimation(){
        let jsonData = {
            id: this.VM_MODEL_ID,
            name: this.VM_ANIM_SHOW_BOX,
            state: 'stop',
        };
        UEapi.Model.playAnimation(jsonData);
        let jsonData1 = {
            id: this.VM_MODEL_ID,
            name: this.VM_ANIM_HIDE_BOX,
            state: 'stop',
        };
        UEapi.Model.playAnimation(jsonData1);
    }


    removeAllModel(){
        for (let eq of this.equipmentList) {
            super.removeModelExec(eq.id)
        }
        this.equipmentList = []
    }

    /**
     * 标记链路显示设备
     * @param {[]} ids 设备id数组
     * @param {boolean} flag 标识
     */
    setLinkEqFlag(ids,flag){
        for (let item of this.equipmentList) {
            if (ids.includes(item.id))
                item.nowLinkEq = flag
        }
    }

    /**
     * 高亮部分设备
     * @param {[]} ids 设备id数组
     */
    highlightEqs(ids){
        for (let id of ids) {
            const find = this.equipmentList.find(item=>item.id == id)
            if(!find) continue
            // 其他设备虚化
            for (let item of this.equipmentList) {
                if (ids.includes(item.id)) {
                    UEapi.Model.setModelStyle({
                        id: item.id,
                        alpha: 1
                    })
                } else {
                    if (!item.nowLinkEq){
                        UEapi.Model.setModelStyle({
                            id: item.id,
                            alpha: 0.4
                        })
                    }else {
                        UEapi.Model.setModelStyle({
                            id: item.id,
                            alpha: 1
                        })
                    }
                }
            }
        }
    }

}
