/*
 * @Author: 李星辰 xingchen@carbonstop.net
 * @Date: 2022-10-28 22:04:32
 * @LastEditors: 李星辰 xingchen@carbonstop.net
 * @LastEditTime: 2022-12-07 23:02:19
 * @FilePath: /machine-room-demo/src/utils/UEapi/Path.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default class Path {
  static addPath(params, succseeFn) {
    return new Promise((resolve) => {
      let defaultOption = {
        id: 'path',
        name: '路径',
        coordType: 0,
        coordTypeZ: 0,
        type: 'Arrow06',
        color: '#ff0000',
        colorPass: '#0000FF',
        width: 40,
        tag: 'custominfo', // 用户自定标签，用户保存用户的扩展数据
        autoScale: false,
        lineDataId: '', // 对应本服务器上线数据对象，如果找到lineDataId，则下方的points不起作用。注意：此属性端渲染未生效
        points: [],
      }
      let jsonData = Object.assign({}, defaultOption, params)
      window.appInstance.uniCall('addPath', jsonData, (res) => {
        // console.log(`addPath`, jsonData, res);
        if (succseeFn && typeof succseeFn == 'function') succseeFn(res)
        resolve(res)
      })
    })
  }

  static removeOverlay(params, succseeFn) {
    let defaultOption = {
      id: 'path',
      overlayType: 'path',
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('removeOverlay', jsonData, (res) => {
      console.log(`removeOverlay`, jsonData, res)
      // succseeFn(res)
    })
  }

  static async updatePathCoord(params) {
    let defaultOption = {
      id: 'path',
      name: '路径',
      coordType: 0,
      coordTypeZ: 0,
      isAppend: false,
      tag: 'custominfo',
      points: [],
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('updatePathCoord', jsonData, (res) => {
      // console.log('updatePathCoord', jsonData, res)
    })
  }

  static updateStyle(params) {
    return new Promise((resolve) => {
      window.appInstance.uniCall('updatePathStyle', params, (res) => {
        console.log('updatePathStyle', params, res)
        resolve(res)
      })
    })
  }
}
