import BaseLayerController from './BaseLayerController'
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 容量图层控制器
 * @author haowei
 */
export default class CapacityLayerController extends BaseLayerController {
  // 展示的模型数据
  showModelList = []
  // 用作创建模板的box模型id
  tempBoxModelId = '机柜白膜'
  // 默认高度
  defaultZ = 8.563979961849723
  // 颜色列表
  colorList = ['#ffff00', '#ffcc00', '#ff8800', '#ff4400', '#ff0000']
  // 颜色列表2
  colorList2 = ['#01bd01', '#c3ab08', '#bd0606']
  // 透明度列表
  alphaList = [0.66, 0.4, 0.4]

  currentShowLayerId = ''

  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data
    layerMap.layerConfig.status = 'hide'
  }

  async clearLayerExec(layerMap) {
    layerMap.layerConfig.status = 'none'
    for (let item of this.showModelList) {
      await UEapi.Model.removeModel(item.id)
    }
    window.modelManager.controls.rack.showAllModels()
  }

  async showLayerExec(layerMap) {
    await window.modelManager.controls.rack.showAllModels()
    for (let item of this.showModelList) {
      await UEapi.Model.removeModel(item.id)
    }
    let data = await this.getData(layerMap)
    this.showModelList = [...data]
    layerMap.layerConfig.status = 'show'
    await window.modelManager.controls.rack.hideAllModels()
    for (let item of this.showModelList) {
      if (layerMap.layerConfig.showType != '空间容量') {
        let temp = item.value / layerMap.layerConfig.maxValue
        if (temp > 1){
          temp = 1
        }
        let percent = temp * 100
        if (layerMap.layerConfig.showType == '高度颜色变换') {
          item.scale = [1, temp, 1]
        } else if (layerMap.layerConfig.showType == '颜色变换') {
          item.scale = 1
        }
        UEapi.Model.addModel(item).then((res) => {
          console.debug('addModel', res, item)
          let styleData = {
            id: item.id,
            maskType: 'color',
            maskAlpha: 0.8,
            maskColor: this.getColor(percent),
            visible:true
          }
          window.appInstance.uniCall('setModelStyle', styleData, res => {
            // console.debug('setModelStyle', res, styleData)
          })
        })
      } else {
        item.scale = [0.85, 0.75 / 42, 1]
        UEapi.Model.addModel(item).then((res) => {
          console.debug('addModel', res, item)
          let styleData = {
            id: item.id,
            maskType: 'color',
            maskAlpha: this.getAlpha(item.colorType),
            maskColor: this.getColor2(item.colorType),
            visible:true
          }
          window.appInstance.uniCall('setModelStyle', styleData, res => {
            // console.debug('setModelStyle', res, styleData)
          })
        })
      }
      this.currentShowLayerId = layerMap.id

    }
  }

  async hideLayerExec(layerMap) {
    await window.modelManager.controls.rack.showAllModels()
    layerMap.layerConfig.status = 'hide'
    this.currentShowLayerId = ''
    this.showModelList.forEach(item => {
      UEapi.Model.removeModel(item.id)
    })
    console.warn('hideLayerExec-capaicty')
  }

  async getData(layerMap) {
    let data = await super.getRowData(layerMap)
    for (let datum of data) {
      if (datum.ratio){
        datum.oldValue = datum.value
        datum.value = datum.ratio
      }
    }
    if (layerMap.layerConfig.showType != '空间容量') {
      return data.map(item => {
        return {
          ...item,
          id: item.id + '-box',
          coordType: 0,
          coordTypeZ: 0,
          coordZ: layerMap.layerConfig.coordZ || item.coordZ || this.defaultZ,
          rotation: item.rotation || [0, 0, 0],
          alpha: 0.8,
          modelType: this.tempBoxModelId
        }
      })
    } else {
      let arr = []
      data.forEach(item => {
        item.value.forEach(i1 => {
          arr.push({
            ...item,
            id: `${item.id}-box-${i1.up}-temp`,
            coordType: 0,
            coordTypeZ: 0,
            coordZ: window.modelManager.controls.equipment.getCoordZByUp(i1.up, layerMap.layerConfig.coordZ || item.coordZ || this.defaultZ),
            rotation: item.rotation || [0, 0, 0],
            alpha: 0.8,
            modelType: this.tempBoxModelId,
            colorType: i1.type
          })
        })
      })
      return arr
    }
  }

  getColor(val) {
    if (val > 0 && val <= 20) {
      return this.colorList[0]
    } else if (val > 20 && val <= 40) {
      return this.colorList[1]
    } else if (val > 40 && val <= 60) {
      return this.colorList[2]
    } else if (val > 60 && val <= 80) {
      return this.colorList[3]
    } else if (val > 80 && val <= 100) {
      return this.colorList[4]
    }
  }

  getColor2(type) {
    if (type == 'empty') {
      return this.colorList2[0]
    } else if (type == 'warn') {
      return this.colorList2[1]
    } else {
      return this.colorList2[2]
    }
  }

  getAlpha(type){
    if (type == 'empty') {
      return this.alphaList[0]
    } else if (type == 'warn') {
      return this.alphaList[1]
    } else {
      return this.alphaList[2]
    }
  }
}
