import StandardStateHandler from "@/core/state/StandardStateHandler";
import ObjectManager from "@/components/objectManager/ObjectManager";

/**
 * 标准状态--北京
 * @author haowei
 */
export default class BeijingStandardStateHandler extends StandardStateHandler{

    async init() {
        window.cameraManager.onSetCamera('北京',null,{})
        ObjectManager.updateExpandKeys('北京数据中心')
        await super.init();
    }

    async reset() {
        await super.reset();
    }
}
