
export default class Events {
  // 注册事件监听
  static initEventCallback() {
    let eventCallbackList = [
      'onServiceSwitch',
      'onLandmarkLayerHover',
      'onLandmarkLayerUnhover',
      'onLandmarkLayerClick',
      'onModelHover',
      'onModelUnhover',
      'onModelClick',
      'onBuildingHover',
      'onBuildingUnhover',
      'onBuildingClick',
      'onFloorHover',
      'onFloorUnhover',
      'onFloorClick',
      'onCameraMove',
      'onCameraToMoveEnd',
      'on3DMarkerClick',
      'onEventLayerClick',
      'onEventLayerHover',
    ]
    eventCallbackList.forEach(item => {
      window.appInstance.uniCall('addEventListener', {
        eventName: item,
        callback(res) {
          let cEvent = new CustomEvent(item, {
            detail: res
          });
          if (item == 'onEventLayerClick'||item == 'onLandmarkLayerClick'){
            console.warn(`事件名称${item}`)
          }
          window.dispatchEvent(cEvent);
        }
      }, res => {
        // console.log('注册UE事件', item, res)
      })
    })
  }

  // 开启模型点击
  static pickModel() {
    appInstance.uniCall(
      'pickModel',
      {
        modelType: 'model',
        type: 'click',
        isShowDecorator:false,
      },
      result => {
        // console.log('开启模型点击', result);
      }
    );
  }
  static pickBuilding() {
    appInstance.uniCall(
      'pickModel',
      {
        modelType: 'building',
        type: 'click',
        isShowDecorator:false,
      },
      result => {
        // console.log('开启建筑点击', result);
      }
    );
  }
  // 开启模型hover
  static hoverModel() {
    appInstance.uniCall(
      'pickModel',
      {
        modelType: 'model',
        type: 'hover',
        isShowDecorator:false,
      },
      result => {
        // console.log('开启模型hover', result);
      }
    );
  }
  static hoverBuilding() {
    appInstance.uniCall(
      'pickModel',
      {
        modelType: 'building',
        type: 'hover',
        isShowDecorator:false,
      },
      result => {
        // console.log('开启建筑hover', result);
      }
    );
  }
  // 开启图层点击事件
  static pickOverlay(id, overlayType, type = 'click') {
    appInstance.uniCall('pickOverlay', {
      overlayType: overlayType,
      idLayer: id,
      type: type,
      allowMultiple: false,
      isShowDecorator: true
    }, res => {
      // console.log('注册图层事件', id, type, res)
    })
  }
}
