var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wraper",style:({width: _vm.cssWidth, height:_vm.cssHeight})},_vm._l((_vm.LoopData),function(item,index){return _c('div',{key:index,staticClass:"gzqaqpfpm_item"},[_c('div',{staticClass:"item_text"},[_vm._v(_vm._s(item.category))]),_c('div',{staticClass:"item_value",style:({color:_vm.ValueColor})},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"item_unit"},[_vm._v(_vm._s(item.unit))]),_c('div',{staticClass:"star",style:({
          left: (item.rate-0.1)+'%',
          boxShadow:`0px 0px 15px  ${_vm.star},0px 0px 30px ${_vm.star}, 0px 0px 15px ${_vm.star}`,
    
          })}),_c('div',{staticClass:"roteBG"},[_c('div',{staticClass:"rotAnimation",style:({
              width: item.rate+'%',
          })},[_c('div',{staticClass:"animateColor"}),_c('div',{staticClass:"rote rote1",style:({  backgroundImage:`linear-gradient(to right, ${_vm.color[0]},  ${_vm.color[1]})`})})])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }