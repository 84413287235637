/**
 * 控制器事件
 */
export default class CtrlEventManager {

    eventsMap = {
        // 模型点击
        'ModelClick': 'ModelClick',
        // 图层点击
        'LayerClick': 'LayerClick',
        // 模型鼠标浮动
        'ModelHover': 'ModelHover',
        // 图层鼠标浮动
        'LayerHover': 'LayerHover',
        // 服务切换完成
        'ServiceSwitch': 'ServiceSwitch',
        // 服务初始化完成
        'ServiceInit': 'ServiceInit',
        // 状态初始化完成
        'StateInit': 'StateInit',
        // 状态复位完成
        'StateReset': 'StateReset',
        // 层级切换
        'SpaceLevelChange':'SpaceLevelChange'
    }

    comList = []

    /**
     * 绑定页面组件实例
     * @param com vue页面组件实例
     */
    bindComponent(com) {
        let hasCom = false
        for (let item of this.comList) {
            if (item._uid == com._uid) {
                hasCom = true
                break
            }
        }
        if (!hasCom) {
            this.comList.push(com)
        }
    }

    /**
     * 解绑页面组件实例
     * @param com vue页面组件实例
     */
    unBindComponent(com) {
        for (let item of this.comList) {
            if (item._uid == com._uid) {
                this.comList.splice(this.comList.indexOf(item), 1)
            }
        }
    }

    /**
     * 发布事件
     * @param eventName 事件名称
     * @param args 参数
     */
    publish(eventName, ...args) {
        for (let item of this.comList) {
            try {
                if (item.$options && item.$options[`on${eventName}`] && typeof (item.$options[`on${eventName}`]) == 'function')
                    item.$options[`on${eventName}`].call(item, ...args)
            } catch (e) {
                console.warn(`控制器事件:【on${eventName}】在组件【${item.$options.name}】中发生错误`, e)
            }
        }
    }
}
