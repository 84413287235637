
export default class TypeAreaLayer {
  // 添加类型区域图
  static async addTypeAreaLayer(params) {
    let defaultConfig = {
      id: "idObj",
      name: "layerName",
      coordType: 0,
      coordTypeZ: 0,
      coordZ: 10,
      alpha: 0.5,
      areas: [],
      visible: true,
      legends: [{
        name: "legendName1",
        type: "Segment03",
        color: "#ff0000",
        areaHeight: 10,
        fillArea: "none",
        fillPosition: "top"
      }],
      data: []
    }
    let jsonData = Object.assign({}, defaultConfig, params)
    window.appInstance.uniCall("addTypeAreaLayer", jsonData, res => {
      console.log('添加类型区域图', jsonData, res);
    });
  }

  static async updateTypeAreaLayer(layer, subLayer) {
    const updateConfig = {
      id: "idObj",
      name: "layerName",
      isAppend: false,
      areas: [],
      data: []
    };
    try {
      let layerInfo = await gisDataManager.getTypeAreaLayerData(
        layer,
        updateConfig,
        subLayer
      );
      // 删除用户图层配置，减少数据传输体积
      delete layerInfo.layerConfig;

      window.appInstance.uniCall('updateTypeAreaLayerCoord', layerInfo, res => {
        console.log('更新图层' + layer.id, res);
      });
    } catch (error) {
      console.log('更新图层失败' + layer.id, res);
    }
  }
}