<template>
  <div class="loop">
    <div class="container">
      <div ref="loop" :style="{ width: `${width}`, height: `${height}` }"></div>
      <img src="../../assets/img/layout/wc.png" class="wc" alt />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: { type: String, default: '300px' }, //高度
    width: { type: String, default: '300px' }, //宽度
    // value
    rowData: {
      type: Array,
      default: () => {
        return [
          { category: '类型1', value: 0.7 },
          { category: '类型2', value: 0.3 }
        ]
      }
    },
    //图例value
    chartStyle: {
      type: Object,
      default: () => {
        return {
          left: 6,
          top: 20,
          right: 15,
          bottom: 25
        }
      }
    }
  },

  data() {
    return {
      ind: 0,
      loopData: [],
      //当前选中的value
      selectedSeries: {
        category: '',
        value: ''
      },
      sum: 0,
      Status: [],
      myChart:null
    }
  },
  mounted() {
    this.draw()
  },
  methods: {
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.loop)
      let that = this
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{d}%'
        },
        series: [
          {
            // ...this.chartStyle,
            type: 'pie',
            // radius: '50%',
            radius: ['92%', '80%'],
            hoverAnimation: false,
            selectedMode: 'single',
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    {
                      c1: '#0093EA', //实践
                      c2: '#EDE069'
                    },
                    {
                      c1: '#35424F', //管理
                      c2: '#35424F'
                    },

                    {
                      c1: '#63E587', //操作
                      c2: '#5FE2E4'
                    }
                  ]
                  return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0,
                      color: colorList[params.dataIndex].c1
                    },
                    {
                      offset: 1,
                      color: colorList[params.dataIndex].c2
                    }
                  ])
                },
                borderCap: 'round'
              }
            },

            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      }
      this.loopData = this.convertData(that.rowData)
      console.log(this.loopData)
      option.series[0].data = this.loopData
      this.myChart.setOption(option)
    },
    //value转换
    convertData(rowData) {
      this.sum = 0
      let returnData = []
      rowData.forEach(d => {
        this.sum += d.value
        returnData.push({
          value: d.value,
          name: d.category
        })
      })
      console.log(rowData, returnData)
      returnData.forEach(d => {
        d.num =
          this.sum == 0 ? '0' : ((d.value / this.sum) * 100).toFixed(2) + '%'
      })

      //进度条模式时 倒序排序
      if (this.displayModel == 1) {
        returnData.sort((a, b) => {
          return b.value - a.value
        })
      }

      //增加千分位
      if (this.displayModel == 2) {
        let re = /(?=(?!(\b))(\d{3})+$)/g
        returnData.forEach(r => {
          r.value = String(r.value).replace(re, ',')
        })
      }

      return returnData
    }
  },
  watch: {
    rowData: {
      handler() {
        this.$echarts.init(this.$refs.loop).dispose()
        this.draw()
      }
    }
  },
  beforeDestroy() {
    try {
      this.$echarts.init(this.$refs.loop).dispose()
    }catch (e){
      console.error(e);
    }
  }
}
</script>
<style lang="less" scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 5s 0s linear infinite;
}
::-webkit-scrollbar {
  width: 5px;
  height: 20px;
  background: #00d6fc;
}
.loop {
  .container {
    display: flex;
    align-items: center;
    position: relative;
    .wc {
      width: 233px;
      height: 233px;
      position: absolute;
      left: 32px;
      top: 31px;
      text-align: center;
    }
    .left {
      flex: 1;
    }
    .right {
      max-height: calc(100% - 20px);
      overflow: auto;
      height: 100%;
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .right_item {
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 35px;
        line-height: 24px;

        .legend {
          width: 3px;
          height: 12px;
        }

        .text {
          padding-left: 5px;
          color: #b8e5fe;
          font-size: 14px;
          font-weight: '400';
          width: 90px;
        }

        .num {
          font-size: 30px;
          // padding-left: 40px;
          font-family: 'DINCond-Medium';
          font-weight: '400';
          overflow: hidden;
        }
      }

      .right_item_2 {
        padding-top: 10px;
        align-items: center;
        padding-left: 20px;
        height: 80px;
        line-height: 80px;

        .top {
          height: 40px;
          line-height: 40px;
          display: flex;
        }

        .text {
          width: 60%;
          color: #b8e5fe;
          font-size: 26px;
          font-weight: '400';
        }
        .num {
          width: 40%;
          color: #e9e9e9;
          font-size: 15px;
          font-family: 'DINCond-Medium';
          text-align: center;
          font-weight: '400';
        }

        .unit {
          width: 24px;
          color: #13bfff;
          font-size: 20px;
        }

        .progress {
          margin-top: 10px;
          height: 10px;
        }
      }

      .right_item_3 {
        align-items: center;
        height: 60px;
        line-height: 55px;
        display: flex;

        .legend {
          width: 6px;
          height: 24px;
        }

        .text {
          padding-left: 5px;
          color: #b8e5fe;
          font-size: 26px;
          font-weight: '400';
        }

        .num {
          font-size: 30px;
          padding-left: 60px;
          font-family: 'DINCond-Medium';
          overflow: hidden;
          font-weight: '400';
        }

        .unitNum {
          font-size: 15px;
          color: #e9e9e9;
          padding-left: 30px;
          font-family: 'DINCond-Medium';
          margin-left: auto;
        }
        .unit {
          color: #13bfff;
          padding-left: 10px;
          font-size: 22px;
          margin-right: 20px;
        }
      }
    }
    .rose_legend {
      // margin-left: -50px;
      // margin-right: -82px;
      width: 392px;
      position: relative;
      // height: 192px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // margin-top: 5px;
      .legend {
        position: relative;
        height: 40px;
        display: flex;
        margin-bottom: 22px;
        border-bottom: 1px solid #999;
        padding-bottom: 22px;
        .legend_progress {
          width: 100%;
          height: 10px;
          background: red;
          position: absolute;
          // left: 16px;
          top: 39px;
        }
        .legend_color {
          width: 12px;
          height: 12px;
          margin-top: 7px;
          border-radius: 50%;
        }
        .legend_name {
          font-size: 24px;
          color: #aae3ff;
          font-family: 'PingFang';
          line-height: 22px;
          // margin-left: 15px;
          width: 190px;
        }
        .legend_value {
          position: absolute;
          right: 35px;
          top: 2px;
          font-size: 36px;
          color: #00dfff;
          font-family: 'Voltage';
          line-height: 22px;
          // margin-right: 20px;
        }
        .legend_unit {
          position: absolute;
          top: 5px;
          right: 0px;
          line-height: 22px;
          font-size: 22px;
          color: #d2f0ff;
          font-family: '微软雅黑';
        }
      }
    }
    // 新添加的图例
    .gdyadd_legend {
      width: 200px;
      position: relative;
      // height: 192px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // margin-top: 5px;
      .legend {
        position: relative;
        height: 40px;
        display: flex;
        margin-bottom: 22px;
        .legend_progress {
          width: 3px;
          height: 16px;
          background: red;
          position: absolute;
          left: -9px;
          top: 3px;
        }
        .legend_Color {
          width: 12px;
          height: 12px;
          margin-top: 7px;
          border-radius: 50%;
        }
        .legend_name {
          font-size: 18px;
          color: #aae3ff;
          font-family: 'PingFang';
          line-height: 18px;
          // margin-left: 15px;
          width: 190px;
        }
        .legend_value {
          position: absolute;
          left: 0px;
          top: 30px;
          font-size: 30px;
          color: #00dfff;
          font-family: 'Voltage';
          line-height: 24px;
          // margin-right: 20px;
        }
        .legend_unit {
          position: absolute;
          top: 5px;
          right: 0px;
          line-height: 22px;
          font-size: 22px;
          color: #d2f0ff;
          font-family: '微软雅黑';
        }
      }
    }
  }
}
</style>
