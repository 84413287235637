import BaseHandler from "@/core/layer/handler/BaseHandler";
import Store from "@/store";

export default class PersonHandler extends BaseHandler{

    personMap = {
        "安保人员":"AnBaoRenYuan",
        "巡检人员":"XunJianRenYuan"
    }

    handleClick({id, idLayer}) {
        let com = ''
        for (let key in this.personMap) {
            if (id.indexOf(key) != -1 || idLayer.indexOf(key) != -1){
                com = this.personMap[key]
                break
            }
        }
        let find = window.allData.find(item=>item.id == id&&item.idLayer == idLayer)
        Store.commit('setRightType',com)
        Store.commit('setObjectInfo',{name:find.name})
    }

    handleHover({id, idLayer}) {
        super.handleHover({id, idLayer});
    }

    filterLayer({id, idLayer}) {
        for (let key in this.personMap) {
            if (id.indexOf(key) > -1 || idLayer.indexOf(key) > -1){
                return true
            }
        }
        return false
    }
}
