import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 全局loading状态
        showLoading: true,
        // 空间层级
        spaceLevel: '中国',
        // 空间层级二级区分
        subSpaceLevel: '',
        // 顶部legend类别
        functionStatus: '',
        // 相机旋转状态
        cameraRotate: false,
        // 左侧组件名称
        leftType: '',
        // 右侧组件名称
        rightType: '',
        // 态势类型
        posture: '',
        // 预加载服务标识
        loadServiceCode: 0,
        // 对象信息
        objectInfo:{},
        // 对象管理默认展开key列表
        spaceExpandKeys:['中国'],
        // 对象管理折叠面板
        objectManagerKey:'',
        // 楼层是否展开
        floorExpand:false,
        showLinkList:[]
    },
    mutations: {
        setShowLoading(state, val) {
            state.showLoading = val
        },
        setSpaceLevel(state, val) {
            state.spaceLevel = val
        },
        setSubSpaceLevel(state, val) {
            state.subSpaceLevel = val
        },
        setFunctionStatus(state, val) {
            state.functionStatus = val
        },
        setCameraRotate(state, val) {
            state.cameraRotate = val
        },
        setLeftType(state, val) {
            state.leftType = val
        },
        setRightType(state, val) {
            state.rightType = val
        },
        setPosture(state, val) {
            state.posture = val
        },
        plusLoadService(state) {
            state.loadServiceCode++
        },
        setObjectInfo(state,val){
            state.objectInfo = val
        },
        setSpaceExpandKeys(state,val){
            state.spaceExpandKeys = val
        },
        appendSpaceExpandKeys(state,item){
            state.spaceExpandKeys.push(item)
        },
        clearAllSpaceExpandKeys(state){
            state.spaceExpandKeys = []
        },
        setObjectManagerKey(state,val){
            state.objectManagerKey = val
        },
        setFloorExpand(state,val){
            state.floorExpand = val
        },
        setShowLinkList(state,val){
            if (!Array.isArray(val)){
                val = []
            }
            state.showLinkList = val
        },
        appendShowLinkList(state,val){
            state.showLinkList.push(val)
        },
        rmShowLinkList(state,val){
            let number = state.showLinkList.indexOf(val);
            state.showLinkList.splice(number,1)
        }
    }
})
