import StandardStateHandler from "@/core/state/StandardStateHandler";
import ObjectManager from "@/components/objectManager/ObjectManager";

/**
 * 标准状态--园区
 * @author haowei
 */
export default class GardenStandardStateHandler extends StandardStateHandler{

    async init() {
        window.cameraManager.onSetCamera('园区',null,{})
        ObjectManager.updateExpandKeys('分数据中心-01')
        await super.init();
    }

    async reset() {
        await super.reset();
    }
}
