export default class LandmarkLayer {
    // 添加散点图层
    static addLandmarkLayer(params) {
        return new Promise(resolve => {
            let defaultOption = {
                id: 'idObj',
                name: 'layerName',
                coordType: 0,
                coordTypeZ: 0,
                autoScale: false,
                visible: true,
                legends: [],
                data: [],
            }
            let jsonData = Object.assign({}, defaultOption, params)
            delete jsonData.layerConfig
            // console.log('jsonData', jsonData)
            window.appInstance.uniCall('addLandmarkLayer', jsonData, (res) => {
                // console.log('addLandmarkLayer', jsonData, res)
                resolve(res);
                // 调整图层顺序
                if (res.result == 1) {


                    let offsetData = {
                        idLayer: jsonData.id,
                        offset: jsonData.offset || 0
                    }

                    window.appInstance.uniCall(
                        'moveOverlayBackward',
                        offsetData,
                        (res) => {
                            // console.log(`[moveOverlayForward]--- ${offsetData} --- [res]: `, res);
                        }
                    )
                }

                let clickShowDecorator = params.clickShowDecorator
                let hoverShowDecorator = params.clickShowDecorator
                if (clickShowDecorator == undefined) {
                    clickShowDecorator = true
                }
                if (hoverShowDecorator == undefined) {
                    hoverShowDecorator = true
                }
                let pickJsonData1 = {
                    overlayType: 'landmarkLayer',
                    idLayer: jsonData.id,
                    type: 'click',
                    allowMultiple: false,
                    isShowDecorator: clickShowDecorator,
                }
                let pickJsonData2 = {
                    overlayType: 'landmarkLayer',
                    idLayer: jsonData.id,
                    type: 'hover',
                    allowMultiple: false,
                    isShowDecorator: hoverShowDecorator,
                }
                if (!params.clickEventDisable){
                    window.appInstance.uniCall('pickOverlay', pickJsonData2, (res) => {
                        // console.log('pickOverlay2', pickJsonData2, res)
                    })
                    setTimeout(() => {
                        window.appInstance.uniCall('pickOverlay', pickJsonData1, (res) => {
                            // console.log('pickOverlay1', pickJsonData1, res)
                        })
                    }, 1000)
                }
            })
        });
    }

    // 更新图层数据
    static async updateLandmarkLayerCoord(params) {
        let defaultOption = {
            id: 'idObj',
            name: 'layerName',
            coordType: 0,
            coordTypeZ: 0,
            isAppend: false,
            data: [],
        }
        let jsonData = {...defaultOption}
        for (const key in jsonData) {
            jsonData[key] = params[key] || jsonData[key]
        }
        window.appInstance.uniCall('updateLandmarkLayerCoord', jsonData, (res) => {
            // console.log('updateLandmarkLayerCoord', jsonData, res)
        })
    }

    // 更新图层样式
    static updateLandmarkLayerStyle(layer) {
        window.appInstance.uniCall('updateLandmarkLayerStyle', {}, (res) => {
            super.setCallBack('更新地标图样式', res, layerConfig)
        })
    }

    // 设置图层显隐
    static setLayerVisible(layer, isVisible = true) {
        if (!layer) {
            return
        }
        window.appInstance.uniCall(
            'setOverlayVisibility',
            {
                id: layer.id,
                overlayType: layer.layerType,
                visible: isVisible,
            },
            (res) => {
                // console.log(`设置图层显隐-${layer.id}-${isVisible}`, res)
            }
        )
    }

    // 删除散点图层
    static removeLandmarkLayer(layer) {
        if (!layer) {
            throw new Error('缺少参数layer')
        }
        window.appInstance.uniCall(
            'removeOverlay',
            {
                id: layer.id,
                overlayType: 'landmarkLayer',
            },
            (res) => {
                // console.log(`删除图层-${layer.id}`, res)
            }
        )
    }

    // 开启图层点击事件
    static clickLandmarkLayerFn(layer) {
        window.appInstance.uniCall(
            'pickOverlay',
            {
                overlayType: layer.layerConfig.layerType,
                idLayer: layer.id,
                type: 'click',
                isShowDecorator: false,
            },
            (res) => {
            }
        )
    }

    // 调整图层顺序
    static moveOverlayForward(layer) {
        let jsonData = {
            idLayer: layer.id,
            offset: layer.offset || 0
        }

        window.appInstance.uniCall(
            'moveOverlayForward',
            jsonData,
            (res) => {
                // console.log(`[moveOverlayForward]--- ${jsonData} --- [res]: `, res);
            }
        )
    }
}
