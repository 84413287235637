import StandardStateHandler from "@/core/state/StandardStateHandler";
import BuildingController from "@/core/logic/BuildingController";
import Store from "@/store";
import SpaceController from "@/core/logic/SpaceController";

/**
 * 机柜标准状态
 * @author haowei
 */
export default class RackStandardHandler extends StandardStateHandler {

    async init() {
        BuildingController.expandBuilding()
        window.cameraManager.onSetCamera(Store.state.subSpaceLevel,null,{})
        window.modelManager.closeClickEvent(['louCeng'])
        await super.init();
    }

    async reset() {
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo();
        if (nowSpaceLevelInfo.lv <= 7){
            let rackCtrl = window.modelManager.controls.rack;
            // 重置高亮状态
            rackCtrl.unDoHighlightRack()
            rackCtrl.highlightRackGroup(rackCtrl.currentRackGroup)
            rackCtrl.currentRack = ''
            rackCtrl.closeAllRack()
        }
        window.modelManager.openClickEvent()
        await super.reset();
    }
}
