import BaseHandler from "@/core/layer/handler/BaseHandler";
import Store from "@/store";

/**
 * 资产图层处理
 * @author haowei
 */
export default class AssetsHandler extends BaseHandler{

    assetsMap = {
        "门禁设备":"MenJinSheBei",
        "消防烟感":"XiaoFangYanGan",
        "固定摄像头":"SheXiangTouGuDing",
        "云台摄像头":"SheXiangTouYunTai",
        "人脸识别摄像头":"SheXiangTouRenLianShiBie",
        "水表设备":"ShuiBiaoSheBei",
        "电表设备":"DianBiaoSheBei",
        "空调":"KongTiao",
        "可燃气体探测器":"KeRanQiTiTanCeQi",
        "环境监测设备":"HuanJingJianCeSheBei"
    }

    handleClick({id, idLayer}) {
        let com = ''
        for (let key in this.assetsMap) {
            if (id.indexOf(key) != -1 || idLayer.indexOf(key) != -1){
                com = this.assetsMap[key]
                break
            }
        }
        let find = window.allData.find(item=>item.id == id&&item.idLayer == idLayer)
        Store.commit('setRightType',com)
        Store.commit('setObjectInfo',{name:find.name})
    }

    filterLayer({id, idLayer}) {
        for (let key in this.assetsMap) {
            if (id.indexOf(key) > -1 || idLayer.indexOf(key) > -1){
                return true
            }
        }
        return false
    }


    handleHover({id, idLayer}) {
        super.handleHover({id, idLayer});
    }
}
