// import gisDataManager from '../../api/gisDataManager';

export default class ODLineLayer {
  static defaultConfig = {
    id: 'ODLineLayer',
    name: 'ODLineLayerName',
    coordType: 0,
    coordTypeZ: 0,
    valueMax: 1000,
    valueMin: 0,
    isShowBubble: true,
    bubbleRadiusMax: 1000,
    bubbleRadiusMin: 50,
    bubbleSpeed: 50,
    lineWidthMax: 10,
    lineWidthMin: 10,
    lineSpeed: 10,
    curvature: 0.5,
    visible: true,
    legends: [
      {
        name: 'default',
        type: 'default',
        bubbleColor: 'ff0000',
        lineColor: 'ff0000',
        labelColor: '#000000',
        labelBackgroundColor: '#333333'
      }
    ],
    data: []
  };

  // 添加关系图图层
  static addODLineLayer(params) {
    return new Promise(resolve => {
      let defaultOption = {}
      let jsonData = Object.assign({}, defaultOption, params)
      delete jsonData.layerConfig
      window.appInstance.uniCall('addODLineLayer', jsonData, res => {
        // console.log('addODLineLayer' + jsonData.id, res);
        resolve(res);
      });
    })
  }

  // 更新关系图数据
  static async updateODLineLayerCoord(layer, subLayer) {
    const updateConfig = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      isAppend: false,
      data: []
    };
    try {
      let layerInfo = await gisDataManager.getODLineLayerData(
        layer,
        updateConfig,
        subLayer
      );
      delete layerInfo.layerConfig;
      window.appInstance.uniCall('updateODLineLayerCoord', layerInfo, res => {
        // console.log('更新关系图图层' + layer.id, res);
      });
    } catch (error) {
      // console.log('更新关系图图层失败' + layer.id, res);
    }
  }

  //更新关系图样式
  static updateStyle(layer) {
    window.appInstance.uniCall('updateODLineLayerStyle', {}, res => {
      super.setCallBack('更新关系图样式', res, layerConfig);
    });
  }
}
