import UEapis from './UEapi'
export default class TrailLayer {
  // 缓存当前轨迹图数据
  static trailLayerTemp = new Map()
  static indexTemp = {};
  static sortFontTemp = {};
  // 添加轨迹图图层
  static addTrailLayer(params) {
    let defaultOption = {
      id: 'trail',
      name: '轨迹图',
      coordType: 0,
      coordTypeZ: 0,
      trackStyle: 'style001',
      trackDuration: 10,
      objLife: 30,
      trackWidth: 10,
      duraiotn: 1,
      visible: true,
      legends: [
        {
          name: 'trail1',
          iconName: 'car',
          trackColor: '#ff0000',
        },
      ],
      data: [],
    }
    let jsonData = Object.assign({}, defaultOption, params)
    // console.warn('addTrailLayer-pre', jsonData)
    return new Promise((resolve, reject)=>{
      try {
        window.appInstance.uniCall('addTrailLayer', jsonData, (res) => {
          this.clickTrailLayerFn(jsonData)
          // console.warn('addTrailLayer', jsonData, res)
          // this.updateLayer(params)
          resolve(res)
        })
      }catch (e){
        reject(e)
      }
    })


  }

  // 使用本地JSON更新地图
  static async updateLayer(params) {
    let defaultOption = {
      id: 'trail',
      name: '轨迹图',
      coordType: 0,
      coordTypeZ: 0,
      isAppend: true,
      duration: 1,
      data: [],
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('updateTrailLayerCoord', jsonData, (res) => {
      console.log('updateTrailLayerCoord', jsonData, res)
    })
  }
  //更新轨迹图图层 必须要有你添加图层时候的唯一id 下面函数中用的name字段
  static async updateData(layer, data, coordZ) {
    data.forEach((trail) => {
      trail._data = [...trail.data]
      trail.flag = true
      if (TrailLayer.trailLayerTemp.has(layer.name)) {
        TrailLayer.trailLayerTemp.get(layer.name).push(trail)
      } else {
        TrailLayer.trailLayerTemp.set(layer.name, [])
        TrailLayer.trailLayerTemp.get(layer.name).push(trail)
      }
    })

    UEapis.TimerList[layer.name] = setInterval(() => {
      let data = []
      TrailLayer.trailLayerTemp.get(layer.name).forEach((trail) => {
        if (trail._data.length <= 0) {
          trail._data = [...trail.data]
          trail.flag = !trail.flag
        }

        let curData = null
        if (trail.flag) {
          curData = trail._data.shift()
        } else {
          curData = trail._data.pop()
        }

        data.push({
          id: curData.name, // 数据唯一子id
          label: '',
          coord: [curData.lon, curData.lat],
          coordZ: coordZ || 0,
          type: curData.name, // 数据唯一样式id
        })
      })
      let jsonData = {
        id: layer.name,
        name: layer.name,
        coordType: 0,
        coordTypeZ: 0,
        isAppend: true,
        duration: 1,
        data: data,
      }
      window.appInstance.uniCall('updateTrailLayerCoord', jsonData, (res) => {
        // console.log('更新轨迹图图层', res)
      })
    }, 1000)
  }

  static clearTrailLayerTimers(id) {
    let TimerList = UEapis.TimerList
    if (id) {
      if (TimerList[id]) {
        clearInterval(TimerList[id])
        delete TimerList[id]
        TrailLayer.trailLayerTemp.delete(id)
      }
    } else {
      if (Object.keys(TimerList).length > 0) {
        Object.keys(TimerList).forEach((layerName) => {
          clearInterval(TimerList[layerName])
          delete TimerList[layerName]
          TrailLayer.trailLayerTemp.delete(layerName)
        })
      }
    }
  }

  static updateLayerTimer(layer,dataTimeArr){
    // 执行方法,同时更新两条轨迹图数据
    const execDataHandle = (jsonData)=>{
      // console.debug('execDataHandle',jsonData);
      return new Promise(resolve => {
        window.appInstance.uniCall('updateTrailLayerCoord', jsonData, (res) => {
          // console.debug('updateLayerTimer-updateTrailLayerCoord', jsonData, res);
          resolve(true);
        })
      });
    };

    this.indexTemp[layer.name] = 0;
    this.sortFontTemp[layer.name] = true;
    UEapis.TimerList[layer.name] = setInterval(async ()=>{
      // console.debug("index",this.indexTemp[layer.name])
      let jsonData = dataTimeArr[this.indexTemp[layer.name]];
      await execDataHandle(jsonData,this.indexTemp[layer.name]);
      if(this.sortFontTemp[layer.name]){
        this.indexTemp[layer.name]++;
      }else {
        this.indexTemp[layer.name]--;
      }
      if (this.indexTemp[layer.name] > dataTimeArr.length - 1){
        this.sortFontTemp[layer.name] = false;
      }
      if(this.indexTemp[layer.name] <= 0){
        this.sortFontTemp[layer.name] = true;
      }
    },1000);
  }
  //更新轨迹图图层 必须要有你添加图层时候的唯一id 下面函数中用的name字段

  static async updateTrailLayer(layer, subLayer) {
    let layerInfo = await gisDataManager.getLocalTrailLayerData(
      layer,
      this.defaultConfig,
      subLayer
    )
    this.updateLayer(layer, layerInfo.data)
  }

  static clickTrailLayerFn(layer) {
    window.appInstance.uniCall(
      'pickOverlay',
      {
        overlayType: 'trailLayer',
        idLayer: layer.id,
        type: 'click',
        allowMultiple: false,
        isShowDecorator: false,
      },
      (res) => {
        console.log('开启轨迹图点击事件——', layer.id)
      }
    )
  }
}
