<template>
  <div class="Table">
    <ul class="Table_nav">
      <li
        v-for="(item, index) of NavData"
        :key="index"
        :style="{
          width: NavWidth[index],
        }"
      >
        {{ item }}
      </li>
    </ul>
    <ul class="content">
      <li v-for="(item, index) of showData" :key="index">
        <div :style="{ width: NavWidth[0] }">
          {{ item.time }}
        </div>
        <div :style="{ width: NavWidth[1] }">{{ item.content }}</div>
        <div :style="{ width: NavWidth[2] }">{{ item.second }}</div>
      </li>
    </ul>

    <div>
      <Pagination :alldata="CData" @handleCurrentChange="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import Pagination from './Pagination.vue'
export default {
  components: {
    Pagination,
  },
  props: {
    NavData: {
      type: Array,
      default: () => {
        return ['时间', '内容']
      },
    },
    TableStyle: {
      type: Object,
      default: () => {
        return {
          TableWidth: '619px',
          NavHeight: '40px',
          NavlineHeight: '40px',
          NavTop: '0px',
        }
      },
    },
    NavWidth: {
      type: Array,
      default: () => {
        return ['268px', '340px','0px']
      },
    },
    CData: {
      type: Array,
      default: () => {
        return [
          { time: '2022-07-11 12:00:00', content: '1数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '2数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '3数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '4数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '5数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '6数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '7数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '8数据中心发生温度过高告警' },
          { time: '2022-07-11 12:00:00', content: '9数据中心发生温度过高告警' },
          {
            time: '2022-07-11 12:00:00',
            content: '10数据中心发生温度过高告警',
          },
          {
            time: '2022-07-11 12:00:00',
            content: '11数据中心发生温度过高告警',
          },
          {
            time: '2022-07-11 12:00:00',
            content: '12数据中心发生温度过高告警',
          },
          {
            time: '2022-07-11 12:00:00',
            content: '13数据中心发生温度过高告警',
          },
          {
            time: '2022-07-11 12:00:00',
            content: '14数据中心发生温度过高告警',
          },
        ]
      },
    },
  },
  data() {
    return {
      allData: [],
      showData: [],
    }
  },

  methods: {
    handleCurrentChange(data) {
      this.showData = data
    },
  },

  watch: {
    CData: {
      handler(val) {
        this.allData = val
      },
      deep: true,
    },
  },
}
</script>

<style lang="less" scoped>
.Table {
  width: 100%;
  .Table_nav {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > li {
      padding-left: 15px;
      box-sizing: border-box;
      line-height: 50px;
      background: rgba(3, 109, 188, 0.4);
      text-align: left;
      font-size: 26px;
      font-family: '微软雅黑';
      color: #ffffff;
    }
  }

  .content {
    width: 100%;
    height: 250px;
    overflow-y: auto;
    & > li {
      width: 100%;
      height: 51px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px rgba(255, 255, 255, 0.15);
      box-sizing: border-box;
      & > div {
        line-height: 50px;
        padding-left: 15px;
        box-sizing: border-box;
        text-align: left;
        font-size: 26px;
        font-family: '微软雅黑';
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>