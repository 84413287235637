export default class TrackLayer {
  // 添加点迹图图层
  static async addTrackLayer(params) {
    let defaultOption = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      visible: true,
      legends: [],
      data: [],
    }
    let jsonData = Object.assign({}, defaultOption, params)
    window.appInstance.uniCall('addTrackLayer', jsonData, (res) => {
      // console.log('addTrackLayer', jsonData, res)
    })
  }
  // 删除点迹图层
  static removeTrackLayer(layer) {
    if (!layer) {
      throw new Error('缺少参数layer')
    }
    window.appInstance.uniCall(
      'removeOverlay',
      {
        id: layer.id,
        overlayType: 'trackLayer',
      },
      (res) => {
        // console.log(`删除图层-${layer.id}`, res)
      }
    )
  }

}
