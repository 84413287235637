import api from '@/api'

/**
 * 资源态势相关接口
 * @author haowei
 */
export default {
    // 总量统计
    reqGetzltjData() {
        return api.doGet('/data/ZYTS/zltjData.json');
    },
    // 分中心计算资源
    reqGetfzxjszyData() {
        return api.doGet('/data/ZYTS/fzxjszyData.json');
    }
    // 资源设备类型统计
    , reqGetzysblttjData() {
        return api.doGet('/data/ZYTS/zysblttjData.json');
    }
    // 资源使用率月变化趋势
    , reqGetzysylybhqsData() {
        return api.doGet('/data/ZYTS/zysylybhqsData.json');
    }
    // 机房分布图
    , reqGetjffbtData() {
        return api.doGet('/data/ZYTS/jffbtData.json');

    }
    // 机房报表
    , reqGetjfbbData(p) {
        return api.doGet(`/data/ZYTS/jfbbData${p}.json`);
    }
    // 分中心业务告警
    , reqGetfzxywgjData() {
        return api.doGet('/data/ZYTS/fzxywgjData.json');
    }
}
