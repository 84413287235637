<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse title="基础信息" @changeisReceiveFair="isReceiveFair" :isReceiveFair="jcxxIsShow">
            <Tag
              v-for="(item,index) in KRQTTCQjcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="ztxx">
          <Collapse
            title="状态信息"
            :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="ztxxIsShow"
          >
            <Tag
              v-for="(item,index) in KRQTTCQztxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="gjxx">
          <Collapse
            title="告警信息"
            :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table :CData="KRQTTCQgjxx" :NavData="['时间 ','内容']" :NavWidth="['268px','340px']" />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea
  },
  data() {
    return {
      KRQTTCQjcxx: [
        {
          name: '编号',
          value: 'G5-DT-MJSB-001'
        },
        {
          name: '所属',
          value: 'G5-DT'
        },
        {
          name: '型号',
          value: '可燃气体探测器-001'
        }
      ],
      KRQTTCQztxx: [
        {
          name: '设备状态',
          value: '在线'
        },
        {
          name: '消防栓按钮状态告警',
          value: '正常'
        },
        {
          name: '可燃气体泄露报警',
          value: '正常'
        },
        {
          name: '无线底座防拆报警',
          value: '正常'
        },
        {
          name: '无线底座低电压报警 ',
          value: '正常'
        },
        {
          name: '无线底座其他故障报警 ',
          value: '正常'
        }
      ],
      KRQTTCQgjxx: [
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '可燃气体泄露报警  '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '无线底座防拆报警  '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '无线底座低电压报警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '无线底座其他故障报警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        },
        {
          time: '2022-07-11 12:00:00',
          content: '消防栓按钮状态告警 '
        }
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo'])
  },
  mounted() {
    this.KRQTTCQjcxx[0].value = this.objectInfo.name
  },
  watch: {
    objectInfo: {
      handler(val) {
        this.KRQTTCQjcxx[0].value = val.name
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
        .Tag:nth-child(2),
        .Tag:nth-child(3),
        .Tag:nth-child(4),
        .Tag:nth-child(5),
        .Tag:nth-child(6),
        .Tag:nth-child(7) {
          .Tag_right {
            color: #00ffff;
          }
        }
      }
    }
  }
}
</style>
