export default [
  {
    id:'C03-004-04_SM_配线架',
    portId:'API_配线架_正_A05'
  },{
    id:'C03-004-04_SM_交换机',
    portId: 'API_交换机_正_c05'
  },{
    id:'C03-004-04_SM_服务器',
    portId: 'API_服务器_正_A05'
  }
]
