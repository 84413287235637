/**
 * 镜头管理类
 */

import DefaultModelCameraController from '@/core/camera/DefaultModelCameraController'
import DefaultLayerCameraController from '@/core/camera/DefaultLayerCameraController'
import RackModelCameraController from "@/core/camera/RackModelCameraController";
import EqModelCameraController from "@/core/camera/EqModelCameraController";

export default class CameraManager {
  static instance

  static getInstance() {
    return this.instance ? this.instance : new CameraManager()
  }

  modelCtrl = {
    // 默认模型
    'defaultModel': new DefaultModelCameraController(),
    // 机柜组、机柜
    'rack':new RackModelCameraController(),
    // 设备
    'equipment':new EqModelCameraController()
  }

  layerCtrl = {
    // 默认图层
    'defaultLayer': new DefaultLayerCameraController()
  }

  /**
   * 根据模型id获取模型镜头控制器
   * @param id 模型id
   * @return {DefaultModelCameraController|*}
   */
  getModelCtrlById(id) {
    const { ctrl: modelCtrl, key } = window.modelManager.getControlById(id)
    if (!key || !this.modelCtrl[key]) {
      return this.modelCtrl.defaultModel
    }
    return this.modelCtrl[key]
  }

  /**
   * 获取图层镜头控制器
   * @param id 图层子id
   * @param idLayer 图层id
   */
  getLayerCtrlById(id, idLayer) {
    const layerMap = window.layerManager.getLayerMap(idLayer)
    let key = layerMap.layerConfig.layerType
    if (!key || !this.layerCtrl[key]) {
      return this.layerCtrl.defaultLayer
    }
    return this.layerCtrl[key]
  }

  /**
   * 触发镜头控制
   * @param id 图层子id、模型id
   * @param idLayer 图层id -- 图层控制时必须要有，模型必须不设置此参数
   * @param params 镜头参数 -- 可选
   */
  onSetCamera(id, idLayer = '', params = {}) {
    // 通过idLayer区分是图层镜头控制还是模型镜头控制
    if (idLayer) {
      const layerCtrl = this.getLayerCtrlById(id, idLayer)
      layerCtrl.setCamera({ id, idLayer, ...params })
      console.warn(`镜头控制器设置镜头-图层:${id};${idLayer}`,params,layerCtrl)
    } else {
      const modelCtrl = this.getModelCtrlById(id)
      modelCtrl.setCamera({ id, ...params })
      console.warn(`镜头控制器设置镜头-模型:${id}`,params,modelCtrl)
    }
  }

}
