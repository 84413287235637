<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse
            title="基础信息"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="jcxxIsShow"
          >
            <Tag
              v-for="(item, index) in JianZhujcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="czzl">
          <Collapse
            title="操作指令"
            :iconUrl="require('@/assets/img/SSGJ/czzlIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="czzlIsShow"
          >
            <TagButton
              v-for="(item, index) in JianZhuczzl"
              :key="index"
              @runInHole="runInHole"
              :text="item"
              :iconUrl="btnimg[index]"
            />
          </Collapse>
        </div>
        <div class="gjxx">
          <Collapse
            title="告警信息"
            :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table :CData="JianZhugjxx" />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
  },
  data() {
    return {
      JianZhujcxx: [
        {
          name: '名称',
          value: '建筑-01',
        },
        {
          name: '总层数',
          value: '3层',
        },
        {
          name: '机房总数',
          value: '10个',
        },
        {
          name: '机柜总数',
          value: '300个',
        },
        {
          name: '服务器总数',
          value: '3000个',
        },
      ],
      JianZhuczzl: ['下钻至F3'],
      JianZhugjxx: [
        { time: '2022-07-11 12:00:00', content: '服务器G2 7914AC10-01' },
        { time: '2022-07-12 12:00:00', content: '交换机-05连接异常' },
        { time: '2022-07-13 12:00:00', content: 'SQL数据库服务器-01CPU' },
        { time: '2022-07-14 12:00:00', content: '服务器01-CPU使用率过高' },
        { time: '2022-07-15 12:00:00', content: '路由器-03连接异常' },
        { time: '2022-07-16 12:00:00', content: '6数据中心发生温度过高告警' },
        { time: '2022-07-17 12:00:00', content: '7数据中心发生温度过高告警' },
        { time: '2022-07-18 12:00:00', content: '8数据中心发生温度过高告警' },
        { time: '2022-07-19 12:00:00', content: '9数据中心发生温度过高告警' },
        {
          time: '2022-07-11 12:00:00',
          content: '10数据中心发生温度过高告警',
        },
        {
          time: '2022-07-11 12:00:00',
          content: '11数据中心发生温度过高告警',
        },
        {
          time: '2022-07-11 12:00:00',
          content: '12数据中心发生温度过高告警',
        },
        {
          time: '2022-07-11 12:00:00',
          content: '13数据中心发生温度过高告警',
        },
        {
          time: '2022-07-11 12:00:00',
          content: '14数据中心发生温度过高告警',
        },
      ],
      btnimg: [
        require('@/assets/img/SSGJ/fIcon.png'),
        require('@/assets/img/SSGJ/jzIcon.png'),
        require('@/assets/img/SSGJ/lcIcon.png'),
        require('@/assets/img/SSGJ/fIcon.png'),
        require('@/assets/img/SSGJ/fIcon.png'),
      ],

      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true,
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    },
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo']),
  },
  mounted() {
    this.JianZhujcxx[0].value = this.objectInfo.name
    this.JianZhuczzl = ['下钻至F3']
    // switch (this.functionStatus) {
    //   case '默认状态':
    //     this.JianZhuczzl = ['下钻至F3']
    //     this.czzlIsShow = true
    //     break
    //   case '对象管理':
    //     this.JianZhuczzl = []
    //     this.czzlIsShow = false
    //     break
    // }
  },
  watch: {
    functionStatus: {
      handler(val) {
        this.JianZhuczzl = ['下钻至F3']
        // switch (val) {
        //   case '默认状态':
        //     this.JianZhuczzl = ['下钻至F3']
        //     this.czzlIsShow = true
        //     break
        //   case '对象管理':
        //     this.JianZhuczzl = []
        //     this.czzlIsShow = false
        //     break
        // }
      },
      deep: true,
    },
    objectInfo: {
      handler(val) {
        this.JianZhujcxx[0].value = val.name
      },
      deep: true,
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
}
</style>
