export default {
    legend: {
        中国: {
            数据中心: {
                数据中心: {
                    layers: ['中国-数据中心'],
                    checked: true,
                },
                数据传输: {
                    layers: ['中国-数据传输'],
                    checked: true,
                },
            },
            告警: {
                数据传输: {
                    layers: ['中国-告警_数据传输'],
                    checked: true,
                },
            },
            // 省份标识: {
            //   省份标识: {
            //     layers: ['中国-省份标识'],
            //     checked: true,
            //   },
            // },
        },
        北京: {
            数据中心: {
                数据中心: {
                    layers: ['北京-数据中心'],
                    checked: true,
                },
                数据传输: {
                    layers: ['北京-数据传输'],
                    checked: true,
                },
            },
            告警: {
                数据传输: {
                    layers: ['北京-告警_数据传输'],
                    checked: true,
                },
            },
            // 行政区标识: {
            //   行政区标识: {
            //     layers: ['北京-行政区标识'],
            //     checked: true,
            //   },
            // },
        },
        园区: {
            安防: {
                固定摄像头: {
                    layers: ['园区-固定摄像头'],
                    checked: true,
                },
                云台摄像头: {
                    layers: ['园区-云台摄像头'],
                    checked: true,
                },
                人脸识别摄像头: {
                    layers: ['园区-人脸识别摄像头'],
                    checked: true,
                },
                门禁设备: {
                    layers: ['园区-门禁设备'],
                    checked: true,
                },
                可燃气体探测器: {
                    layers: ['园区-可燃气体探测器'],
                },
                消防烟感: {
                    layers: ['园区-消防烟感'],
                },
            },
            人员: {
                安保人员: {
                    layers: ['园区-安保人员'],
                    checked: true,
                },
                巡检人员: {
                    layers: ['园区-巡检人员'],
                    checked: true,
                },
            },
            告警: {
                设备: {
                    layers: ['园区-告警_设备'],
                    checked: true,
                },
                告警事件分布: {
                    layers: ['园区-告警事件分布'],
                    checked: true,
                },
            },
        },
        楼层: {
            安防: {
                固定摄像头: {
                    layers: ['楼层-固定摄像头'],
                    checked: true,
                },
                云台摄像头: {
                    layers: ['楼层-云台摄像头'],
                    checked: true,
                },
                人脸识别摄像头: {
                    layers: ['楼层-人脸识别摄像头'],
                    checked: true,
                },
                门禁设备: {
                    layers: ['楼层-门禁设备'],
                    checked: true,
                },
                可燃气体探测器: {
                    layers: ['楼层-可燃气体探测器'],
                    checked: true,
                },
                消防烟感: {
                    layers: ['楼层-消防烟感'],
                    checked: true,
                },
            },
            人员: {
                安保人员: {
                    layers: ['楼层-安保人员'],
                    checked: true,
                },
                巡检人员: {
                    layers: ['楼层-巡检人员'],
                    checked: true,
                },
            },
            容量: {
                空间使用: {
                    layers: ['设备-空间使用'],
                    radio: true,
                },
                功耗使用: {
                    layers: ['设备-功耗使用'],
                    radio: true,
                },
                承重使用: {
                    layers: ['设备-承重使用'],
                    radio: true,
                },
            },
            能耗: {
                水表: {
                    layers: ['楼层-水表设备'],
                },
                电表: {
                    layers: ['楼层-电表设备'],
                },
                燃气表: {
                    layers: ['楼层-燃气表设备'],
                },
            },
            动环: {
                空调: {
                    layers: ['楼层-空调'],
                    checked: false,
                },
                环境监测设备: {
                    layers: ['楼层-环境监测设备'],
                    checked: false,
                },
                温度云图: {
                    layers: ['楼层-温度云图'],
                    checked: false,
                },
                UPS: {
                    layers: ['楼层-UPS'],
                },
                配电柜: {
                    layers: ['楼层-配电柜'],
                },
            },
            告警: {
                设备: {
                    layers: ['楼层-告警_设备'],
                    checked: true,
                },
                能耗: {
                    layers: ['楼层-告警_能耗'],
                },
                机柜: {
                    layers: ['楼层-告警_机柜'],
                },
            },
        },
        房间: {
            安防: {
                固定摄像头: {
                    layers: ['房间-固定摄像头'],
                    checked: true,
                },
                云台摄像头: {
                    layers: ['房间-云台摄像头'],
                    checked: true,
                },
                人脸识别摄像头: {
                    layers: ['房间-人脸识别摄像头'],
                    checked: true,
                },
                门禁设备: {
                    layers: ['房间-门禁设备'],
                    checked: true,
                },
                可燃气体探测器: {
                    layers: ['房间-可燃气体探测器'],
                    checked: true,
                },
                消防烟感: {
                    layers: ['房间-消防烟感'],
                    checked: true,
                },
            },
            容量: {
                空间使用: {
                    layers: ['设备-空间使用'],
                    radio: true,
                },
                功耗使用: {
                    layers: ['设备-功耗使用'],
                    radio: true,
                },
                承重使用: {
                    layers: ['设备-承重使用'],
                    radio: true,
                },
            },
            动环: {
                空调: {
                    layers: ['房间-空调'],
                },
                环境监测设备: {
                    layers: ['房间-环境监测设备'],
                },
                温度云图: {
                    layers: ['房间-温度云图'],
                },
                UPS: {
                    layers: ['房间-UPS'],
                },
                配电柜: {
                    layers: ['房间-配电柜'],
                },
            },
            告警: {
                设备: {
                    layers: ['房间-告警-设备'],
                    checked: true,
                },
                机柜: {
                    layers: ['房间-告警-机柜'],
                },
                机器: {
                    layers: ['房间-告警-机器'],
                },
                能耗: {
                    layers: ['房间-告警-能耗'],
                },
                端口: {
                    layers: ['房间-告警-端口'],
                },
            },
        },
        机柜: {
            告警: {
                机柜: {
                    layers: ['机柜-告警_机柜', '机柜-机柜实体'],
                },
                机器: {
                    layers: ['机柜-告警_机器', '机柜-告警-机器实体'],
                },
            },
        },
        机柜组: {
            告警: {
                机柜: {
                    layers: ['机柜-告警_机柜', '机柜-机柜实体'],
                },
                机器: {
                    layers: ['机柜-告警_机器', '机柜-告警-机器实体'],
                },
            },
        },
        设备: {
            告警: {
                机柜: {
                    layers: ['设备-告警_机柜'],
                },
                机器: {
                    layers: ['设备-告警_机器'],
                },
                端口: {
                    layers: ['设备-告警_端口'],
                },
            },
            链路: {
                // 设备链路: {
                //     layers: ['设备-链路设备链路'],
                // },
                网口: {
                    layers: ['设备-链路网口'],
                },
            },
        },
    },
    legendImgs: {
        数据中心: '/img/legend/ShuJuZhongXin',
        固定摄像头: '/img/legend/SheXiangTouGuDing',
        云台摄像头: '/img/legend/SheXiangTouYunTai',
        人脸识别摄像头: '/img/legend/SheXiangTouRenLianShiBie',
        门禁设备: '/img/legend/MenJinShiJian',
        可燃气体探测器: '/img/legend/KeRanQiTiTanCeQi',
        消防烟感: '/img/legend/XiaoFangYanGan',
        空间使用: '/img/legend/JiGuiGaoJing',
        功耗使用: '/img/legend/JiGuiGongHaoShiYong',
        承重使用: '/img/legend/JiGuiChengZhongZhuTu',
        水表: '/img/legend/ShuiBiaoSheBei',
        电表: '/img/legend/DianBiaoSheBei',
        燃气表: '/img/legend/RanQiBiaoSheBei',
        空调: '/img/legend/KongTiao',
        环境监测设备: '/img/legend/HuanJingJianCeSheBei',
        温度云图: '/img/legend/WenDuYunTu',
        UPS: '/img/legend/UPS',
        配电柜: '/img/legend/PeiDianGui',
        安保人员: '/img/legend/AnBaoRenYuan',
        巡检人员: '/img/legend/XunJianRenYuan',
        省份标识: '/img/legend/SheBeiLianLu',
        行政区标识: '/img/legend/SheBeiLianLu',

        设备: '/img/legend/SheBeiGaojing',
        机柜: '/img/legend/JiGuiGaoJing',
        机器: '/img/legend/JiQiGaoJing',
        能耗: '/img/legend/NengHaoGaoJing',
        // '端口': '/img/legend/',
        告警事件分布: '/img/legend/GaoJingShiJianFenBu',
        数据传输: '/img/legend/ShuJuChuanShu',
        设备链路: '/img/legend/SheBeiLianLu',
        网口: '/img/legend/WangKou',
    },
    groupImgs: {
        数据中心: '/img/group/ShuJuZhongXin',
        安防: '/img/group/AnFang',
        容量: '/img/group/RongLiang',
        能耗: '/img/group/NengHao',
        动环: '/img/group/DongHuan',
        人员: '/img/group/RenYuan',
        告警: '/img/group/GaoJing',
        链路: '/img/group/LianLu',
        省份标识: '/img/group/RongLiang',
        行政区标识: '/img/group/RongLiang',
    },
}
