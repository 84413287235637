export const linkList = [
  {
    name: 'Server-b22',
    value: 'SQL-1',
    id: 'C03-004-04_SM_配线架',
    btn: ['设 备', '端 口'],
  },
  {
    name: 'SQL数据库服务_port_SQL-1_D04_ODF1_port_OP-2',
    value: 'OP-2',
    id: 'Link_SM_机柜通讯线C03',
    btn: ['配 线'],
  },
  {
    name: 'Server-b23',
    id: 'C03-004-04_SM_交换机',
    value: 'OP-3',
    btn: ['设 备', '端 口'],
  },
  {
    name: 'D04-ODF1_port_OPB-2_F03_ODF1_port_OP-4',
    id: 'Link_SM_机柜通讯线CF01',
    value: 'OP-4',
    btn: ['配 线'],
  },
  {
    name: 'F03-ODF1',
    id: 'C03-004-04_SM_数据服务器_虚拟机',
    value: 'OP-5',
    btn: ['设 备', '端 口'],
  },
  {
    name: 'D04-ODF1_port_OPB-4_F03_BANKA_01_port_OP-3',
    value: 'OP-6',
    id: 'Link_SM_机柜通讯线CA',
    btn: ['配 线'],
  },
  {
    name: 'F03-ODF1',
    value: 'OP-5',
    id: 'C03-004-04_SM_服务器',
    btn: ['设 备', '端 口'],
  },
  {
    name: 'D04-ODF1_port_OPB-4_F03_BANKA_01_port_OP-3',
    value: 'OP-6',
    id: 'Link_SM_机柜通讯线CA',
    btn: ['配 线'],
  },
]
