import BaseLayerController from './BaseLayerController'
import UEapi from '@/utils/UEapi/UEapi'
import LinkController from "@/core/logic/linkController";

/**
 * 路径图层控制器
 * @author haowei
 */
export default class PathController extends BaseLayerController {
  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data
    layerMap.layerConfig.status = 'hide'
  }

  async clearLayerExec(layerMap) {
    layerMap.layerConfig.status = 'none'
    let tagDatas = layerMap.layerConfig.jsonData
    console.log('clearLayerExec', tagDatas)
    try {
      for (let tagData of tagDatas) {
        await UEapi.Common.removeOverlay({ id: tagData.id, overlayType: 'path' })
      }
    } catch (e) {

    }
  }


  async showLayerExec(layerMap) {
    let tagDatas = layerMap.layerConfig.jsonData
    if (!tagDatas[0]) {
      let data = await this.getData(layerMap)
      layerMap.layerConfig.jsonData = data
      tagDatas = data
    }
    console.log('showLayerExec', tagDatas)
    for (let tagData of tagDatas) {
      // 打开门
      window.modelManager.controls.rack.openRack(tagData.tag.startRackId)
      window.modelManager.controls.rack.openRack(tagData.tag.endRackId)
      layerMap.layerConfig.status = 'show'
      await UEapi.Path.addPath(tagData)
      await UEapi.Common.moveOverlayBackward(tagData.id, 0);
    }
  }


  async showLayerSingle(idLayer, pathId) {
    let layerMap = window.layerManager.getLayerMap(idLayer)
    let tagDatas = layerMap.layerConfig.jsonData
    console.log('showLayerSingle',idLayer,pathId,layerMap)
    if (!tagDatas[0]) {
      let data = await this.getData(layerMap)
      layerMap.layerConfig.jsonData = data
      tagDatas = data
    }
    for (const tagData of tagDatas) {
      if (tagData.id == pathId) {
        await UEapi.Path.addPath(tagData).then(res=>{
          console.warn('addPath-res',res)
        })
        await UEapi.Common.moveOverlayBackward(tagData.id, 0);
      }
    }
  }

  async hideLayerSingle(idLayer, pathId) {
    let layerMap = window.layerManager.getLayerMap(idLayer)
    let tagDatas = layerMap.layerConfig.jsonData
    if (!tagDatas[0]) {
      let data = await this.getData(layerMap)
      layerMap.layerConfig.jsonData = data
      tagDatas = data
    }
    for (const tagData of tagDatas) {
      if (tagData.id == pathId) {
        await UEapi.Common.removeOverlay({ id: tagData.id, overlayType: 'path' })
      }
    }
  }

  async hideLayerExec(layerMap) {
    layerMap.layerConfig.status = 'hide'
    let tagDatas = layerMap.layerConfig.jsonData
    for (let tagData of tagDatas) {
      // 关闭门
      window.modelManager.controls.rack.closeRack(tagData.tag.startRackId)
      window.modelManager.controls.rack.closeRack(tagData.tag.endRackId)
      await UEapi.Common.removeOverlay({ id: tagData.id, overlayType: 'path' })
    }

  }

  async getData(layerMap) {
    let data = await super.getRowData(layerMap)
    // console.log('getData',data)
    let linkData = LinkController.getLinkData(data)
    // layerMap.data = linkData
    layerMap.data = data
    let jsonDatas = []
    for (let key in linkData) {
      let defaultOption = {
        id: 'idObj',
        name: 'layerName',
        coordType: 0,
        coordTypeZ: 0,
        autoScale: false,
        visible: true,
        legends: [],
        points: []
      }
      let jsonData = Object.assign({}, defaultOption, layerMap)
      jsonData.color = linkData[key].config.linkColor
      jsonData.id = linkData[key].config.id
      delete jsonData.layerConfig
      jsonData.points = linkData[key].points
      jsonData.tag = linkData[key].config
      jsonDatas.push(jsonData)
    }
    return jsonDatas
  }
}
