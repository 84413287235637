import Store from '@/store'

/**
 * 镜头控制基类
 *
 * @author haowei
 */
export default class BaseCameraController{


  TYPE_FOCUS = 'focus'

  TYPE_CAMERA = 'camera'

  /**
   * 设置镜头
   * @param params 参数
   * @return {Promise<*>}
   */
  async setCamera(params){

  }

  /**
   * 获取当前设置的镜头信息
   * @return {*}
   */
  getNowCameraInfo(){

  }

  /**
   * 获取当前位置的镜头信息
   * @return {Promise<*>}
   */
  async getCamera(){
    return new Promise(resolve => {
      window.appInstance.uniCall('getCamera',{},res=>{
        console.debug(res);
        resolve(res);
      })
    });
  }

  /**
   * 获取当前空间层级聚焦距离
   * @return {number} 距离
   */
  getNowFocusDistance(){
    let spaceLevel = Store.state.spaceLevel
    // 根据空间层级不同，聚焦距离也不同
    let distance;
    switch (spaceLevel){
      case '中国':
        distance = 1900000
        break
      case '北京':
        distance = 10000
        break
      default:
        distance = 10
        break
    }
    return distance
  }

}
