// import gisDataManager from '../../api/gisDataManager';

export default class GridColumnLayer {
  static defaultConfig = {
    id: "idObj",
    name: "layerName",
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 10,
    gridType: "cube",
    gridHeight: 10,
    gridAlpha: 0.3,
    gridGap: 0,
    gridWidth: 50,
    colorMax: "#ff0000",
    colorMin: "#ffff00",
    valueMax: 1000,
    valueMin: 0,
    visible: true,
    data: [
      {
        id: "gridId1",
        coord: [113.326675, 23.124065],
        value: 10
      },
      {
        id: "gridId2",
        coord: [113.327721, 23.127426],
        value: 18.2
      }
    ]
  }
  static async add3DGridLayer(layer) {
    let layerInfo = await gisDataManager.getGridColumnLayerData(layer, this.defaultConfig);
    delete layerInfo.layerConfig;
    delete layerInfo.status;
    // console.log('layerInfo', layerInfo);

    window.appInstance.uniCall('add3DGridLayer', layerInfo, res => {
      // console.log('添加栅格柱图' + layer.id, res);
    })
  }
}


