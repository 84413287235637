<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse title="基础信息" @changeisReceiveFair="isReceiveFair" :isReceiveFair="jcxxIsShow">
            <Tag
              v-for="(item,index) in menjinshebeijcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="ztxx">
          <Collapse
            title="状态信息"
            :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="ztxxIsShow"
          >
            <Tag
              v-for="(item,index) in menjinshebeiztxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="czzl">
          <Collapse
            title="操作指令"
            :iconUrl="require('@/assets/img/SSGJ/czzlIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="czzlIsShow"
          >
            <TagButton
              v-for="(item,index) in menjinshebeiczzl"
              :key="index"
              @runInHole="runInHole"
              :text="item"
            />
          </Collapse>
        </div>
        <div class="gjxx">
          <Collapse
            title="告警信息"
            :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table
              :CData="menjinshebeigjxx"
              :NavData="['时间 ','类别','类型 ']"
              :NavWidth="['278px','128px','200px']"
            />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea
  },
  data() {
    return {
      menjinshebeijcxx: [
        {
          name: '编号',
          value: 'G5-DT-MJSB-001'
        },
        {
          name: '所属',
          value: 'G5-DT'
        },
        {
          name: '型号',
          value: 'SZ-MJSB-001'
        }
      ],
      menjinshebeiztxx: [
        {
          name: '设备状态',
          value: '在线'
        }
      ],
      menjinshebeiczzl: ['打  开'],
      menjinshebeigjxx: [
        {
          time: '2022-07-11 12:00:00',
          content: '业务告警',
          second: '消防告警'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '业务告警',
          second: '防盗告警'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '设备告警',
          second: '体温告警'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '设备告警',
          second: '门磁报警'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '设备告警',
          second: '被撬告警'
        },
        {
          time: '2022-07-11 12:00:00',
          content: '设备告警',
          second: '服务器故障'
        },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' }
      ],
      btnimg: [require('@/assets/img/SSGJ/close.png')],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true
    }
  },
  methods: {
    runInHole(p) {
      if(p == '关  闭'){
        this.$set(this.menjinshebeiczzl,0,'打  开')
      }else {
        this.$set(this.menjinshebeiczzl,0,'关  闭')
      }
      console.debug('runInHole-mjsb-B',this.menjinshebeiczzl);
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo'])
  },
  mounted() {
    this.menjinshebeijcxx[0].value = this.objectInfo.name
  },
  watch: {
    objectInfo: {
      handler(val) {
        this.menjinshebeijcxx[0].value = val.name;
        let objName = val.name;
        if(objName == '门禁设备2'){
          if(this.$store.state.gateAnimState.B.indexOf('关') > -1){
            this.$set(this.menjinshebeiczzl,0,'打  开')
          }else{
            this.$set(this.menjinshebeiczzl,0,'关  闭')
          }
        }else if (objName == '门禁设备3'){
          if(this.$store.state.gateAnimState.A.indexOf('关') > -1){
            this.$set(this.menjinshebeiczzl,0,'打  开')
          }else{
            this.$set(this.menjinshebeiczzl,0,'关  闭')
          }
        }else {
          this.$set(this.menjinshebeiczzl,0,'打  开')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
      }
    }
  }
}
</style>
