/**
 * todo 此文件只用于当前版本，后续可以删除
 */

/**
 * 链路模型id数组1
 * @type string[]}
 */
export const linkList = [
    'Link_SM_机柜通讯线C03',
    'Link_SM_机柜通讯线CF01', // 02机柜链接01机柜 02侧
    'Link_SM_机柜通讯线CA', // 02机柜链接03机柜
]

/**
 * 链路模型id数组2
 * @type string[]}
 */
export const linkList2 = [
    'Link_SM_机柜通讯线CF02', // 02机柜链接01机柜 01侧
    'Link_SM_机柜通讯线A01', // 03机柜
    'Link_SM_机柜通讯线F06',
]

/**
 * 机柜白膜id数组
 * @type string[]}
 */
export const boxList = [
    'Box_A01-001-01_机柜容量',
    'Box_A01-001-02_机柜容量',
    'Box_A01-001-03_机柜容量',
    'Box_A01-001-04_机柜容量',
    'Box_A01-001-05_机柜容量',
    'Box_A01-001-06_机柜容量',
    'Box_A01-001-07_机柜容量',
    'Box_A01-001-08_机柜容量',
    'Box_A01-001-09_机柜容量',
    'Box_A01-001-10_机柜容量',
    'Box_A01-002-01_机柜容量',
    'Box_A01-002-02_机柜容量',
    'Box_A01-002-03_机柜容量',
    'Box_A01-002-04_机柜容量',
    'Box_A01-002-05_机柜容量',
    'Box_A01-002-06_机柜容量',
    'Box_A01-002-07_机柜容量',
    'Box_A01-002-08_机柜容量',
    'Box_A01-002-09_机柜容量',
    'Box_A01-002-10_机柜容量',
    'Box_A01-003-01_机柜容量',
    'Box_A01-003-02_机柜容量',
    'Box_A01-003-03_机柜容量',
    'Box_A01-003-04_机柜容量',
    'Box_A01-003-05_机柜容量',
    'Box_A01-003-06_机柜容量',
    'Box_A01-003-07_机柜容量',
    'Box_A01-003-08_机柜容量',
    'Box_A01-003-09_机柜容量',
    'Box_A01-003-10_机柜容量',
    'Box_A01-004-01_机柜容量',
    'Box_A01-004-02_机柜容量',
    'Box_A01-004-03_机柜容量',
    'Box_A01-004-04_机柜容量',
    'Box_A01-004-05_机柜容量',
    'Box_A01-004-06_机柜容量',
    'Box_A01-004-07_机柜容量',
    'Box_A01-004-08_机柜容量',
    'Box_A01-004-09_机柜容量',
    'Box_A01-004-10_机柜容量',
    'Box_D04-001-01_机柜容量',
    'Box_D04-001-02_机柜容量',
    'Box_D04-001-03_机柜容量',
    'Box_D04-001-04_机柜容量',
    'Box_D04-001-05_机柜容量',
    'Box_D04-001-06_机柜容量',
    'Box_D04-001-07_机柜容量',
    'Box_D04-001-08_机柜容量',
    'Box_D04-001-09_机柜容量',
    'Box_D04-001-10_机柜容量',
    'Box_D04-002-01_机柜容量',
    'Box_D04-002-02_机柜容量',
    'Box_D04-002-03_机柜容量',
    'Box_D04-002-04_机柜容量',
    'Box_D04-002-05_机柜容量',
    'Box_D04-002-06_机柜容量',
    'Box_D04-002-07_机柜容量',
    'Box_D04-002-08_机柜容量',
    'Box_D04-002-09_机柜容量',
    'Box_D04-002-10_机柜容量',
    'Box_D04-003-01_机柜容量',
    'Box_D04-003-02_机柜容量',
    'Box_D04-003-03_机柜容量',
    'Box_D04-003-04_机柜容量',
    'Box_D04-003-05_机柜容量',
    'Box_D04-003-06_机柜容量',
    'Box_D04-003-07_机柜容量',
    'Box_D04-003-08_机柜容量',
    'Box_D04-003-09_机柜容量',
    'Box_D04-003-10_机柜容量',
    'Box_D04-004-01_机柜容量',
    'Box_D04-004-02_机柜容量',
    'Box_D04-004-03_机柜容量',
    'Box_D04-004-04_机柜容量',
    'Box_D04-004-05_机柜容量',
    'Box_D04-004-06_机柜容量',
    'Box_D04-004-07_机柜容量',
    'Box_D04-004-08_机柜容量',
    'Box_D04-004-09_机柜容量',
    'Box_D04-004-10_机柜容量',
    'Box_B02-001-01_机柜容量',
    'Box_B02-001-02_机柜容量',
    'Box_B02-001-03_机柜容量',
    'Box_B02-001-04_机柜容量',
    'Box_B02-001-05_机柜容量',
    'Box_B02-001-06_机柜容量',
    'Box_B02-001-07_机柜容量',
    'Box_B02-001-08_机柜容量',
    'Box_B02-001-09_机柜容',
    'Box_B02-001-10_机柜容量',
    'Box_B02-002-01_机柜容量',
    'Box_B02-002-02_机柜容量',
    'Box_B02-002-03_机柜容量',
    'Box_B02-002-04_机柜容量',
    'Box_B02-002-05_机柜容量',
    'Box_B02-002-06_机柜容量',
    'Box_B02-002-07_机柜容量',
    'Box_B02-002-08_机柜容量',
    'Box_B02-002-09_机柜容量',
    'Box_B02-002-10_机柜容量',
    'Box_B02-003-01_机柜容量',
    'Box_B02-003-02_机柜容量',
    'Box_B02-003-03_机柜容量',
    'Box_B02-003-04_机柜容量',
    'Box_B02-003-05_机柜容量',
    'Box_B02-003-06_机柜容量',
    'Box_B02-003-07_机柜容量',
    'Box_B02-003-08_机柜容量',
    'Box_B02-003-09_机柜容量',
    'Box_B02-003-10_机柜容量',
    'Box_B02-004-01_机柜容量',
    'Box_B02-004-02_机柜容量',
    'Box_B02-004-03_机柜容量',
    'Box_B02-004-04_机柜容量',
    'Box_B02-004-05_机柜容量',
    'Box_B02-004-06_机柜容量',
    'Box_B02-004-07_机柜容量',
    'Box_B02-004-08_机柜容量',
    'Box_B02-004-09_机柜容量',
    'Box_B02-004-10_机柜容量',
    'Box_E05-001-01_机柜容量',
    'Box_E05-001-02_机柜容量',
    'Box_E05-001-03_机柜容量',
    'Box_E05-001-04_机柜容量',
    'Box_E05-001-05_机柜容量',
    'Box_E05-001-06_机柜容量',
    'Box_E05-001-07_机柜容量',
    'Box_E05-001-08_机柜容量',
    'Box_E05-001-09_机柜容量',
    'Box_E05-001-10_机柜容量',
    'Box_E05-002-01_机柜容量',
    'Box_E05-002-02_机柜容量',
    'Box_E05-002-03_机柜容量',
    'Box_E05-002-04_机柜容量',
    'Box_E05-002-05_机柜容量',
    'Box_E05-002-06_机柜容量',
    'Box_E05-002-07_机柜容量',
    'Box_E05-002-08_机柜容量',
    'Box_E05-002-09_机柜容量',
    'Box_E05-002-10_机柜容量',
    'Box_E05-003-01_机柜容量',
    'Box_E05-003-02_机柜容量',
    'Box_E05-003-03_机柜容量',
    'Box_E05-003-04_机柜容量',
    'Box_E05-003-05_机柜容量',
    'Box_E05-003-06_机柜容量',
    'Box_E05-003-07_机柜容量',
    'Box_E05-003-08_机柜容量',
    'Box_E05-003-09_机柜容量',
    'Box_E05-003-10_机柜容量',
    'Box_E05-004-01_机柜容量',
    'Box_E05-004-02_机柜容量',
    'Box_E05-004-03_机柜容量',
    'Box_E05-004-04_机柜容量',
    'Box_E05-004-05_机柜容量',
    'Box_E05-004-06_机柜容量',
    'Box_E05-004-07_机柜容量',
    'Box_E05-004-08_机柜容量',
    'Box_E05-004-09_机柜容量',
    'Box_E05-004-10_机柜容量',
    'Box_C03-001-01_机柜容量',
    'Box_C03-001-02_机柜容量',
    'Box_C03-001-03_机柜容量',
    'Box_C03-001-04_机柜容量',
    'Box_C03-001-05_机柜容量',
    'Box_C03-001-06_机柜容量',
    'Box_C03-001-07_机柜容量',
    'Box_C03-001-08_机柜容量',
    'Box_C03-001-09_机柜容量',
    'Box_C03-001-10_机柜容量副',
    'Box_C03-002-01_机柜容量',
    'Box_C03-002-02_机柜容量',
    'Box_C03-002-03_机柜容量',
    'Box_C03-002-04_机柜容量',
    'Box_C03-002-05_机柜容量',
    'Box_C03-002-06_机柜容量',
    'Box_C03-002-07_机柜容量',
    'Box_C03-002-08_机柜容量',
    'Box_C03-002-09_机柜容量',
    'Box_C03-002-10_机柜容量',
    'Box_C03-003-01_机柜容量',
    'Box_C03-003-02_机柜容量',
    'Box_C03-003-03_机柜容量',
    'Box_C03-003-04_机柜容量',
    'Box_C03-003-05_机柜容量',
    'Box_C03-003-06_机柜容量',
    'Box_C03-003-07_机柜容量',
    'Box_C03-003-08_机柜容量',
    'Box_C03-003-09_机柜容量',
    'Box_C03-003-10_机柜容量',
    'Box_C03-004-01_机柜容量',
    'Box_C03-004-02_机柜容量',
    'Box_C03-004-03_机柜容量',
    'Box_C03-004-04_机柜容量',
    'Box_C03-004-05_机柜容量',
    'Box_C03-004-06_机柜容量',
    'Box_C03-004-07_机柜容量',
    'Box_C03-004-08_机柜容量',
    'Box_C03-004-09_机柜容量',
    'Box_C03-004-10_机柜容量',
    'Box_F06-001-01_机柜容量',
    'Box_F06-001-02_机柜容量',
    'Box_F06-001-03_机柜容量',
    'Box_F06-001-04_机柜容量',
    'Box_F06-001-05_机柜容量',
    'Box_F06-001-06_机柜容量',
    'Box_F06-001-07_机柜容量',
    'Box_F06-001-08_机柜容量',
    'Box_F06-001-09_机柜容量',
    'Box_F06-001-10_机柜容量',
    'Box_F06-002-01_机柜容量',
    'Box_F06-002-02_机柜容量',
    'Box_F06-002-03_机柜容量',
    'Box_F06-002-04_机柜容量',
    'Box_F06-002-05_机柜容量',
    'Box_F06-002-06_机柜容量',
    'Box_F06-002-07_机柜容量',
    'Box_F06-002-08_机柜容量',
    'Box_F06-002-09_机柜容量',
    'Box_F06-002-10_机柜容量',
    'Box_F06-003-01_机柜容量',
    'Box_F06-003-02_机柜容量',
    'Box_F06-003-03_机柜容量',
    'Box_F06-003-04_机柜容量',
    'Box_F06-003-05_机柜容量',
    'Box_F06-003-06_机柜容量',
    'Box_F06-003-07_机柜容量',
    'Box_F06-003-08_机柜容量',
    'Box_F06-003-09_机柜容量',
    'Box_F06-003-10_机柜容量',
    'Box_F06-004-02_机柜容量',
    'Box_F06-004-01_机柜容量',
    'Box_F06-004-03_机柜容量',
    'Box_F06-004-04_机柜容量',
    'Box_F06-004-05_机柜容量',
    'Box_F06-004-06_机柜容量',
    'Box_F06-004-07_机柜容量',
    'Box_F06-004-08_机柜容量',
    'Box_F06-004-09_机柜容量',
    'Box_F06-004-10_机柜容量',
]

/**
 * 机柜组id数组
 * @type {string[]}
 */
export const boxGroupList = [
    'A01-003_SM_机柜组',
    'A01-001_SM_机柜组',
    'B02-001_SM_机柜组',
    'B02-002_SM_机柜组',
    'C03-001_SM_机柜组',
    'C03-002_SM_机柜组',
    'A01-002_SM_机柜组',
    'B02-003_SM_机柜组',
    'C03-003_SM_机柜组',
    'C03-004_SM_机柜组',
    'B02-004_SM_机柜组',
    'A01-004_SM_机柜组',
    'D04-001_SM_机柜组',
    'D04-002_SM_机柜组',
    'D04-003_SM_机柜组',
    'D04-004_SM_机柜组',
    'E05-001_SM_机柜组',
    'E05-002_SM_机柜组',
    'E05-003_SM_机柜组',
    'E05-004_SM_机柜组',
    'F06-001_SM_机柜组',
    'F06-002_SM_机柜组',
    'F06-003_SM_机柜组',
    'F06-004_SM_机柜组',
]
