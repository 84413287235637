<template>
  <ObjectManager v-show="functionStatus == '对象管理'"/>
</template>

<script>
// 对象管理面板
import { mapState } from 'vuex'
import ObjectManager from "@/components/objectManager";
export default {
  components: {
    ObjectManager,
  },
    computed: {
    ...mapState(['functionStatus'])
  },
}
</script>

<style lang="scss" scoped></style>
