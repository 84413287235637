export default [
  {
    id: '中国-数据中心',
    name: '中国-数据中心',
    offset: 90,
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'shujuzhongxin' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '数据中心',
      coordZ: 100000,
      jsonPath: './data/layers/中国/中国-数据中心.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '中国-数据传输',
    name: '中国-数据传输',
    coordType: 0,
    coordTypeZ: 0,
    'valueMax': 10,
    'valueMin': 0,
    'isShowBubble': false,
    'bubbleRadiusMax': 0,
    'bubbleRadiusMin': 0,
    'bubbleSpeed': 0,
    'lineWidthMax': 25000,
    'lineWidthMin': 25000,
    'lineSpeed': 200000,
    'curvature': 0.6,
    legends: [{
      name: 'default',
      type: 'ray',
      'bubbleColor': 'ff0000',
      'lineColor': '#f8d74b',
      'labelColor': '#ffffffff',
      'labelBackgroundColor': '#ffffffff'
    }],
    data: [],
    layerConfig: {
      layerType: 'ODLineLayer',
      scatterType: '数据传输',
      'startCoordZ': '0',
      'targetCoordZ': '0',
      jsonPath: './data/layers/中国/中国-数据传输.json',
      isClick: false,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '中国-告警_数据传输',
    name: '中国-告警_数据传输',
    coordType: 0,
    coordTypeZ: 0,
    visible: true,
    legends: [{
      name: '紧急告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#ff0000',
      fillArea: 'type01',
      speed: 100000,
      radius: 250000
    }, {
      name: '重要告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#FF7F27',
      fillArea: 'type01',
      speed: 50000,
      radius: 200000
    }, {
      name: '次要告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#A69D00',
      fillArea: 'type01',
      speed: 50000,
      radius: 100000
    }, {
      name: '提示告警',
      icon: 'shujuchuanshu',
      type: 'wave',
      color: '#2C71D6',
      fillArea: 'type01',
      speed: 30000,
      radius: 100000
    }],
    layerConfig: {
      layerType: 'eventLayer',
      scatterType: '告警_数据传输',
      'startCoordZ': '20',
      'targetCoordZ': '20',
      jsonPath: './data/layers/中国/中国-告警-数据传输.json',
      isClick: true,
      status: 'none',
      rawData: [],
      jsonData: {}
    }
  }, {
    id: '中国-省份标识',
    name: '中国-省份标识',
    coordType: 0,
    coordTypeZ: 0,
    visible: true,
    offset: 0,
    legends: [{ name: 'default', color: '#ffff', 'iconName': 'shujuzhongxin' }, {
      name: 'shenganhui',
      color: '#ffff',
      'iconName': 'shenganhui'
    }, { name: 'shengaomen', color: '#ffff', 'iconName': 'shengaomen' }, {
      name: 'shengbeijing',
      color: '#ffff',
      'iconName': 'shengbeijing'
    }, { name: 'shengchongqing', color: '#ffff', 'iconName': 'shengchongqing' }, {
      name: 'shengfujian',
      color: '#ffff',
      'iconName': 'shengfujian'
    }, { name: 'shenggansu', color: '#ffff', 'iconName': 'shenggansu' }, {
      name: 'shengguangdong',
      color: '#ffff',
      'iconName': 'shengguangdong'
    }, { name: 'shengguangxi', color: '#ffff', 'iconName': 'shengguangxi' }, {
      name: 'shengguizhou',
      color: '#ffff',
      'iconName': 'shengguizhou'
    }, { name: 'shenghainan', color: '#ffff', 'iconName': 'shenghainan' }, {
      name: 'shenghebei',
      color: '#ffff',
      'iconName': 'shenghebei'
    }, { name: 'shenghenan', color: '#ffff', 'iconName': 'shenghenan' }, {
      name: 'shengheilongjiang',
      color: '#ffff',
      'iconName': 'shengheilongjiang'
    }, { name: 'shenghubei', color: '#ffff', 'iconName': 'shenghubei' }, {
      name: 'shenghunan',
      color: '#ffff',
      'iconName': 'shenghunan'
    }, { name: 'shengjilin', color: '#ffff', 'iconName': 'shengjilin' }, {
      name: 'shengjiangsu',
      color: '#ffff',
      'iconName': 'shengjiangsu'
    }, { name: 'shengliaoning', color: '#ffff', 'iconName': 'shengliaoning' }, {
      name: 'shengjiangxi',
      color: '#ffff',
      'iconName': 'shengjiangxi'
    }, { name: 'shengmeimenggu', color: '#ffff', 'iconName': 'shengmeimenggu' }, {
      name: 'shengningxia',
      color: '#ffff',
      'iconName': 'shengningxia'
    }, { name: 'shengqinghai', color: '#ffff', 'iconName': 'shengqinghai' }, {
      name: 'shengshandong',
      color: '#ffff',
      'iconName': 'shengshandong'
    }, { name: 'shengshanxi', color: '#ffff', 'iconName': 'shengshanxi' }, {
      name: 'shengshanxis',
      color: '#ffff',
      'iconName': 'shengshanxis'
    }, { name: 'shengshanghai', color: '#ffff', 'iconName': 'shengshanghai' }, {
      name: 'shengsichuan',
      color: '#ffff',
      'iconName': 'shengsichuan'
    }, { name: 'shengtaiwan', color: '#ffff', 'iconName': 'shengtaiwan' }, {
      name: 'shengtianjin',
      color: '#ffff',
      'iconName': 'shengtianjin'
    }, { name: 'shengxizang', color: '#ffff', 'iconName': 'shengxizang' }, {
      name: 'shengxianggang',
      color: '#ffff',
      'iconName': 'shengxianggang'
    }, { name: 'shengxinjiang', color: '#ffff', 'iconName': 'shengxinjiang' }, {
      name: 'shengyunnan',
      color: '#ffff',
      'iconName': 'shengyunnan'
    }, { name: 'shengzhejiang', color: '#ffff', 'iconName': 'shengzhejiang' }],
    layerConfig: {
      layerType: 'landmarkLayer',
      scatterType: '省份标识',
      jsonPath: './data/layers/中国/中国-省份标识.json',
      coordZ: -90000,
      isClick: false,
      status: 'none',
      rawData: [],
      jsonData: {},
      clickShowDecorator:false,
      hoverShowDecorator:false,
      clickEventDisable:true
    }
  }
]
