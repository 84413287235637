<template>
  <div class="wraper" :style="{width: cssWidth, height:cssHeight}">
    <div class="gzqaqpfpm_item" v-for="(item, index) in LoopData" :key="index">
      <div class="item_text">{{ item.category }}</div>
      <div class="item_value" :style="{color:ValueColor}">{{ item.value }}</div>
      <div class="item_unit">{{item.unit}}</div>
      <div
        class="star"
        :style="{
          left: (item.rate-0.1)+'%',
          boxShadow:`0px 0px 15px  ${star},0px 0px 30px ${star}, 0px 0px 15px ${star}`,
    
          }"
      ></div>
      <div class="roteBG">
        <div class="rotAnimation" :style="{
              width: item.rate+'%',
          }">
          <div class="animateColor"></div>
          <div
            class="rote rote1"
            :style="{  backgroundImage:`linear-gradient(to right, ${color[0]},  ${color[1]})`}"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarDomChart',
  props: {
    cssWidth: {
      type: String,
      default: '100%'
    },
    cssHeight: {
      type: String,
      default: '400px'
    },
    IndexColor: {
      type: String,
      default: '#189AF9'
    },
    ValueColor: {
      type: String,
      default: '#189AF9'
    },
    rowData: {
      type: Array,
      default: () => {
        return [
          {
            category: '运达',
            value: 901,
            unit: '台'
          },
          {
            category: '明阳',
            value: 250,
            unit: '台'
          },
          {
            category: '海装',
            value: 880,
            unit: '台'
          },
          {
            category: '金风科技',
            value: 82,
            unit: '台'
          },
          {
            category: '南车',
            value: 8123,
            unit: '台'
          }
        ]
      }
    },
    star: {
      type: String,
      default: '#189AF9'
    },
    color: {
      type: Array,
      default: () => {
        return ['#189BFA00', '#189BFA']
      }
    }
  },
  data() {
    return {
      LoopData: []
    }
  },
  mounted() {
    if (this.rowData && this.rowData.length) {
      let arr = []
      let n = 0
      this.rowData.forEach((item, index) => {
        let { category, value, unit, rate } = item
        n += Number(value)
      })
      this.rowData.forEach((item, index) => {
        let { category, value, unit, rate } = item
        arr.push({
          category,
          value,
          unit,
          rate: (value / n) * 100 
        })
      })
      arr.sort((a, b) => {
        return b.value - a.value
      })
      this.LoopData = arr
    }
  },
  watch: {
    rowData: {
      handler() {
        let arr = []
        let n = 0
        this.rowData.forEach((item, index) => {
          let { category, value, unit, rate } = item
          n += Number(value)
        })
        this.rowData.forEach((item, index) => {
          let { category, value, unit, rate } = item
          arr.push({
            category,
            value,
            unit,
            rate: (value / n) * 100 
          })
        })
        arr.sort((a, b) => {
          return b.value - a.value
        })
        this.LoopData = arr
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes move {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.wraper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .gzqaqpfpm_item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    position: relative;

    .item_index {
      width: 40px;
      height: 40px;
      // background: url('../../assets/img/CSZL/gzqaqpfpm_bg.png') no-repeat;
      background-size: cover;
      text-align: center;
      font-family: 'DINCond-Medium';
      font-size: 32px;
      color: #d09b2c;
      line-height: 40px;
    }
    .item_text {
      text-align: left;
      width: 497px;
      font-family: '微软雅黑';
      font-size: 28px;
      color: #fff;
    }
    .item_value {
      font-family: 'Voltage';
      font-size: 48px;
      color: #189af9;
      width: 98px;
      text-align: right;
      margin-right: 8px;
    }
    .item_unit {
      font-family: '微软雅黑';
      font-size: 26px;
      color: #77c5fe;
      margin-left: 5px;
      font-weight: bold;
    }
    .star {
      z-index: 2000;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 10px;
      height: 10px;
      color: #fff;
      background: #fff;
      border-radius: 50%;
    }
    .roteBG {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background: rgba(191, 235, 255, 0.1);
      .rotAnimation {
        height: 100%;
        position: relative;
        .rote {
          height: 12px;
          width: 100%;
          background-image: linear-gradient(to right, #009b61, #00b08e);
          animation: move 1s infinite;
          animation-iteration-count: 1;
        }
      }
    }
    @keyframes move {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
  .unit {
    font-family: 'PingFang';
    color: #3fcbff;
    font-size: 18px;
    line-height: 20px;
    margin-left: 10px;
    transform: translateY(5px);
  }
  .titleclass {
    position: absolute;
    top: 1px;
    left: 120px;
    font-size: 18px;
    font-family: '微软雅黑';
    color: #fff;
    // background: linear-gradient(to bottom, #fff, #C5FBFC);
    // -webkit-background-clip: text;
    // color: transparent;
  }
}
.fontcolor {
  color: #ebcc46 !important;
  margin-top: 15px;
}
.fontcolor1 {
  color: #18e18c !important;
  margin-top: 15px;
}
.fontcolor2 {
  color: #09c4ff !important;
  margin-top: 15px;
}
.fontcolor3 {
  color: #1fefef !important;
  margin-top: 15px;
}
.fontcolor4 {
  color: #1fefef !important;
  margin-top: 15px;
}
</style>