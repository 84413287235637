import BaseModelController from "@/core/model/BaseModelController";

/**
 * 机房内防尘顶/防尘门模型处理
 * @author haowei
 */
export default class FangChenModelController extends BaseModelController {

    modelIdList = ['A01-001_SM_机柜防尘门', 'A01-002_SM_机柜防尘门', '机柜防尘盖副本5',
    'B02-002_SM_机柜防尘门','B02-001_SM_机柜防尘门','机柜防尘盖副本2',
    'C03-002_SM_机柜防尘门','C03-001_SM_机柜防尘门','机柜防尘盖',
    'F06-001_SM_机柜防尘门','F06-002_SM_机柜防尘门','机柜防尘盖副本',
    'E05-001_SM_机柜防尘门','E05-002_SM_机柜防尘门','机柜防尘盖副本3',
    'D04-001_SM_机柜防尘门','D04-002_SM_机柜防尘门','机柜防尘盖副本6',
    'D04-004_SM_机柜防尘门','D04-003_SM_机柜防尘门','机柜防尘盖副本7',
    'E05-004_SM_机柜防尘门','E05-003_SM_机柜防尘门','机柜防尘盖副本4',
    'F06-003_SM_机柜防尘门','F06-004_SM_机柜防尘门','机柜防尘盖副本1']

    getModelIdsList() {
        return this.modelIdList
    }
}
