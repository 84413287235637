<template>
  <!--  -->
  <div class="layout_wraper" v-show="functionStatus == '数据分析'">
    <div class="layout_left">
      <BaseDrawer>
        <div class="nhgl_left">
          <div class="jgsl">
            <MainTitle val="机柜数量" />
            <ul>
              <li v-for="(item,index) in jgslData" :key="index">
                <p>{{item.name}}</p>
                <p>{{item.value}}</p>
                <p>{{item.unit}}</p>
              </li>
            </ul>
          </div>
          <div class="sbsl">
            <MainTitle val="设备数量" />
            <ul>
              <li v-for="(item,index) in sbslData.list" :key="index">
                <p>{{item.name}}</p>
                <p>{{item.value}}</p>
                <p>{{item.unit}}</p>
              </li>
            </ul>
            <div class="head">
              <span></span>
              <span>设备在线情况</span>
            </div>
            <div class="con">
              <div class="left">
                <Water class="charts" />
                <img src="@/assets/img/SBTS/sjbjl.png" class="logo" alt />
                <p>事件办结率</p>
              </div>
              <div class="right">
                <div class="li" v-for="(item,index) in sbslData.data" :key="index">
                  <img :src="require(`@/assets/img/SBTS/img${index}.png`)" alt />
                  <div class="wz">
                    <p>{{item.name}}</p>
                    <p :style="{color:['#4CFFA5','#A7A7FF'][index]}">{{item.value}}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sblxzb">
            <MainTitle val="设备类型占比" />
            <pieChart
              :displayModel="8"
              roseheight="256px"
              :rowData="sblxzbData"
              class="charts"
              :color="['#A68CDB','#17CAA6','#73FFFF','#56A2E7','#1F7ED6']"
              :loop_labelStyle="{

                  left:'-8px',
                  top:'10%'
                }"
              :backImg="{
               url: require('@/assets/img/WLTS/zxpie.png'),
             pos: '54px 68px',
             size: '226px 226px'


              }"
            />
          </div>
        </div>
      </BaseDrawer>
    </div>
    <div class="layout_center"></div>
    <div class="layout_right">
      <BaseDrawer direction="rtl">
        <div class="nhgl_right">
          <div class="gklb">
            <MainTitle val="告警列表" />
            <TableInnerBG
              class="tab"
              :Data="gklbData"
              :TableStyle="{
                 TableHeaderWidth:['144px', '270px', '116px',],
                 TableWidth: '620px',
                  NavHeight: '50px',
                NavlineHeight: '50px',
                NavTop: '0px',
            }"
            />
          </div>
          <div class="hjjc">
            <MainTitle val="环境监测" />
            <ul>
              <li v-for="(item,index) in hjjcData.topData" :key="index">
                <p>{{item.name}}</p>
                <p>{{item.value}}</p>
                <p>{{item.unit}}</p>
              </li>
            </ul>
            <div class="ul">
              <div class="li" v-for="(item,index) in hjjcData.botData" :key="index">
                <p>{{item.name}}</p>
                <p>{{item.value}}</p>
              </div>
            </div>
          </div>
          <div class="nxjc">
            <MainTitle val="能效监测" />
            <div class="con">
              <div class="charts">
                <DeiFenHuan />
                <div class="bg">
                  <p>能耗</p>
                  <p>{{nxjcData.nh}}</p>
                  <p>小时 PUE</p>
                </div>
              </div>
              <div class="ul">
                <div class="li" v-for="(item,index) in nxjcData.list" :key="index">
                  <p>{{item.name}}</p>
                  <p>{{item.value}}</p>
                  <p>{{item.unit}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseDrawer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseDrawer from '@/components/common/BaseDrawer'
import MainTitle from '@/components/common/MainTitle'
import BarChart from '@/components/charts/BarChart'
import pieChart from '@/components/charts/pieChart'
import BarChartV2 from '@/components/charts/BarChartV2'
import BarDomChart from '@/components/charts/BarDomChart'
import LineCharts from '@/components/charts/LineCharts+xuxian'
import Table from '@/components/charts/Table'
import ChartLineArea from '@/components/charts/ChartLineArea'
import DuitieZhutu from '@/components/charts/DuitieZhutu'
import Water from '@/components/charts/Water'
import TableInnerBG from '@/components/charts/TableInnerBG'
import DeiFenHuan from '@/components/charts/DeiFenHuan'
export default {
  name: 'ChengShiZhiLi',
  components: {
    BaseDrawer,
    MainTitle,
    BarChart,
    pieChart,
    BarChartV2,
    BarDomChart,
    LineCharts,
    Table,
    DuitieZhutu,
    ChartLineArea,
    Water,
    TableInnerBG,
    DeiFenHuan
  },
  data() {
    return {
      // 机柜数量
      jgslData: [
        {
          name: '总机柜数',
          value: '250',
          unit: '个'
        },

      ],
      // 设备数量
      sbslData: {
        list: [
          {
            name: '设备总数',
            value: '3845',
            unit: '个'
          },
          {
            name: '在线总数',
            value: '3415',
            unit: '个'
          }
        ],
        data: [
          {
            name: '在线率',
            value: '96'
          },

        ]
      },
      // 设备类型占比
      sblxzbData: [
        { category: '服务器', value: 50 },

      ],
      // 告警列表
      gklbData: [
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        },
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        },
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        },
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        },
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        },
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        },
        {
          first: '服务器',
          second: '2022-07-06 14:09:15',
          third: '温度太高'
        }
      ],
      // 环境监测
      hjjcData: {
        topData: [
          {
            name: '湿度',
            value: '43.2',
            unit: '%'
          },
          {
            name: '温度',
            value: '45',
            unit: '℃'
          }
        ],
        botData: [
          {
            name: '水浸',
            value: '正常'
          },
          {
            name: '烟感',
            value: '正常'
          },

        ]
      },
      // 能效监测
      nxjcData: {
        nh: '1.63',
        list: [
          {
            name: 'IT耗电量',
            value: '112.8',
            unit: 'KWh'
          },

        ]
      }
    }
  },
  computed: {
    ...mapState(['functionStatus'])
  },

  created() {
    // 机柜数量
    this.reqGetjgslData()
    // 设备数量
    this.reqGetsbslData()
    // 设备类型占比
    this.reqGetsblxzbData()
    // 告警列表
    this.reqGetgklbData()
    // 环境监测
    this.reqGethjjcData()
    // 能效监测
    this.reqGetnxjcData()
  },
  beforeDestroy() {},
  methods: {
    // 机柜数量
    async reqGetjgslData() {
      let res = await this.$req.SBTS.reqGetjgslData()
      this.jgslData=res.data
    },
    // 设备数量
    async reqGetsbslData() {
      let res = await this.$req.SBTS.reqGetsbslData()
      this.sbslData=res.data

    },
    // 设备类型占比
    async reqGetsblxzbData() {
      let res = await this.$req.SBTS.reqGetsblxzbData()
      this.sblxzbData=res.data

    },
    // 告警列表
    async reqGetgklbData() {
      let res = await this.$req.SBTS.reqGetgklbData()
      this.gklbData=res.data

    },
    // 环境监测
    async reqGethjjcData() {
      let res = await this.$req.SBTS.reqGethjjcData()
      this.hjjcData=res.data

    },
    // 能效监测
    async reqGetnxjcData() {
      let res = await this.$req.SBTS.reqGetnxjcData()
      this.nxjcData=res.data

    }
  }
}
</script>

<style lang="less" scoped>
.layout_wraper {
  width: 100%;
  height: 100%;
  position: relative;
  .nhgl_left {
    width: 100%;
    height: 100%;
    background: url('@/assets/img/WLTS/left.png') no-repeat;
    box-sizing: border-box;
    text-align: center;
    .jgsl {
      position: relative;
      height: 372px;
      ul {
        margin: 0 auto;
        margin-top: 46px;
        width: 600px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 292px;
          height: 237px;
          background: url('@/assets/img/SBTS/li.png') no-repeat bottom center;
          text-align: center;
          margin-bottom: 20px;
          p:nth-child(1) {
            line-height: 64px;
            font-weight: bold;
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
            text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
            // text-shadow: 0px 10px 10px red;
          }
          p:nth-child(2) {
            line-height: 90px;
            font-family: 'Voltage';
            font-size: 60px;
            color: #fff;
          }
          p:nth-child(3) {
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
          }
        }
      }
    }
    .sbsl {
      height: 800px;
      ul {
        margin: 0 auto;
        margin-top: 24px;
        width: 640px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 290px;
          height: 184px;
          background: url('@/assets/img/ZYTS/liBG.png') no-repeat bottom center;
          text-align: center;
          margin-bottom: 20px;
          p:nth-child(1) {
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
            text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
            // text-shadow: 0px 10px 10px red;
          }
          p:nth-child(2) {
            font-family: 'Voltage';
            font-size: 60px;
            color: #fff;
          }
          p:nth-child(3) {
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
          }
        }
      }
      .head {
        margin-top: 30px;
        margin-left: 40px;
        align-items: center;
        display: flex;
        font-family: '微软雅黑';
        font-size: 30px;
        color: #fff;
        text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
        span:nth-child(1) {
          width: 0;
          height: 0;
          border-left: 10px solid white;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid transparent;
          box-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
        }
        span:nth-child(2) {
        }
      }
      .con {
        width: 600px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: 42px;
        .left {
          text-align: center;
          position: relative;
          width: 292px;
          height: 313px;
          background: url('@/assets/img/SBTS/waterBG.png') no-repeat;
          .charts {
            position: absolute;
            left: 57px;
            top: -24px;
          }
          .logo {
            position: absolute;
            left: 50%;
            top: 32%;
            transform: translate(-50%, -50%);
          }
          p {
            line-height: 495px;
            font-family: '微软雅黑';
            font-size: 30px;
            color: #fff;
          }
        }
        .right {
          width: 299px;
          height: 326px;
          background: url('@/assets/img/SBTS/war.png') no-repeat;
          .li {
            margin-left: 48px;
            width: 320px;
            display: flex;
            margin-bottom: 40px;
            img {
              width: 91px;
              height: 91px;
              margin-right: 8px;
            }
            .wz {
              text-align: left;
              p:nth-child(1) {
                font-family: '微软雅黑';
                font-size: 28px;
                color: #fff;
                text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
                // text-shadow: 0px 10px 10px red;
              }
              p:nth-child(2) {
                font-family: 'Voltage';
                font-size: 40px;
                color: #189af9;
              }
            }
          }
          .li:nth-child(1) {
            margin-top: 46px;
          }
        }
      }
    }
    .sblxzb {
      height: 486px;
      position: relative;
      .charts {
        transform: translate(0%, -5%);
      }
    }
  }
  .nhgl_right {
    width: 100%;
    height: 100%;
    background: url('@/assets/img/WLTS/left.png') no-repeat;
    box-sizing: border-box;
    text-align: center;
    .gklb {
      height: 634px;
      position: relative;
      .tab {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
      }
    }
    .hjjc {
      height: 548px;
      position: relative;
      ul {
        margin: 0 auto;
        margin-top: 20px;
        width: 600px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 292px;
          height: 237px;
          background: url('@/assets/img/SBTS/li.png') no-repeat bottom center;
          text-align: center;
          margin-bottom: 20px;
          p:nth-child(1) {
            line-height: 64px;
            font-weight: bold;
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
            text-shadow: 0px 0px 30px rgba(0, 128, 255, 0.15);
            // text-shadow: 0px 10px 10px red;
          }
          p:nth-child(2) {
            line-height: 90px;
            font-family: 'Voltage';
            font-size: 60px;
            color: #fff;
          }
          p:nth-child(3) {
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
          }
        }
      }
      .ul {
        margin: 0 auto;
        width: 620px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .li {
          width: 200px;
          height: 172px;
          background: url('@/assets/img/SBTS/lijiao.png') no-repeat bottom
            center;
          text-align: center;
          p:nth-child(1) {
            font-weight: bold;
            font-family: '微软雅黑';
            font-size: 28px;
            color: #fff;
            margin-bottom: 30px;
            // text-shadow: 0px 10px 10px red;
          }
          p:nth-child(2) {
            font-family: '微软雅黑';
            font-size: 40px;
            color: #26ffff;
          }
        }
      }
    }
    .nxjc {
      height: 468px;
      position: relative;
      .con {
        width: 620px;
        display: flex;
        margin: 0 auto;
        margin-top: 54px;
        justify-content: space-between;
        .charts {
          width: 300px;
          position: relative;
          .bg {
            position: absolute;
            left: 50%;
            top: 61%;
            transform: translate(-50%, -50%);
            width: 233px;
            height: 233px;
            font-family: '微软雅黑';
            p:nth-child(1) {
              color: #e5ffff;
              font-size: 28px;
            }
            p:nth-child(2) {
              color: #04dee7;
              font-size: 64px;
              font-family: 'Voltage';
            }
            p:nth-child(3) {
              color: #e5ffff;
              font-size: 28px;
            }
          }
        }
        .ul {
          width: 324px;
          height: 312px;
          background: url('@/assets/img/SBTS/dfhBG.png') no-repeat;
          .li:nth-child(1) {
            margin-top: 6px;
          }
          .li {
            display: flex;
            align-items: center;
            margin-bottom: 28px;
            margin-left: 26px;
            width: 298px;
            height: 80px;
            font-family: '微软雅黑';
            background: url('@/assets/img/SBTS/dfhBGli.png') no-repeat;

            p:nth-child(1) {
              color: #fff;
              font-size: 28px;
              width: 160px;
              text-indent: 16px;
              text-align: left;
            }
            p:nth-child(2) {
              margin-right: 7px;
              color: #ffff;
              font-size: 48px;
              font-family: 'Voltage';
            }
            p:nth-child(3) {
              color: #77c5fe;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
