/*
 * @Author: yejinliang
 * @Date: 2022-05-27 00:24:38
 * @LastEditTime: 2022-05-27 02:16:59
 * @LastEditors: yejinliang
 * @Description: 3维标牌弹窗 vue扩展
 */
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import UEapis from '@/utils/UEapi/UEapi';
export default class PanelController {
  static domInstance = null;
  static create(domId, com, info, changjing2, type, opts = {}) {
    // 如果有实例了先销毁
    if (changjing2 != 1) {
      PanelController.destroy();
    }
    const instance = Vue.extend(com);
    PanelController.domInstance = new instance({
      ...opts,
      store,
      router
    }).$mount();
    PanelController.domType = type;
    PanelController.domInstance.$el.id = domId;
    PanelController.domInstance.$el.style.position = 'absolute';
    PanelController.domInstance.$el.style.left = '0px';
    document.body.append(PanelController.domInstance.$el);
    // 请求数据 拿到数据之后给对应的props绑定
    // panelControl.domInstance.$props['rightList'||'listvalue'] = info.papanls;
    PanelController.domInstance.$props.info = info;
    // 监听组件$emit的事件
    PanelController.domInstance.$on('onClose', () => {
      if (changjing2 != 1) {
        UEapis.OverlayTip.removeOverLayTip();
        PanelController.destroy();
        if (PanelController.domType == 'model') {
          let modelTip = store.state.modelTip;

          window.appInstance.uniCall('removeModelTip', {
            modelTip
          });
          window.appInstance.uniCall('clearModelSelected', {});
        }
      }
    });
    return PanelController.domInstance.$el;
  }
  static destroy() {
    if (PanelController.domInstance) {
      PanelController.domInstance.$destroy && PanelController.domInstance.$destroy();
      PanelController.domInstance.$el &&
        PanelController.domInstance.$el.parentNode &&
        PanelController.domInstance.$el.parentNode.removeChild(
          PanelController.domInstance.$el
        );
    }
    PanelController.domInstance = null;
  }
}
