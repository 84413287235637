import BaseLayerController from './BaseLayerController'
import Store from '@/store'
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 事件图图层控制器
 *
 * @author haowei
 */
export default class EventLayerController extends BaseLayerController {

  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data;
    layerMap.layerConfig.status = 'hide';
    await UEapi.EventLayer.addEventLayer(data);
  }

  async getData(layerMap) {
    let rowData = await super.getRowData(layerMap)
    layerMap.layerConfig.rawData = rowData;
    const { spaceLevel } = Store.state

    let defaultOption = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      visible: true,// todo 其他地方都是false，这里必须是true，否则事件图层覆盖物不显示
      legends: [],
      data: [],
    }
    let jsonData = Object.assign({}, defaultOption, layerMap)
    jsonData.data = rowData.map((item) => {
      return {
        id: item.id,
        coord: [item.lon, item.lat],
        coordZ: item.coordZ || BaseLayerController.coordZList[spaceLevel] || 1,
        message: '',
        type: item.category,
      }
    })
    return jsonData
  }

}
