<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse title="基础信息" @changeisReceiveFair="isReceiveFair" :isReceiveFair="jcxxIsShow">
            <Tag
              v-for="(item,index) in dianbiaoshebeijcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="ztxx">
          <Collapse
            title="状态信息"
            :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="ztxxIsShow"
          >
            <Tag
              v-for="(item,index) in dianbiaoshebeiztxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="gjxx">
          <Collapse
            title="告警信息"
            :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table
              :CData="dianbiaoshebeigjxx"
              :NavData="['时间 ','内容 ']"
              :NavWidth="['308px','300px','0px']"
            />
          </Collapse>
        </div>
        <div class="lssj">
          <Collapse
            title="历史数据"
            :iconUrl="require('@/assets/img/SSGJ/lssjIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="lssjIsShow"
            :isywxt="true"
          >
            <Tag :name="'近7日数据监测分析'" :value="''" />
            <ChartLineArea
              class="charts"
              :rowData="JservenSJJCFX"
              left="3%"
              right="5%"
              unit="kwh"
              height="384px"
              :barColor="['#4DA6FF','#4DA6FF00']"
            />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea
  },
  data() {
    return {
      dianbiaoshebeijcxx: [
        {
          name: '编号',
          value: 'G5-F8-DBSB-001'
        },
        {
          name: '所属',
          value: 'G5-F8-DBSB'
        },
        {
          name: '型号',
          value: 'SZ-SBSB-001'
        }
      ],
      dianbiaoshebeiztxx: [
        {
          name: '设备状态',
          value: '在线'
        }
      ],
      dianbiaoshebeigjxx: [
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' },
        { time: '2022-07-11 12:00:00', content: '用量超标' },
        { time: '2022-07-11 12:00:00', content: '设备故障告警' }
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true,
      JservenSJJCFX: [
        {
          name: '07-01',
          value: 74
        },
        {
          name: '07-02',
          value: 40
        },
        {
          name: '07-03',
          value: 43
        },
        {
          name: '07-04',
          value: 70
        },
        {
          name: '07-05',
          value: 42
        },
        {
          name: '07-06',
          value: 43
        },
        {
          name: '07-07',
          value: 40
        }
      ]
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo'])
  },
  mounted() {
    this.dianbiaoshebeijcxx[0].value = this.objectInfo.name
  },
  watch: {
    objectInfo: {
      handler(val) {
        this.dianbiaoshebeijcxx[0].value = val.name
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  overflow-y: auto;
  overflow-x: hidden;
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
          }
        }
      }
    }
  }
  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0px;
  border-radius: 0px;
  background: rgba(17, 63, 89, 0.65);
}
::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 0px;
  background: rgba(255, 255, 255, 0.15);
  min-height: 00px;
}
</style>
