import China from '@/config/layerListModule/l1/china'
import Beijing from '@/config/layerListModule/l2/beijing'
import Park from '@/config/layerListModule/l3/park'
import Floor from '@/config/layerListModule/l3/floor'
import Room from "@/config/layerListModule/l3/room";

export default [
    ...China,
    ...Beijing,
    ...Park,
    ...Floor,
    ...Room,
    {
        id: '设备-空间使用',
        name: '设备-空间使用',
        legends: [
            {
                name: '高度颜色变换',
            },
            {
                name: '颜色变换',
            },
        ],
        layerConfig: {
            layerType: 'capacityLayer',
            scatterType: '空间使用',
            jsonPath: './data/layers/空间使用.json',
            showType: '颜色变换', // 展示类型
            maxValue: 100, // 数据最大值
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    }, {
        id: '设备-承重使用',
        name: '设备-承重使用',
        legends: [
            {
                name: '高度颜色变换',
            },
            {
                name: '颜色变换',
            },
        ],
        layerConfig: {
            layerType: 'capacityLayer',
            scatterType: '承重使用',
            jsonPath: './data/layers/承重使用.json',
            showType: '颜色变换', // 展示类型
            maxValue: 100, // 数据最大值
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    }, {
        id: '设备-功耗使用',
        name: '设备-功耗使用',
        legends: [
            {
                name: '高度颜色变换',
            },
            {
                name: '颜色变换',
            },
        ],
        layerConfig: {
            layerType: 'capacityLayer',
            scatterType: '功耗使用',
            jsonPath: './data/layers/功耗使用.json',
            showType: '高度颜色变换', // 展示类型
            maxValue: 100, // 数据最大值
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '设备-链路集合A1-S1_A2-S2',
        name: '设备-链路集合A1-S1_A2-S2',
        coordType: 0,
        coordTypeZ: 0,
        type: 'Arrow03',
        texture: '',
        textureSpeed: 1,
        color: '#3ae83c',
        colorPass: '#0000FF',
        width: 4,
        autoScale: false,
        data: [],
        points: [],
        layerConfig: {
            layerType: 'linkLayer', // 图层类型，必要
            scatterType: '链路设备链路', // 之前用于分割数据的，目前没有用到
            jsonPath: './data/layers/链路/链路集合A1-S1_A2-S2.json', // 此处如果是静态json可以填静态路径，如果是后端接口，也可以写接口路径，但注意返回格式
            isClick: true,
            status: 'none', // 显示状态 none show hide
            rawData: [], // 原始数据
            jsonData: {}, // 添加时候的数据
        },
    },
    {
        id: '设备-链路集合A1-S2_A1-S3',
        name: '设备-链路集合A1-S2_A1-S3',
        coordType: 0,
        coordTypeZ: 0,
        type: 'Arrow03',
        texture: '',
        textureSpeed: 1,
        color: '#3ae83c',
        colorPass: '#0000FF',
        width: 4,
        autoScale: false,
        data: [],
        points: [],
        layerConfig: {
            layerType: 'linkLayer', // 图层类型，必要
            scatterType: '链路设备链路', // 之前用于分割数据的，目前没有用到
            jsonPath: './data/layers/链路/链路集合A1-S2_A1-S3.json', // 此处如果是静态json可以填静态路径，如果是后端接口，也可以写接口路径，但注意返回格式
            isClick: true,
            status: 'none', // 显示状态 none show hide
            rawData: [], // 原始数据
            jsonData: {}, // 添加时候的数据
        },
    },
]
