<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: guozhiqing
 * @Date: 2021-06-21 15:21:13
 * @LastEditors: guozhiqing
 * @LastEditTime: 2021-06-23 14:07:10
-->
<template>
  <div class="clock">
    <div class="lefttime" v-cloak>{{ time }}</div>
    <div class="date">
      <div class="day" v-cloak>{{ day }}</div>
      <!-- <div class="week" v-cloak>{{ week }}</div> -->
    </div>
  </div>
</template>
<script>
import Time from '@/utils/common/time'
export default {
  data() {
    return {
      timer: null,
      now: null,
      day: null,
      time: null,
      week: null
    }
  },
  mounted() {
    this.initDay()
    this.timer = setInterval(() => {
      this.initDay()
    }, 1000)
  },
  methods: {
    initDay() {
      this.now = new Date()
      this.week = Time.getWeek(this.now)
      this.day = Time.format('yyyy/MM/dd', this.now)
      this.time = Time.format('HH:mm:ss', this.now)
    }
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
.clock {
  width: 200px;
  height: 40px;
  position: relative;
  .lefttime {
    width: 200px;
    font-family: 'Arial';
    font-size: 38px;
    color: #ffffff;
    text-align: center;
    margin-top: 14px;
  }
  .date {
    width: 200px;
    .day {
      text-align: center;
      font-family: '微软雅黑';
      font-size: 26px;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
.clock::after {
  content: '';
  display: block;
  width: 2px;
  height: 60px;
  position: absolute;
  top: 20px;
  right: 0px;
  background-color: #ffffff25;
}
</style>
