// import gisDataManager from '../../api/gisDataManager';

export default class Agg3DColumnLayer {
  static defaultConfig = {
    id: 'agg', // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
    name: 'layerName', // 图层名称，支持为图层自定义名称
    coordZ: 500, // 聚合结果图层的显示 Z 轴高度（单位：米）
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    aggType: 2, // 聚簇方法：0-就近聚合 ，1-行政区划聚合, 2-栅格聚簇
    aggRadius: 103, // 将点就近聚簇的范围半径（单位：像素）
    drawMax: 100000, // 最近距离点的绘制最大数量
    minViewDistance: 100, // 点图标最小可见距离，（单位：米）
    maxViewDistance: 2000000, // 点图标最大可见距离，（单位：米）
    aggDataId: 'aggData', // 对应本服务器上的聚合数据 id
    columnType: 'cube', // 柱体外观类别，cube：方柱；cylinder：圆柱
    columnMinHeight: 10, // 单柱体表达最小值的实际高度（单位：米）
    columnMaxHeight: 5000, // 单柱体表达最大值的实际高度（单位：米）
    columnAlpha: 1, // 柱体透明度，0：完全透明；1：完全不透明
    columnPaint: 'solid', // 柱体样式类别，solid：单色着色；linear：线性着色
    columnWidth: 300, // 柱体宽度（单位：米）
    labelColor: '#ffffff', // 标签文本颜色，可选值，默认白色
    labelBackgroundColor: '#33333300', // 标签文本背景颜色，可选值，默认灰色
  }
  static async addPointData(layer) {
    let data = await gisDataManager.getAgg3DColumnLayerData(layer);
    let defaultConfig = {
      id: 'aggData' || layer.aggDataId,
      coordZ: layer.layerConfig.coordZ || 200,
      data: [],
    };
    defaultConfig.data = data;
    // console.log('gzq', defaultConfig);
    window.appInstance.uniCall('addPointData', defaultConfig, res => {
      // console.log('添加聚簇柱图数据' + layer.id, res);
      this.addAgg3DColumnLayer(layer)
    })
  }
  static async addAgg3DColumnLayer(layer) {
    let defaultConfig = {
      id: 'agg', // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
      name: 'layerName', // 图层名称，支持为图层自定义名称
      coordZ: 500, // 聚合结果图层的显示 Z 轴高度（单位：米）
      coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
      aggType: 2, // 聚簇方法：0-就近聚合 ，1-行政区划聚合, 2-栅格聚簇
      aggRadius: 103, // 将点就近聚簇的范围半径（单位：像素）
      drawMax: 100000, // 最近距离点的绘制最大数量
      minViewDistance: 100, // 点图标最小可见距离，（单位：米）
      maxViewDistance: 2000000, // 点图标最大可见距离，（单位：米）
      aggDataId: 'aggData', // 对应本服务器上的聚合数据 id
      columnType: 'cube', // 柱体外观类别，cube：方柱；cylinder：圆柱
      columnMinHeight: 4000, // 单柱体表达最小值的实际高度（单位：米）
      columnMaxHeight: 5000, // 单柱体表达最大值的实际高度（单位：米）
      columnAlpha: 1, // 柱体透明度，0：完全透明；1：完全不透明
      columnPaint: 'solid', // 柱体样式类别，solid：单色着色；linear：线性着色
      columnWidth: 100, // 柱体宽度（单位：米）
      labelColor: '#ffffff', // 标签文本颜色，可选值，默认白色
      labelBackgroundColor: '#333333', // 标签文本背景颜色，可选值，默认灰色
      clusters: [
        {
          value_range_min: 0, // 聚簇结果范围最小值（包含）
          value_range_max: 100, // 聚簇结果范围最大值（包含）
          colorMax: '#ff0000', // 最大值上限颜色（HEX 颜色值）
          colorMin: '#ffff00', // 最小值下限颜色（HEX 颜色值）
        },
        {
          value_range_min: 101, // 聚簇结果范围最小值（包含）
          value_range_max: 500, // 聚簇结果范围最大值（包含）
          colorMax: '#ff00ff', // 最大值上限颜色（HEX 颜色值）
          colorMin: '#ffff0f', // 最小值下限颜色（HEX 颜色值）
        },
        {
          value_range_min: 501, // 聚簇结果范围最小值（包含）
          value_range_max: 7000, // 聚簇结果范围最大值（包含）
          colorMax: '#ffffff', // 最大值上限颜色（HEX 颜色值）
          colorMin: '#00fff0', // 最小值下限颜色（HEX 颜色值）
        },
      ],
    };
    let resConfig = Object.assign({}, defaultConfig, layer);
    // console.log('gzq', resConfig);
    delete resConfig.layerConfig;
    delete resConfig.status;
    window.appInstance.uniCall('addAgg3DColumnLayer', resConfig, res => {
      // console.log('添加聚簇柱图' + layer.id, res);
    })
  }

}
