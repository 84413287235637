<template>
  <div class="TagButton" @click="runHole">
    <div class="TagButton_image">
      <img :src="iconUrl" alt />
    </div>
    <div class="TagButton_text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    iconUrl: {
      type: String,
      default: require('../../../assets/img/TagButton/XZZBJS.png')
    },
    text: {
      type: String,
      default: '下钻至北京市'
    }
  },

  methods: {
    runHole() {
      this.$emit('runInHole', this.text)
    }
  }
}
</script>

<style lang="less" scoped>
.TagButton {
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: #189bfa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
   margin-bottom: 20px;
  .TagButton_image {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .TagButton_text {
    margin-left: 10px;
    font-size: 28px;
    font-family: '微软雅黑';
    color: #ffffff;
    font-weight: bold;

  }
}
</style>
