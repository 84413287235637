/**
 * 带标牌的地标图层控制器
 * @author haowei
 */
import UEapi from '@/utils/UEapi/UEapi'
import BaseTipLayerController from '@/core/layer/BaseTipLayerController'

/**
 * 带标牌的地标图层控制器
 * @author haowei
 */
export default class TipLandmarkLayerController extends BaseTipLayerController {

  constructor() {
    super()
    this.layerType = 'landmarkLayer'
  }

  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data
    await UEapi.LandmarkLayer.addLandmarkLayer(data)
  }
}
