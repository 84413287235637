var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loop"},[_c('div',{staticClass:"container",style:({ alignItems: _vm.containerFlex, flexDirection: _vm.flexDirection })},[_c('div',{ref:"loop",style:({ width: `${_vm.width}`, height: `${_vm.height}` })}),_c('div',{staticClass:"loop_label",style:({
          width:_vm.width,
          height: _vm.height,
          left: _vm.loop_labelStyle.left,
          top: _vm.loop_labelStyle.top,

          backgroundImage: `url(${_vm.backImg.url})`,
          backgroundPosition: _vm.backImg.pos,
          backgroundSize: _vm.backImg.size
        })},[(0)?_c('div',{staticClass:"text",style:({
            fontSize: _vm.loop_labelStyle.wzSize,
            marginTop: _vm.marginTop,
            lineHeight: _vm.loop_labelStyle.lineHeight
          }),domProps:{"textContent":_vm._s(_vm.selectedSeries.category)}}):_vm._e(),(0)?_c('div',{staticClass:"num",style:({ fontSize: _vm.loop_labelStyle.numSize }),domProps:{"textContent":_vm._s(_vm.selectedSeries.value)}}):_vm._e()]),(_vm.displayModel === 3)?_c('div',{staticClass:"rose_legend",style:({ height: _vm.roseheight })},_vm._l((_vm.loopData),function(item,index){return _c('div',{key:index,staticClass:"legend",style:({ paddingBottom: _vm.paddingBottom })},[(0)?_c('div',{staticClass:"legend_color",style:({ backgroundColor: _vm.color[index] })}):_vm._e(),_c('div',{staticClass:"legend_progress",style:({
             backgroundImage:`linear-gradient(to right, ${_vm.color[0]}00,  ${_vm.color[index]})`,
              width: 'calc(' + item.num + ' - 0px)'
            })}),_c('div',{staticClass:"legend_name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"legend_bar"}),_c('div',{staticClass:"legend_value",style:({ right: _vm.legendsStyle.valueright })},[_vm._v(_vm._s(item.num))]),(0)?_c('div',{staticClass:"legend_num",style:({ right: _vm.legendsStyle.right, color: '#E9E9E9' })},[_vm._v(_vm._s(item.value))]):_vm._e(),(0)?_c('div',{staticClass:"legend_unit",style:({ right: _vm.legendsStyle.unitright })},[_vm._v(_vm._s(_vm.legthunit))]):_vm._e()])}),0):_vm._e(),(_vm.displayModel === 4)?_c('div',{staticClass:"botrose_legend"},_vm._l((_vm.loopData),function(item,index){return _c('div',{key:index,staticClass:"legend",style:({ paddingBottom: _vm.paddingBottom })},[_c('div',{staticClass:"legend_color",style:({ backgroundColor: _vm.color[index] })}),(0)?_c('div',{staticClass:"legend_progress",style:({
              backgroundColor: _vm.color[index],
              width: 'calc(' + item.num + ' - 0px)'
            })}):_vm._e(),_c('div',{staticClass:"legend_name"},[_vm._v(_vm._s(item.name))]),(0)?_c('div',{staticClass:"legend_value",style:({ color: _vm.color[index], display: _vm.legendsStyle.display })},[_vm._v(_vm._s(item.num))]):_vm._e(),_c('div',{staticClass:"legend_num",style:({ right: _vm.legendsStyle.right, color: _vm.color[index] })},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"legend_unit",style:({ right: _vm.legendsStyle.unitright })},[_vm._v(_vm._s(_vm.legthunit))])])}),0):_vm._e(),(_vm.displayModel === 5)?_c('div',{staticClass:"progrerose_legend",style:({ height: _vm.roseheight })},_vm._l((_vm.loopData),function(item,index){return _c('div',{key:index,staticClass:"legend",style:({ paddingBottom: _vm.paddingBottom })},[(0)?_c('div',{staticClass:"legend_progress",style:({
              backgroundColor: _vm.color[index],
              width: 'calc(' + item.num + ' - 0px)'
            })}):_vm._e(),_c('div',{staticClass:"top"},[_c('div',{staticClass:"legend_color",style:({ backgroundColor: _vm.color[index] })}),_c('div',{staticClass:"legend_name"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"bot"},[_c('div',{staticClass:"legend_value",style:({ right: _vm.legendsStyle.valueright })},[_vm._v(_vm._s(item.num))]),_c('div',{staticClass:"legend_num",style:({ right: _vm.legendsStyle.right, color: _vm.color[index] })},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"legend_unit",style:({ right: _vm.legendsStyle.unitright })},[_vm._v(_vm._s(_vm.legthunit))])])])}),0):_vm._e(),(_vm.displayModel === 6)?_c('div',{staticClass:"rectrose_legend",style:({ height: _vm.roseheight })},_vm._l((_vm.loopData),function(item,index){return _c('div',{key:index,staticClass:"legend",style:({ paddingBottom: _vm.paddingBottom })},[_c('div',{staticClass:"legend_color",style:({ backgroundColor: _vm.color[index] })}),_c('div',{staticClass:"legend_name"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e(),(_vm.displayModel === 7)?_c('div',{staticClass:"rectbottomrose_legend",style:({ height: _vm.roseheight })},_vm._l((_vm.loopData),function(item,index){return _c('div',{key:index,staticClass:"legend",style:({ paddingBottom: _vm.paddingBottom })},[_c('div',{staticClass:"legend_color",style:({ backgroundColor: _vm.color[index] })}),_c('div',{staticClass:"legend_name"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e(),(_vm.displayModel === 8)?_c('div',{staticClass:"rose_legendValue",style:({ height: _vm.roseheight })},_vm._l((_vm.loopData),function(item,index){return _c('div',{key:index,staticClass:"legend",style:({ paddingBottom: _vm.paddingBottom })},[(0)?_c('div',{staticClass:"legend_color",style:({ backgroundColor: _vm.color[index] })}):_vm._e(),_c('div',{staticClass:"legend_progress",style:({
             backgroundImage:`linear-gradient(to right, ${_vm.color[0]}00,  ${_vm.color[index]})`,
              width: 'calc(' + item.num + ' - 0px)'
            })}),_c('div',{staticClass:"legend_name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"legend_bar"}),(0)?_c('div',{staticClass:"legend_value",style:({ right: _vm.legendsStyle.valueright })},[_vm._v(_vm._s(item.num))]):_vm._e(),_c('div',{staticClass:"legend_num",style:({ right: _vm.legendsStyle.right, color: '#E9E9E9' })},[_vm._v(_vm._s(item.value))]),(0)?_c('div',{staticClass:"legend_unit",style:({ right: _vm.legendsStyle.unitright })},[_vm._v(_vm._s(_vm.legthunit))]):_vm._e()])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }