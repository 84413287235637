import BaseHandler from "@/core/layer/handler/BaseHandler";
import Store from "@/store";

/**
 * 告警图层处理
 * @author haowei
 */
export default class WarningHandler extends BaseHandler{

    handleClick({id, idLayer}) {
        Store.commit('setRightType','SheBeiGaoJing')
        Store.commit('setObjectInfo',{name:id})
    }

    handleHover({id, idLayer}) {

    }

    filterLayer({id, idLayer}) {
        return idLayer.includes('告警');
    }
}
