import BaseCameraController from '@/core/camera/BaseCameraController'
import Store from '@/store'
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 默认图层镜头控制器
 * @author haowei
 */
export default class DefaultLayerCameraController extends BaseCameraController {

  cameraInfo = {}

  async setCamera(params) {
    // 去cameraList查看是否存在相机数据，如不存在就默认为聚焦图层点位
    // const cameraInfoStatic = CameraList[params.id]
    // if (cameraInfoStatic){
    //   this.cameraInfo = cameraInfoStatic
    //   this.cameraInfo.type = super.TYPE_CAMERA
    //   return await UEapi.Camera.setCamera(cameraInfoStatic)
    // }
    let layerMap = window.layerManager.getLayerMap(params.idLayer)
    let json = {
      id: params.id,
      idLayer: params.idLayer,
      layerType: layerMap.layerConfig.layerType,
      distance: params.distance ? params.distance : super.getNowFocusDistance()
    }
    this.cameraInfo = json
    this.cameraInfo.type = super.TYPE_FOCUS
    await UEapi.Camera.focusByLayer(json)
    // this.focusCameraReverse(params.id,params.idLayer,layerMap);
  }

  getNowCameraInfo() {
    super.getNowCameraInfo()
  }

}
