<template>
  <div class="legend_wraper">
    <div class="legend_list" v-for="(item, index) of legends" :key="index">
      <div
        class="top_text"
        @click="showOption(index)"
        :class="item.highlight ? 'top_text_ac' : ''"
      >
        <div>
          <img v-if="item.highlight" :src="item.img + 'ac.png'" alt />
          <img v-else :src="item.img + '.png'" alt />
        </div>
        <div>{{ item.type }}</div>
        <div>
          <img src="/img/legend/scanner.png" alt />
        </div>
      </div>
      <div class="item_cont" v-if="optionIndex == index">
        <div
          class="item"
          v-for="(ele, sub) in item.children"
          @click.prevent="legendClick(ele, item)"
          :key="sub"
          :class="ele.highlight ? 'item_ac' : ''"
        >
          <div class="item_img">
            <!-- <img v-if="ele.highlight" :src="ele.img + 'ac.png'" alt />
            <img v-else :src="ele.img + '.png'" alt /> -->

            <img :src="ele.img + 'ac.png'" alt />
          </div>
          <div class="item_text">{{ ele.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'legend_wraper',
  props: {
    legends: {
      type: Array,
      default: () => {
        return [
          {
            type: '安防',
            img: '/img/legend/af',
            highlight: false,
            children: [
              {
                text: '安防',
                img: '/img/legend/af',
                highlight: false,
              },
              {
                text: '安防',
                img: '/img/legend/af',
                highlight: false,
              },
              {
                text: '安防',
                img: '/img/legend/af',
                highlight: false,
              },
              {
                text: '安防',
                img: '/img/legend/af',
                highlight: false,
              },
            ],
          },
          {
            type: '告警',
            img: '/img/legend/gaojing.png',
            highlight: false,
            children: [
              {
                text: '告警',
                img: '/img/legend/af',
                highlight: false,
              },
              {
                text: '告警',
                img: '/img/legend/af',
                highlight: false,
              },
              {
                text: '告警',
                img: '/img/legend/af',
                highlight: false,
              },
              {
                text: '告警',
                img: '/img/legend/af',
                highlight: false,
              },
            ],
          },
          {
            type: '人员',
            img: '/img/legend/ry',
            highlight: false,
            children: [
              {
                text: '人员',
                highlight: false,
              },
              {
                text: '人员',
                highlight: false,
              },
              {
                text: '人员',
                highlight: false,
              },
              {
                text: '人员',
                highlight: false,
              },
            ],
          },
        ]
      },
    },
  },
  data() {
    return {
      optionIndex: -1,
    }
  },
  methods: {
    changeSelected() {},
    showOption(index) {
      if (index == this.optionIndex) {
        this.optionIndex = -1
      } else {
        this.optionIndex = index
      }
    },
    legendClick(ele, item) {
      if (item.type == '实体对象') {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].text !== ele.text) {
            item.children[i].highlight = false
          } else {
            item.children[i].highlight = !item.children[i].highlight
            this.$store.commit(
              'setenergyConsumption',
              item.children[i].highlight ? ele.text : ''
            )
            this.$emit('legendClick', ele)
          }
        }
        return
      }
      // 单选时的情况
      if (ele.radio) {
        item.children?.forEach((ite) => {
          if (ite.text !== ele.text) {
            this.$set(ite, 'highlight', false)
          }
        })
      }
      ele.highlight = !ele.highlight
      this.$emit('legendClick', ele)
      if (ele.highlight) {
        item.highlight = true
      } else {
        item.highlight = false
        item.children.filter((i) => {
          if (i.highlight) {
            item.highlight = true
          }
        })
      }
    },
    /**
     * @description:
     * @param {*} legend 图例模块名称
     * @param {*} children 子图例名称
     * @return {*}
     */

    highLight(legend, children) {
      const currentLegend = this.legends.find((item) => item.type == legend)
      children.forEach((cname) => {
        currentLegend.children.forEach((item) => {
          if (cname == item.text) {
            item.highlight = true
          }
        })
      })
    },
  },
  created() {
    console.log('legends', this.legends)
  },
  computed: {
    ...mapState(['selectLegends', 'functionStatus', 'spaceLevel']),
  },
  watch: {
    $route: {
      handler: function (to, from) {
        this.optionIndex = -1
      },
      deep: true,
    },
    functionStatus: {
      handler: function (to, from) {
        this.optionIndex = -1
      },
      deep: true,
    },
    spaceLevel: {
      handler: function (to, from) {
        this.optionIndex = -1
      },
      deep: true,
    },
    legends: {
      handler: function (to, from) {
        console.log('legends', to)
      },
      deep: true,
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.legend_wraper {
  display: flex;
  height: 100px;
  align-items: center;
  padding-right: 40px;
  .legend_list {
    cursor: pointer;
    width: 240px;
    height: 60px;
    background: #001e3c;
    border: 1px solid #a5c0eb40;
    border-radius: 5px;
    box-sizing: border-box;
    margin-left: 20px;
    line-height: 60px;
    display: flex;
    position: relative;
    .top_text {
      width: 240px;
      display: flex;
      justify-content: space-around;
      font-family: '微软雅黑';
      font-size: 28px;
      color: #cccccc;
      background: #22222275;
    }
    .top_text_ac {
      background: #001e3c;
    }
    img {
      vertical-align: middle;
    }
    .item_cont {
      position: absolute;
      bottom: 75px;
      font-family: '微软雅黑';
      font-size: 24px;
      .item {
        display: flex;
        width: 240px;
        height: 50px;
        border: 1px solid #a5c0eb40;
        box-sizing: border-box;
        margin-bottom: 1px;
        line-height: 50px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        .item_img {
          width: 60px;
          text-align: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .item_text {
          width: 180px;
          overflow: hidden;
        }
      }
      .item_ac {
        border: 1px solid #5caaf875;
        color: #ffffff;
        background: #5caaf8;
      }
    }
  }
}
</style>
