/**
 * 事件总线
 * @author haowei
 */
export default class EventBus {

  eventObject = {}

  constructor() {
  }

  /**
   * 触发事件
   * @param eventName 事件名称
   * @param args 参数
   */
  emit(eventName, ...args) {
    if (typeof eventName != 'string') {
      return console.warn('eventName不是字符串',typeof eventName)
    }
    let callbackList = this.eventObject[eventName]
    if (callbackList) {
      for (const callback of callbackList) {
        callback(...args)
      }
    } else {
      this.eventObject[eventName] = []
    }
  }

  /**
   * 监听事件
   * @param eventName 事件名称
   * @param callback 回调
   * @return {number} 事件id
   */
  on(eventName, callback) {
    if (typeof eventName != 'string') {
      console.warn('eventName不是字符串')
      return -1
    }
    if (typeof callback != 'function') {
      console.warn('callback不是方法!')
      return -1
    }
    if (!this.eventObject[eventName]) {
      this.eventObject[eventName] = []
    }
    this.eventObject[eventName].push(callback)
    return this.eventObject[eventName].indexOf(callback)
  }

  /**
   * 取消监听事件
   * @param eventName 事件名称
   * @param eventId 事件id（从on方法获取的）
   */
  off(eventName, eventId) {
    if (typeof eventName != 'string') {
      return console.warn('eventName不是字符串')
    }
    if (typeof eventId != 'number' && this.eventObject[eventName][eventId]) {
      return console.warn('eventId非法或不存在')
    }
    this.eventObject[eventName].splice(eventId, 1)
  }
}
