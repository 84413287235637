<!--
    * 场景承载组件
    * @date 2023/01/04 16:48:37
    * @author haowei
-->
<template>
  <div id="streaming_box">
    <div id="container" class="ccontainer"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UEapi from "@/utils/UEapi/UEapi";
import LayerManager from "@/core/LayerManager";
import ServiceSwitchFinishController from "@/core/logic/ServiceSwitchFinishController";
import StateManager from "@/core/StateManager";
import eventMixin from "@/mixins/eventMixin";

export default {
  name: "AVW",
  mixins:[eventMixin],
  data() {
    return {

    }
  },
  computed:{
    ...mapState([
      'showLoading','loadServiceCode'
    ])
  },
  watch:{
    loadServiceCode(val){
      // 3个场景都预加载完毕才把loading取消
      if (this.$store.state.showLoading && val >= 3){
        this.$store.commit('setShowLoading',false)
      }
    }
  },
  methods:{
    init(){
      let initConfig = window.streamingConfig.mapList[0]
      UEapi.Common.initAVW(initConfig)
    },
    loadService(){
      for (let item of window.streamingConfig.mapList) {
        UEapi.Common.loadService(item)
      }
    },
    eventsLoad(){
      // 地标图点位点击事件
      window.addEventListener('onLandmarkLayerClick', (res) => {
        let { idLayer, selected, idObj } = res.detail
        this.$layerManager.onLayerClick(idObj, idLayer)
      })

      // 模型点击
      window.addEventListener('onModelClick', async (res) => {
        const { id } = res.detail
        console.log('onModelClick',id)
        this.$modelManager.onModelClick(id)
      })

      // 事件图点击
      window.addEventListener('onEventLayerClick', async (res) => {
        let { idLayer, idObj, selected } = res.detail
        this.$store.commit('setObjectInfo', res.detail)
        this.$layerManager.onLayerClick(idObj, idLayer)
        // 处理告警选中逻辑
        // WarningController.selectedWarning(idObj, idLayer)
      })

      // 注册初始化事件
      window.appInstance.uniCall('addEventListener', {
        eventName: 'onServiceInit',
        callback: async (res) => {
          this.loadService()

          UEapi.Camera.setCameraRestrictionState('free')
          UEapi.Common.setResolution()
          UEapi.Events.initEventCallback()
          // console.log('onServiceInit', res)
          // 初始化需要切换到标准状态
          this.$stateManager.switchState(StateManager.STATES.standardLv1.key)

          UEapi.Events.pickModel()
          UEapi.Events.hoverModel()
          UEapi.Events.pickBuilding()
          UEapi.Events.hoverBuilding()

          // appInstance.uniCall(
          //     'getModelsByType',
          //     { modelType: 'model' },
          //     (res) => {
          //       console.error(res, 'getModelsByType')
          //     }
          // )
        },
      })

      // 预加载
      window.appInstance.uniCall('addEventListener', {
        eventName: 'onServiceLoad',
        callback: (res) => {
          console.log('onServiceLoad', res)
          this.$store.commit('plusLoadService')
        },
      })

      // 切换服务
      window.appInstance.uniCall('addEventListener', {
        eventName: 'onServiceSwitch',
        callback: (res) => {
          console.log('onServiceSwitch', res)
          this.$store.commit('setShowLoading', false)
          UEapi.Common.setResolution()

          UEapi.Events.pickModel()
          UEapi.Events.hoverModel()
          UEapi.Events.pickBuilding()
          UEapi.Events.hoverBuilding()

          // 服务切换的逻辑统一处理
          ServiceSwitchFinishController.handle(res);

          appInstance.uniCall(
              'getModelsByType',
              { modelType: 'model' },
              (res) => {
                console.error(res, 'getModelsByType')
              }
          )
        },
      })
      // window.appInstance.uniCall(
      //     'addEventListener',
      //     {
      //       eventName: 'onSceneClick',
      //       callback: (res) => {
      //         // resetPanel()
      //         console.log('onSceneClick-callback', res)
      //       },
      //     },
      //     (res) => {
      //       console.log('onSceneClick', res)
      //     }
      // )
    },
    panelClose() {
      window.addEventListener('closePanel', (e) => {
        window.appInstance.uniCall('removeOverlayTip', {
          overlayType: e.detail.layerType,
          id: e.detail.id,
        })
      })
    },
  },
  mounted() {
    this.init()
    this.panelClose()
    this.eventsLoad()
  },
  destroyed() {
    window.appInstance.uniCall('destroy')
  },
}
</script>

<style lang="less" scoped>
#streaming_box {
  width: 100%;
  height: 100%;
}

#container {
  width: 100%;
  height: 100%;
}
</style>
