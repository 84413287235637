import BaseLayerController from '@/core/layer/BaseLayerController'
import panelControl from '@/core/logic/PanelController'
import UEapi from '@/utils/UEapi/UEapi'
import HJJCSB from '@/components/UE4Panel/HJJCSB'
import KT from '@/components/UE4Panel/KT'
import ShuiBiaoSheBeiDetails from '@/components/UE4Panel/ShuiBiaoSheBeiDetails'
import DianBiao from '@/components/UE4Panel/DianBiao'
import RQBSB from '@/components/UE4Panel/RQBSB'

/**
 * 带标牌的图层控制器基类
 * @author haowei
 */
export default class BaseTipLayerController extends BaseLayerController{

  // 标牌配置
  PanelSetting = {
    '环境监测设备': { // key与图层id保持一致
      com: HJJCSB, // 显示标牌使用的Vue组件
      size: [330, 270], // 标牌大小
      offset: [-40, -20] // 对于 图层中心的x,y偏移值
    },
    '空调': {
      com: KT,
      size: [630, 310],
      offset: [-60, -40] //0.5
    },
    '水表设备':{
      com: ShuiBiaoSheBeiDetails,
      size: [350, 180], // 标牌大小
      offset: [-40, -20] // 对于 图层中心的x,y偏移值
    },
    '电表设备':{
      com: DianBiao,
      size: [350, 180], // 标牌大小
      offset: [-40, -20] // 对于 图层中心的x,y偏移值
    },
    '燃气表设备':{
      com: RQBSB,
      size: [350, 180], // 标牌大小
      offset: [-40, -20] // 对于 图层中心的x,y偏移值
    }
  }

  // 原有图层类型
  layerType = ''

  divTipList = {

  }


  async getData(layerMap) {
    // 与landmarkLayer控制器是一样的获取数据的方式
    const controller = window.layerManager.getControl(this.layerType)
    // const controller = window.layerManager.getControl('landmarkLayer');
    return await controller.getData(layerMap)
  }

  async showLayerExec(layerMap) {
    layerMap.layerConfig.status = 'show'
    await this.setLayerVisible(layerMap, true)
    let data = layerMap.layerConfig.jsonData
    this.addTip(layerMap, data.data)
  }

  async hideLayerExec(layerMap) {
    layerMap.layerConfig.status = 'hide'
    await this.removeTip(layerMap)
    await this.setLayerVisible(layerMap, false)
  }

  async clearLayerExec(layerMap) {
    // await super.clearLayerExec(layerMap);
    await this.removeTip(layerMap)
    UEapi.Common.removeOverlay({
      id: layerMap.id,
      overlayType: this.layerType
    })
  }

  /**
   * 添加标牌
   * @param layerMap 图层信息
   * @param data 图层数据
   */
  addTip(layerMap, data) {
    for (let index = 0; index < data.length; index++) {
      let p = data[index]
      let ele = panelControl.create(p.id, this.PanelSetting[layerMap.layerConfig.panelType].com, p, 1)
      let jsonParams = {
        id: p.id,
        url: '',
        size: [ele.clientWidth, ele.clientHeight],
        offset: this.PanelSetting[layerMap.layerConfig.panelType].offset || [-1000, -1000],
        overlayType: this.layerType,
        isShowClose: false,
        divId: ele.id
      }
      window.appInstance.uniCall(
        'addOverlayTip',
        jsonParams,
        (tip) => {
          console.log('添加标牌addTip', ele, tip)
          if (!this.divTipList[layerMap.id]){
            this.divTipList[layerMap.id] = []
          }
          this.divTipList[layerMap.id].push(jsonParams)
        }
      )
    }
  }

  /**
   * 删除所有标牌
   */
  async removeTip(layerMap) {
    const func = (item) => {
      return new Promise(resolve => {
        window.appInstance.uniCall('removeOverlayTip', {
          id: item.id,
          overlayType: this.layerType
        }, res => {
          panelControl.destroy()
          console.warn('删除图层标牌', {
            id: item.id,
            overlayType: this.layerType
          }, res)
          resolve(res)
        })
      })
    }
    if (this.divTipList[layerMap.id]){
      for (let item of this.divTipList[layerMap.id]) {
        await func(item)
      }
      this.removeAllDom(layerMap)
    }
  }

  /**
   * 删除所有标牌dom
   * @private
   */
  removeAllDom(layerMap) {
    if (!this.divTipList[layerMap.id]){
      return
    }
    for (let info of this.divTipList[layerMap.id]) {
      let ele = document.getElementById(info.id)
      ele?.remove()
    }
    this.divTipList = []
  }

  /**
   * 设置图层显隐（独立）
   * @param layerMap 图层对象信息
   * @param visible 是否可见
   * @return {Promise<unknown>}
   * @private
   */
  setLayerVisible(layerMap, visible) {
    return new Promise(resolve => {
      window.appInstance.uniCall(
        'setOverlayVisibility',
        {
          id: layerMap.id,
          overlayType: this.layerType,
          visible: visible
        },
        (res) => {
          console.warn(`设置图层显隐-${layerMap.id}-${visible}`, res)
          resolve(res)
        }
      )
    })
  }
}
