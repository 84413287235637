<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse
            title="基础信息"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="jcxxIsShow"
          >
            <Tag
              v-for="(item, index) in jiguilixianjcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="ztxx">
          <Collapse
            title="状态信息"
            :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="ztxxIsShow"
          >
            <Tag
              v-for="(item, index) in jiguilixianztxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="czzl" v-if="jiguilixianczzl.length">
          <Collapse
            title="操作指令"
            :iconUrl="require('@/assets/img/SSGJ/czzlIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="czzlIsShow"
          >
            <TagButton
              v-for="(item, index) in jiguilixianczzl"
              :key="index"
              @runInHole="runInHole"
              :text="item"
              :iconUrl="btnimg[index]"
            />
          </Collapse>
        </div>
        <div class="gjxx">
          <Collapse
            title="告警信息"
            :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table
              :CData="jiguilixiangjxx"
              :NavData="['时间 ', '告警级别', '内容 ']"
              :NavWidth="['278px', '128px', '200px']"
            />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
  },
  props: {
    rightProps: {
      type: Object,
      default: () => ({ title: '北京数据中心' }),
    },
  },
  data() {
    return {
      jiguilixianjcxx: [
        {
          name: '编号',
          value: '机柜组-01',
        },
        {
          name: 'U位',
          value: '50',
        },
        {
          name: '机柜数',
          value: '10个'
        },
        {
          name: '设备总数',
          value: '150个',
        },
      ],
      jiguilixianztxx: [
        {
          name: '设备状态',
          value: '告警',
        },
      ],
      jiguilixianczzl: [
        // '下钻至机柜',
        // '下钻至设备01',
        // '下钻至设备02',
        // '下钻至设备03',
        // '下钻至设备04',
      ],
      jiguilixiangjxx: [
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器G2 79' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '交换机-05连' },
        { time: '2022-07-11 12:00:00', content: '三级', second: 'SQL数据库服' },
        {
          time: '2022-07-11 12:00:00',
          content: '三级',
          second: '服务器01-CPU',
        },
        { time: '2022-07-11 12:00:00', content: '三级', second: '路由器-03连' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
        { time: '2022-07-11 12:00:00', content: '三级', second: '服务器故障' },
      ],
      btnimg: [
        require('@/assets/img/SSGJ/jgIcon.png'),
        require('@/assets/img/SSGJ/sbIcon.png'),
        require('@/assets/img/SSGJ/sbIcon.png'),
        require('@/assets/img/SSGJ/sbIcon.png'),
        require('@/assets/img/SSGJ/sbIcon.png'),
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true,
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    },
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo']),
  },
  mounted() {
    this.jiguilixianjcxx[0].value = this.objectInfo.name

    switch (this.functionStatus) {
      // case '默认状态' :
      //   this.jiguilixianczzl = [
      //     '下钻至机柜',
      //     '下钻至设备01',
      //     '下钻至设备02',
      //     '下钻至设备03',
      //     '下钻至设备04',
      //   ]
      //   this.czzlIsShow = true
      //   break
      case 'Box_C03-004-04_机柜容量':
        this.jiguilixianczzl = [
          '下钻至机柜',
          // '下钻至设备01',
          // '下钻至设备02',
          // '下钻至设备03',
          // '下钻至设备04',
        ]
        this.czzlIsShow = true
        break
      case '对象管理':
        this.jiguilixianczzl = []
        this.czzlIsShow = false
        break
      default:
        this.jiguilixianczzl = []
        this.czzlIsShow = false
        break
    }
  },
  watch: {
    functionStatus: {
      handler(val) {
        switch (val) {
          // case '默认状态':
          //   this.jiguilixianczzl = [
          //     '下钻至机柜',
          //     '下钻至设备01',
          //     '下钻至设备02',
          //     '下钻至设备03',
          //     '下钻至设备04',
          //   ]
          //   this.czzlIsShow = true
          //   break
          case 'Box_C03-004-04_机柜容量':
            this.jiguilixianczzl = [
              '下钻至机柜',
              // '下钻至设备01',
              // '下钻至设备02',
              // '下钻至设备03',
              // '下钻至设备04',
            ]
            this.czzlIsShow = true
            break
          case '对象管理':
            this.jiguilixianczzl = []
            this.czzlIsShow = false
            break
          default:
            this.jiguilixianczzl = []
            this.czzlIsShow = false
            break
        }
      },
      deep: true,
    },
    objectInfo: {
      handler(val) {
        this.jiguilixianjcxx[0].value = val.name
      },
      deep: true,
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
            width: 19px;
            height: 26px;
          }
        }
      }
    }
  }
  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag_right {
          color: #ff4a4a;
        }
      }
    }
  }
}
</style>
