
import BaseLayerController from './BaseLayerController'
import Store from '@/store'
import UEapi from "@/utils/UEapi/UEapi";
/**
 * 关系图层控制器
 * @author haowei
 */
export default class ODLineLayerController extends BaseLayerController{

  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap);
    layerMap.layerConfig.jsonData = data;
    layerMap.layerConfig.status = 'hide'
    await UEapi.ODLineLayer.addODLineLayer(data);
  }

  async getData(layerMap) {
    let rowData = await super.getRowData(layerMap)
    layerMap.layerConfig.rawData = rowData;
    const { spaceLevel } = Store.state
    let defaultOption = {}
    let jsonData = Object.assign({}, defaultOption, layerMap)
    jsonData.data = rowData.map((item) => {
      return {
        id: item.id,
        startCoord: item.startCoord,
        startCoordZ: BaseLayerController.coordZList[spaceLevel] || 1,
        targetCoord: item.targetCoord,
        targetCoordZ: BaseLayerController.coordZList[spaceLevel] || 1,
        label: '',
        value: 10,
        visible:false,
        type: 'default',
      }
    })
    return jsonData
  }
}
