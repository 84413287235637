import { render, staticRenderFns } from "./DianBiao.vue?vue&type=template&id=ec9a1342&scoped=true&"
import script from "./DianBiao.vue?vue&type=script&lang=js&"
export * from "./DianBiao.vue?vue&type=script&lang=js&"
import style0 from "./DianBiao.vue?vue&type=style&index=0&id=ec9a1342&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec9a1342",
  null
  
)

export default component.exports