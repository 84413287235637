<template>
  <div class="title">
    <img :src="iconUrl" alt />
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  props: {
    iconUrl: {
      type: String,
      default: require('../../../assets/img/SSGJ/titleIcon.png')
    },
    title: {
      type: String,
      default: '对象详情'
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.title {

    height: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
  width: 100%;
  font-size: 36px;
  font-family: "微软雅黑";
  font-weight: bold;
  color: #fff;
  img{
      margin-left: 26px;
      margin-right: 15px;
  }
}
</style>