var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
      '--resH': _vm.resolution.height + 'px',
      '--resW': _vm.resolution.width + 'px',
      '--pageW': _vm.pageInfo.width + 'px',
      '--pageH': _vm.pageInfo.height + 'px',
    }),attrs:{"id":"app"}},[_c('div',{staticClass:"gis-container",style:({
        transform:
          'translateX(-50%) translateY(-50%) translateZ(0) scale(' +
          _vm.scaleMap +
          ')',
      })},[(!_vm.devMode)?_c('AVW'):_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/img/bg.png")}})],1),_c('div',{staticClass:"screen",style:({
        '--resTransform':
          'translateX(-50%) translateY(-50%) translateZ(0) scale(' +
          _vm.scale +
          ')',
        transform: 'var(--resTransform)',
      })},[_c('HeaderCustom'),_c('div',{staticClass:"main"})],1),_c('div',{staticClass:"layer-container",style:({
        transform:
          'translateX(-50%) translateY(-50%) translateZ(0) scale(' +
          _vm.scale +
          ')',
      }),attrs:{"id":"layer-container"}},[_c('ComContainer'),_c('BottomCustom'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoading),expression:"showLoading"}],staticClass:"loading"},[_c('img',{attrs:{"src":"/img/loading.gif","alt":""}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }