import Store from "@/store";
import BaseHandler from "@/core/layer/handler/BaseHandler";

/**
 * 数据中心图层处理逻辑
 * @author haowei
 */
export default class DataCenterHandler extends BaseHandler {

    layerNames = ['中国-数据中心', '北京-数据中心']

    handleClick({id, idLayer}) {
        if (idLayer == this.layerNames[0]) {
            Store.commit('setRightType', 'ShuJuZhongXingSheng')
            Store.commit('setObjectInfo', {name: '北京数据中心'})
        } else {
            Store.commit('setRightType', 'ShuJuZhongXingYuanQu')
            Store.commit('setObjectInfo', {name: '分数据中心-01'})
        }
    }

    handleHover({id, idLayer}) {
        super.handleHover({id, idLayer});
    }

    filterLayer({id, idLayer}) {
        return this.layerNames.includes(idLayer)
    }
}
