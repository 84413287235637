import BaseStateHandler from "@/core/state/BaseStateHandler";
import SpaceController from "@/core/logic/SpaceController";
import LayerManager from "@/core/LayerManager";
import UEapi from "@/utils/UEapi/UEapi";
import StateManager from "@/core/StateManager";
import BuildingController from "@/core/logic/BuildingController";
import Store from "@/store";
/**
 * 标准状态处理
 * @author haowei
 */
export default class StandardStateHandler extends BaseStateHandler{

    async init() {
        await super.init()
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo()
        let layerNames = window.layerManager.getLoadedLayerNames()
        let allLegendsLayerNames = window.legendManager.getAllLegendsLayerNames()
        let filter = allLegendsLayerNames.filter(item=>!layerNames.includes(item))
        if (filter.length > 0){
            LayerManager.loadLayer()
        }
        // 数据分析面板打开的情况下如果切换了层级，需要再切换到数据分析状态
        if (Store.state.functionStatus == '数据分析'){
            window.stateManager.switchState(StateManager.STATES.analysis.key)
        }
    }

    async reset() {
        let nowSpaceLevelInfo = SpaceController.getNowSpaceLevelInfo()
        // 层级小于楼层时，收起楼体
        if (nowSpaceLevelInfo.lv < 4){
            BuildingController.unExpandBuilding()
        }
        // 收起容量图层
        // console.warn('window.layerManager.controls.capacityLayer.currentShowLayerId',window.layerManager.controls.capacityLayer.currentShowLayerId)
        if (window.layerManager.controls.capacityLayer.currentShowLayerId){
            // 如果已经切换到数据分析，那就不处理容量图层
            // if(Store.state.functionStatus == '数据分析') return
            if(Store.state.functionStatus == '数据分析'){
                await window.layerManager.hideLayer(window.layerManager.controls.capacityLayer.currentShowLayerId)
                window.legendManager.unHighlightLegend('容量','空间使用')
                window.legendManager.unHighlightLegend('容量','功耗使用')
                window.legendManager.unHighlightLegend('容量','承重使用')
            }
        }
        await super.reset()
    }
}
