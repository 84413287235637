<!--
 * @Author: yejinliang
 * @Date: 2021-12-13 18:14:49
 * @LastEditTime: 2022-04-21 14:22:03
 * @LastEditors: yejinliang
 * @Description: 二级导航
-->
<template>
  <!-- <div class="nav-second" v-if="functionStatus == '数据分析'"> -->
  <div class="nav-second">
    <div class="nav_cont" v-if="showList.length > 0">
      <div
          v-for="(nav,index) in showList"
          :key="nav.name"
          class="nav-second-item"
          :class="index==i?'nav-second-item_ac':''"
          @click="TabSwitching(nav,index)"
      >
        <img :src="imgUrl[index]" alt/>
        <span>{{ nav.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'NavSecond',
  props: {
    imgUrl: {
      type: Array,
      default: () => [
        require('../../assets/img/layout/wlts.png'),
        require('../../assets/img/layout/zyts.png')
      ]
    }
  },
  data() {
    return {
      i: 0,
      router: [
        {
          name: '网络态势',
          type: 'WangLuoTaiShi',
          levels: ['中国', '北京'],
          show: false
        },
        {
          name: '资源态势',
          type: 'ZiYuanTaiShi',
          levels: ['中国', '北京'],
          show: false
        }, {
          name: '设备态势',
          type: 'SheBeiTaiShi',
          levels: ['园区', '房间', '楼层','设备', '机柜', '机柜组'],
          show: false
        }
      ]
    }
  },
  methods: {
    TabSwitching(item, index) {
      this.i = index
      this.$store.commit('setPosture', item.type)
      this.$emit('TabSwitching', item.type)
    },
    calcShow(){
      this.i = 0;
      for (let item of this.router) {
        item.show = item.levels.includes(this.$store.state.spaceLevel);
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'spaceLevel']),
    showList(){
      return this.router.filter(item=>item.show)
    }
  },
  watch: {
    spaceLevel: {
      handler(val) {
        this.calcShow()
      },
      deep: true
    },
    functionStatus(val){
      if (val == '数据分析'){
        this.calcShow()
        if (this.showList.length > 0){
          this.$store.commit('setPosture',this.showList[0].type)
        }
      }
    }
  },
  mounted() {
    this.calcShow()
  }
}
</script>

<style lang="less" scoped>
.nav-second {
  pointer-events: all;

  .nav_cont {
    height: 100px;
    display: flex;
    z-index: 10;
    padding-left: 50px;
    position: relative;
  }

  .nav_cont::before {
    position: absolute;
    left: 0px;
    content: '';
    display: block;
    width: 2px;
    height: 100px;
    background: url('../../assets/img/layout/sx.png') no-repeat;
  }

  .nav-second-item {
    cursor: pointer;
    width: 240px;
    height: 100px;
    display: flex;
    align-items: center;

    img {
      // height: 34px;
      margin: 0px 10px 0px 38px;
    }

    span {
      font-family: '微软雅黑';
      font-size: 32px;
      line-height: 36px;
      font-weight: 700;
      color: #cccccc;
    }
  }

  .nav-second-item_ac {
    color: #ffffff;
    background: #189bfa40;

    span {
    }
  }
}
</style>
