// import gisDataManager from '../../api/gisDataManager';

export default class RoadSgHeatLayer {
  static updateTimer = {};
  static defaultConfig = {
    id: '路径段热力图', // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
    name: 'layerName', // 图层名称，支持为图层自定义名称
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    alpha: 1, // 透明度，0：完全透明；1：完全不透明
    width: 300, // 路径宽度（单位：米）
    colorMax: '#FF0000', // 最大值上限颜色（HEX 颜色值）
    colorMin: '#00FF00', // 最小值下限颜色（HEX 颜色值）
    valueMax: 1000, // 最大值上限
    valueMin: 0, // 最小值下限
    segments: [],
    visible: true,
    data: []
  };

  // 添加散点图层
  static async addRoadSgHeatLayer(layer) {
    let layerInfo = await gisDataManager.getRoadSgHeatLayerData(
      layer,
      this.defaultConfig
    );
    // 删除用户图层配置，减少数据传输体积
    delete layerInfo.layerConfig;
    // console.log('gzq', layerInfo);
    if (layerInfo.segments.length > 100) {
      let allData = {
        segments: JSON.parse(JSON.stringify(layerInfo.segments)),
        data: JSON.parse(JSON.stringify(layerInfo.data))
      };
      layerInfo.segments = allData.segments.splice(0, 100);
      layerInfo.data = allData.data.splice(0, 100);
      window.appInstance.uniCall('addRoadSgHeatLayerCoord', layerInfo, res => {
        let all = Math.ceil(allData.data.length / 100);
        let index = 0;
        this.updateTimer[layer.id] = setInterval(() => {
          index++;
          // console.log('gzq', index, all);
          this.appendRoadSgHeatLayer(layer, allData);
          if (index >= all) {
            clearInterval(this.updateTimer[layer.id]);
          }
        }, 300);
      });
    } else {
      window.appInstance.uniCall('addRoadSgHeatLayerCoord', layerInfo, res => {
        // console.log('添加图层' + layer.id, res);
      });
    }
  }

  static async updateRoadSgHeatLayer(layer, subLayer) {
    let layerInfo = await gisDataManager.getRoadSgHeatLayerData(
      layer,
      this.defaultConfig,
      subLayer
    );
    // 删除用户图层配置，减少数据传输体积
    delete layerInfo.layerConfig;

    if (layerInfo.segments.length > 100) {
      let allData = {
        segments: JSON.parse(JSON.stringify(layerInfo.segments)),
        data: JSON.parse(JSON.stringify(layerInfo.data))
      };
      let all = Math.ceil(allData.data.length / 100);
      let index = 0;
      layerInfo.segments = allData.segments.splice(0, 100);
      layerInfo.data = allData.data.splice(0, 100);

      window.appInstance.uniCall('updateRoadSgHeatLayerCoord', {
        id: layerInfo.id,
        isAppend: false,
        data: layerInfo.data
      });
      window.appInstance.uniCall('updateRoadSgHeatLayerSegment', {
        id: layerInfo.id, // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
        name: layerInfo.name, // 图层名称，支持为图层自定义名称
        coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
        coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
        isAppend: false, // 是否追加数据（按数据顺序），true：按新数据 更新原有重复数据 & 追加新数据；false：清除原有数据 & 重建新数据
        segments: layerInfo.segments
      });

      this.updateTimer[layer.id] = setInterval(() => {
        index++;
        this.appendRoadSgHeatLayer(layer, allData);
        if (index >= all) {
          clearInterval(this.updateTimer[layer.id]);
        }
      }, 300);
    } else {
      window.appInstance.uniCall('updateRoadSgHeatLayerCoord', {
        id: layerInfo.id,
        isAppend: false,
        data: layerInfo.data
      });
      window.appInstance.uniCall('updateRoadSgHeatLayerSegment', {
        id: layerInfo.id, // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
        name: layerInfo.name, // 图层名称，支持为图层自定义名称
        coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
        coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
        isAppend: false, // 是否追加数据（按数据顺序），true：按新数据 更新原有重复数据 & 追加新数据；false：清除原有数据 & 重建新数据
        segments: layerInfo.segments
      });
    }
  }

  // 更新图层数据
  static appendRoadSgHeatLayer(layer, allData) {
    // console.log('gzq', allData);
    window.appInstance.uniCall('updateRoadSgHeatLayerCoord', {
      id: layer.id,
      isAppend: true,
      data: allData.data.splice(0, 100)
    });
    window.appInstance.uniCall('updateRoadSgHeatLayerSegment', {
      id: layer.id, // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
      name: layer.name, // 图层名称，支持为图层自定义名称
      coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
      coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
      isAppend: true, // 是否追加数据（按数据顺序），true：按新数据 更新原有重复数据 & 追加新数据；false：清除原有数据 & 重建新数据
      segments: allData.segments.splice(0, 100)
    });
  }
}
