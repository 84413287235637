import BaseLayerController from './BaseLayerController'
import Store from '@/store'
import UEapi from '@/utils/UEapi/UEapi'

/**
 * 路径图层控制器
 * @author haowei
 */
export default class PathLayerController extends BaseLayerController {
  async addLayerExec(layerMap) {
    let data = await this.getData(layerMap)
    layerMap.layerConfig.jsonData = data
    layerMap.layerConfig.status = 'hide'
    await UEapi.Path.addPath(data)
    await this.hideLayerExec(layerMap)
  }

  async clearLayerExec(layerMap) {
    layerMap.layerConfig.status = 'none'
    await UEapi.Common.removeOverlay({ id: layerMap.id, overlayType: 'path' })
  }

  async showLayerExec(layerMap) {
    layerMap.layerConfig.status = 'show'
    await UEapi.Path.addPath(layerMap.layerConfig.jsonData)
    await UEapi.Common.moveOverlayBackward(layerMap.id, 0);
  }

  async hideLayerExec(layerMap) {
    layerMap.layerConfig.status = 'hide'
    await UEapi.Common.removeOverlay({ id: layerMap.id, overlayType: 'path' })
  }

  async getData(layerMap) {
    let rowData = await super.getRowData(layerMap)
    const { spaceLevel } = Store.state
    let defaultOption = {
      id: 'idObj',
      name: 'layerName',
      coordType: 0,
      coordTypeZ: 0,
      autoScale: false,
      visible: true,
      legends: [],
      points: [],
    }
    let jsonData = Object.assign({}, defaultOption, layerMap)
    jsonData.points = rowData.map((item)=>{
      return {
        coord: [item.lon, item.lat],
        coordZ: item.coordZ || BaseLayerController.coordZList[spaceLevel] || 0
      }
    })
    delete jsonData.layerConfig;
    return jsonData
  }
}
