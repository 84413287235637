import { render, staticRenderFns } from "./FangJian.vue?vue&type=template&id=3c69b746&scoped=true&"
import script from "./FangJian.vue?vue&type=script&lang=js&"
export * from "./FangJian.vue?vue&type=script&lang=js&"
import style0 from "./FangJian.vue?vue&type=style&index=0&id=3c69b746&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c69b746",
  null
  
)

export default component.exports