import { render, staticRenderFns } from "./Collapse.vue?vue&type=template&id=739d8bd3&scoped=true&"
import script from "./Collapse.vue?vue&type=script&lang=js&"
export * from "./Collapse.vue?vue&type=script&lang=js&"
import style0 from "./Collapse.vue?vue&type=style&index=0&id=739d8bd3&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739d8bd3",
  null
  
)

export default component.exports