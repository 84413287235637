import DefaultModelCameraController from "@/core/camera/DefaultModelCameraController";
import UEapi from "@/utils/UEapi/UEapi";

/**
 * 设备模型镜头控制器
 * @author haowei
 */
export default class EqModelCameraController extends DefaultModelCameraController {
    // 基础机柜镜头信息
    baseCameraInfo = {
        coordType: 0,
        coordTypeZ: 0,
        coordZ: 1.86,
        distance: 1.75,
        pitch: 10,
        fly: true,
        duration: 1,
    }

    // 镜头纬度偏移量
    cameraLatOffset = 0.000006
    // 镜头设备反面纬度偏移量
    cameraLatReverseOffSet = 0.000000
    // 镜头高度偏移量
    cameraHeightOffset = 0.100018

    async setCamera(params) {
        const find = window.modelManager.controls.equipment.equipmentList.find(
            (item) => item.id == params.id
        )
        let rackCtrl = window.modelManager.controls.rack
        let paramsValue = {
            ...find,
            pos: params.pos,
            reverse: rackCtrl.isReverseRack(find)
        }
        // console.warn('setCamera',params,find,paramsValue)
        let cmCoord = Object.assign({}, paramsValue.coord)
        // 反向偏航角的设备处理
        // 镜头数据从坐标位置计算偏移量获得
        // 设备正反面使用不同的纬度偏移量
        let heading = 0
        if (paramsValue.pos == 1) {
            // 反面
            heading = paramsValue.reverse ? 0 : 180
            cmCoord[1] = paramsValue.reverse
                ? cmCoord[1] + this.cameraLatReverseOffSet
                : cmCoord[1] - this.cameraLatReverseOffSet
        } else {
            // 正面
            heading = paramsValue.reverse ? 180 : 0
            cmCoord[1] = paramsValue.reverse
                ? cmCoord[1] - this.cameraLatOffset
                : cmCoord[1] + this.cameraLatOffset
        }
        let cameraInfo = {
            ...this.baseCameraInfo,
            centerCoord: cmCoord,
            coordZ: paramsValue.coordZ - this.cameraHeightOffset,
            heading: heading,
        }
        this.cameraInfo = cameraInfo
        await UEapi.Camera.setCamera(cameraInfo)
    }
}
