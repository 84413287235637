import UEapi from '@/utils/UEapi/UEapi'
import DataManager from '@/core/DataManager'
import Store from '@/store/index'
import DataCenterHandler from "@/core/layer/handler/DataCenterHandler";
/**
 * 点位图层控制器基类
 * @author haowei
 */
export default class BaseLayerController{
  // coordZ在不同空间层次的值
  static coordZList = {
    中国: -87000,
    北京: 50,
    主楼: 5,
    楼层: 10,
    房间: 10,
    机柜: 10,
  }

  /**
   * 添加图层-子类实现
   * @param layerMap 图层属性对象
   */
  async addLayerExec(layerMap) {}

  /**
   * 修改图层-子类实现
   * @param layerMap 图层属性对象
   */
  async updateLayerExec(layerMap) {}

  /**
   * 隐藏图层-子类可以重写
   * @param layerMap 图层属性对象
   */
  async hideLayerExec(layerMap) {
    layerMap.layerConfig.status = 'hide'
    await UEapi.Common.setLayerVisible(layerMap, false)
  }

  /**
   * 显示图层-子类可以重写
   * @param layerMap 图层属性对象
   */
  async showLayerExec(layerMap) {
    layerMap.layerConfig.status = 'show'
    await UEapi.Common.setLayerVisible(layerMap, true)
  }

  /**
   * 删除图层-子类可以重写
   * @param layerMap 图层属性对象
   */
  async clearLayerExec(layerMap) {
    UEapi.Common.removeOverlay({
      id: layerMap.id,
      overlayType: layerMap.layerConfig.layerType,
    })
  }

  async clickLayerExec(e){}

  /**
   * 获取数据-子类实现
   * @param 图层属性对象
   */
  async getData(layerMap) {}


  /**
   * 获取坐标数据-通用
   * @param layerMap 图层属性对象
   */
  async getRowData(layerMap) {
    const { spaceLevel, spaceName } = Store.state
    let dataFilter = spaceLevel || spaceName

    // 从jsonPath获取到json数据
    let { jsonPath } = layerMap.layerConfig
    try {
      let data = await DataManager.getDataByPath(jsonPath)
      // console.log('getRowData',data)
      if (!data || !data.RECORDS) {
        throw new Error('数据未找到')
      }
      const isApi = await DataManager.isApiGetData(jsonPath)
      if (!isApi){
        let filter = data.RECORDS.filter((item) => {
          return item.type == dataFilter || item.type == 'all'
        })
        return filter
      }
      return data.RECORDS
    } catch (e) {
      console.warn(
        `图层数据异常:${layerMap.id},检查layerList.js下jsonPath属性,*.json文件内容 或 后端接口内容`,
        e
      )
      return []
    }
  }
}
