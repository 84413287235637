import api from '@/api'

/**
 * 网络态势相关接口
 * @author haowei
 */
export default {
    // 专属宽带排名
    reqGetzskdpmData() {
        return api.doGet('/data/WLTS/zskdpmData.json')
    },
    // 专线类型占比
    reqGetzxlxzbData() {
        return api.doGet('/data/WLTS/zxlxzbData.json')
    },
    // 网络性能
    reqGetwlxnData(p){
        return api.doGet(`/data/WLTS/wlxnData${p}.json`)
    },
    // 各省综合告警排名
    reqGetgszhgjpmData(){
        return api.doGet('/data/WLTS/gszhgjpmData.json')
    },
    // 告警统计分析
    reqGetgjtjfxData(){
        return api.doGet('/data/WLTS/gjtjfxData.json')
    },
    // 告警信息列表
    reqGetgjxxlbData(){
        return api.doGet('/data/WLTS/gjxxlbData.json')
    }
}
