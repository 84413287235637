export default {
  /**
   * 分为两种配置
   * 1、当前主题的摄像机参数
   * 2、当前地图的摄像机视野限制参数
   */

  //#region 相机参数

  中国: {
    centerCoord: [104.2441423, 34.3973044],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 6364256.8,
    heading: 0,
    pitch: 60,
    duration: 1,
    fly: true,
  },
  北京: {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2267499,
      40.3055045
    ],
    coordZ: -56983.3,
    distance: 299023.27,
    pitch: 64.97,
    heading: 19.36,
    duration: 1,
    fly: true,
  },
  园区: {
    centerCoord: [116.2925561, 39.8282213],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: -3.06,
    distance: 81.91,
    heading: 0.14,
    pitch: 21.11,
    duration: 1,
    fly: true,
  },
  '分数据中心-01': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2925561, 39.8282213],
    coordZ: -3.06,
    distance: 81.91,
    pitch: 21.11,
    heading: 0.14,
    duration: 1,
    fly: true,
  },

  建筑01: {
    centerCoord: [116.2925712, 39.82811],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 17.1,
    distance: 39.94,
    heading: 0.86,
    pitch: 15,
    duration: 1,
    fly: true,
  },

  F3: {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2925519, 39.8282233],
    coordZ: 2.93,
    distance: 37.95,
    pitch: 51.09,
    heading: 0.35,
    duration: 1,
    fly: true,
  },

  机柜组机房: {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2925551, 39.828192],
    coordZ: -0.17,
    distance: 26.5,
    pitch: 71.85,
    heading: 1.04,
    duration: 1,
    fly: true,
  },
  机柜组机房下钻:{
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2925616, 39.8281975],
    coordZ: -2.58,
    distance: 23.92,
    pitch: 65.33,
    heading: 1,
    duration: 1,
    fly: true
  },
  'A01-003_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925963,
      39.8281888
    ],
    coordZ: -0.86,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration:1,
    fly:true
  },
  'A01-001_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2926114,
      39.8281502
    ],
    coordZ: 7.73,
    distance: 9.26,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'B02-002_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925545,
      39.8281752
    ],
    coordZ: -0.96,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'C03-001_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925083,
      39.8281674
    ],
    coordZ: -0.62,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'C03-002_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925102,
      39.8281766
    ],
    coordZ: -0.09,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'B02-003_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925531,
      39.828188
    ],
    coordZ: -0.16,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'C03-003_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925176,
      39.828191
    ],
    coordZ: 0.65,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'C03-004_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925176,
      39.828191
    ],
    coordZ: 0.65,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'B02-004_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.29255,
      39.828195
    ],
    coordZ: 0.32,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'A01-004_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2926028, 39.8281864],
    coordZ: -2.83,
    distance: 19.48,
    pitch: 72.9,
    heading: 13,
    duration: 1,
    fly: true,
  },
  'D04-001_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925946,
      39.8282091
    ],
    coordZ: 0.4,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'D04-002_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925176,
      39.828191
    ],
    coordZ: 0.65,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'D04-003_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2926132,
      39.8281933
    ],
    coordZ: 10.31,
    distance: 9.26,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'D04-004_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2926062,
      39.8282294
    ],
    coordZ: 1.43,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'E05-001_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925481,
      39.8282087
    ],
    coordZ: 1.19,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'E05-002_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925591,
      39.8282176
    ],
    coordZ: 1.54,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'E05-003_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925516,
      39.8282212
    ],
    coordZ: 1.89,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'E05-004_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925542,
      39.828225
    ],
    coordZ: 2.08,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'F06-001_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925172,
      39.828208
    ],
    coordZ: 1.69,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'F06-002_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925201,
      39.8282138
    ],
    coordZ: 1.99,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'F06-003_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925306,
      39.8281935
    ],
    coordZ: 11.77,
    distance: 9.26,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'F06-004_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925205,
      39.8282356
    ],
    coordZ: 3.31,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'A01-002_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925938,
      39.8281775
    ],
    coordZ: -1.5,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  'B02-001_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [
      116.2925542,
      39.8281725
    ],
    coordZ: -1.11,
    distance: 19.48,
    pitch: 65.88,
    heading: 20.57,
    duration: 1,
    fly: true,
  },
  电池组机房: {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2926789, 39.828244],
    coordZ: 1.06,
    distance: 15.07,
    pitch: 60.76,
    heading: 359,
    duration: 1,
    fly: true,
  },
  UPS设备机房: {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2926691, 39.8282271],
    coordZ: -1.26,
    distance: 25.18,
    pitch: 44.54,
    heading: 0.14,
    duration: 1,
    fly: true,
  },
  'F06-004-07_SM_设备机柜': {
    // 机柜01
    centerCoord: [116.2925103, 39.828201],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 8.94,
    distance: 2.8,
    heading: 180.49,
    pitch: 22.46,
    duration: 1,
    fly: true,
  },
  'C03-004-04_SM_设备机柜': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2925099, 39.8281638],
    coordZ: 9.5,
    distance: 1.36,
    pitch: 31.5,
    heading: 0.16,
    duration: 1,
    fly: true,
  },
  'C03-004-04_SM_配线架': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.5, // Z 轴高度（单位：米）
    distance: 0.6, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 1, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_配线架设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925102, 39.8281675],
    coordZ: 9.5, // Z 轴高度（单位：米）
    distance: 0.6, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 180, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_数据服务器_虚拟机': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.3, // Z 轴高度（单位：米）
    distance: 0.5, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_数据服务器_虚拟机设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.3, // Z 轴高度（单位：米）
    distance: 0.5, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 180.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_路由器': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.3, // Z 轴高度（单位：米）
    distance: 0.5, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_路由器设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.3, // Z 轴高度（单位：米）
    distance: 0.5, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 180.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_交换机': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.3, // Z 轴高度（单位：米）
    distance: 0.5, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_交换机设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.3, // Z 轴高度（单位：米）
    distance: 0.5, // 镜头距中心点距离(单位:米)
    pitch: 17, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_服务器': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.1, // Z 轴高度（单位：米）
    distance: 0.4, // 镜头距中心点距离(单位:米)
    pitch: 18, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_服务器设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.1, // Z 轴高度（单位：米）
    distance: 0.4, // 镜头距中心点距离(单位:米)
    pitch: 18, // 镜头俯仰角(5~89)
    heading: 180.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_网络存储服务器': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.1, // Z 轴高度（单位：米）
    distance: 0.4, // 镜头距中心点距离(单位:米)
    pitch: 18, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_网络存储服务器设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 9.1, // Z 轴高度（单位：米）
    distance: 0.4, // 镜头距中心点距离(单位:米)
    pitch: 18, // 镜头俯仰角(5~89)
    heading: 180.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_GPU服务器': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 8.9, // Z 轴高度（单位：米）
    distance: 0.9, // 镜头距中心点距离(单位:米)
    pitch: 18, // 镜头俯仰角(5~89)
    heading: 0.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'C03-004-04_SM_GPU服务器设备反面': {
    coordType: 0, // XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
    coordTypeZ: 0, // Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
    centerCoord: [116.2925105, 39.8281675],
    coordZ: 8.9, // Z 轴高度（单位：米）
    distance: 0.9, // 镜头距中心点距离(单位:米)
    pitch: 18, // 镜头俯仰角(5~89)
    heading: 180.16, // 镜头偏航角(0正北, 0~359)
    fly: true, // true: 飞行动画(飞行时间根据当前点与目标点计算,则pitch及heading不生效, 会自行计算);
    // false: 立刻跳转过去(有一个短暂飞行动画,并按照distance, pitch, heading设置镜头)
    duration: 0.5, // 飞行时间，秒
  },
  'A01-004-04_SM_机柜组': {
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.292618, 39.8281375],
    coordZ: 6.28,
    distance: 6.98,
    pitch: 36.1,
    heading: 179.32,
    duration: 1,
    fly: true,
  },
  建筑: {
    centerCoord: [-0.00129918392, -0.00106545069],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 212,
    fov: 90,
    heading: 211,
    pitch: 37,
    duration: 1,
    fly: true,
  },
  楼层组1: {
    centerCoord: [-0.00157069726, -0.00135044534],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 268,
    fov: 90,
    heading: 229,
    pitch: 36,
    duration: 1,
    fly: true,
  },
  楼层组: {
    centerCoord: [-0.00158756225, -0.00154539326],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 287,
    fov: 90,
    heading: 211,
    pitch: 37,
    duration: 1,
    fly: true,
  },
  停车场: {
    centerCoord: [-0.00079478068, -0.00077705288],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 109,
    fov: 90,
    heading: 15,
    pitch: 47,
    duration: 1,
    fly: true,
  },
  边界通行: {
    centerCoord: [-0.00079478068, -0.00077705288],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 109,
    fov: 90,
    heading: 15,
    pitch: 47,
    duration: 1,
    fly: true,
  },
  车辆闸机: {
    centerCoord: [-0.00079478068, -0.00077705288],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 109,
    fov: 90,
    heading: 15,
    pitch: 47,
    duration: 1,
    fly: true,
  },
  安防态势: {
    centerCoord: [-0.0006396611, -0.00002836127],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 256,
    fov: 90,
    heading: 246,
    pitch: 55,
    result: 1,
    duration: 1,
    fly: true,
  },
  人车感知: {
    centerCoord: [-0.00014706282, -0.00022643842],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 102,
    fov: 90,
    heading: 272,
    pitch: 29,
    duration: 1,
    fly: true,
  },
  告警监测: {
    centerCoord: [-0.0005140439, 0.00006183523],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 0,
    distance: 207,
    fov: 90,
    heading: 238,
    pitch: 44,
    duration: 1,
    fly: true,
  },
  环境空间: {
    centerCoord: [-0.00105148716, -0.00079572354],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 23.68793457031,
    distance: 80,
    fov: 90,
    heading: 221,
    pitch: 45,
    duration: 1,
    fly: true,
  },
  火灾: {
    centerCoord: [-0.0007432015, -0.00102839501],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 27.31736816406,
    distance: 40,
    fov: 90,
    heading: 270,
    pitch: 51,
    duration: 1,
    fly: false,
  },
  火灾疏散路径: {
    centerCoord: [-0.0007432015, -0.00102839501],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 27.31736816406,
    distance: 40,
    fov: 90,
    heading: 270,
    pitch: 51,
    duration: 1,
    fly: false,
  },
  空间使用: {
    centerCoord: [116.2925634,39.828213],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 5.35,
    distance: 26.36,
    fov: 90,
    heading: 348.53,
    pitch: 42.54,
    duration: 1,
    fly: true,
  },
  功耗使用: {
    centerCoord: [116.2925722,39.828206],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 5.08,
    distance: 27.75,
    fov: 90,
    heading: 334.33,
    pitch: 37.62,
    duration: 1,
    fly: true,
  },
  承重使用: {
    centerCoord: [116.2925675,39.8281871],
    coordType: 0,
    coordTypeZ: 0,
    coordZ: 4.35,
    distance: 27.75,
    fov: 90,
    heading: 15.29,
    pitch: 42.54,
    duration: 1,
    fly: true,
  },

  //#endregion

  '门禁设备2':{
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2923985, 39.828178],
    coordZ: 5.74,
    distance: 12.92,
    pitch: 20.08,
    heading: 85.32,
    duration: 1,
    fly: true
  },

  '门禁设备3':{
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2926401, 39.8281424],
    coordZ: 9.57,
    distance: 1.94,
    pitch: 15,
    heading: 268.42,
    duration: 1,
    fly: true
  },
  '门禁设备4':{
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2926435, 39.8281397],
    coordZ: 8.67,
    distance: 4.88,
    pitch: 16.26,
    heading: 85,
    duration: 1,
    fly: true
  },
  '门禁设备5':{
    coordType: 0,
    coordTypeZ: 0,
    centerCoord: [116.2926526, 39.8282083],
    coordZ: 9.42,
    distance: 1.51,
    pitch: 19.2,
    heading: 267.89,
    duration: 1,
    fly: true
  },

  //#region 视野限制参数
  默认: {
    center: [[-0.00129918392, -0.00106545069]],
    coordType: 0,
    coordTypeZ: 0,
    limitYaw: [0, 359],
    limitPitch: [15, 80],
    limitCoordZ: [-10, 15000],
    limitDistance: [0, 160000],
    radius: 10000000,
  },

  //#endregion
}
