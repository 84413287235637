<!--
    * 页面组件容器（左右两边组件）
    * @date 2023/01/05 11:33:15
    * @author haowei
-->
<template>
  <div id="com-container">
    <!-- 数据分析页面组件 -->
    <component :is="dataAnalysis"></component>
    <!-- 左侧组件 -->
    <component :is="leftType"></component>
    <!-- 右侧组件 -->
    <!-- <span style="color: #fff">{{ rightType }},{{ leftType }}</span> -->
    <component
        @runInHole="rightTypeClick"
        :is="rightType"
        @close="close"
        :rightProps="rightProps"
    ></component>
  </div>
</template>

<script>
import {mapState} from "vuex";
import WangLuoTaiShi from "@/views/dataAnalysis/WangLuoTaiShi";
import ZiYuanTaiShi from "@/views/dataAnalysis/ZiYuanTaiShi";
import SheBeiTaiShi from "@/views/dataAnalysis/SheBeiTaiShi";
import DuiXiangGuanLi from "@/views/objectManager/DuiXiangGuanLi";
import ShiShiGaoJingleft from "@/views/shishigaojing/ShiShiGaoJingleft";
import ShuJuZhongXingSheng from "@/components/right/ShuJuZhongXingSheng";
import SpaceController from "@/core/logic/SpaceController";
import ShuJuZhongXingYuanQu from "@/components/right/ShuJuZhongXingYuanQu";
import JianZhu from "@/components/right/JianZhu";
import JiGuiLixian from "@/components/right/JiGuiLixian";
import JiGuiZu from "@/components/right/JiGuiZu";
import LouCeng from "@/components/right/LouCeng";
import FangJian from "@/components/right/FangJian";
import Store from "@/store";
import FuWuQiBiaoZhun from "@/components/right/FuWuQiBiaoZhun";
import SheBeiGaoJing from "@/components/right/SheBeiGaoJing";
import MenJinSheBei from "@/components/right/MenJinSheBei";
import XiaoFangYanGan from "@/components/right/XiaoFangYanGan";
import SheXiangTouGuDing from "@/components/right/SheXiangTouGuDing";
import SheXiangTouYunTai from "@/components/right/SheXiangTouYunTai";
import SheXiangTouRenLianShiBie from "@/components/right/SheXiangTouRenLianShiBie";
import ShuiBiaoSheBei from "@/components/right/ShuiBiaoSheBei";
import DianBiaoSheBei from "@/components/right/DianBiaoSheBei";
import KongTiao from "@/components/right/KongTiao";
import KeRanQiTiTanCeQi from "@/components/right/KeRanQiTiTanCeQi";
import HuanJingJianCeSheBei from "@/components/right/HuanJingJianCeSheBei";
import AnBaoRenYuan from "@/components/right/AnBaoRenYuan";
import XunJianRenYuan from "@/components/right/XunJianRenYuan";

export default {
  name: "ComContainer",
  components:{
    WangLuoTaiShi,
    ZiYuanTaiShi,
    SheBeiTaiShi,
    DuiXiangGuanLi,
    ShiShiGaoJingleft,
    ShuJuZhongXingSheng,
    ShuJuZhongXingYuanQu,
    JianZhu,
    LouCeng,
    FangJian,
    JiGuiLixian,
    JiGuiZu,
    FuWuQiBiaoZhun,
    SheBeiGaoJing,
    MenJinSheBei,
    XiaoFangYanGan,
    SheXiangTouGuDing,
    SheXiangTouYunTai,
    SheXiangTouRenLianShiBie,
    ShuiBiaoSheBei,
    DianBiaoSheBei,
    KongTiao,
    KeRanQiTiTanCeQi,
    HuanJingJianCeSheBei,
    AnBaoRenYuan,
    XunJianRenYuan,
  },
  data() {
    return {
      dataAnalysis:'',
      rightProps:{}
    }
  },
  computed:{
    ...mapState([
      'leftType','rightType','functionStatus','posture'
    ])
  },
  watch:{
    posture(val){
      if (this.$store.state.functionStatus == '数据分析'){
        this.dataAnalysis = val
      }else{
        this.dataAnalysis = ''
      }
    }
  },
  methods:{
    rightTypeClick(text){
      console.warn('rightTypeClick',text)
      switch (text){
        case '下钻至北京市':
          SpaceController.changeSpaceLevel('北京')
          break
        case '下钻至数据中心':
          SpaceController.changeSpaceLevel('园区')
          break
        case '下钻至F3':
          // 下钻F3相当于点击了模型
          window.modelManager.onModelClick('F3')
          break
        case '下钻至机柜组机房':
          SpaceController.changeSpaceLevel('房间','机柜组机房')
          Store.commit('setRightType','FangJian')
          Store.commit('setObjectInfo',{name:Store.state.subSpaceLevel})
          break
        case '下钻至电池组机房':
          SpaceController.changeSpaceLevel('房间','电池组机房')
          Store.commit('setRightType','FangJian')
          Store.commit('setObjectInfo',{name:Store.state.subSpaceLevel})
          break
        case '下钻至UPS设备机房':
          SpaceController.changeSpaceLevel('房间','UPS设备机房')
          Store.commit('setRightType','FangJian')
          Store.commit('setObjectInfo',{name:Store.state.subSpaceLevel})
          break
        default:break
      }
    },
    close(){
      this.$store.commit('setRightType','')
    }
  },
  mounted() {
    this.dataAnalysis = this.posture
  }
}
</script>

<style scoped>

</style>
