<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse title="基础信息" @changeisReceiveFair="isReceiveFair" :isReceiveFair="jcxxIsShow">
            <Tag
              v-for="(item,index) in shexiangtougudingjcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="ztxx">
          <Collapse
            title="状态信息"
            :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="ztxxIsShow"
          >
            <Tag
              v-for="(item,index) in shexiangtougudingztxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
            <video src="/video/05.mp4" autoplay loop muted></video>
            <img src="@/assets/img/SSGJ/video.png" class="logo" alt />
          </Collapse>
        </div>
        <div class="czzl">
          <Collapse
            title="操作指令"
            :iconUrl="require('@/assets/img/SSGJ/czzlIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="czzlIsShow"
          >
            <TagButton
              v-for="(item,index) in shexiangtougudingczzl"
              :key="index"
              @runInHole="runInHole"
              :text="item"
              :iconUrl="btnimg[index]"
            />
          </Collapse>
        </div>
        <div class="gjxx">
          <Collapse
            title="告警信息"
            :iconUrl="require('@/assets/img/SSGJ/gjxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="gjxxIsShow"
          >
            <Table :CData="shexiangtougudinggjxx" :NavWidth="['368px','240px','0px']" />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea
  },
  data() {
    return {
      shexiangtougudingjcxx: [
        {
          name: '编号',
          value: 'G5-SXT-001'
        },
        {
          name: '所属',
          value: 'G5-DT'
        },
        {
          name: '型号',
          value: '监控摄像头-001'
        },
        {
          name: '视频地址',
          value: 'https://solutiondem...'
        }
      ],
      shexiangtougudingztxx: [
        {
          name: '设备状态',
          value: '在线'
        },
        {
          name: '视频画面',
          value: ''
        }
      ],
      shexiangtougudingczzl: ['工单派发', '创建会议'],
      shexiangtougudinggjxx: [
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '烟雾告警' },
        { time: '2022-07-11 12:00:00', content: '非法捕捞' },
        { time: '2022-07-11 12:00:00', content: '烟雾告警' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' },
        { time: '2022-07-11 12:00:00', content: '人群聚集' }
      ],
      btnimg: [
        require('@/assets/img/SSGJ/gdpfIcon.png'),
        require('@/assets/img/SSGJ/cjhyIcon.png')
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    }
  },
  computed: {
    ...mapState(['functionStatus', 'objectInfo'])
  },
  mounted() {
    this.shexiangtougudingjcxx[0].value = this.objectInfo.name
  },
  watch: {
    objectInfo: {
      handler(val) {
        this.shexiangtougudingjcxx[0].value = val.name
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  overflow-y: auto;
  overflow-x: hidden;
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
          }
        }
      }
    }
  }
  .ztxx {
    position: relative;
    .logo {
      position: absolute;
      right: 8%;
      top: 38%;
    }
    video {
      width: 586px;
      height: 333px;
      object-fit: fill;
    }
    /deep/ .Collapse {
      .Collapse_main {
        margin-bottom: 0px;
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0px;
  border-radius: 0px;
  background: rgba(17, 63, 89, 0.65);
}
::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 0px;
  background: rgba(255, 255, 255, 0.15);
  min-height: 00px;
}
</style>
