export default [
    {
        id: '房间-固定摄像头',
        name: '房间-固定摄像头',
        legends: [{ name: 'default', color: '#ffff', 'iconName': 'shexiangtouguding' }],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '固定摄像头',
            jsonPath: './data/layers/房间/房间-固定摄像头.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-云台摄像头',
        name: '房间-云台摄像头',
        coordType: 0,
        coordTypeZ: 0,
        visible: true,
        legends: [
            {
                name: 'default',
                iconName: 'shexiangtouyuntai',
                color: '#ff0000'
            }
        ],
        data: [],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '云台摄像头',
            jsonPath: './data/layers/房间/房间-云台摄像头.json',
            isClick: true,
            status: 'none', // 显示状态 none show hide
            rawData: [], // 原始数据
            jsonData: {} // 添加时候的数据
        }
    },
    {
        id: '房间-人脸识别摄像头',
        name: '房间-人脸识别摄像头',
        legends: [{ name: 'default', color: '#ffff', 'iconName': 'shexiangtourenlianshibie' }],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '人脸识别摄像头',
            jsonPath: './data/layers/房间/房间-人脸识别摄像头.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-可燃气体探测器',
        name: '房间-可燃气体探测器',
        legends: [{name: 'default', color: '#ffff', 'iconName': 'keranqititanceqi'}],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '可燃气体探测器',
            jsonPath: './data/layers/房间/房间-可燃气体探测器.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-告警-机柜',
        name: '房间-告警-机柜',
        coordType: 0,
        coordTypeZ: 0,
        visible: true,
        legends: [
            {
                name: '重要告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#ff0000',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '紧急告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#ff0000',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '次要告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#A69D00',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '提示告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#2C71D6',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }],
        layerConfig: {
            layerType: 'eventLayer',
            scatterType: '告警',
            jsonPath: './data/layers/房间/房间-告警-机柜.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-告警-能耗',
        name: '房间-告警-能耗',
        coordType: 0,
        coordTypeZ: 0,
        visible: true,
        legends: [
            {
                name: '重要告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#ff0000',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '紧急告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#ff0000',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '次要告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#A69D00',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '提示告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#2C71D6',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }],
        layerConfig: {
            layerType: 'eventLayer',
            scatterType: '告警',
            jsonPath: './data/layers/房间/房间-告警-能耗.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-告警-设备',
        name: '房间-告警-设备',
        coordType: 0,
        coordTypeZ: 0,
        visible: true,
        legends: [
            {
                name: '重要告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#ff0000',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '紧急告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#ff0000',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '次要告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#A69D00',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }, {
                name: '提示告警',
                icon: 'gaojing',
                type: 'wave',
                color: '#2C71D6',
                fillArea: 'type01',
                speed: 1,
                radius: 2.5
            }],
        layerConfig: {
            layerType: 'eventLayer',
            scatterType: '告警',
            jsonPath: './data/layers/房间/房间-告警-设备.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-水表',
        name: '房间-水表',
        legends: [{name: 'default', color: '#ffff', 'iconName': 'shuibiaoshebei'}],
        layerConfig: {
            layerType: 'tip-landmarkLayer',
            scatterType: '房间-水表',
            panelType: '水表设备',
            jsonPath: './data/layers/房间/房间-水表.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    }, {
        id: '房间-消防烟感',
        name: '房间-消防烟感',
        legends: [{name: 'default', color: '#ffff', 'iconName': 'xiaofangyangan'}],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '消防烟感',
            jsonPath: './data/layers/房间/房间-消防烟感.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-温度云图',
        name: '房间-温度云图',
        coordType: 0,
        coordTypeZ: 0,
        coordZ: 9.98,
        type: 'default',
        'alpha': 1,
        'colorMax': '#FF6000',
        'colorMin': '#009acf',
        'valueMax': 50,
        'valueMin': 0,
        radius: 2,
        visible: true,
        'pointDataId': '',
        data: [],
        layerConfig: {
            layerType: 'heatMapLayer',
            scatterType: '温度云图',
            jsonPath: './data/layers/楼层/楼层-温度云图.json',
            isClick: false,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-燃气表',
        name: '房间-燃气表',
        legends: [
            {
                name: 'default',
                color: '#ffff',
                iconName: 'ranqibiaoshebei'
            }
        ],
        layerConfig: {
            layerType: 'tip-landmarkLayer',
            panelType: '燃气表设备',
            scatterType: '燃气表设备', // 点位汇总中数据的名称
            jsonPath: './data/layers/房间/房间-燃气表.json',
            isClick: true,
            status: 'none', // 显示状态 none show hide
            rawData: [], // 原始数据
            jsonData: {} // 添加时候的数据
        }
    },
    {
        id: '房间-环境监测设备',
        name: '房间-环境监测设备',
        legends: [{ name: 'default', color: '#ffff', 'iconName': 'huanjingjianceshebei' }],
        layerConfig: {
            layerType: 'tip-landmarkLayer',
            scatterType: '环境监测设备',
            pointType: 'landmarkLayer',
            panelType: '环境监测设备',
            jsonPath: './data/layers/房间/房间-环境监测设备.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-电表',
        name: '房间-电表',
        legends: [
            {
                name: 'default',
                color: '#ffff',
                iconName: 'dianbiaoshebei'
            }
        ],
        layerConfig: {
            layerType: 'tip-landmarkLayer',
            panelType: '电表设备',
            scatterType: '电表设备', // 点位汇总中数据的名称
            jsonPath: './data/layers/房间/房间-电表.json',
            isClick: true,
            status: 'none', // 显示状态 none show hide
            rawData: [], // 原始数据
            jsonData: {} // 添加时候的数据
        }
    },
    {
        id: '房间-空调',
        name: '房间-空调',
        legends: [{ name: 'default', color: '#ffff', 'iconName': 'kongtiao' }],
        layerConfig: {
            layerType: 'tip-landmarkLayer',
            scatterType: '空调',
            pointType: 'landmarkLayer',
            panelType: '空调',
            jsonPath: './data/layers/房间/房间-空调.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    },
    {
        id: '房间-配电柜',
        name: '房间-配电柜',
        legends: [
            {
                name: 'default',
                color: '#ffff',
                iconName: 'ranqibiaoshebei'
            }
        ],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '配电柜', // 点位汇总中数据的名称
            jsonPath: './data/layers/房间/房间-配电柜.json',
            isClick: true,
            status: 'none', // 显示状态 none show hide
            rawData: [], // 原始数据
            jsonData: {} // 添加时候的数据
        }
    },
    {
        id: '房间-门禁设备',
        name: '房间-门禁设备',
        legends: [{ name: 'default', color: '#ffff', 'iconName': 'menjinshijian' }],
        layerConfig: {
            layerType: 'landmarkLayer',
            scatterType: '门禁设备',
            jsonPath: './data/layers/房间/房间-门禁设备.json',
            isClick: true,
            status: 'none',
            rawData: [],
            jsonData: {}
        }
    }
]
