<template>
  <div class="shujuzhongxinSheng">
    <BaseDrawer direction="rtl" :ShowClose="true">
      <div class="wrap">
        <Title />
        <div class="jcxx">
          <Collapse
            title="基础信息"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="jcxxIsShow"
          >
            <Tag
              v-for="(item, index) in HJJCSBjcxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="ztxx">
          <Collapse
            title="状态信息"
            :iconUrl="require('@/assets/img/SSGJ/ztxxIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="ztxxIsShow"
          >
            <Tag
              v-for="(item, index) in HJJCSBztxx"
              :key="index"
              :name="item.name"
              :value="item.value"
            />
          </Collapse>
        </div>
        <div class="lssj">
          <Collapse
            title="历史数据"
            :iconUrl="require('@/assets/img/SSGJ/lssjIcon.png')"
            @changeisReceiveFair="isReceiveFair"
            :isReceiveFair="lssjIsShow"
            :isywxt="true"
          >
            <div class="tabList">
              <div
                @click="TabLine(index)"
                class="li"
                :class="index == i ? 'ac' : ''"
                v-for="(item, index) in ['温度', '湿度']"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <Tag :name="'近7日数据监测分析'" :value="''" />
            <ChartLineArea
              :smooth="true"
              v-show="i == 0"
              class="charts"
              :rowData="JservenSJJCFXWD"
              left="3%"
              right="5%"
              unit="℃"
              height="354px"
              :legthWZ="['新风', '回风']"
              :barColor="['#C4EA40', '#C4EA4000']"
            />

            <ChartLineArea
              :smooth="true"
              v-show="i == 1"
              class="charts"
              :rowData="JservenSJJCFXSD"
              left="3%"
              right="5%"
              unit="%"
              height="354px"
              :legthWZ="['新风', '回风']"
              :barColor="['#CCBE5E', '#CCBE5E00']"
            />
          </Collapse>
        </div>
      </div>
    </BaseDrawer>
  </div>
</template>

<script>
import Collapse from '@/components/common/communal/Collapse'
import Pagination from '@/components/common/communal/Pagination'
import Table from '@/components/common/communal/Table'
import Tag from '@/components/common/communal/Tag'
import TagButton from '@/components/common/communal/TagButton'
import BaseDrawer from '@/components/common/BaseDrawer'
import Title from '@/components/common/communal/Title'
import ChartLineArea from '@/components/charts/ChartLineArea'
import LineCharts from '@/components/charts/LineCharts+xuxian'
import { mapState } from 'vuex'
export default {
  components: {
    Collapse,
    Pagination,
    Table,
    Tag,
    TagButton,
    Title,
    BaseDrawer,
    ChartLineArea,
    LineCharts,
  },
  data() {
    return {
      i: 0,
      HJJCSBjcxx: [
        {
          name: '编号',
          value: 'G5-F8-HJJCSB-001',
        },
        {
          name: '所属',
          value: 'G5-F8-HJJCSB',
        },
        {
          name: '型号',
          value: 'SZ-HJJCSB-001',
        },
      ],
      HJJCSBztxx: [
        {
          name: '设备状态',
          value: '在线',
        },
        {
          name: '当前温度',
          value: '24℃',
        },
        {
          name: '当前湿度',
          value: '45%',
        },
      ],
      jcxxIsShow: true,
      ztxxIsShow: true,
      czzlIsShow: true,
      ckllIsShow: true,
      gjxxIsShow: true,
      lssjIsShow: true,
      JservenSJJCFXWD: [
        {
          name: '07-01',
          value: '30',
        },
        {
          name: '07-02',
          value: '20',
        },
        {
          name: '07-03',
          value: '20',
        },
        {
          name: '07-04',
          value: '30',
        },
        {
          name: '07-05',
          value: '20',
        },
        {
          name: '07-06',
          value: '30',
        },
        {
          name: '07-07',
          value: '20',
        },
      ],
      JservenSJJCFXSD: [
        {
          name: '07-01',
          value: '75',
        },
        {
          name: '07-02',
          value: '70',
        },
        {
          name: '07-03',
          value: '75',
        },
        {
          name: '07-04',
          value: '70',
        },
        {
          name: '07-05',
          value: '70',
        },
        {
          name: '07-06',
          value: '75',
        },
        {
          name: '07-07',
          value: '70',
        },
      ],
    }
  },
  methods: {
    runInHole(p) {
      this.$emit('runInHole', p)
    },
    isReceiveFair(p) {
      switch (p) {
        case '基础信息':
          this.jcxxIsShow = !this.jcxxIsShow
          break
        case '状态信息':
          this.ztxxIsShow = !this.ztxxIsShow
          break
        case '操作指令':
          this.czzlIsShow = !this.czzlIsShow
          break
        case '查看链路':
          this.ckllIsShow = !this.ckllIsShow
          break
        case '告警信息':
          this.gjxxIsShow = !this.gjxxIsShow
          break
        case '历史数据':
          this.lssjIsShow = !this.lssjIsShow
          break
      }
    },
    TabLine(i) {
      this.i = i
    },
  },
  computed: {
    ...mapState(['objectInfo']),
  },
  mounted() {
    this.HJJCSBjcxx[0].value = this.objectInfo.name
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 680px;
  height: 1720px;
  background: url('@/assets/img/WLTS/left.png') no-repeat;
  overflow-y: auto;
  overflow-x: hidden;
  .czzl {
    /deep/ .Collapse {
      .Collapse_main {
        .TagButton:nth-child(1) {
          .TagButton_image {
          }
        }
      }
    }
  }
  .ztxx {
    /deep/ .Collapse {
      .Collapse_main {
        .Tag:nth-child(1) {
          .Tag_right {
            color: #00ffbf;
          }
        }
      }
    }
  }
}

.lssj {
  position: relative;
  .tabList {
    position: absolute;
    right: 9%;
    top: 19%;
    display: flex;
    justify-content: space-between;
    width: 202px;
    .li {
      width: 100px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 24px;
      color: #bfebff;
      font-family: '微软雅黑';
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    }
    .ac {
      border-bottom: 4px solid #77c7fe;
      color: #77c5fe;
    }
  }
}
::-webkit-scrollbar {
  width: 0px;
  border-radius: 0px;
  background: rgba(17, 63, 89, 0.65);
}
::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 0px;
  background: rgba(255, 255, 255, 0.15);
  min-height: 00px;
}
</style>
