import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store";
import VueRouter from "vue-router";
import ElementUI from "element-ui";
import echarts from 'echarts';

import 'element-ui/lib/theme-chalk/index.css';

import './assets/style/base.css';
import './assets/style/animate.css';

import Validate from "@/utils/validate";
import api from "@/api";
import instance from "@/api/instance";
import init from "@/init";

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRouter)

// 验证工具全局挂载
Vue.prototype.$valid = Validate;
// 请求工具全局挂载
Vue.prototype.$api = api;
// 请求方法列表全局挂载
Vue.prototype.$req = instance;
// 图表全局挂载
Vue.prototype.$echarts = echarts;
// 全局初始化
init.init(Vue).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})


